import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FILTER_SECTION, FILTER_TYPE, HIDDEN_FEATURES, MODULE_TYPES} from '../../../../constants';
import {ButtonGroup, CaretIcon} from 'web-components';
import {
	AdvancedText,
	DropdownContainer,
	DropdownGrid,
	FilterTypeContainer,
	ShowAdvancedClickable,
	ShowAdvancedContainer,
	ShowMoreToggle,
	Title,
	TitleAndAdvancedContainer,
	TitleWithMargin,
} from './filter.styled';

import LabelDropdown from '../../../../components/dropdowns/label-dropdown/Label_dropdown';
import RoleDropdown from '../dropdowns/Role_dropdown';
import BillableDropdown from '../../../../components/dropdowns/billable-dropdown/Billable_dropdown';
import PersonDropdown from '../dropdowns/Person_dropdown';
import ProjectPersonDropdown from '../../../../components/dropdowns/project-person-dropdown/Project_person_dropdown';
import ClientDropdown from '../../../../components/dropdowns/client-dropdown/Client_dropdown';
import PhasesDropdown from '../../../../components/dropdowns/phases-dropdown/Phases_dropdown';
import SprintDropdown from '../../../../components/dropdowns/sprint-dropdown/Sprint-dropdown';
import TeamDropdown from '../../../../components/dropdowns/team-dropdown/Team_dropdown';
import ProjectDropdown from '../dropdowns/project-dropdown-b/Project_dropdown';
import ProjectStatusColumnDropdown from '../../../../components/dropdowns/project-status-column-dropdown/Project_status_column_dropdown';
import ProjectContactDropdown from '../../../../components/dropdowns/project-contact-dropdown/Project_contact_dropdown';
import RateCardDropdown from '../../../../components/dropdowns/rate-card-dropdown/Rate_card_dropdown';
import DepartmentDropdown from '../../../../components/dropdowns/department-dropdown/DepartmentDropdown';
import IdleTimeDropdown from '../../../../components/dropdowns/idle-time-dropdown/IdleTimeDropdown';

import SingleTextDropdown from '../../../../components/dropdowns/single-text-dropdown/Single_text_dropdown';
import IconDropdown from '../../../../components/dropdowns/icon-dropdown/Icon-dropdown';
import CircleTextDropdown from '../../../../components/dropdowns/circle-text-dropdown/Circle_text_dropdown';

import Util from '../../util/util';
import {FILTER_OPERATOR, filtersToObject, isFilterOperatorEnabled} from './filter_logic';
import {getTypeLabel, sortPrimaryFiltersFirst} from './filter_util';
import InvoicedDropdown from '../../../../components/dropdowns/invoiced-dropdown/Invoiced_dropdown';
import SkillDropdown from '../dropdowns/skill-dropdown/SkillDropdown';
import {getLabelFilterByFilterSectionAndType} from '../../util/LabelUtil';
import SwitchLine from '../dropdowns/skill-dropdown/SwitchLine';
import {useIntl} from 'react-intl';
import SkillLevelDropdown from '../../../../components/dropdowns/skill-level-dropdown/SkillLevelDropdown';
import {hasModule} from '../../util/ModuleUtil';
import ProgramUtil from '../../util/ProgramUtil';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../util/FeatureUtil';

import InvoicingIntegrationIcon from '../../../../forecast-app/admin-tab/finance-page/ts/InvocingIntegrationIcon';
import BillToDropdown from '../../../../components/dropdowns/bill-to-dropdown/BillToDropdown';
import AllPhasesDropdown from '../../../../components/dropdowns/all-phases-dropdown/AllPhases_dropdown';

export const FILTER_SECTIONS = {
	PROJECTS: 'project',
	PEOPLE: 'person',
	TASKS: 'task',
	TIMEREGS: 'timeRegs',
	INVOICES: 'invoices',
	EXPENSES: 'expenses',
	SKILLS: 'skills',
	LABELS: 'labels',
	PLACEHOLDERS: 'placeholder',
	RESOURCES: 'resources',
};

const OPERATOR_REQUIRE_ALL = 'requireAll';
const OPERATOR_EXCLUDE = 'exclude';

const EXCLUDED_FILTER_TYPE_OPERATOR_OPTIONS = {
	[FILTER_SECTIONS.TASKS]: {
		[OPERATOR_EXCLUDE]: [
			FILTER_TYPE.DEPENDENCIES,
			FILTER_TYPE.TASK_LEVEL,
			FILTER_TYPE.APPROVAL_STATUS,
			FILTER_TYPE.APPROUVED,
			FILTER_TYPE.RECENT_TASK_ACTIVITY,
		],
	},
	[FILTER_SECTIONS.TIMEREGS]: {
		[OPERATOR_EXCLUDE]: [FILTER_TYPE.ROLE, FILTER_TYPE.LABEL_TIME_REG, FILTER_TYPE.APPROUVED],
	},
	[FILTER_SECTIONS.EXPENSES]: {
		[OPERATOR_EXCLUDE]: [FILTER_TYPE.APPROUVED],
	},
};

const FILTER_TYPE_OPERATOR_OPTIONS = {
	[FILTER_SECTIONS.PEOPLE]: {
		[OPERATOR_REQUIRE_ALL]: [
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.SKILL,
			FILTER_TYPE.TEAM,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.INTERNAL_TIME,
			FILTER_TYPE.TIME_OFF,
		],
	},
	[FILTER_SECTIONS.PROJECTS]: {
		[OPERATOR_REQUIRE_ALL]: [FILTER_TYPE.PERSON, FILTER_TYPE.LABEL],
	},
	[FILTER_SECTIONS.TASKS]: {
		[OPERATOR_REQUIRE_ALL]: [
			FILTER_TYPE.PROJECT_FOLLOWER,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.INDICATOR,
			FILTER_TYPE.INDICATOR_FILTERED,
			FILTER_TYPE.PERSON,
			FILTER_TYPE.PROJECT_PERSON,
			FILTER_TYPE.TEAM,
		],
	},
	[FILTER_SECTIONS.PLACEHOLDERS]: {
		[OPERATOR_REQUIRE_ALL]: [FILTER_TYPE.SKILL, FILTER_TYPE.TEAM],
	},
	[FILTER_SECTIONS.RESOURCES]: {
		[OPERATOR_REQUIRE_ALL]: [FILTER_TYPE.SKILL, FILTER_TYPE.PROJECT, FILTER_TYPE.TEAM],
	},
	[FILTER_SECTIONS.TIMEREGS]: {
		[OPERATOR_REQUIRE_ALL]: [FILTER_TYPE.TEAM],
	},
};

const FILTER_TYPE_OPERATOR_OPTIONS_OVERRIDE = {
	[FILTER_SECTION.UTILIZATION_REPORT]: {
		[FILTER_SECTIONS.PEOPLE]: {
			[OPERATOR_REQUIRE_ALL]: [FILTER_TYPE.SKILL],
		},
	},
};
const operatorAllowed = (selectedSection, filterType, operatorOption) =>
	FILTER_TYPE_OPERATOR_OPTIONS[selectedSection]?.[operatorOption]?.includes(filterType);

const operatorNotAllowed = (selectedSection, filterType, operatorOption) =>
	EXCLUDED_FILTER_TYPE_OPERATOR_OPTIONS[selectedSection]?.[operatorOption]?.includes(filterType);

const overrideOperatorAllowed = (filterSection, selectedSection, filterType, operatorOption) =>
	FILTER_TYPE_OPERATOR_OPTIONS_OVERRIDE[filterSection]?.[selectedSection]?.[operatorOption]?.includes(filterType);

function getPhaseDropdownProps(appliedFiltersObject, projects, phases, standardProps, intl) {
	const taskFilterAppliedProjectFilter = appliedFiltersObject?.task?.project;
	const filterBySelectedProject = taskFilterAppliedProjectFilter?.length > 0;
	const filteredProjects = filterBySelectedProject
		? projects.filter(project => taskFilterAppliedProjectFilter.includes(project.node.id))
		: projects;

	const filteredPhases = filterBySelectedProject
		? phases.filter(phase => taskFilterAppliedProjectFilter.includes(phase.node.projectId))
		: phases;

	return {
		...standardProps,
		projects: filteredProjects,
		phases: filteredPhases,
		optionsName: intl.formatMessage({id: 'common.phases'}),
		selectedGroupName: intl.formatMessage({id: 'common.applied'}),
	};
}

export const DropdownSection = ({
	operatorOptions,
	appliedFilterOperators,
	appliedFilters,
	primaryFilters,
	viewer,
	intl,
	onAddFilter,
	onRemoveFilter,
	projectFilters,
	peopleFilters,
	taskFilters,
	timeRegFilters,
	invoiceFilters,
	expenseFilters,
	skillFilters,
	labelFilters,
	placeholderFilters,
	resourceFilters,
	selectedSection,
	setSelectedSection,
	filterSection,
	handleFilterModeChange,
	enableFilterMode,
}) => {
	const {formatMessage} = useIntl();
	const appliedFiltersObject = filtersToObject(appliedFilters);
	const advancedSections = [];
	if (projectFilters && projectFilters.length > 0)
		advancedSections.push({
			value: 'project',
			text: intl.formatMessage({id: 'common.projects'}),
			filters: projectFilters,
		});

	if (peopleFilters && peopleFilters.length > 0)
		advancedSections.push({
			value: 'person',
			text: intl.formatMessage({id: 'common.people'}),
			filters: projectFilters,
		});

	if (taskFilters && taskFilters.length > 0)
		advancedSections.push({
			value: 'task',
			text: intl.formatMessage({id: 'common.tasks'}),
			filters: taskFilters,
		});

	if (timeRegFilters && timeRegFilters.length > 0) {
		advancedSections.push({
			value: 'timeRegs',
			text: 'Time Registrations',
			filters: timeRegFilters,
		});
	}

	if (invoiceFilters && invoiceFilters.length > 0) {
		advancedSections.push({
			value: 'invoices',
			text: intl.formatMessage({id: 'common.invoices'}),
			filters: invoiceFilters,
		});
	}

	if (expenseFilters && expenseFilters.length > 0) {
		advancedSections.push({
			value: 'expenses',
			text: intl.formatMessage({id: 'common.expenses'}),
			filters: expenseFilters,
		});
	}

	if (skillFilters?.length > 0) {
		advancedSections.push({
			value: 'skills',
			text: intl.formatMessage({id: 'common.skills'}),
			filters: skillFilters,
		});
	}

	if (labelFilters?.length > 0) {
		advancedSections.push({
			value: 'labels',
			text: intl.formatMessage({id: 'common.labels'}),
			filters: labelFilters,
		});
	}

	if (placeholderFilters?.length > 0) {
		advancedSections.push({
			value: 'placeholder',
			text: intl.formatMessage({id: 'common.placeholders'}),
			filters: placeholderFilters,
		});
	}

	if (resourceFilters?.length > 0) {
		advancedSections.push({
			value: 'resources',
			text: intl.formatMessage({id: 'common.resources'}),
			filters: resourceFilters,
		});
	}

	const [showAdvanced, setShowAdvanced] = useState(false);
	const [showMoreFilters, setShowMoreFilters] = useState(false);

	const handleDropdownAdd = (filterType, ids, updatedSelection = undefined) => {
		onAddFilter(selectedSection, filterType, ids, updatedSelection);
	};

	const handleDropdownRemove = (filterType, ids, updatedSelection = undefined) => {
		onRemoveFilter(selectedSection, filterType, ids, updatedSelection);
	};
	const handleSectionClick = value => {
		setSelectedSection(value);
	};

	const handleAdvancedClick = () => {
		setShowAdvanced(!showAdvanced);
	};

	const handleShowMoreToggle = () => {
		trackEvent('Show More Filters', 'Toggled', {hasExpanded: !showMoreFilters});
		setShowMoreFilters(!showMoreFilters);
	};

	const removeIrrelevantFilters = filters => {
		return filters.filter(filter => {
			if (
				(filter === FILTER_TYPE.CLIENT || filter === FILTER_TYPE.CLIENT_GUEST_USERS) &&
				Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
			) {
				return false;
			}
			if (filter === FILTER_TYPE.PROJECT_TYPE && Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE)) {
				return false;
			}
			if (filter === FILTER_TYPE.PRIORITY_LEVEL && !viewer.company.priorityLevels.edges.length > 0) {
				return false;
			}
			if (filter === FILTER_TYPE.PROGRAM && !viewer.company.programs.edges.length > 0) {
				return false;
			}
			if (filter === FILTER_TYPE.PROJECT_ALLOCATIONS && !hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)) {
				return false;
			}
			return true;
		});
	};

	const getFilters = () => {
		let filters = null;
		switch (selectedSection) {
			case FILTER_SECTIONS.PROJECTS:
				filters = projectFilters;
				break;
			case FILTER_SECTIONS.PEOPLE:
				filters = peopleFilters;
				break;
			case FILTER_SECTIONS.TASKS:
				filters = taskFilters;
				break;
			case FILTER_SECTIONS.TIMEREGS:
				filters = timeRegFilters;
				break;
			case FILTER_SECTIONS.INVOICES:
				filters = invoiceFilters;
				break;
			case FILTER_SECTIONS.EXPENSES:
				filters = expenseFilters;
				break;
			case FILTER_SECTIONS.SKILLS:
				filters = skillFilters;
				break;
			case FILTER_SECTIONS.LABELS:
				filters = labelFilters;
				break;
			case FILTER_SECTIONS.PLACEHOLDERS:
				filters = placeholderFilters;
				break;
			case FILTER_SECTIONS.RESOURCES:
				filters = resourceFilters;
				break;
			default:
				filters = [];
		}
		filters = removeIrrelevantFilters(filters);

		return filters;
	};

	const getSelectedForDropdown = (section, filterType) => {
		return appliedFiltersObject[section] && appliedFiltersObject[section][filterType]
			? appliedFiltersObject[section][filterType]
			: [];
	};

	const filters = getFilters();
	const currentPrimaryFilters = primaryFilters?.[selectedSection];
	const primaryFiltersToShow = currentPrimaryFilters
		? filters.filter(filter => currentPrimaryFilters.includes(filter))
		: filters;
	const showShowMore = filters.length > primaryFiltersToShow.length;
	const filtersToShow = showMoreFilters ? filters : primaryFiltersToShow;
	sortPrimaryFiltersFirst(filtersToShow, currentPrimaryFilters);

	const getDropdowns = () => {
		const numberOfFilters = filtersToShow.length;
		const filterDropdowns = [];

		// Using forloop instead of forEach so we can break out on i < than filter length
		for (let i = 0; i < numberOfFilters; i++) {
			let alignment = '';
			const position = (i + 1) % 3;
			switch (position) {
				case 1:
					alignment = 'left';
					break;
				case 2:
					alignment = 'center';
					break;
				case 0:
					alignment = 'right';
					break;
				default:
					alignment = '';
			}
			const width = 477;

			const filter = filtersToShow[i];

			const showRequireAllToggle =
				(operatorOptions.allowRequireAll && operatorAllowed(selectedSection, filter, OPERATOR_REQUIRE_ALL)) ||
				overrideOperatorAllowed(filterSection, selectedSection, filter, OPERATOR_REQUIRE_ALL);
			const showExcludeToggle =
				operatorOptions.allowExclude && !operatorNotAllowed(selectedSection, filter, OPERATOR_EXCLUDE);

			const getHeaderLines = () => {
				return (
					<div>
						{!!showRequireAllToggle && (
							<SwitchLine
								name={formatMessage({id: 'common.filter.require_all'})}
								onToggle={checked =>
									handleFilterModeChange(selectedSection, filter, FILTER_OPERATOR.REQUIRE_ALL, checked)
								}
								initToggleState={isFilterOperatorEnabled(
									appliedFilterOperators,
									selectedSection,
									filter,
									FILTER_OPERATOR.REQUIRE_ALL
								)}
							/>
						)}
						{!!showExcludeToggle && (
							<SwitchLine
								name={formatMessage({id: 'common.filter.exclude'})}
								onToggle={checked =>
									handleFilterModeChange(selectedSection, filter, FILTER_OPERATOR.EXCLUDE, checked)
								}
								initToggleState={isFilterOperatorEnabled(
									appliedFilterOperators,
									selectedSection,
									filter,
									FILTER_OPERATOR.EXCLUDE
								)}
							/>
						)}
					</div>
				);
			};

			const standardProps = {
				selectedItems: getSelectedForDropdown(selectedSection, filter),
				onRemove: ids => handleDropdownRemove(filter, ids),
				onSelect: ids => handleDropdownAdd(filter, ids),
				dropdownAlignment: alignment,
				key: i,
				width,
				userpilot: 'filter-dropdown',
				cy: `filter-dropdown-${filter}`,
				name: getTypeLabel(filter, intl.formatMessage, selectedSection),
				isMultiSelect: true,
				emphasizeEmptyState: true,
			};

			standardProps.headerLines = getHeaderLines();

			switch (filter) {
				case FILTER_TYPE.LABEL:
				case FILTER_TYPE.LABEL_TIME_REG:
				case FILTER_TYPE.TASK_PROJECT_LABEL: {
					const labels = viewer.company.labels.edges
						.map(edge => edge.node)
						.filter(getLabelFilterByFilterSectionAndType(selectedSection, filter));
					const props = {
						...standardProps,
						labels,
						optionsName: intl.formatMessage({id: 'common.labels'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					filterDropdowns.push(
						<LabelDropdown
							{...props}
							onSelect={id => standardProps.onSelect([id])}
							onRemove={id => standardProps.onRemove([id])}
						/>
					);
					break;
				}
				case FILTER_TYPE.SKILL_ROLE:
				case FILTER_TYPE.ROLE: {
					const props = {
						...standardProps,
						roles: viewer.company.roles.edges,
						optionsName: intl.formatMessage({id: 'settings.roles'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<RoleDropdown isMultiSelect {...props} />);
					break;
				}
				case FILTER_TYPE.BILLABLE: {
					const props = {
						...standardProps,
						options: Util.getBillableFilters(),
						optionsName: intl.formatMessage({id: 'common.billable'}),
						name: intl.formatMessage({id: 'common.billable'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					filterDropdowns.push(<BillableDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.INVOICED: {
					const props = {
						...standardProps,
						options: Util.getInvoicedFilters(intl),
						optionsName: intl.formatMessage({id: 'project_budget.invoiced'}),
						name: intl.formatMessage({id: 'project_budget.invoiced'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					filterDropdowns.push(<InvoicedDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PERSON: {
					const props = {
						...standardProps,
						persons: viewer.company.allPersons.edges,
						optionsName: intl.formatMessage({id: 'common.persons'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					const filterClients = selectedSection !== FILTER_SECTIONS.TASKS;

					filterDropdowns.push(<PersonDropdown {...props} filterClients={filterClients} />);
					break;
				}
				case FILTER_TYPE.TEAM_MEMBER: {
					const props = {
						...standardProps,
						persons: viewer.company.allPersons.edges,
						optionsName: intl.formatMessage({id: 'common.people'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					const filterClients = selectedSection !== FILTER_SECTIONS.TASKS;

					filterDropdowns.push(<PersonDropdown {...props} filterClients={filterClients} />);
					break;
				}
				case FILTER_TYPE.PROJECT_OWNER_TIME_REG_REPORT:
				case FILTER_TYPE.OWNER: {
					const props = {
						...standardProps,
						persons: viewer.company.allPersons.edges,
						optionsName: intl.formatMessage({id: 'common.owners'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					const filterClients = selectedSection !== FILTER_SECTIONS.TASKS;

					filterDropdowns.push(<PersonDropdown {...props} filterClients={filterClients} />);
					break;
				}
				case FILTER_TYPE.FOLLOWER: {
					const props = {
						...standardProps,
						persons: viewer.company.allPersons.edges,
						optionsName: intl.formatMessage({id: 'common.followers'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					const filterClients = selectedSection !== FILTER_SECTIONS.TASKS;

					filterDropdowns.push(<PersonDropdown {...props} filterClients={filterClients} />);
					break;
				}
				case FILTER_TYPE.PROJECT_PERSON: {
					let persons = [];
					// Check if its project or projectGroup, and get persons.
					if (viewer && viewer.project && viewer.project.projectPersons && viewer.project.projectPersons.edges) {
						persons = viewer.project.projectPersons.edges;
					} else if (
						viewer &&
						viewer.projectGroup &&
						viewer.projectGroup.projects &&
						viewer.projectGroup.projects.edges &&
						viewer.projectGroup.projects.edges.length !== 0
					) {
						persons = viewer.projectGroup.projects.edges[0].node.projectPersons.edges;
					}
					const props = {
						...standardProps,
						persons,
						optionsName: intl.formatMessage({id: 'common.persons'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<ProjectPersonDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.SKILL_PERSON: {
					const props = {
						...standardProps,
						persons: viewer.company.allPersons.edges,
						optionsName: intl.formatMessage({id: 'common.persons'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<PersonDropdown includeDeactivatedPersons {...props} />);
					break;
				}
				case FILTER_TYPE.SKILL_RESOURCE_TYPE: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getUserResourcesFilterOptions(intl)}
							optionsName={intl.formatMessage({id: 'common.resource_type'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);

					break;
				}
				case FILTER_TYPE.SKILL_ASSIGNED: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getSkillAssignedFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.assigned'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.SKILL_LEVEL: {
					const props = {
						...standardProps,
						company: viewer.company,
						optionsName: intl.formatMessage({id: 'filter_type.skill_level'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<SkillLevelDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PROJECT_STAGE: {
					const hasOpportunityAccess = Util.hasOpportunityAccess(viewer.company.modules);
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getProjectStageForDropdown(intl, hasOpportunityAccess)}
							optionsName={intl.formatMessage({id: 'settings.project-stage'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
							cy={'project-stage-dropdown'}
						/>
					);
					break;
				}
				case FILTER_TYPE.ALL_PROJECT_PHASES: {
					// Get all phases and projects, that the user has access to
					const projects = viewer.projects.edges;
					const phases = viewer.phases.edges;
					const props = getPhaseDropdownProps(appliedFiltersObject, projects, phases, standardProps, intl);
					filterDropdowns.push(<AllPhasesDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PROJECT_PHASE: {
					let phases = [];
					// Check if its project or projectGroup, and get phases of all the inner projects.
					if (viewer && viewer.project && viewer.project.phases && viewer.project.phases.edges) {
						phases = viewer.project.phases.edges;
					} else if (
						viewer &&
						viewer.projectGroup &&
						viewer.projectGroup.projects &&
						viewer.projectGroup.projects.edges &&
						viewer.projectGroup.projects.edges.length !== 0
					) {
						phases = viewer.projectGroup.projects.edges.reduce(
							(acc, project) => acc.concat(project.node.phases ? project.node.phases.edges : []),
							[]
						);
					}
					const props = {
						...standardProps,
						phases,
						optionsName: intl.formatMessage({id: 'common.phases'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<PhasesDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PROJECT_SPRINT: {
					let sprints = [];
					if (viewer && viewer.project && viewer.project.sprints && viewer.project.sprints.edges) {
						sprints = viewer.project.sprints.edges;
					} else if (
						viewer &&
						viewer.projectGroup &&
						viewer.projectGroup.projects &&
						viewer.projectGroup.projects.edges &&
						viewer.projectGroup.projects.edges.length !== 0
					) {
						sprints = viewer.projectGroup.projects.edges[0].node.sprints.edges;
					}

					const props = {
						...standardProps,
						sprints,
						isProjectGroup: !!viewer.projectGroup,
						optionsName: intl.formatMessage({id: 'common.sprints'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<SprintDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.RECENT_TASK_ACTIVITY: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getRecentUpdateFilters(intl)}
							optionsName={intl.formatMessage({id: 'timesheets.recent'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.RECENT_ACTIVITY: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getRecentUpdateFilters(intl)}
							optionsName={intl.formatMessage({id: 'timesheets.recent'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROJECT_OWNER: {
					let persons = [];
					// Check if its project or projectGroup, and get persons.
					if (viewer && viewer.project && viewer.project.projectPersons && viewer.project.projectPersons.edges) {
						persons = viewer.project.projectPersons.edges;
					} else if (
						viewer &&
						viewer.projectGroup &&
						viewer.projectGroup.projects &&
						viewer.projectGroup.projects.edges &&
						viewer.projectGroup.projects.edges.length !== 0
					) {
						persons = viewer.projectGroup.projects.edges[0].node.projectPersons.edges;
					}
					const props = {
						...standardProps,
						persons,
						optionsName: intl.formatMessage({id: 'common.owners'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<ProjectPersonDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PROJECT_FOLLOWER: {
					let persons = [];
					// Check if its project or projectGroup, and get persons.
					if (viewer && viewer.project && viewer.project.projectPersons && viewer.project.projectPersons.edges) {
						persons = viewer.project.projectPersons.edges;
					} else if (
						viewer &&
						viewer.projectGroup &&
						viewer.projectGroup.projects &&
						viewer.projectGroup.projects.edges &&
						viewer.projectGroup.projects.edges.length !== 0
					) {
						persons = viewer.projectGroup.projects.edges[0].node.projectPersons.edges;
					}
					const props = {
						...standardProps,
						persons,
						optionsName: intl.formatMessage({id: 'common.followers'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<ProjectPersonDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.CONTACT: {
					const props = {
						...standardProps,
						projects: viewer.projects.edges,
						name: intl.formatMessage({id: 'common.project_owners'}),
						optionsName: intl.formatMessage({id: 'common.project_owners'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<ProjectContactDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.INDICATOR: {
					filterDropdowns.push(
						<IconDropdown
							{...standardProps}
							options={Util.GetIndicatorFiltersV2(intl, viewer)}
							optionsName={intl.formatMessage({id: 'common.filter_title.indicators'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.INDICATOR_FILTERED:
					filterDropdowns.push(
						<IconDropdown
							{...standardProps}
							options={Util.GetIndicatorFiltersV2(intl, viewer, true)}
							optionsName={intl.formatMessage({id: 'common.filter_title.indicators'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break; // TODO
				case FILTER_TYPE.SKILL_TEAM:
				case FILTER_TYPE.TEAM: {
					const props = {
						...standardProps,
						teams: viewer.company.teams.edges,
						optionsName: intl.formatMessage({id: 'settings.teams'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<TeamDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PROJECT: {
					let projects = [];
					if (viewer?.projectGroup?.projects?.edges) {
						projects = viewer.projectGroup.projects.edges;
					} else if (viewer?.program?.projects?.edges) {
						projects = viewer.program.projects.edges
							.map(edge => edge.node.legacyProject.edges)
							.reduce((array, legacyProjectEdges) => array.concat(legacyProjectEdges), []);
					} else if (viewer?.projects?.edges) {
						projects = viewer.projects.edges;
					}
					const props = {
						...standardProps,
						projects,
						optionsName: intl.formatMessage({id: 'common.projects'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<ProjectDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.STATUS_CATEGORY: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getWorkFlowCategoryFilters(intl)}
							optionsName={
								selectedSection === FILTER_SECTIONS.TASKS && hasFeatureFlag('timesheet_remaster')
									? 'Task Status'
									: intl.formatMessage({id: 'workflow.title'})
							}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PHASE_CATEGORY: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getGroupProgressCategoriesFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.SPRINT_CATEGORY: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getSprintStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.sprint_status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROJECT_STATUS_COLUMN: {
					let columns = [];
					// Check if its project or projectGroup, and get persons.
					if (viewer && viewer.project && viewer.project.statusColumnsV2 && viewer.project.statusColumnsV2.edges) {
						columns = viewer.project.statusColumnsV2.edges;
					} else if (
						viewer &&
						viewer.projectGroup &&
						viewer.projectGroup.projects &&
						viewer.projectGroup.projects.edges &&
						viewer.projectGroup.projects.edges.length !== 0
					) {
						columns = viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges;
					}
					const props = {
						...standardProps,
						columns,
						isProjectGroup: viewer.projectGroup,
						optionsName: intl.formatMessage({id: 'workflow.title'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<ProjectStatusColumnDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PERMISSION_LEVEL: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getgetPermissionLevelFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.permissions'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.CLIENT_GUEST_USERS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getClientGuestUsersFilters(intl)}
							optionsName={intl.formatMessage({id: 'filter_type.client_guest_users_title'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.SUB_TASKS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getSubTasksFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.sub_tasks'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.TASK_HIERARCHY: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getTaskHierarchyFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.sub_tasks'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.TASK_LEVEL: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getTaskLevelFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.task_level'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.DEADLINE: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getDeadlineFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.deadlines'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.RATE_CARD: {
					const props = {
						...standardProps,
						noRateCardOption: true,
						rateCards: viewer.company.rateCards.edges,
						optionsName: intl.formatMessage({id: 'common.rate-cards'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
						isNested: true,
						isMultiSelect: true,
						showDisabled: true,
					};

					filterDropdowns.push(<RateCardDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.PROJECT_TYPE: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getProjectTypeFilterOptions(intl, viewer)}
							optionsName={intl.formatMessage({id: 'common.budget_types'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.SPRINT_END_DATE: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getSprintDeadlineFilterOptions(intl)}
							optionsName={intl.formatMessage({id: 'filter_type.sprint_end_date_title'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROGRESS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getProgressFilterOptions(intl)}
							optionsName={intl.formatMessage({id: 'common.progress'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROJECT_STATUS: {
					filterDropdowns.push(
						<CircleTextDropdown
							showCircleText={false}
							{...standardProps}
							options={Util.GetProjectStatusFilters()}
							optionsName={intl.formatMessage({id: 'settings.project-status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.CLIENT: {
					const props = {
						...standardProps,
						clients: viewer.company.clients.edges,
						optionsName: intl.formatMessage({id: 'settings.clients'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					filterDropdowns.push(<ClientDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.CAPACITY_WORKLOAD: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getCapacityWorkloadFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.capacity'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROJECT_AND_IDLE:
					break; // TODO
				case FILTER_TYPE.PERSON_ALLOCATED_TO_PROJECT_AND_IDLE:
					break; // TODO
				case FILTER_TYPE.DEPENDENCIES: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getDependenciesFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.dependencies'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.APPROUVED: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getApprovedFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.approved'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.APPROVAL_STATUS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getApprovalStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.approved'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PREDICTED_OVERRUN: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getOverrunFilters(viewer?.project?.demo)}
							optionsName={'Overrun'}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.SKILL_DEPARTMENT:
				case FILTER_TYPE.DEPARTMENT: {
					const props = {
						...standardProps,
						departments: viewer.company.departments.edges,
						optionsName: intl.formatMessage({id: 'common.departments'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<DepartmentDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.INTERNAL_TIME: {
					const props = {
						...standardProps,
						idleTimes: viewer.company.idleTimes.edges,
						optionsName: intl.formatMessage({id: 'common.internal_time'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<IdleTimeDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.TIME_OFF: {
					const props = {
						...standardProps,
						timeOff: true,
						idleTimes: viewer.company.idleTimes.edges,
						optionsName: intl.formatMessage({id: 'common.time_off'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};

					filterDropdowns.push(<IdleTimeDropdown {...props} />);
					break;
				}
				case FILTER_TYPE.INVOICE_STATUS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getInvoicesStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);

					break;
				}
				case FILTER_TYPE.PAYMENT_STATUS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getPaymentStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'invoicing.payment_status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);

					break;
				}
				case FILTER_TYPE.SKILL: {
					const filterType = filter;
					const sectionSupportingSkillLevels = [
						FILTER_SECTION.SCHEDULING_PEOPLE,
						FILTER_SECTION.SCHEDULING_PROJECTS,
						FILTER_SECTION.SCHEDULING_PLACEHOLDERS,
						FILTER_SECTION.SCHEDULING_CAPACITY_OVERVIEW,
						FILTER_SECTION.UTILIZATION_REPORT,
					].includes(filterSection);
					const props = {
						...standardProps,
						usePortal: false,
						company: viewer.company,
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
						selectLevels: sectionSupportingSkillLevels && viewer.company.skillLevelsEnabled,
						multiSelect: sectionSupportingSkillLevels && viewer.company.skillLevelsEnabled,
						selectedItems: getSelectedForDropdown(selectedSection, filterType),
						onSelect: (addedItems, updatedSelection) => handleDropdownAdd(filterType, addedItems, updatedSelection),
						onRemove: (removedItems, updatedSelection) =>
							handleDropdownRemove(filterType, removedItems, updatedSelection),
					};

					filterDropdowns.push(<SkillDropdown {...props} />);

					break;
				}
				case FILTER_TYPE.LABEL_APPLIED: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getLabelAppliedFilterOptions(intl)}
							optionsName={intl.formatMessage({id: 'filter_type.label.applied_to'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);

					break;
				}
				case FILTER_TYPE.LABEL_ALLOWED: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getLabelAllowedFilterOptions(intl)}
							optionsName={intl.formatMessage({id: 'filter_type.label.allowed_on'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);

					break;
				}
				case FILTER_TYPE.PRIORITY_LEVEL: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getPriorityLevelOptions(
								intl,
								viewer.company.priorityLevels.edges.map(priorityLevel => priorityLevel.node)
							)}
							optionsName={intl.formatMessage({id: 'project_settings.priority_level'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROGRAM: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getPrograms(
								intl,
								viewer.company.programs.edges.map(program => program.node)
							)}
							optionsName={ProgramUtil.programText(formatMessage)}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROJECT_WIN_CHANCE: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getProjectWinChanceFilters(intl)}
							optionsName={intl.formatMessage({id: 'filter_type.project.win_chance'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PROJECT_ALLOCATIONS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getProjectAllocationsFilters(intl)}
							optionsName={intl.formatMessage({id: 'filter_type.project.allocations'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.PERSON_ALLOCATIONS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getPersonAllocationsFilters(intl)}
							optionsName={intl.formatMessage({id: 'filter_type.project.allocations'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.DUE_DATE: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getDueDateFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.integration_status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.QBO_STATUS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getQBOStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.integration_status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.XERO_STATUS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getXeroStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.integration_status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.ECONOMIC_STATUS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getEconomicsStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.integration_status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.SAGE_INTACCT_STATUS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getSageIntacctStatusFilters(intl)}
							optionsName={intl.formatMessage({id: 'common.integration_status'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				case FILTER_TYPE.BILL_FROM: {
					filterDropdowns.push(
						<IconDropdown
							{...standardProps}
							options={viewer.company.billFroms.map(billFrom => ({
								value: billFrom.id,
								label: billFrom.name,
								icon: <InvoicingIntegrationIcon integration={billFrom.integration} />,
							}))}
							optionsName={intl.formatMessage({id: 'filter_type.bill_froms'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);

					break;
				}
				case FILTER_TYPE.BILL_TO: {
					const billFroms = viewer.company.billFroms;
					const props = {
						...standardProps,
						billFroms,
						optionsName: intl.formatMessage({id: 'filter_type.bill_tos'}),
						selectedGroupName: intl.formatMessage({id: 'common.applied'}),
					};
					filterDropdowns.push(
						<BillToDropdown
							{...props}
							onSelect={id => standardProps.onSelect([id])}
							onRemove={id => standardProps.onRemove([id])}
						/>
					);

					break;
				}
				case FILTER_TYPE.EXPENSE_CATEGORY: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={viewer.company.expenseCategories.edges.map(edge => ({
								value: edge.node.id,
								label: edge.node.name,
							}))}
							optionsName={intl.formatMessage({id: 'common.expense-category'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);

					break;
				}
				case FILTER_TYPE.EXPENSE_BILLING_OPTIONS: {
					filterDropdowns.push(
						<SingleTextDropdown
							{...standardProps}
							options={Util.getExpenseBillingOptionsFilters(intl)}
							optionsName={intl.formatMessage({id: 'expense_item_modal.billing_options'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
						/>
					);
					break;
				}
				default:
					break;
			}
		}
		return filterDropdowns;
	};

	const getSectionName = () => {
		let section = advancedSections.find(section => section.value === selectedSection);
		return section.text;
	};

	return (
		<div data-userpilot={'filter-dropdown-container'}>
			{advancedSections.length > 1 && (
				<FilterTypeContainer>
					<TitleWithMargin>{formatMessage({id: 'common.filter-as-verb'})}</TitleWithMargin>
					<ButtonGroup onButtonClick={handleSectionClick}>
						{advancedSections.map(section => (
							<ButtonGroup.Button
								key={section.value}
								value={section.value}
								data-cy={'section-' + section.value}
								isSelected={section.value === selectedSection}
							>
								{section.text}
							</ButtonGroup.Button>
						))}
					</ButtonGroup>
				</FilterTypeContainer>
			)}
			<DropdownContainer>
				{advancedSections.length === 1 && (
					<TitleAndAdvancedContainer>
						<Title>
							{intl.formatMessage(
								{id: 'filter.filter_by'},
								{section: <span className={'highlight'}>{getSectionName()}</span>}
							)}
						</Title>
						{advancedSections.length > 1 && (
							<ShowAdvancedContainer>
								<ShowAdvancedClickable onClick={handleAdvancedClick}>
									<AdvancedText data-userpilot={'filter-show-advanced'}>
										{intl.formatMessage({id: 'filters.advanced_filters'})}
									</AdvancedText>
									<CaretIcon direction={showAdvanced ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN} />
								</ShowAdvancedClickable>
							</ShowAdvancedContainer>
						)}
					</TitleAndAdvancedContainer>
				)}
				<DropdownGrid>{getDropdowns()}</DropdownGrid>
				{showShowMore && (
					<ShowMoreToggle data-cy="show-more-toggle" onClick={handleShowMoreToggle}>
						<CaretIcon
							color="#727483"
							direction={showMoreFilters ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
						/>
						{formatMessage({id: showMoreFilters ? 'filters.less_options' : 'filters.more_options'})}
					</ShowMoreToggle>
				)}
			</DropdownContainer>
		</div>
	);
};

export const operatorOptionsPropType = PropTypes.shape({
	allowExclude: PropTypes.bool,
	allowRequireAll: PropTypes.bool,
});

DropdownSection.propTypes = {
	operatorOptions: operatorOptionsPropType,
	appliedFilterOperators: PropTypes.any,
	appliedFilters: PropTypes.any,
	viewer: PropTypes.any,
	intl: PropTypes.any,
	onChange: PropTypes.func,
	projectFilters: PropTypes.arrayOf(PropTypes.string),
	peopleFilters: PropTypes.arrayOf(PropTypes.string),
	taskFilters: PropTypes.arrayOf(PropTypes.string),
	timeRegFilters: PropTypes.arrayOf(PropTypes.string),
	selectedSection: PropTypes.string,
	setSelectedSection: PropTypes.func,
	filterSection: PropTypes.string,
};

DropdownSection.defaultProps = {
	operatorOptions: {
		allowExclude: true,
		allowRequireAll: true,
	},
};
