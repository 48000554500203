/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type appHiBob_viewer$ref = any;
export type appHiBob_QueryVariables = {||};
export type appHiBob_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: appHiBob_viewer$ref,
  |}
|};
export type appHiBob_Query = {|
  variables: appHiBob_QueryVariables,
  response: appHiBob_QueryResponse,
|};
*/


/*
query appHiBob_Query {
  viewer {
    actualPersonId
    component(name: "app_hibob")
    ...appHiBob_viewer
    id
  }
}

fragment appHiBob_viewer on Viewer {
  id
  company {
    id
    hiBobEnabled
    workatoCustomerId
    workatoForecastHiBobConnectionId
    workatoHiBobConnectionId
    workatoIframeToken
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "app_hibob"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"app_hibob\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "appHiBob_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "appHiBob_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "appHiBob_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hiBobEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workatoCustomerId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workatoForecastHiBobConnectionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workatoHiBobConnectionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workatoIframeToken",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "appHiBob_Query",
    "operationKind": "query",
    "text": "query appHiBob_Query {\n  viewer {\n    actualPersonId\n    component(name: \"app_hibob\")\n    ...appHiBob_viewer\n    id\n  }\n}\n\nfragment appHiBob_viewer on Viewer {\n  id\n  company {\n    id\n    hiBobEnabled\n    workatoCustomerId\n    workatoForecastHiBobConnectionId\n    workatoHiBobConnectionId\n    workatoIframeToken\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '170358a37f2515d22189d8650b05cd62';

module.exports = node;
