// @ts-check
import {
	Button,
	DeprecatedRichTextField as RichTextField,
	DeprecatedText as Text,
	RichTextEditor,
	useRichTextEditorFunctions,
} from '@forecast-it/design-system';
import React, {useState} from 'react';
import {createFragmentContainer} from 'react-relay';
import {graphql} from 'relay-runtime';
import CreateSprintGoalMutation from '../../../../../../mutations/CreateSprintGoalMutation';
import GenericColorIndicatorSlideout from '../../../../../shared/components/slideout/GenericColorIndicatorSlideout';
import Util from '../../../../../shared/util/util';
import SprintGoalRow from './SprintGoalRow';
import {hasFeatureFlag} from '../../../../../shared/util/FeatureUtil';

/**
 * @callback clearCallback
 * @returns {void}
 */

/**
 * @param {string} sprintId
 * @param {string | undefined} description
 * @param {boolean} isProjectGroupSprint
 * @param {clearCallback} clearCallback
 */
const saveSprintGoal = (sprintId, description, isProjectGroupSprint, clearCallback) => {
	const onSuccess = () => {
		if (clearCallback) {
			clearCallback();
		}
	};
	const mutationObject = isProjectGroupSprint
		? {
				projectGroupSprintId: sprintId,
				description,
		  }
		: {
				sprintId,
				description,
		  };
	Util.CommitMutation(
		CreateSprintGoalMutation,
		mutationObject,
		// @ts-ignore
		onSuccess
	);
};

const SprintGoalSlideout = ({viewer, projectColor}) => {
	const sprint = viewer.projectGroupSprint ?? viewer.sprint;
	const isProjectGroupSprint = !!viewer.projectGroupSprint;
	const [description, setDescription] = useState('');
	const {editorRef, resetEditorContent, getEditorValueAsHtml} = useRichTextEditorFunctions();

	/**
	 * @type {clearCallback}
	 */
	const clearDescription = () => {
		if (hasFeatureFlag('plate_editor')) {
			resetEditorContent();
		} else {
			setDescription('');
		}
	};

	const handleSprintGoalSave = () => {
		const html = hasFeatureFlag('plate_editor') ? getEditorValueAsHtml() : description;
		saveSprintGoal(sprint.id, html, isProjectGroupSprint, clearDescription);
	};

	return (
		<GenericColorIndicatorSlideout name={sprint.name} color={projectColor} isMultiIndicator={isProjectGroupSprint}>
			{sprint.sprintGoals && sprint.sprintGoals.edges.length > 0 ? (
				<>
					<div style={{marginBottom: '8px'}}>
						<Text type={'heading'} variant={'m'}>
							Current Sprint Goals
						</Text>
					</div>
					<div style={{marginRight: '12px'}}>
						{sprint.sprintGoals.edges.map(sprintGoal => (
							<SprintGoalRow sprintGoal={sprintGoal.node} key={sprintGoal.node.id} sprintId={sprint.id} />
						))}
					</div>
				</>
			) : null}
			<div style={{marginTop: '32px', marginRight: '12px'}}>
				<div style={{marginBottom: '8px'}}>
					<Text type={'heading'} variant={'m'}>
						New Sprint Goal
					</Text>
				</div>
				{hasFeatureFlag('plate_editor') ? (
					<RichTextEditor
						placeholder={`Enter a new goal for your sprint...`}
						initialHtml={''}
						pluginToggles={{
							link: true,
							paragraphStyles: true,
						}}
						id={'new'}
						data-cy={'sprint-goal-description'}
						ref={editorRef}
					/>
				) : (
					<RichTextField
						limitations={[
							'BlockQuote',
							'Code',
							'CodeBlock',
							'Table',
							'TableToolbar',
							'Indent',
							'MediaEmbed',
							'ImageUpload',
						]}
						editor={'InlineEditor'}
						onSaveHandler={desc => setDescription(desc)}
						data={description}
						placeholder={`Enter a new goal for your sprint...`}
					/>
				)}
			</div>
			<div style={{marginTop: '16px'}}>
				<Button type={'creative'} onClick={() => handleSprintGoalSave()}>
					Create Sprint Goal
				</Button>
			</div>
		</GenericColorIndicatorSlideout>
	);
};

const SprintGoalSlideoutQuery = graphql`
	query SprintGoalSlideout_Query($sprintId: ID, $projectGroupSprintId: ID) {
		viewer {
			actualPersonId
			component(name: "sprint_goal")
			...SprintGoalSlideout_viewer @arguments(sprintId: $sprintId, projectGroupSprintId: $projectGroupSprintId)
		}
	}
`;

export {SprintGoalSlideoutQuery};

export default createFragmentContainer(SprintGoalSlideout, {
	viewer: graphql`
		fragment SprintGoalSlideout_viewer on Viewer
		@argumentDefinitions(sprintId: {type: "ID"}, projectGroupSprintId: {type: "ID"}) {
			sprint(id: $sprintId) {
				id
				name
				sprintGoals(first: 1000000) @connection(key: "Sprint_sprintGoals") {
					edges {
						node {
							id
							description
							statusColor
							stage
						}
					}
				}
			}
			projectGroupSprint(id: $projectGroupSprintId) {
				id
				name
				sprintGoals(first: 1000000) @connection(key: "ProjectGroupSprint_sprintGoals") {
					edges {
						node {
							id
							description
							statusColor
							stage
						}
					}
				}
			}
		}
	`,
});
