import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import {partition} from 'lodash';
import {YearStepper} from 'web-components/design';
import Dropdown from '../../dropdowns/dropdown';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../../../constants';
import Button from '../../buttons/button/button';
import TheEye from './TheEye';
import BulkEdit from '../../../../../components/new-ui/header-components/bulk_edit';
import Filter from '../../../../../components/new-ui/filter';
import Collapse from './collapse';
import BurgerMenu from './burger_menu';
import Search from '../../../../../components/new-ui/search';
import SearchLazy from './SearchLazy';
import DateChanger from '../../../../../components/new-ui/date-controls/date_changer';
import DateChangerV2 from '../../../../../components/new-ui/date-controls/date_changer_v2';
import DatePicker from '../../date-picker/date_picker_v3';
import UploadButton from '../../../../../components/new-ui/project/project-files/upload_button';
import NestedDropdown from '../../dropdowns/nested_dropdown';
import MeetingCreator from '../../../../../components/meeting_creator';
import ProjectStatusIndicator from '../../../../../components/new-ui/project_status_indicator';
import HelpCenterIcon from '../../../../../components/new-ui/help_center_icon';
import CSVButton from './csv_button';
import PDFButton from './pdf_button';
import ViewChanger from '../../../../../components/new-ui/view_changer';
import UnitToggle from '../../../../../components/new-ui/unit_toggle';
import GenericTooltip from '../../tooltips/generic_tooltip';
import TooltipIcon from '../../tooltips/tooltip_icon';
import Switch from '../../switch/switch';
import ActionsMenu from '../../action-menu/actions_menu';
import {DateRangePickerWithQuickRanges} from '../../../../../components/new-ui/date-controls/date_range_picker_with_quick_ranges';
import {HideOrShow} from '../../../../../components/hide_or_show';
import Title from '../../../../../components/title';
import ButtonToggleGroup from '../../../../../components/button_toggle_group';
import OnboardingComponent from '../onboarding/onboarding_component';
import PersonDropdownRelayWrapper, {
	personDropdownRelayWrapperQuery,
} from '../../../../../components/new-ui/header-components/person_dropdown_relay_wrapper';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import FilterV4 from '../../filters/FilterWrapper';
import {PageTitle} from '../../../../../components/header-bar-components/PageTitle';
import InputTitle from '../title/input_title';
import {SimpleTextInputTitle} from '../../../../../components/header-bar-components/SimpleTextReportTitle';
import HeaderLabel from '../../../../../components/new-ui/HeaderLabel';
import {getInitialOptionsFromCheckedOptions} from '../../../../../the_eye_util';
import LastEditInfo from '../../../../../components/new-ui/last_edit_info';
import {ShareMenuItem} from '../../../../../components/canvas-scheduling/projects-scheduling/share/ShareMenuItem';
import {PrintMenuItem} from '../../../../../components/canvas-scheduling/projects-scheduling/share/PrintMenuItem';
import {CopyLinkMenuItem} from '../../../../../components/canvas-scheduling/projects-scheduling/share/CopyLinkMenuItem';
import AllocationControls from '../../../../../components/canvas-scheduling/components/allocation_controls/AllocationControls';
import AllocationControlsOld from '../../../../../components/canvas-scheduling/components/allocation_controls/AllocationControlsOld';
import ToggleWinProbability from '../../../../../components/canvas-scheduling/components/allocation_controls/ToggleWinProbability';
import PresetDateRangePicker from '../../date-picker/preset_date_range_picker';
import {hasFeatureFlag} from '../../../util/FeatureUtil';
import {ZenModeButton} from './../../zen-mode/ZenModeButton';
import {EMPHASIS, SegmentedControl, Button as SkylineButton, SystemStatusMessage} from '@forecast-it/design-system';
import DatePickerWithButtons from '../../date-picker/composite/DatePickerWithButtons';
import DateDisplayWithButtons from '../../date-picker/composite/DateDisplayWithButtons';

class HeaderBar extends Component {
	constructElement(element, index, withSkylineStyle = false) {
		switch (element.type) {
			case ELEMENT_TYPE.SWITCH:
				return (
					<Switch
						cy={element.dataCy}
						header={element.header}
						sliderHeight={element.sliderHeight}
						isDisabled={element.disabled}
						key={`generic-modal-switch-${index}`}
						checked={element.checked}
						onChange={element.callback ? element.callback : null}
					></Switch>
				);

			case ELEMENT_TYPE.INPUT_REPORT_TITLE:
				return (
					<InputTitle
						key={`input-report-title-${index}`}
						userpilot={element.userpilot}
						intl={element.intl}
						defaultTitle={element.defaultTitle}
						width={element.width}
						value={element.value}
						onChange={element.onChange}
						placeholder={element.placeholder}
					/>
				);

			case ELEMENT_TYPE.BUTTON:
				return (
					<Button
						id={element.id}
						cy={element.dataCy}
						isDisabled={element.disabled}
						key={`generic-modal-button-${index}`}
						text={element.text}
						userpilot={element.userpilot}
						buttonStyle={element.style || BUTTON_STYLE.FILLED}
						colorTheme={element.color || BUTTON_COLOR.GREEN}
						tooltipEnabled={element.tooltipEnabled}
						tooltipProps={element.tooltipProps}
						onClick={element.callback ? element.callback : null}
						icon={element.icon}
						className={element.customClassName}
						autoScheduleStyle={element.autoScheduleStyle}
					/>
				);

			case ELEMENT_TYPE.SKYLINE_BUTTON:
				return (
					<SkylineButton
						id={element.id}
						key={`skyline-button-${index}`}
						data-cy={element.dataCy}
						data-userpilot={element.userpilot || ''}
						disabled={element.disabled}
						onClick={element.callback ? element.callback : null}
						theme={element.theme}
						emphasis={element.emphasis}
						icon={element.icon}
						iconOnly={element.iconOnly}
					>
						{element.text}
					</SkylineButton>
				);

			case ELEMENT_TYPE.SYSTEM_STATUS_MESSAGE:
				return (
					<SystemStatusMessage status={element.status}>
						<SystemStatusMessage.Message>{element.text}</SystemStatusMessage.Message>
						<SystemStatusMessage.Link onClick={element.callback ? element.callback : null}>
							{element.linkText}
						</SystemStatusMessage.Link>
					</SystemStatusMessage>
				);
			case ELEMENT_TYPE.LINK:
				return (
					<Link key={`generic-link-${index}`} to={element.linkUrl} className={element.className}>
						<div style={element.style}>{element.linkText}</div>
					</Link>
				);
			case ELEMENT_TYPE.INDICATOR:
				return <ProjectStatusIndicator key={`project-status-indicator-${index}`} status={element.status} />;
			case ELEMENT_TYPE.LEFT_DROPDOWN:
			case ELEMENT_TYPE.DROPDOWN:
			case ELEMENT_TYPE.DROPDOWN_PHASES:
				return (
					<Dropdown
						dataCy={element.dataCy}
						listDataCy={element.listDataCy}
						buttonCy={element.buttonCy}
						key={`generic-dropdown-${index}`}
						customHeight={30}
						customWidth={element.customWidth}
						customClasses={element.customClasses}
						onChange={element.callback ? element.callback : null}
						options={element.dropdownOptions ? element.dropdownOptions : []}
						value={element.value}
						placeholder={element.placeholder ? element.placeholder : ''}
						clearable={element.clearable}
						clearText={element.clearText}
						clearTextDescription={element.clearTextDescription}
						clearTextDisabled={element.clearTextDisabled}
						disabled={element.disabled}
						tooltipEnabled={element.tooltipEnabled}
						tooltipProps={element.tooltipProps}
						userpilot={element.userpilot}
						hideLabel={true}
						multiSelect={element.multiSelect}
					/>
				);
			case ELEMENT_TYPE.DROPDOWN_PERSON:
				return (
					<ForecastQueryRenderer
						key="query-render-personDropdownRelayWrapper"
						query={personDropdownRelayWrapperQuery}
						showLoader={true}
						customLoader={() => <div style={{width: `${element.customWidth}px`}}></div>}
						render={(relayProps, retry) => {
							return (
								<PersonDropdownRelayWrapper
									{...relayProps}
									retry={retry}
									index={index}
									cy={element.cy}
									options={element.personDropdownOptions ? element.personDropdownOptions : null}
									callback={element.callback ? element.callback : null}
									selectedPerson={element.selectedPerson}
									customWidth={element.customWidth}
									tooltipEnabled={element.tooltipEnabled}
									tooltipProps={element.tooltipProps}
									userpilot={element.userpilot}
									withSkylineStyle={withSkylineStyle}
								/>
							);
						}}
					/>
				);
			case ELEMENT_TYPE.BULK_EDIT:
				return (
					<BulkEdit
						dataCy={element.cy}
						disabled={element.disabled}
						key={`bulk-edit-button-${index}`}
						numTasksSelected={element.numTasksSelected}
						dropdownOptions={element.dropdownOptions}
						selectionModeEnabled={element.selectionModeEnabled}
						toggleSelectionMode={element.toggleSelectionMode ? element.toggleSelectionMode.bind(this) : null}
						selectionText={element.selectionText}
						tooltipEnabled={element.tooltipEnabled}
						tooltipProps={element.tooltipProps}
					/>
				);
			case ELEMENT_TYPE.DATE_CHANGER:
				return (
					<DateChanger
						key={`date-changer-button-${index}`}
						dataCy={element.cy}
						currentViewingDate={element.currentViewingDate}
						handleTodayButtonClick={element.handleTodayButtonClick}
						selectedTab={element.selectedTab}
						handleMoveDateButtonClick={element.handleMoveDateButtonClick}
						updateDateRange={element.updateDateRange}
						createPickerHTML={element.createPickerHTML}
						boldLongDayMonth={element.boldLongDayMonth}
						onlyTodayButton={element.onlyTodayButton}
						selectorTooltipEnabled={element.selectorTooltipEnabled}
						selectorTooltipProps={element.selectorTooltipProps}
						tooltipEnabled={element.tooltipEnabled}
						tooltipProps={element.tooltipProps}
						userpilot={element.userpilot ? element.userpilot : ''}
					/>
				);

			case ELEMENT_TYPE.DATE_CHANGER_V2:
				return (
					<DateChangerV2
						key={`date-changer-button-${index}`}
						dataCy={element.cy}
						id={element.id}
						currentViewingDate={element.currentViewingDate}
						handleTodayButtonClick={element.handleTodayButtonClick}
						handleThisWeekButtonClick={element.handleThisWeekButtonClick}
						selectedTab={element.selectedTab}
						handleAdjacentDateButtonClick={element.handleAdjacentDateButtonClick}
						updateDateRange={element.updateDateRange}
						createPickerHTML={element.createPickerHTML}
						boldLongDayMonth={element.boldLongDayMonth}
						onlyTodayButton={element.onlyTodayButton}
						selectorTooltipEnabled={element.selectorTooltipEnabled}
						selectorTooltipProps={element.selectorTooltipProps}
						tooltipEnabled={element.tooltipEnabled}
						tooltipProps={element.tooltipProps}
						startDateLimite={element.startDateLimite}
						//
						useTodayButton={element.useTodayButton}
						useThisWeekButton={element.useThisWeekButton}
						todayButtonLeft={false}
						timePeriod={element.timePeriod}
						userpilot={element.userpilot}
						noAdjacentButton={element.noAdjacentButton}
					/>
				);
			case ELEMENT_TYPE.DATE_PICKER:
				return (
					<DatePicker
						key={`header-bar-date-range-picker-${index}`}
						isNewDateRange={element.isNewDateRange}
						startDate={element.startDate}
						endDate={element.endDate}
						handleDateRangeChange={element.handleDateRangeChange}
						colorInherited={element.colorInherited}
						compactShowYear={element.compactShowYear}
						datePickerStyle={element.datePickerStyle}
						calendarOffsetX={element.calendarOffsetX}
						clearable={element.clearable}
						clearBothDates={element.clearBothDates}
						userpilot={element.userpilot}
						buttonCy={element.cy}
					/>
				);
			case ELEMENT_TYPE.SKYLINE_DATE_PICKER_WITH_BUTTONS:
				return (
					<DatePickerWithButtons
						key={`header-bar-skyline-date-picker-${index}`}
						value={element.value}
						onDatePickerChange={element.onDatePickerChange}
						onBackButton={element.onBackButton}
						onForwardButton={element.onForwardButton}
						data-cy={element.dataCy}
					/>
				);
			case ELEMENT_TYPE.SKYLINE_DATE_DISPLAY_WITH_BUTTONS:
				return (
					<DateDisplayWithButtons
						key={`header-bar-skyline-date-display-${index}`}
						value={element.value}
						onBackButton={element.onBackButton}
						onForwardButton={element.onForwardButton}
						data-cy={element.dataCy}
					/>
				);
			case ELEMENT_TYPE.PRESET_DATE_RANGE_PICKER:
				return (
					<PresetDateRangePicker
						key={`preset_date_range_picker-${index}`}
						handleDateRangeChange={element.handleDateRangeChange}
						initialPresetDateRange={element.initialPresetDateRange}
						options={element.options}
						userpilot={element.userpilot}
						presetDateRange={element.presetDateRange}
						dataCy={element.cy}
					/>
				);
			case ELEMENT_TYPE.THE_EYE:
				let options;
				if (element.defaultOptions) {
					options = getInitialOptionsFromCheckedOptions(
						element.defaultOptions,
						element.checkedOptions,
						this.props.isReportService
					);
				} else {
					options = element.options;
				}

				return (
					<TheEye
						openRight={element.openRight}
						expandLeft={element.expandLeft || this.props.eyeToLeftDefault}
						dataCy={element.cy}
						key={`the-eye-${index}`}
						options={options}
						onSelect={element.onSelect.bind(this)}
						disableTooltip={!!element.disableTooltip}
						ignoreScroll={element.ignoreScroll}
					/>
				);

			case ELEMENT_TYPE.UNIT_TOGGLE:
				return (
					<UnitToggle
						id={element.id}
						key={`unit-toggle-${index}`}
						dataCy={element.dataCy}
						userpilot={element.userPilot}
						options={element.options}
					/>
				);
			case ELEMENT_TYPE.SEGMENTED_CONTROL:
				return (
					<SegmentedControl
						id={element.id}
						key={`segmented-control-${index}`}
						value={element.value}
						onValueChange={element.onValueChange}
						data-cy={element.dataCy}
						data-userpilot={element.userPilot}
					>
						{element.options.map(option => (
							<SegmentedControl.Item value={option.value} label={option.label} data-cy={option.dataCy}>
								{option.label}
							</SegmentedControl.Item>
						))}
					</SegmentedControl>
				);
			case ELEMENT_TYPE.FILTER:
				return <Filter dataCy={element.cy} key={`generic-filter-${index}`} availableFilters={element.filters} />;
			case ELEMENT_TYPE.HEADER_LABEL:
				return <HeaderLabel {...element} />;
			case ELEMENT_TYPE.SEARCH:
				return (
					<Search
						dataCy={element.cy}
						key={`generic-search-${index}`}
						value={element.value}
						onChange={element.onChange}
						onKeyUp={element.onKeyUp}
						placeholder={element.placeholder ? element.placeholder : 'Find in page'}
						useShortcut={element.useShortcut}
						userpilot={element.userpilot}
					/>
				);
			case ELEMENT_TYPE.SEARCH_LAZY:
				return (
					<SearchLazy
						dataCy={element.cy}
						key={`generic-search-${index}`}
						onChange={element.onChange}
						placeholder={element.placeholder ? element.placeholder : 'Find in page'}
					/>
				);
			case ELEMENT_TYPE.COLLAPSE:
				return (
					<Collapse
						dataCy={element.cy}
						key={`collapse-button-${index}`}
						collapsed={element.collapsed}
						toggleCollapse={element.toggleCollapse}
					/>
				);
			case ELEMENT_TYPE.BURGER_MENU:
				return (
					<BurgerMenu
						dataCy={element.cy}
						key={`burger-menu-${index}`}
						options={element.options}
						handleOption={element.handleOption}
					/>
				);
			case ELEMENT_TYPE.EMPTY_SPACE:
				return <div key={'empty-space-' + index} style={{height: element.height, width: element.width}} />;
			case ELEMENT_TYPE.CSV:
				if (withSkylineStyle) {
					return (
						<SkylineButton
							key={'csv-button'}
							onClick={element.callback}
							emphasis={EMPHASIS.MONO}
							icon="download"
							iconOnly
							data-cy={element.cy}
							userpilot={element.userpilot || ''}
							disabled={element.disabled}
						/>
					);
				}
				return (
					<CSVButton
						key={'csv-button'}
						onClick={element.callback}
						text={element.text}
						cy={element.cy}
						disabled={element.disabled}
					/>
				);
			case ELEMENT_TYPE.PDF:
				return (
					<PDFButton
						key={'pdf-button'}
						cy={element.cy}
						document={element.document}
						fileName={element.fileName}
						pageName={element.pageName}
						disabled={element.disabled}
					/>
				);
			case ELEMENT_TYPE.E_CONOMIC:
				return (
					<button key={`e-conomic-${index}`} className="economic-button" onClick={element.callback}>
						e-conomic
					</button>
				);
			case ELEMENT_TYPE.FILE_UPLOAD:
				return (
					<UploadButton
						key={'file-upload' + index}
						newUI={true}
						uploadFile={element.uploadFile}
						uploadGoogleDriveFile={element.uploadGoogleDriveFile}
					/>
				);
			case ELEMENT_TYPE.VIEW_TYPE_BUTTON:
				return <ViewChanger key={'view-changer'} onClick={element.callback} currentView={element.currentView} />;
			case ELEMENT_TYPE.NESTED_DROPDOWN:
				return (
					<NestedDropdown
						key={'nested-dropdown' + index}
						useWhiteColor={false}
						placeholder={element.placeholder ? element.placeholder : ''}
						options={element.options}
						value={element.value}
						onChange={element.callback}
						showArrowWhenCollapsed={true}
						disabled={element.disabled}
						cy={element.cy}
					/>
				);
			case ELEMENT_TYPE.MEETING_CREATOR:
				return (
					<MeetingCreator
						key={'meeting_creator' + index}
						title={element.title}
						description=""
						startTime={
							element.startingDate && moment().isBefore(element.startingDate)
								? moment(element.startingDate.toDate()).set('h', 8).startOf('h')
								: moment().startOf('h').add(1, 'h')
						}
						endTime={
							element.startingDate && moment().isBefore(element.startingDate)
								? moment(element.startingDate.toDate()).set('h', 9).startOf('h')
								: moment().startOf('h').add(2, 'h')
						}
						organizerName={element.organizerName}
						organizerEmail={element.organizerEmail}
						attendees={element.attendees}
						useBurgerMenuStyling={false}
						disabled={element.disabled}
					/>
				);
			case ELEMENT_TYPE.ZOOM_MENU:
				return <div key={'zoom-menu' + index} className="canvas-timeline-zoom-menu-wrapper" id="zoom-menu-wrapper" />;
			case ELEMENT_TYPE.EXPAND_ALL_CANVAS:
				return (
					<div
						key={'expand-all' + index}
						className="canvas-timeline-expand-all-button-container"
						data-cy={element.cy ? element.cy : ''}
						id="expand-all-button-container"
						data-userpilot="expand-all-button"
					/>
				);
			case ELEMENT_TYPE.DEPENDENCY_MODE_SCHEDULING:
				return (
					<button
						key={'dependency-mode' + index}
						className={element.className}
						onClick={element.onClick}
						title={element.title}
						data-cy={element.cy}
					/>
				);
			case ELEMENT_TYPE.HELP_LOGO:
				return <HelpCenterIcon key={'help-center-icon'} href={element.href} onClick={element.onClick} />;
			case ELEMENT_TYPE.BETA_TOOLTIP:
				return (
					<GenericTooltip
						type="beta"
						key={'beta-tooltip' + index}
						showOnRight={element.showOnRight}
						showOnTop={element.showOnTop}
					/>
				);
			case ELEMENT_TYPE.GIVE_FEEDBACK:
				return (
					<Button
						key={'feedback-button' + index}
						className={'feedback-button'}
						text={element.intl.formatMessage({id: 'common.give_feedback'})}
						buttonStyle={BUTTON_STYLE.OUTLINE_THICK}
						colorTheme={BUTTON_COLOR.VERYLIGHTGREY}
						onClick={() =>
							window.open(
								element.link
									? element.link
									: 'https://experience.forecast.it/feedback?email=' + encodeURIComponent(element.userEmail),
								'_blank'
							)
						}
						symbolClass={'email'}
						symbolSize={15}
					></Button>
				);
			case ELEMENT_TYPE.INFO_TEXT:
				return (
					<span key={'info-text' + index} className={'header-bar-info-text'}>
						{element.infoText}
					</span>
				);
			case ELEMENT_TYPE.BACK_BUTTON:
				return (
					<button
						key={'back-button' + index}
						className="header-bar-back-button"
						onClick={element.onClick}
						title="header-bar-back-button"
					/>
				);
			case ELEMENT_TYPE.ACTIONS_MENU:
				return (
					<ActionsMenu
						key={'actions-menu' + index}
						userpilot={element.userpilot ? element.userpilot : ''}
						{...element}
					></ActionsMenu>
				);
			case ELEMENT_TYPE.TOOLTIP_ICON:
				return (
					<TooltipIcon
						key={'tooltip-icon'}
						infoText={element.infoText}
						className={element.className}
						onClick={element.onClick}
					/>
				);
			case ELEMENT_TYPE.SEARCH_INPUT:
				return (
					<input
						key={'search-input' + index}
						className="header-bar-search-input"
						value={element.value}
						onChange={element.onChange}
					/>
				);
			case ELEMENT_TYPE.DATE_RANGE_PICKER_WITH_QUICK_RANGES:
				element.defaultRangeItem.type = 'custom';
				return (
					<DateRangePickerWithQuickRanges
						key={`date-range-picker-with-quick-ranges-${index}`}
						defaultRangeItem={element.defaultRangeItem}
						onRangeItemChange={element.onRangeItemChange}
						clearable={element.clearable}
						userpilot={element.userpilot ? element.userpilot : ''}
						calendarOffsetX={element.calendarOffsetX}
						{...element}
					/>
				);
			case ELEMENT_TYPE.CUSTOM_CONTENT:
				return element.render(`custom-content-${index}`);
			case ELEMENT_TYPE.HIDE_AND_SHOW:
				if (withSkylineStyle) {
					return (
						<SkylineButton
							key={`hide-or-show-elem-${index}`}
							emphasis={EMPHASIS.MONO}
							data-cy={element.cy}
							userpilot={element.userpilot || ''}
							onClick={element.callback}
							rightIcon={element.isShowing ? 'chevronDown' : 'chevronUp'}
						>
							{element.isShowing ? element.showText : element.hideText}
						</SkylineButton>
					);
				}
				return (
					<HideOrShow
						key={`hide-or-show-elem-${index}`}
						cy={element.cy}
						userpilot={element.userpilot || ''}
						showId={element.showId}
						hideId={element.hideId}
						callback={element.callback}
						hideText={element.hideText}
						showText={element.showText}
						isShowing={element.isShowing}
					/>
				);
			case ELEMENT_TYPE.BUTTON_TOGGLE_GROUP:
				return (
					<ButtonToggleGroup
						key={`switch-group-${index}`}
						intl={element.intl}
						defaultOptions={element.defaultOptions}
						selectedSwitch={element.selectedSwitch}
						onSwitchSelection={element.onSwitchSelection}
						singlePicker={element.singlePicker}
						groupLocked={element.groupLocked}
						nothingReturned={element.nothingReturned}
					></ButtonToggleGroup>
				);
			case ELEMENT_TYPE.TITLE:
				return <Title key={`page-title-${index}`} intl={element.intl} textId={element.textId}></Title>;
			case ELEMENT_TYPE.PAGE_TITLE:
				return <PageTitle key={`page-title-${index}`}>{element.title}</PageTitle>;
			case ELEMENT_TYPE.SIMPLE_TEXT_REPORT_TITLE:
				return <SimpleTextInputTitle key={`page-title-${index}`}>{element.title}</SimpleTextInputTitle>;
			case ELEMENT_TYPE.ONBOARDING_POPUP:
				return (
					<OnboardingComponent
						key={`onboarding-component-${index}`}
						intl={element.intl}
						title={element.title}
						options={element.options}
						helpCenterLink={element.helpCenterLink}
						noSubtitle={element.noSubtitle}
						noHelpCenter={element.noHelpCenter}
						subLink={element.subLink}
					></OnboardingComponent>
				);
			case ELEMENT_TYPE.FILTER_V4:
				return (
					<FilterV4
						key={`filter-v4-component-${index}`}
						operatorOptions={element.operatorOptions}
						primaryFilters={element.primaryFilters}
						taskFilters={element.taskFilters}
						timeRegFilters={element.timeRegFilters}
						invoiceFilters={element.invoiceFilters}
						projectFilters={element.projectFilters}
						peopleFilters={element.peopleFilters}
						skillFilters={element.skillFilters}
						labelFilters={element.labelFilters}
						placeholderFilters={element.placeholderFilters}
						resourceFilters={element.resourceFilters}
						viewer={element.viewer}
						defaultSection={element.defaultSection}
						filterSection={element.filterSection}
						projectId={element.projectId}
						projectGroupId={element.projectGroupId}
						programPrefix={element.programPrefix}
						onFiltersChange={element.onFiltersChange}
						appliedFiltersName={element.appliedFiltersName}
						useSavedReport={element.useSavedReport}
						preAppliedFilters={element.preAppliedFilters}
						noMenu={element.noMenu}
						userpilot={element.userpilot}
						cy={element.cy}
						companyProjectGroupId={element.companyProjectGroupId}
						companyProjectId={element.companyProjectId}
						enableFilterMode={true}
						changeOnClose={element.changeOnClose}
					/>
				);
			case ELEMENT_TYPE.YEAR_STEPPER:
				return (
					<YearStepper
						key={`year-stepper-${index}`}
						startYear={element.startYear}
						minYear={element.minYear}
						maxYear={element.maxYear}
						onYearChanged={element.onYearChanged}
						userpilot={element.userpilot || 'year-stepper'}
						cy={element.cy || 'year-stepper'}
					/>
				);
			case ELEMENT_TYPE.LAST_EDIT_INFO:
				return (
					<LastEditInfo
						key={`last-edit-info-${index}`}
						name={element.name}
						date={element.date}
						byYou={element.byYou}
					/>
				);
			case ELEMENT_TYPE.SHARE:
				return (
					<ShareMenuItem
						key={`share-menu-item-${index}`}
						userpilot={element.userpilot}
						onClick={element.onClick}
						disabled={element.disabled}
					/>
				);
			case ELEMENT_TYPE.PRINT:
				return <PrintMenuItem key={`print-menu-item-${index}`} onClick={element.onClick} />;
			case ELEMENT_TYPE.COPY_LINK:
				return <CopyLinkMenuItem key={`copy-link-item-${index}`} link={element.link} />;
			case ELEMENT_TYPE.WIN_PROBABILITY:
				return (
					<ToggleWinProbability
						key={`toggle-win-probability-${index}`}
						disabled={false}
						calcWin={element.calcWin}
						onToggleCalcWin={element.onToggleCalcWin}
					/>
				);
			case ELEMENT_TYPE.ALLOCATION_CONTROLS:
				if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
					return (
						<AllocationControls
							key={`allocation-controls-${index}`}
							disabled={false}
							width={280}
							hideSoft={element.hideSoft}
							onToggleHideSoft={element.onToggleHideSoft}
							hideHard={element.hideHard}
							onToggleHideHard={element.onToggleHideHard}
						/>
					);
				} else {
					return (
						<AllocationControlsOld
							key={`allocation-controls-${index}`}
							disabled={false}
							width={200}
							hideSoft={element.hideSoft}
							onToggleHideSoft={element.onToggleHideSoft}
							hideHard={element.hideHard}
							onToggleHideHard={element.onToggleHideHard}
							calcWin={element.calcWin}
							onToggleCalcWin={element.onToggleCalcWin}
						/>
					);
				}
			case ELEMENT_TYPE.ZEN_MODE:
				return <ZenModeButton />;
			default:
				return element;
		}
	}

	render() {
		return (
			<div
				ref={this.props.innerRef}
				className={
					'header-bar' +
					(this.props.customClasses ? ` ${this.props.customClasses}` : '') +
					(this.props.excludeLeftPadding ? ' exclude-left-padding' : '') +
					(this.props.excludeBottomPadding ? ' exclude-bottom-padding' : '') +
					(this.props.scheduling ? ' scheduling' : '') +
					(this.props.noPadding ? ' no-padding' : '') +
					(this.props.tlnPadding ? ' tln-padding' : '')
				}
			>
				<div className={'header-bar-left'}>
					{this.props.leftContent.map((element, index) => {
						return this.constructElement(element, index, this.props.withSkylineStyle);
					})}
				</div>
				{this.props.centerContent ? (
					<div className={'header-bar-center'}>
						{this.props.centerContent.map((element, index) => {
							return this.constructElement(element, index, this.props.withSkylineStyle);
						})}
					</div>
				) : null}
				{this.props.rightContent ? (
					<div className={'header-bar-right'}>
						{this.props.rightContent.map((element, index) => {
							return this.constructElement(element, index, this.props.withSkylineStyle);
						})}
					</div>
				) : null}
			</div>
		);
	}
}

const getSortOrder = key => {
	switch (key) {
		case ELEMENT_TYPE.BACK_BUTTON: // Still belongs in leftContent on integration pages
			return {rightContent: false, order: 1};
		case ELEMENT_TYPE.LEFT_DROPDOWN:
		case ELEMENT_TYPE.DROPDOWN_PERSON:
		case ELEMENT_TYPE.PRESET_DATE_RANGE_PICKER:
			return {rightContent: false, order: 2};
		case ELEMENT_TYPE.DATE_PICKER:
		case ELEMENT_TYPE.DATE_CHANGER:
		case ELEMENT_TYPE.DATE_CHANGER_V2:
		case ELEMENT_TYPE.DATE_RANGE_PICKER_WITH_QUICK_RANGES:
		case ELEMENT_TYPE.YEAR_STEPPER:
			return {rightContent: false, order: 3};
		case ELEMENT_TYPE.ZOOM_MENU:
			return {rightContent: false, order: 4};
		case ELEMENT_TYPE.UNIT_TOGGLE:
			return {rightContent: false, order: 5};
		case ELEMENT_TYPE.HEADER_LABEL:
			return {rightContent: false, order: 6};
		case ELEMENT_TYPE.LAST_EDIT_INFO:
			return {rightContent: true, order: 1};
		case ELEMENT_TYPE.SEARCH:
		case ELEMENT_TYPE.SEARCH_LAZY:
			return {rightContent: true, order: 2};
		case ELEMENT_TYPE.FILTER:
		case ELEMENT_TYPE.FILTER_V3:
		case ELEMENT_TYPE.FILTER_V4:
			return {rightContent: true, order: 3};
		case ELEMENT_TYPE.FILE_UPLOAD:
			return {rightContent: true, order: 4};
		case ELEMENT_TYPE.BUTTON:
		case ELEMENT_TYPE.SKYLINE_BUTTON:
		case ELEMENT_TYPE.SYSTEM_STATUS_MESSAGE:
			return {rightContent: true, order: 5};
		case ELEMENT_TYPE.NESTED_DROPDOWN:
		case ELEMENT_TYPE.DROPDOWN:
			return {rightContent: true, order: 6};
		case ELEMENT_TYPE.VIEW_TYPE_BUTTON:
			return {rightContent: true, order: 7};
		case ELEMENT_TYPE.DEPENDENCY_MODE_SCHEDULING:
			return {rightContent: true, order: 8};
		case ELEMENT_TYPE.HIDE_AND_SHOW:
			return {rightContent: true, order: 9};
		case ELEMENT_TYPE.COLLAPSE:
		case ELEMENT_TYPE.EXPAND_ALL_CANVAS:
			return {rightContent: true, order: 10};
		case ELEMENT_TYPE.CSV:
			return {rightContent: true, order: 11};
		case ELEMENT_TYPE.ZEN_MODE:
			return {rightContent: true, order: 12};
		case ELEMENT_TYPE.THE_EYE:
			return {rightContent: true, order: 13};
		case ELEMENT_TYPE.ACTIONS_MENU:
			return {rightContent: true, order: 15};
		case ELEMENT_TYPE.SHARE:
			return {rightContent: true, order: 3};
		case ELEMENT_TYPE.PRINT:
			return {rightContent: true, order: 3};
		case ELEMENT_TYPE.COPY_LINK:
			return {rightContent: true, order: 4};
		case ELEMENT_TYPE.WIN_PROBABILITY:
			return {rightContent: true, order: 4};
		case ELEMENT_TYPE.ALLOCATION_CONTROLS:
			return {rightContent: true, order: 5};
		case ELEMENT_TYPE.DROPDOWN_PHASES:
			return {rightContent: true, order: -1};

		// Unsorted section
		case ELEMENT_TYPE.BUTTON_TOGGLE_GROUP: // Only leftContent
		case ELEMENT_TYPE.INDICATOR: // Only leftContent
		case ELEMENT_TYPE.INFO_TEXT: // Only leftContent
		case ELEMENT_TYPE.INPUT_REPORT_TITLE: // Only leftContent, should belong in top-header
		case ELEMENT_TYPE.SIMPLE_TEXT_REPORT_TITLE:
		case ELEMENT_TYPE.BETA_TOOLTIP: // Only leftContent, should belong in top-header
		case ELEMENT_TYPE.GIVE_FEEDBACK: // Only leftContent, should belong in top-header
		case ELEMENT_TYPE.HELP_LOGO: // Should be moved to top-section
		case ELEMENT_TYPE.ONBOARDING_POPUP: // Should be moved to top-section
		case ELEMENT_TYPE.E_CONOMIC: // Not used
		case ELEMENT_TYPE.SEARCH_INPUT: // Not used
		case ELEMENT_TYPE.EMPTY_SPACE: // Should not be used with third-level
		case ELEMENT_TYPE.TITLE: // Only leftContent
		case ELEMENT_TYPE.CUSTOM_CONTENT: // custom component
		case ELEMENT_TYPE.LINK: // Never used
		case ELEMENT_TYPE.BULK_EDIT: // Only used in project files
		case ELEMENT_TYPE.BURGER_MENU: // Never used
		case ELEMENT_TYPE.MEETING_CREATOR: // Never used
		case ELEMENT_TYPE.TOGGLE_SWITCH: // Never used in header
		case ELEMENT_TYPE.TOOLTIP_ICON: // Never used
		default:
			return {rightContent: true, order: -1};
	}
};

const sortContentBySortOrder = elements => {
	const getOrder = element => getSortOrder(element.type).order + (element.sameTypeOrder ? element.sameTypeOrder * 0.1 : 0);
	return elements.sort((a, b) => (getOrder(a) < getOrder(b) ? -1 : 1));
};

/**
 * Generic function to construct headerBar
 * @param {object[]} leftContent List of items in the left part of the header. Anything which has a sortOrder will be transferred to rightContent
 * @param {object[]} rightContent List of items in the right part of the header.
 * @param {string} textId translationId for title in header
 * @param {object} props Object containing props to inject into HeaderBar.
 * @param {boolean} newMargin Style header bar with new values for the margin, defaults true
 */
export const buildHeaderBar = (leftContent = [], rightContent = [], props = {}) => {
	const content = leftContent.concat(rightContent);
	// Items belonging on the right side go in partition[0], items belonging on the left side go in partition[1]
	const contentPartition = partition(content, elem => getSortOrder(elem.type).rightContent === true);

	const navRightContent = sortContentBySortOrder(contentPartition[0]);
	const navLeftContent = sortContentBySortOrder(contentPartition[1]);

	if (props.tlnPadding === undefined) {
		props.tlnPadding = true;
	}

	return <HeaderBar {...props} leftContent={navLeftContent} rightContent={navRightContent} eyeToLeftDefault={true} />;
};

export default injectIntl(HeaderBar);
