import { formatDuration, intervalToDuration } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { FlexRow } from '@forecast-it/design-system';
import React, { useState } from 'react';
import { CalendarEventColorBar, CalendarEventStyled, EventDuration, EventName, TimeInfo } from './CalendarEvent_styled';
import { trackEvent } from '../../../../../tracking/amplitude/TrackingV2';
import { getCalendarEventSize } from './CalendarEventUtil';
import { triggerUserPilotNTimes } from '../../../../shared/util/UserPilotUtil';
import { USER_PILOT_FLOW_GCAL_INTRO } from '../../../../shared/util/UserPilotConstants';
import { hasFeatureFlag } from '../../../../shared/util/FeatureUtil';
const formatDistanceLocale = {
    xSeconds: '{{count}}s',
    xMinutes: '{{count}}m',
    xHours: '{{count}}h',
    xDays: '{{count}}d',
};
const shortEnLocale = { formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count) };
const formatEventTimeInfo = (event) => {
    if (!(event === null || event === void 0 ? void 0 : event.start) || !(event === null || event === void 0 ? void 0 : event.end) || !(event === null || event === void 0 ? void 0 : event.calendarTimeZone)) {
        return '';
    }
    const start = new Date(event.start);
    const end = new Date(event.end);
    const duration = intervalToDuration({
        start,
        end,
    });
    const durationFormatted = formatDuration(duration, {
        format: ['days', 'hours', 'minutes', 'seconds'],
        locale: shortEnLocale,
    });
    const startFormatted = formatInTimeZone(start, event.calendarTimeZone, 'HH:mm');
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null, startFormatted),
        React.createElement(EventDuration, null, durationFormatted)));
};
export const CalendarEvent = ({ event, isLocked, openModal, showContextMenu, isConverted }) => {
    const eventTimeInfo = formatEventTimeInfo(event);
    const [fadeOutRight, setFadeOutRight] = useState(false);
    const handleEventContextMenu = mouseEvent => {
        mouseEvent.preventDefault();
        trackEvent('Timesheet Calendar Event Context Menu', 'Viewed');
        showContextMenu(mouseEvent, event);
    };
    const onClick = e => {
        e.stopPropagation();
        trackEvent('Timesheet Calendar Event', 'Clicked', { eventStart: event === null || event === void 0 ? void 0 : event.start, eventEnd: event === null || event === void 0 ? void 0 : event.end });
        openModal(event);
        if (hasFeatureFlag('timesheets_new_side_modal')) {
            setFadeOutRight(true);
        }
        triggerUserPilotNTimes(USER_PILOT_FLOW_GCAL_INTRO, 3);
    };
    const getEventNameAndTime = (size) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(EventName, { isLocked: isLocked, isConverted: isConverted, size: size }, event === null || event === void 0 ? void 0 : event.name),
            React.createElement(TimeInfo, { isLocked: isLocked, isConverted: isConverted }, eventTimeInfo)));
    };
    const getEventLayout = (size) => {
        return (React.createElement(CalendarEventStyled, { onClick: onClick, onContextMenu: handleEventContextMenu, fadeOutRight: fadeOutRight, onAnimationEnd: () => setFadeOutRight(false) },
            React.createElement(FlexRow, { alignItems: "flex-start" },
                React.createElement(CalendarEventColorBar, { color: (event === null || event === void 0 ? void 0 : event.backgroundColor) || undefined, size: size }),
                getEventNameAndTime(size))));
    };
    return getEventLayout(getCalendarEventSize(event));
};
