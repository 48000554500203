import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {cloneDeep} from 'lodash';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, MODULE_TYPES, SEAT} from '../../../constants';
import {useIntl} from 'react-intl';
import {handleChangedOptions} from '../../../the_eye_util';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import Util from '../../shared/util/util';
import {getSelectableProfiles, personIsCollaboratorUser, personIsVirtualUser} from '../../shared/util/PermissionsUtil';
import SettingsResourcesSubNav from './SettingsResourcesSubNav';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import PeoplePersonGroup from './PeoplePersonGroup';
import {useSettingsPeopleContext} from './SettingsPeopleContext';
import * as tracking from '../../../tracking';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {hasModule} from '../../shared/util/ModuleUtil';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

const SettingsPeoplePage = ({viewer, children}) => {
	const {formatMessage} = useIntl();
	const {company} = viewer;

	const {
		getBulkSelectionPopup,
		setAllPersons,
		getAwaitingPersons,
		getPendingPersons,
		getPersonStatus,
		getSeatTypeName,
		setCompany,
		isChargeBeeCustomer,
		virtualSeats,
		collaboratorSeats,
		userSeats,
	} = useSettingsPeopleContext();

	const hasCost = CompanySetupUtil.hasCost();

	const column = localStorage.getItem('settings-people-sort-column') || null;
	const ascending = localStorage.getItem('settings-people-sort-ascending') === 'true' || true;

	const GROUPING_STATUS = 'STATUS';
	const GROUPING_SEAT_TYPE = 'SEAT_TYPE';
	const GROUPING_PERMISSION = 'PERMISSION';
	const GROUPING_NO_GROUPING = 'NO_GROUPING';

	const groupingOptions = [
		{value: GROUPING_STATUS.valueOf(), label: formatMessage({id: `settings_people.group_by.${GROUPING_STATUS}`})},
		{
			value: GROUPING_SEAT_TYPE.valueOf(),
			label: formatMessage({id: `settings_people.group_by.${GROUPING_SEAT_TYPE}`}),
		},
		{
			value: GROUPING_PERMISSION.valueOf(),
			label: formatMessage({id: `settings_people.group_by.${GROUPING_PERMISSION}`}),
		},
		{
			value: GROUPING_NO_GROUPING.valueOf(),
			label: formatMessage({id: `settings_people.group_by.${GROUPING_NO_GROUPING}`}),
		},
	];

	const _theEyeOptions = [
		{
			name: 'created-at',
			checked: false,
			disabled: false,
			translationId: 'global_filter_field.created_at',
			sortable: true,
		},
		{
			name: 'created-by',
			checked: false,
			disabled: false,
			translationId: 'settings_details.demo_created_by',
			sortable: true,
		},
	];

	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [sortBy, setSortBy] = useState({column, ascending});
	const [theEyeOptions, setTheEyeOptions] = useState(_theEyeOptions);
	const [grouping, setGrouping] = useState(Util.localStorageGetItem('settings-people-grouping') || GROUPING_STATUS.valueOf());

	document.title = 'People - Account - Forecast';
	useTrackPage('Settings People');

	const getPersonName = person => {
		return (person.node.firstName || '') + ' ' + (person.node.lastName || '');
	};

	useEffect(() => {
		if (searchFilterValue !== '') {
			let filterValue = searchFilterValue.trim();
			const matchingPersons = company.allPersons.edges.filter(person => {
				const name = getPersonName(person);
				const role = person.node.role ? person.node.role.name : null;
				const skillNames = '' + person.node.skills?.edges.map(edge => edge.node?.name);
				return (
					Util.normalizedIncludes(name, filterValue) ||
					(role && Util.normalizedIncludes(role, filterValue)) ||
					Util.normalizedIncludes(skillNames, filterValue)
				);
			}).length;
			trackEvent('Search', 'Results', {
				searchString: searchFilterValue,
				matching: matchingPersons,
			});
		}
	}, [searchFilterValue]);

	useEffect(() => {
		setCompany(company);
	}, [company]);

	const showAddTeamMemberModal = () => {
		showModal({
			viewer: viewer,
			type: MODAL_TYPE.ADD_TEAM_MEMBER,
			buttonLocation: 'Project Team',
		});
	};

	const handleTheEyeOptionSelect = (a, b, c, newOptions) => {
		setTheEyeOptions(handleChangedOptions(newOptions));
	};

	const handleSearchChange = value => {
		setSearchFilterValue(value);
	};

	const changeSortBy = column => {
		let ascending = true;
		if (column === sortBy.column) {
			ascending = !sortBy.ascending;
		}
		Util.localStorageSetItem('settings-people-sort-column', column);
		Util.localStorageSetItem('settings-people-sort-ascending', ascending);
		setSortBy({column, ascending});
	};

	const syncJiraUsers = type => {
		showModal({
			type: MODAL_TYPE.JIRA_SYNC_USERS,
			companyId: company.id,
			jiraType: type,
		});
	};

	const importBambooHRUsers = () => {
		showModal({
			type: MODAL_TYPE.BAMBOO_IMPORT_USERS,
			companyId: company.id,
		});
	};

	const importUnit4Users = () => {
		showModal({
			type: MODAL_TYPE.UNIT4_SYNC_USERS,
			companyId: company.id,
		});
	};

	const importADOUsers = () => {
		showModal({
			type: MODAL_TYPE.ADO_IMPORT_USERS,
			companyId: company.id,
		});
	};

	const importSageIntacctUsers = () => {
		tracking.trackEvent('Import team members from Sage Intacct pressed');
		trackEvent('Import Team Members From Sage Intacct', 'Clicked');
		showModal({
			type: MODAL_TYPE.SAGE_INTACCT_IMPORT_USERS,
		});
	};
	const openHiBobImportModal = seatType => {
		trackEvent('Import Team Members From HiBob', 'Clicked');
		showModal({
			type: MODAL_TYPE.HIBOB_IMPORT_TEAM_MEMBERS_MODAL,
			company: company,
			seatType: seatType || SEAT.CORE,
			isChargeBeeCustomer,
		});
	};

	const openChooseSeatModal = () => {
		trackEvent('Import Team Members From HiBob', 'Clicked');
		showModal({
			type: MODAL_TYPE.CHOOSE_SEAT_MODAL,
			callback: seatType => openHiBobImportModal(seatType),
			collaboratorSeats: !!collaboratorSeats,
			virtualSeats: !!virtualSeats,
		});
	};

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];

		leftContent.push({
			type: ELEMENT_TYPE.LEFT_DROPDOWN,
			dropdownOptions: groupingOptions,
			value: grouping,
			callback: group => {
				setGrouping(group.value);
				Util.localStorageSetItem('settings-people-grouping', group.value);
			},
			placeholder: '',
		});

		leftContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			options: theEyeOptions,
			onSelect: handleTheEyeOptionSelect,
			openRight: true,
			userpilot: 'eye-selector',
		});

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH_LAZY,
			value: searchFilterValue,
			onChange: handleSearchChange,
		});

		if (company.jiraCloudEnabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'integrations.jira.import_jira_users'}),
				callback: () => syncJiraUsers('cloud'),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (company.jiraServerEnabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'integrations.jira.import_jira_users'}),
				callback: () => syncJiraUsers('server'),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (company.bambooHREnabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'integrations.bamboohr.import_users'}),
				callback: importBambooHRUsers,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (hasFeatureFlag('use_unit4') && company.unit4Enabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'integrations.unit4.import_users'}),
				callback: importUnit4Users,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (company.vstsEnabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'integrations.ado.import_users'}),
				callback: importADOUsers,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (hasFeatureFlag('sage_intacct_integration') && company.sageIntacctConnectionEnabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'integrations.sage_intacct.import_team_members'}),
				callback: importSageIntacctUsers,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (hasFeatureFlag('hibob_integration') && company.hiBobEnabled && company.hiBobConnectionEnabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'integrations.hibob.import_team_members'}),
				callback: virtualSeats || collaboratorSeats ? openChooseSeatModal : () => openHiBobImportModal(),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_people.create_new_team_member'}),
				callback: showAddTeamMemberModal,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
				dataCy: 'add-team-member-button',
			});
		}

		return buildHeaderBar(leftContent, rightContent);
	};

	const sortedRoles = cloneDeep(viewer.company.roles.edges).sort((a, b) => Util.sortAlphabetically(a.node.name, b.node.name));
	const sortedDepartments = cloneDeep(viewer.company.departments.edges).sort((a, b) =>
		Util.sortAlphabetically(a.node.name, b.node.name)
	);
	const sortedProfiles = getSelectableProfiles(viewer.company.profiles.edges);
	const sortedProfileIds = sortedProfiles.map(profile => profile.id);
	const profileOptions = sortedProfiles.map(profile => {
		return {
			label: profile.name,
			subLabel: `${getSeatTypeName(profile.seat)} ${formatMessage({id: 'common.seat'})}`,
			value: profile.id,
			profile: profile,
		};
	});

	let allPersons = cloneDeep(viewer.company.allPersons.edges).sort((a, b) =>
		Util.sortAlphabetically(getPersonName(a), getPersonName(b))
	);

	useEffect(() => {
		setAllPersons(allPersons);
	}, [allPersons.length]);

	if (searchFilterValue !== '') {
		let filterValue = searchFilterValue.trim();
		allPersons = allPersons.filter(person => {
			const name = getPersonName(person);
			const role = person.node.role ? person.node.role.name : null;
			const personProfileIds = person.node.profiles.edges.map(e => e.node.id);
			const profileLabels =
				'' + profileOptions.filter(p => personProfileIds.some(profileId => p.value === profileId)).map(p => p?.label);
			const skillNames = '' + person.node.skills?.edges.map(edge => edge.node?.name);
			return (
				Util.normalizedIncludes(name, filterValue) ||
				(role && Util.normalizedIncludes(role, filterValue)) ||
				Util.normalizedIncludes(profileLabels, filterValue) ||
				Util.normalizedIncludes(skillNames, filterValue)
			);
		});
	}
	const sortFunction = (a, b) => {
		const {column} = sortBy;
		const multiplier = sortBy.ascending ? 1 : -1;
		let sortFunc = () => 1;
		if (column === 'name') {
			sortFunc = obj => obj.node.fullName.toLowerCase();
		} else if (column === 'role') {
			sortFunc = obj => (obj.node.role ? obj.node.role.name.toLowerCase() : '');
		} else if (column === 'department') {
			sortFunc = obj => (obj.node.department ? obj.node.department.name.toLowerCase() : '');
		} else if (column === 'permission') {
			sortFunc = obj => sortedProfileIds.indexOf(obj.node.profiles.edges[0]?.node.id);
		} else if (column === 'startDate') {
			sortFunc = obj => (obj.node.startDate ? obj.node.startDate : 0);
		} else if (column === 'endDate') {
			sortFunc = obj => (obj.node.endDate ? obj.node.endDate : 0);
		} else if (column === 'seat') {
			sortFunc = obj => getSeatTypeName(obj.node?.seat);
		} else if (column === 'status') {
			sortFunc = obj => getPersonStatus(obj.node);
		}
		const sortA = sortFunc(a);
		const sortB = sortFunc(b);
		const res = sortA > sortB ? 1 : sortA === sortB ? 0 : -1;
		return multiplier * res;
	};
	allPersons.sort(sortFunction);

	const activePersons = allPersons.map(p => p.node).filter(person => person.active);

	const integrationsEnabled =
		company.harvestEnabled ||
		company.vstsEnabled ||
		company.githubEnabled ||
		company.gitlabEnabled ||
		company.gitlabServerEnabled ||
		company.jiraCloudEnabled ||
		company.jiraServerEnabled ||
		company.unit4Enabled ||
		viewer.asanaEnabled ||
		company.teamsEnabled ||
		company.bambooHREnabled ||
		company.sageIntacctConnectionEnabled;
	const deactivated = allPersons.map(p => p.node).filter(p => !p.active);
	const sortByColumn = sortBy.column;

	const defaultSeatsInfo = seats => {
		if (seats.length === 1) {
			return formatMessage({id: 'settings_people.one_team_member'});
		} else {
			return formatMessage({id: 'settings_people.no_team_members'}, {number: seats});
		}
	};

	const sortByName = (g1, g2) => (g1.name > g2.name ? 1 : -1);

	const getGroups = () => {
		switch (grouping) {
			case GROUPING_STATUS.valueOf(): {
				const awaiting = getAwaitingPersons(activePersons);
				const pending = getPendingPersons(activePersons);
				const active = activePersons.filter(person => !awaiting.includes(person) && !pending.includes(person));

				return [
					{
						name: formatMessage({id: 'settings_people.status.awaiting'}),
						members: awaiting,
						seatsInfo: defaultSeatsInfo(awaiting.length),
					},
					{
						name: formatMessage({id: 'settings_people.status.pending'}),
						members: pending,
						seatsInfo: defaultSeatsInfo(pending.length),
					},
					{
						name: formatMessage({id: 'settings_people.status.active'}),
						members: active,
						seatsInfo: defaultSeatsInfo(active.length),
					},
				];
			}
			case GROUPING_PERMISSION.valueOf(): {
				const profileGroups = activePersons.reduce((acc, person) => {
					const profiles = person.profiles?.edges
						.map(profile => profile.node.name)
						.sort((p1, p2) => (p1 > p2 ? 1 : -1))
						.join(', ');

					if (!acc[profiles]) {
						acc[profiles] = [];
					}
					acc[profiles].push(person);
					return acc;
				}, {});

				return Object.keys(profileGroups)
					.map(groupKey => {
						return {
							name: groupKey,
							members: profileGroups[groupKey],
							seatsInfo: defaultSeatsInfo(profileGroups[groupKey].length),
						};
					})
					.sort(sortByName);
			}

			case GROUPING_SEAT_TYPE.valueOf(): {
				const virtual = activePersons.filter(person => personIsVirtualUser(person));
				const collaborator = activePersons.filter(person => personIsCollaboratorUser(person));
				const full = activePersons.filter(person => !personIsVirtualUser(person) && !personIsCollaboratorUser(person));

				return [
					{
						name:
							viewer.company.tier === 'CORE'
								? formatMessage({id: 'settings_people.seat.core'})
								: formatMessage({id: 'settings_people.seat.full'}),
						members: full,
						manageSubscriptions: true,
						seatsInfo: isChargeBeeCustomer
							? formatMessage({id: 'settings_people.no_team_members_max'}, {number: full.length, max: userSeats})
							: defaultSeatsInfo(full.length),
					},
					{
						name: formatMessage({id: 'settings_people.seat.collaborator'}),
						members: collaborator,
						manageSubscriptions: true,
						seatsInfo: isChargeBeeCustomer
							? formatMessage(
									{id: 'settings_people.no_team_members_max'},
									{number: collaborator.length, max: collaboratorSeats}
							  )
							: defaultSeatsInfo(collaborator.length),
					},
					{
						name: formatMessage({id: 'settings_people.seat.virtual'}),
						members: virtual,
						manageSubscriptions: true,
						seatsInfo: isChargeBeeCustomer
							? formatMessage(
									{id: 'settings_people.no_team_members_max'},
									{number: virtual.length, max: virtualSeats}
							  )
							: defaultSeatsInfo(virtual.length),
					},
				].sort(sortByName);
			}
			case GROUPING_NO_GROUPING.valueOf(): {
				return [
					{
						name: formatMessage({id: 'settings_subscription.users'}),
						members: allPersons.map(p => p.node).filter(person => person.active),
						seatsInfo: defaultSeatsInfo(activePersons.length),
					},
				];
			}
		}
	};

	const groups = getGroups().filter(group => group.members.length > 0);

	return (
		<div className={'section-content settings-app settings-people'} data-cy={'settings-people-page'}>
			{children}
			{getBulkSelectionPopup()}
			<SettingsResourcesSubNav viewer={viewer} />
			{getHeader()}
			<CustomScrollDiv>
				<div className={'section-body'}>
					<div className="inner">
						{groups.map(group => (
							<PeoplePersonGroup
								company={company}
								changeSortBy={changeSortBy}
								groupMembers={group.members}
								sortBy={sortBy}
								departments={sortedDepartments}
								hasFinancial={hasCost}
								viewer={viewer}
								integrationsEnabled={integrationsEnabled}
								initialShowMembers={true}
								profiles={profileOptions}
								sortByColumn={sortByColumn}
								roles={sortedRoles}
								theEyeOptions={theEyeOptions}
								groupName={group.name}
								seatsInfo={group.seatsInfo}
								key={group.name}
								manageSubscriptions={group.manageSubscriptions}
							/>
						))}

						{deactivated.length > 0 && (
							<PeoplePersonGroup
								company={company}
								changeSortBy={changeSortBy}
								groupMembers={deactivated}
								sortBy={sortBy}
								departments={sortedDepartments}
								hasFinancial={hasCost}
								viewer={viewer}
								integrationsEnabled={integrationsEnabled}
								initialShowMembers={false}
								profiles={profileOptions}
								sortByColumn={sortByColumn}
								roles={sortedRoles}
								theEyeOptions={theEyeOptions}
								groupName={formatMessage({id: 'team.deactivated-group-title'})}
								seatsInfo={defaultSeatsInfo(deactivated.length)}
								key={'deactivated'}
								manageSubscriptions={false}
							/>
						)}
					</div>
				</div>
			</CustomScrollDiv>
		</div>
	);
};

SettingsPeoplePage.propTypes = {};

const SettingsPeoplePageQuery = graphql`
	query SettingsPeoplePage_Query {
		viewer {
			actualPersonId
			component(name: "settings_people_page")
			...SettingsPeoplePage_viewer
		}
	}
`;

graphql`
	fragment SettingsPeoplePage_weekdays on Person {
		monday
		tuesday
		wednesday
		thursday
		friday
		saturday
		sunday
	}
`;

export {SettingsPeoplePageQuery};

export default createFragmentContainer(SettingsPeoplePage, {
	viewer: graphql`
		fragment SettingsPeoplePage_viewer on Viewer {
			...SettingsResourcesSubNav_viewer
			id
			actualPersonId
			language
			asanaEnabled
			availableFeatureFlags {
				key
			}
			company {
				id
				isChargebeeCustomer
				currency
				harvestEnabled
				hiBobConnectionEnabled
				hiBobEnabled
				vstsEnabled
				githubEnabled
				gitlabEnabled
				gitlabServerEnabled
				jiraCloudEnabled
				jiraServerEnabled
				unit4Enabled
				teamsEnabled
				bambooHREnabled
				oktaEnabled
				oneloginEnabled
				AADEnabled
				sageIntacctConnectionEnabled
				userSeats
				collaboratorSeats
				virtualSeats
				tier
				isCoreTier
				modules {
					moduleType
				}
				contactPerson {
					id
				}
				contactPersons(first: 10000) @connection(key: "Company_contactPersons") {
					edges {
						node {
							id
							contactType
							person {
								id
								fullName
							}
						}
					}
				}
				profiles(first: 10000) @connection(key: "Company_profiles", filters: []) {
					edges {
						node {
							id
							name
							canBeEdited
							personsCount
							permissions
							disabled
							seat
						}
					}
				}
				roles(first: 1000) {
					edges {
						node {
							id
							name
						}
					}
				}
				departments(first: 1000) @connection(key: "Company_departments") {
					edges {
						node {
							id
							name
						}
					}
				}
				allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
					edges {
						node {
							id
							createdAt
							createdBy
							harvestUser
							economicUser
							initials
							seat
							gitlabUser {
								integrationUserId
							}
							gitlabServerUser {
								integrationUserId
							}
							githubUser {
								integrationUserId
							}
							jiraServerUser
							jiraCloudUser
							asanaUser
							adoUserId
							unit4User
							msTeamsId
							bambooHRId
							sageIntacctId
							slackId
							firstName
							lastName
							fullName
							email
							startDate
							endDate
							profilePictureId
							profilePictureDefaultId
							role {
								id
								name
							}
							skillPersons {
								skill {
									id
								}
								level {
									id
								}
							}
							profiles(first: 10000) {
								edges {
									node {
										id
										name
										seat
									}
								}
							}
							permissions
							active
							invited
							isViewer
							costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
								edges {
									node {
										id
										startDay
										startMonth
										startYear
										cost
									}
								}
							}
							department {
								id
								name
							}
							hasLoggedIn
						}
					}
				}
			}
		}
	`,
});
