import React, {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {Draggable, Droppable} from '@forecasthq/react-virtualized-dnd';
import {createPaginationContainer, graphql} from 'react-relay';
import {CardLoaderWrapper, CardWrapper} from './ProjectWorkflow.styled';
import DraggableCard from '../../../shared/components/card/Draggable_card';
import {useHistory, withRouter} from 'react-router-dom';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {getColumnSocketConfig} from './ProjectWorkflowSocket';
import Util from '../../../shared/util/util';
import {HIDDEN_FEATURES} from '../../../../constants';
import {DragAndDropGroup, WorkflowContext} from './ProjectWorkflowPage';

import ReactVisibilitySensor from 'react-visibility-sensor';
import {CardLoader} from 'web-components';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';

const ProjectWorkflowColumnContent = ({
	relay,
	retry,
	projectIds,
	statusColumnIds,
	statusColumnId,
	viewer,
	columnHeight,
	taskMapRef,
	taskSizeMapRef,
	imgSizeMapRef,
	isConnectedParent,
	setSocketConfig,
	setSocketVariables,
	theEyeOptions,
	shouldShowDisabledOverlay,
	unselectTasks,
}) => {
	const lastSelected = useRef();
	const {selectedTasks, deselectTasksByIds, selectTasks, cardReorderInProgress} = useContext(WorkflowContext);
	const fetching = useRef(false);
	const isClientActionsRestricted = useMemo(() => Util.isClientTaskActionsRestricted(viewer), [viewer]);

	const history = useHistory();
	const showTaskModal = useCallback(taskId => {
		Util.showTaskModal(taskId, history);
	}, []);

	const reload = useCallback(result => {
		if (result.deleteStatusColumn) {
			retry();
		}
	}, []);

	useEffect(() => {
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, reload);
		return () => {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, reload);
		};
	}, []);

	useEffect(() => {
		const statusColumnIdsParsed = statusColumnIds.map(sId => parseInt(atob(sId).replace('StatusColumnV2:', '')));
		setSocketConfig(getColumnSocketConfig(projectIds, statusColumnIdsParsed), relay.refetchConnection);
	}, []);

	useEffect(() => {
		if (selectedTasks.length <= 0 || !selectedTasks.some(task => task.node.id === lastSelected.current)) {
			lastSelected.current = null;
		}
	}, [selectedTasks]);
	const sortBySortOrder = (a, b) => {
		return a.node.sortOrder > b.node.sortOrder ? 1 : -1;
	};

	const tasks = viewer.company.allTasks.edges.filter(task => task.node.approved);
	const sortedTasks = useMemo(() => [...tasks].sort(sortBySortOrder), [tasks]);
	const pageInfo = viewer.company.allTasks.pageInfo;

	const loadMore = (pageSize, callback, options) => {
		relay.loadMore(pageSize, callback, options);
		setSocketVariables(sortedTasks.length + pageSize);
	};

	const fetchMoreTasks = async () => {
		const onSuccess = () => {
			fetching.current = false;
		};
		loadMore(30, onSuccess);
	};

	const handleScroll = e => {
		if (!fetching.current && pageInfo.hasNextPage && e.scrollHeight - e.scrollTop < e.clientHeight + 500) {
			fetching.current = true;
			fetchMoreTasks();
		}
	};

	useEffect(() => {
		const taskMap = taskMapRef.current;
		const taskNodes = sortedTasks.map(task => task.node);
		taskMap.set(statusColumnId, taskNodes);
		taskMapRef.current = taskMap;
	}, [sortedTasks]);

	const updateCardHeight = useCallback(
		(taskId, height) => {
			const taskSizeMap = taskSizeMapRef.current;
			const currentTaskHeight = taskSizeMap.get(taskId);
			if (height && height !== currentTaskHeight) {
				taskSizeMap.set(taskId, height);
				taskSizeMapRef.current = taskSizeMap;
			}
		},
		[taskMapRef.current]
	);

	const handleSelect = (task, shiftKey) => {
		if (shiftKey && lastSelected.current) {
			const lastSelectedIndex = sortedTasks.findIndex(sortedTask => sortedTask.node.id === lastSelected.current);
			const newSelectedIndex = sortedTasks.findIndex(selectedTask => selectedTask.node.id === task.node.id);

			const isReverseSelection = newSelectedIndex - lastSelectedIndex < 0;
			const selectedTasks = sortedTasks.reduce((tasksToBeSelected, task, index) => {
				if (isReverseSelection && index <= lastSelectedIndex && index >= newSelectedIndex) {
					tasksToBeSelected.push(task);
				} else if (!isReverseSelection && index >= lastSelectedIndex && index <= newSelectedIndex) {
					tasksToBeSelected.push(task);
				}

				return tasksToBeSelected;
			}, []);

			lastSelected.current = selectedTasks[selectedTasks.length - 1].node.id;
			selectTasks(selectedTasks);
		} else {
			selectTasks([task]);
			lastSelected.current = task.node.id;
		}
	};

	return (
		<Droppable
			dragAndDropGroup={DragAndDropGroup}
			placeholderStyle={{marginRight: 10, marginLeft: 10, border: '1px dashed lightgray', marginBottom: 10}}
			activeHeaderClass={'drop-active'}
			containerHeight={columnHeight}
			containerMinHeight={columnHeight}
			dynamicElemHeight={true}
			minElemHeight={78}
			externalVirtualization={true}
			droppableId={statusColumnId.toString()}
			isDropDisabled={!!shouldShowDisabledOverlay}
			onScroll={e => handleScroll(e)}
		>
			{sortedTasks.map((task, index) => {
				const taskStatusId = task.node.id + '-' + statusColumnId;
				const cardLocked = task.node.readOnly?.isReadOnly;
				return (
					<ReactVisibilitySensor
						partialVisibility={true}
						key={taskStatusId}
						draggableId={taskStatusId}
						droppableId={statusColumnId.toString()}
					>
						{({isVisible}) => {
							return isVisible ? (
								<Draggable
									draggableId={taskStatusId}
									droppableId={statusColumnId.toString()}
									index={index}
									key={taskStatusId}
									disabled={cardLocked || cardReorderInProgress || isClientActionsRestricted}
									dragActiveClass={'active'}
									dragAndDropGroup={DragAndDropGroup}
									noCancelOnMove={true}
								>
									<CardWrapper className={'board-card-outer'} id={taskStatusId} data-cy="task-card">
										<DraggableCard
											isConnectedParent={isConnectedParent}
											selectedTasks={selectedTasks}
											unselectTasks={unselectTasks}
											isSelected={selectedTasks.some(
												selectedTask => selectedTask.node.id === task.node.id
											)}
											onSelect={(task, shiftKey) => handleSelect(task, shiftKey)}
											onDeselect={id => deselectTasksByIds([id])}
											showProjectIndicator={isConnectedParent}
											imgSizeMapRef={imgSizeMapRef}
											theEyeOptions={theEyeOptions}
											onClick={showTaskModal}
											updateCardHeight={updateCardHeight}
											task={task.node}
											viewer={viewer}
											showContext
											showCheckbox
											showTimeReg={!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)}
											showProgressBar
										/>
									</CardWrapper>
								</Draggable>
							) : (
								<CardLoaderWrapper>
									<CardLoader
										height={(taskSizeMapRef.current && taskSizeMapRef.current.get(task.node.id)) || 123}
									/>
								</CardLoaderWrapper>
							);
						}}
					</ReactVisibilitySensor>
				);
			})}
		</Droppable>
	);
};

const ProjectWorkflowColumnContentQuery = graphql`
	query ProjectWorkflowColumnContent_Query(
		$pageSize: Int
		$cursor: String
		$filterColumnId: String
		$searchQuery: TaskSearchQueryType
		$personId: ID!
	) {
		viewer {
			actualPersonId
			component(name: "project_workflow_column")
			...ProjectWorkflowColumnContent_viewer
				@arguments(
					pageSize: $pageSize
					cursor: $cursor
					filterColumnId: $filterColumnId
					searchQuery: $searchQuery
					personId: $personId
				)
		}
	}
`;

// Little bit of a hack to allow paginationContainer to contain other connections without breaking
graphql`
	fragment ProjectWorkflowColumnContent_task on Task {
		id
		thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
			edges {
				node {
					id
					type
					thisDependsOnTask {
						statusColumnV2 {
							category
						}
					}
				}
			}
		}
		dependsOnThisTask(first: 1000) @connection(key: "Task_dependsOnThisTask") {
			edges {
				node {
					id
					type
					taskDependsOnThis {
						id
						statusColumnV2 {
							category
						}
					}
				}
			}
		}
	}
`;

export {ProjectWorkflowColumnContentQuery};

export default React.memo(
	withRouter(
		withSocketHandling(
			createPaginationContainer(
				ProjectWorkflowColumnContent,
				{
					viewer: graphql`
						fragment ProjectWorkflowColumnContent_viewer on Viewer
						@argumentDefinitions(
							pageSize: {type: "Int"}
							cursor: {type: "String"}
							filterColumnId: {type: "String"}
							searchQuery: {type: "TaskSearchQueryType"}
							personId: {type: "ID!"}
						) {
							actualPersonId
							email
							firstName
							lastName
							timerStartDate
							excludeFromCompanyLockedPeriod
							submitLockedDateYear
							submitLockedDateMonth
							submitLockedDateDay
							timerTask {
								id
							}
							startDate
							endDate
							createdAt
							monday
							tuesday
							wednesday
							thursday
							friday
							saturday
							sunday
							availableFeatureFlags {
								key
							}
							harvestUser
							unit4User
							company {
								...genericTaskContextMenu_company
								id
								harvestEnabled
								unit4Enabled
								characterLimit
								lockedPeriodYear
								lockedPeriodMonth
								lockedPeriodDay
								modules {
									moduleType
								}
								roles {
									edges {
										node {
											id
											name
										}
									}
								}
								allTasks(
									first: $pageSize
									after: $cursor
									sortValue: "new-sort-order"
									filterColumnId: $filterColumnId
									searchQuery: $searchQuery
									simpleResponse: true
									approvedOnly: true
								) @connection(key: "Company_allTasks", filters: ["filterColumnId"]) {
									pageInfo {
										endCursor
										hasNextPage
									}
									edges {
										node {
											id
											progress
											name
											billable
											sortOrder
											canStart
											hasInvoicedTime
											hasLockedTime
											canBeSetToDone
											companyTaskId
											readOnly {
												isReadOnly
											}
											userCanDeleteTask
											userCantDeleteTaskReason
											hasTimeRegistrations
											startYear
											startMonth
											startDay
											deadlineDay
											deadlineMonth
											deadlineYear
											timeLeft
											jiraId
											hasChildren
											sageIntacctId
											vstsId
											approved
											statusColumnV2 {
												id
												category
												projectGroupStatusColumnId
											}
											project {
												id
												name
												status
												projectColor
												companyProjectId
												manualProgressOnProjectEnabled
												manualProgressOnPhasesEnabled
												manualProgressOnTasksEnabled
												projectPerson(personId: $personId) {
													role {
														id
														name
													}
												}
											}
											assignedPersons {
												id
											}
											parentTaskId
											...ProjectWorkflowColumnContent_task @relay(mask: false)
											...DraggableCard_task
										}
									}
								}
							}
						}
					`,
				},
				{
					direction: 'forward',
					getConnectionFromProps({viewer}) {
						return viewer.company.allTasks;
					},
					getFragmentVariables(prevVars, totalCount) {
						return {
							...prevVars,
							pageSize: totalCount,
						};
					},
					getVariables(_, {count, cursor}, fragmentVariables) {
						return {
							...fragmentVariables,
							pageSize: count,
							cursor,
						};
					},
					query: graphql`
						query ProjectWorkflowColumnContentRefetchQuery(
							$pageSize: Int
							$cursor: String
							$filterColumnId: String
							$searchQuery: TaskSearchQueryType
							$personId: ID!
						) {
							viewer {
								component(name: "project_workflow_column")
								...ProjectWorkflowColumnContent_viewer
									@arguments(
										pageSize: $pageSize
										cursor: $cursor
										filterColumnId: $filterColumnId
										searchQuery: $searchQuery
										personId: $personId
									)
							}
						}
					`,
				}
			)
		)
	)
);
