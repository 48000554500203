import React from 'react';
import {useIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

import ActionsMenu from '../../shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import Util from '../../shared/util/util';
import DeleteRateCardMutation from '../../../mutations/delete_rate_card_mutation.modern';
import UpdateRateCardMutation from '../../../mutations/update_rate_card_mutation';
import Warning from '../../../components/warning';
import DuplicateRateCardMutation from '../../../mutations/duplicate_rate_card_mutation.modern';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {Table, Tag} from 'web-components';
import styled from 'styled-components';

const DisabledStyle = styled.div`
	color: #c4c4cc;
`;

export const RateCardRowV2 = withRouter(
	({history, card, company, projects, rateCards, tableColumnsProps, openRateCardModal}) => {
		const intl = useIntl();
		const {formatMessage} = intl;

		const projectsWithRateCard = projects.filter(project => (project.rateCard ? project.rateCard.id === card.id : null));
		const isDefault = company.defaultRateCard ? company.defaultRateCard.id === card.id : false;

		const setDefaultRateCard = card => {
			const onSuccess = response => {
				createToast({duration: 5000, message: formatMessage({id: 'settings_finance.default_rate_card_changed'})});
			};

			Util.CommitMutation(
				UpdateCompanyMutation,
				{
					id: company.id,
					defaultRateCardId: card.id,
				},
				onSuccess
			);
		};

		const disableCard = card => {
			Util.CommitMutation(
				UpdateRateCardMutation,
				{
					id: card.id,
					disabled: !card.disabled,
				},
				null,
				true
			);
		};

		const deleteCard = card => {
			const childRateCard = rateCards.find(rateCard => rateCard.parentRateCardId === card.id);

			const onSuccess = () => {
				createToast({duration: 5000, message: formatMessage({id: 'rate-card.has-been-deleted'})});
			};

			const callbackPositive = () => {
				Util.CommitMutation(
					DeleteRateCardMutation,
					{
						id: card.id,
						companyId: company.id,
					},
					onSuccess
				);
			};

			// Show a different modal
			if (childRateCard) {
				const projectWithChildRateCard = projects.find(
					project => project.rateCard && project.rateCard.id === childRateCard.id
				);

				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<div className="default-warning-modal">
							<Warning messageId="delete-parent-rate-card.title" />
							<div className="warning-part-2">
								{formatMessage(
									{id: 'delete-parent-rate-card.description'},
									{projectNumber: projectWithChildRateCard ? projectWithChildRateCard.companyProjectId : 0}
								)}
							</div>
						</div>
					),
					buttons: [
						{
							text: formatMessage({id: 'common.cancel'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
						{
							text: formatMessage({id: 'common.yes'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.RED,
							callback: callbackPositive,
						},
					],
				});
			} else {
				showModal({
					type: MODAL_TYPE.GENERIC_DELETION_WARNING,
					deleteCallback: callbackPositive,
				});
			}
		};

		const duplicateRateCard = card => {
			const onSuccess = () => {
				createToast({duration: 5000, message: formatMessage({id: 'rate-card.has-been-duplicated'})});
			};
			Util.CommitMutation(
				DuplicateRateCardMutation,
				{
					rateCardId: card.id,
					companyId: company.id,
				},
				onSuccess
			);
		};

		const renderActionMenu = card => {
			return (
				<ActionsMenu
					whiteInner={true}
					options={[
						{
							text: formatMessage({id: 'common.edit'}),
							onClick: openRateCardModal.bind(null, card),
							cy: 'edit',
						},
						{
							text: formatMessage({id: 'common.duplicate'}),
							onClick: duplicateRateCard.bind(null, card),
							cy: 'duplicate',
						},
						{
							text: formatMessage({id: 'settings_rate_card.set_default'}),
							onClick: setDefaultRateCard.bind(null, card),
							locked: isDefault || card.disabled,
							cy: 'set_default',
						},
						{
							text: card.disabled ? formatMessage({id: 'common.enable'}) : formatMessage({id: 'common.disable'}),
							onClick: disableCard.bind(null, card),
							locked: isDefault && !card.disabled,
							cy: 'disable',
						},
						{
							text: formatMessage({id: 'common.delete'}),
							onClick: deleteCard.bind(null, card),
							locked: projectsWithRateCard.length > 0,
							disabledTitle: formatMessage({id: 'settings_finance.rate_card_delete_disabled_description'}),
							cy: 'delete',
						},
					]}
					cy={'rate-card'}
				/>
			);
		};

		const renderColumnText = (text, card) => {
			return card.disabled ? <DisabledStyle>{text}</DisabledStyle> : text;
		};

		const renderName = () => {
			return (
				<Table.Column userpilot={'rate-card-name'}>
					{renderColumnText(card.name, card)}
					{isDefault ? <Tag name={formatMessage({id: 'common.default'})} color={'#A5A5AE'} /> : null}
				</Table.Column>
			);
		};

		return (
			<>
				<Table.Row {...tableColumnsProps}>
					{renderName()}
					<Table.Column>{renderColumnText(projectsWithRateCard.length, card)}</Table.Column>
					<Table.Column>{renderColumnText(card.currency, card)}</Table.Column>
					<Table.Column>{renderActionMenu(card)}</Table.Column>
				</Table.Row>
			</>
		);
	}
);
