import styled, {css} from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';
import diagonals_background from '../../../images/diagonal-lines-pattern.svg';

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex: 1;
	height: initial;
	${({overflowHidden}) =>
		overflowHidden &&
		css`
			overflow: hidden;
		`}

	/* Keep the header / content aligned when page gets a scrollbar */
	.scrollbar {
		scrollbar-gutter: stable;
	}
`;

export const MyTimesheetWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 0 24px;
	/* if scrollbar-gutter is no supported by the browser (safari up to 18.1) 
	 * always add 14px of padding on the right side so the header and content 
	 * are aligned without the scrollbar
	 */
	@supports (scrollbar-gutter: stable) {
		padding: 0 10px 0 24px;
	}
	align-items: stretch;
	min-height: 100%;

	@media screen and (min-width: 2137px) {
		align-items: center;
	}
`;

export const MyTimesheetLeftColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const MyTimesheetsColumns = styled.div`
	display: flex;
	flex-direction: row;
	gap: 24px;
`;

export const MyTimesheetsSideModal = styled.div`
	position: sticky;
	top: 0;
	display: flex;
	width: 460px;
	height: 100%;
	flex-direction: column;
	background-color: ${props => props.theme.sys.color.background.neutral['2']};
	border: 1px solid ${props => props.theme.sys.color.border.decorative};
	border-radius: ${props => props.theme.sys.size.borderradius['3']};
	padding: 16px 24px 24px 24px;
`;

export const TopSectionWrapper = styled.div`
	max-width: ${props => (props.timeRegSideModalOpen ? '1578px' : '2089px')};
	@media screen and (min-width: 2137px) {
		width: ${props => (props.timeRegSideModalOpen ? '1578px' : '2065px')};
	}
`;

export const BottomSectionWrapper = styled.div`
	flex-grow: 1;
	max-width: ${props => (props.timeRegSideModalOpen ? '1578px' : '2089px')};
	padding-top: 0;
	border: solid ${CSS_CONSTANTS.app_border_color};
	border-radius: 0 0 ${props => props.theme.sys.size.borderradius['3']} ${props => props.theme.sys.size.borderradius['3']};
	border-width: 0 1px 1px 1px;

	.header-bar {
		min-height: 0;
	}
	> div {
		padding: 0 24px;
	}
	.header-bar-center {
		flex-grow: 1;
	}
	@media screen and (min-width: 2137px) {
		width: ${props => (props.timeRegSideModalOpen ? '1578px' : '2065px')};
	}
`;

export const BottomSectionWrapperOld = styled(BottomSectionWrapper)`
	border-width: 1px;
`;

export const TabSectionWrapper = styled.div`
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: #fff;
	border-top: solid 1px ${CSS_CONSTANTS.app_border_color};
	border-radius: 8px;

	/* these two pseudo elements render a 6 px white line that hides the vertical borders of the table border 
	   that would otherwise be visible when the table is scrolled
	   This became necessary after adding rounded corners to the table (after adding the time reg side modal with 
	   rounded corners it looked off having an element with square corners)
	 */
	> div:first-child::before {
		width: 1px;
		height: 6px;
		background: white;
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		left: -1px;
	}
	> div:last-child::after {
		width: 1px;
		height: 6px;
		background: white;
		content: '';
		display: block;
		position: relative;
		top: -29px;
		left: 25px;
	}
`;

export const DateInfoSectionWrapper = styled.div`
	padding: 8px 12px 8px 12px;
`;

export const PageTitle = styled.div`
	padding-top: 24px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 600;
	color: ${CSS_CONSTANTS.v2_text_gray};
	position: sticky;
	left: 66px;
`;

export const BottomSectionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
`;

export const BottomSectionSubHeader = styled.div`
	.header-bar {
		padding: 16px 0 0;
	}
`;

export const SubHeaderElem = styled.div`
	display: flex;
	font-size: 16px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 600;
	.week-info,
	.total-info,
	.day-info {
		display: flex;
		flex-direction: row;
		align-items: center;
		.holiday-names {
			font-weight: 400;
		}
		.week-number {
			font-weight: 400;
			text-transform: lowercase;
		}
		.total-title {
			margin-right: 16px;
		}
		.checkmark-icon,
		.warning-icon {
			margin-right: 5px;
		}
	}
`;

export const ButtonGroupContainer = styled.div`
	padding: 10px 24px !important;
	border-bottom: 1px solid ${CSS_CONSTANTS.app_border_color};
	position: static;
	.header-bar-info-text {
		font-size: 13px;
		font-weight: bold;
		white-space: nowrap;
	}
	.header-bar {
		padding: 0;
		margin: 0;

		.custom-button-container {
			min-width: 0;
			padding: 0 16px;
		}
	}
`;

export const SubHeaderContainer = styled.div`
	margin-top: 16px;
	.header-bar {
		padding: 0 24px 16px 24px;
		.header-bar-left,
		.header-bar-right {
			max-width: 15%;
		}
		&.no-padding {
			padding: 0;
		}
		.info-component-icon-wrapper {
			margin-left: 0;
			margin-right: 16px;
			top: 3px;
			.tooltip-right {
				font-size: 16px;
				line-height: 1.5;
			}
		}
	}
`;

export const HeaderPageContainer = styled.div`
	.header-bar {
		flex-grow: 1;
		padding: 16px 24px;
		&.no-padding {
			padding: 0;
		}
		.info-component-icon-wrapper {
			margin-left: 0;
			margin-right: 16px;
			top: 3px;
			.tooltip-right {
				font-size: 16px;
				line-height: 1.5;
			}
		}
	}
	@media screen and (min-width: 2137px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		.top-header-bar {
			padding: 16px 0 0;
			width: 2065px;
		}
		.header-bar {
			padding: 16px 0 16px;
			width: 2065px;
		}
	}
`;

export const AddTimeSection = styled.div`
	background-color: white;
	margin-left: 24px;
	border: 1px solid ${CSS_CONSTANTS.app_border_color};
	max-width: 610px;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1980px) {
		flex-grow: 1;
	}
`;

export const AddTimeEntryWrapper = styled.div`
	padding: 16px 0;
	border-top: 1px solid ${CSS_CONSTANTS.app_border_color};
	display: flex;
	flex-grow: 1;
`;

export const AddTimeEntryHeader = styled.div`
	height: 35px;
	background-color: #f5f5f8;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	.time-entry-text {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		margin-right: 4px;
	}
`;

export const InnerTopSectionWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 24px;
`;

export const afterRules = `
content: '';
position: absolute;
top: -1px;
right: -1px;
bottom: -1px;
left: -1px;
z-index: 3;
`;

export const PeriodBarWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	flex-direction: row;
	height: 20px;
	width: 100%;
	.current-date-range {
		flex-grow: 1;
		font-size: 16px;
		font-weight: 500;
		color: ${CSS_CONSTANTS.v2_text_gray};
		.week-number {
			font-weight: normal;
		}
	}
	.total-hours-container {
		display: flex;
		flex-direction: row;
		flex-grow: 0;
		align-self: flex-end;
		font-size: 16px;
		font-weight: 500;
		color: ${CSS_CONSTANTS.v2_text_gray};
		.total-text {
			margin-right: 16px;
		}
	}
`;
export const CalenderStyle = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
`;
export const CalendarTableStyles = styled.table`
	//min-width: 813px;
	max-width: 1455px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	border-collapse: collapse;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	tr {
		display: flex;
		flex-grow: 1;
		th,
		td {
			flex: 1 1 0;
			min-width: 93px;
			white-space: nowrap;
			border-top: solid 1px ${CSS_CONSTANTS.app_border_color};
			border-left: solid 1px ${CSS_CONSTANTS.app_border_color};
			border-right: solid 1px rgba(0, 0, 0, 0);
			border-bottom: solid 1px rgba(0, 0, 0, 0);
			&:first-of-type {
				//week column
				background: ${CSS_CONSTANTS.v2_fixed_element_grey};
			}
			&:last-child {
				border-right: solid 1px ${CSS_CONSTANTS.app_border_color};
			}
		}

		th {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			text-transform: capitalize;
			text-align: center;
			height: 35px;
			color: ${CSS_CONSTANTS.v2_text_light_gray};
			max-width: 182px;
		}
		td {
			height: ${props => (props.bigCells ? '98px' : '86px')};
			max-width: 182px;
			&:hover {
				border: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
			}
			.week-selected {
				//this will add a extra border on top of the current one
				position: relative;
				&:after {
					${afterRules};
					border-top: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
					border-bottom: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
					//background-color: rgba(110, 15, 234, 0.1);
				}
			}
			&:last-of-type {
				.week-selected {
					//this will add a extra border on top of the current one
					position: relative;
					&:after {
						${afterRules};
						border-right: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
						//background-color: rgba(110, 15, 234, 0.1);
					}
				}
			}
			&:first-of-type {
				.week-selected {
					//this will add a extra border on top of the current one
					position: relative;
					&:after {
						${afterRules};
						border-left: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
						//background-color: rgba(110, 15, 234, 0.1);
					}
				}
			}
			&.week-cell {
				&:hover {
					border-right: solid 1px rgba(0, 0, 0, 0);
				}
				&:hover ~ td {
					position: relative;
					&:after {
						${afterRules};
						border-top: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
						border-bottom: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
					}
					&:last-of-type {
						//this will add a extra border on top of the current one
						position: relative;
						&:after {
							${afterRules};
							border-right: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
						}
					}
					&:first-of-type {
						//this will add a extra border on top of the current one
						position: relative;
						&:after {
							${afterRules};
							border-left: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
						}
					}
				}
			}
		}
		&.six-weeks-month {
			td {
				height: 80px;
			}
		}
	}
	@media screen and (min-width: 1980px) {
		tbody tr {
			td {
				height: 4.95vw;
				max-height: 137px;
			}
			&.six-weeks-month {
				td {
					height: 4.04vw;
				}
			}
		}
	}
`;

export const CalendarCellStyle = styled.div`
	position: relative;
	height: 100%;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-size: 13px;
	font-weight: 600;
	cursor: pointer;
	&:hover,
	&:focus {
		//this will add a extra border on top of the current one
		position: relative;
		outline: none;
		&.day-cell {
			&:after {
				${afterRules};
				border: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
			}
		}
		&.week-cell {
			&:after {
				${afterRules};
				border-top: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
				border-bottom: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
				border-left: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
			}
		}
	}
	&.selected {
		position: relative;
		&:after {
			${afterRules};
			border: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
			//background-color: rgba(110, 15, 234, 0.1);
		}
	}
	&.locked {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
	}

	.week-number {
		position: absolute;
		left: 7px;
		top: 5px;
		font-size: 18px;
		color: ${CSS_CONSTANTS.v2_grey};
	}
	.week-total {
		position: absolute;
		right: 10px;
		bottom: 5px;
		text-align: right;
		.week-total-title {
			font-size: 9px;
			text-transform: uppercase;
			color: ${CSS_CONSTANTS.v2_text_light_gray};
			margin-bottom: 5px;
		}
		.week-total-value {
			display: flex;
			justify-content: flex-end;
			.checkmark-icon,
			.warning-icon-title {
				z-index: 4; //needed to have it on top of the :after and have the title
				align-self: flex-end;
				margin-right: 5px;
			}
		}
	}
	.day-number {
		z-index: 2;
		position: absolute;
		top: 8px;
		right: 10px;
		font-size: 12px;
		font-weight: 500;
		text-align: center;

		&.non-working-day {
			color: ${CSS_CONSTANTS.v2_text_light_gray};
		}
		&.outside-month {
			color: ${CSS_CONSTANTS.v2_grey};
		}
		&.today {
			height: 22px;
			width: 22px;
			border-radius: 12px;
			color: ${CSS_CONSTANTS.color_white};
			background: ${CSS_CONSTANTS.v2_branding_purple};
			padding-top: 3px;
			top: 5px;
			right: 5px;
		}
	}
	.time-worked {
		z-index: 2;
		position: absolute;
		right: 10px;
		bottom: 6px;
		display: flex;

		.checkmark-icon {
			align-self: flex-end;
			svg {
				margin-top: 2px;
			}
			margin-right: 5px;
		}
		.total {
			align-self: flex-end;
		}
	}
	.holiday-name {
		z-index: 2;
		position: absolute;
		left: 10px;
		right: 10px;
		top: 29px;
		font-size: 11px;
		text-align: right;
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.background {
		z-index: 1;
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		height: 100%;
		transition: height 0.3s ease-in-out;
		overflow: hidden;
		.white-space-background {
			height: ${props => props.whiteSpaceHeight}%;
			background: white;
			&.non-working-day {
				background-image: url(${diagonals_background});
				background-position: center;
				background-size: auto auto;
			}
		}
		.time-off-background {
			transition: height 0.3s ease-in-out;
			height: ${props => props.timeOffHeight}%;
			background: ${CSS_CONSTANTS.non_project_time_allocation};
		}
		.time-worked-background {
			transition: height 0.3s ease-in-out;
			height: ${props => props.timeWorkedHeight}%;
			background: #eaf8ff;
		}
		&.has-colored-bg {
			height: calc(100% + 1px);
			div {
				width: calc(100% + 1px);
				overflow: visible;
			}
		}
	}
`;
