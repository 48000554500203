import React from 'react';
import { getFormattedName, getLinkFromVariant, processTextToHTML } from './FormatterUtil';
const getFormattedProjectHighlightField = highlightField => {
    switch (highlightField) {
        case 'name': // Name will be shown in the Project field, instead show Project ID in description
        case 'name.shingled_bigram':
        case 'company_project_id':
        case 'company_project_group_id':
        case 'company_project_group_id_fallback':
            return 'ID: '; // it is a Connected Project, but 'Project ID: ' is shorter
        default:
            return `${highlightField}: `;
    }
};
const getFormattedProjectHighlightValue = (highlightField, highlightValue, companyProjectGroupId) => {
    switch (highlightField) {
        case 'name':
        case 'name.shingled_bigram':
            return `X${companyProjectGroupId}`;
        case 'company_project_id':
            return React.createElement("em", null, `X${companyProjectGroupId}`);
        case 'company_project_group_id':
            return React.createElement("em", null, `X${companyProjectGroupId}`);
        case 'company_project_group_id_fallback':
            return `X${companyProjectGroupId}`;
        default:
            return React.createElement("span", null,
                "\"",
                processTextToHTML(highlightValue),
                "\"");
    }
};
const getFormattedProjectHighlight = (highlight, companyProjectGroupId) => {
    // Default to company_project_group_id_fallback if highlight is undefined
    const highlightField = highlight ? highlight.highlightField : 'company_project_group_id_fallback';
    const highlightValue = highlight ? highlight.highlightValue : '';
    const formattedHighlightField = getFormattedProjectHighlightField(highlightField);
    const formattedHighlightValue = getFormattedProjectHighlightValue(highlightField, highlightValue, companyProjectGroupId);
    return {
        highlightField: formattedHighlightField,
        highlightValue: formattedHighlightValue,
    };
};
export const projectGroupFormatter = ({ searchResult, trackAndHideSearch, }) => {
    const onSelect = (rank, openedInNewTab) => {
        trackAndHideSearch({ id: searchResult.id, variant: searchResult.variant, rank, searchResult }, !openedInNewTab);
    };
    const formattedName = getFormattedName(searchResult.name, searchResult.highlight);
    const formattedHighlight = getFormattedProjectHighlight(searchResult.noNameHighlight, searchResult.companyProjectGroupId);
    const link = getLinkFromVariant(searchResult.companyProjectGroupId, searchResult.customProjectId, searchResult.variant);
    return {
        title: searchResult.name,
        name: formattedName,
        highlight: formattedHighlight,
        variant: searchResult.variant,
        companyProjectId: searchResult.companyProjectGroupId,
        projectColor: searchResult.projectColor,
        projectIndicatorName: searchResult.name,
        ariaLabel: `Search result. Type: Connected Project. Title: ${searchResult.name}. Highlight: ${formattedHighlight.highlightField}: ${formattedHighlight.highlightValue}`,
        link,
        onSelect,
    };
};
