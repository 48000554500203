import React, { useMemo } from 'react';
import FilterV4 from '../../../shared/components/filters/FilterWrapper';
import { FILTER_SECTIONS } from '../../../shared/components/filters/dropdown_section';
import { FILTER_SECTION, FILTER_TYPE } from '../../../../constants';
import { getFiltersAlphabetically } from '../../../shared/components/filters/filter_util';
import { useIntl } from 'react-intl';
import Util from '../../../shared/util/util';
import { isClientUser } from '../../../shared/util/PermissionsUtil';
import { createFragmentContainer, graphql } from 'react-relay';
import { hasFeatureFlag } from '../../../shared/util/FeatureUtil';
const localStorageKey = 'timesheets-filters';
const getTaskFilters = () => {
    const taskFilters = [
        FILTER_TYPE.PROJECT,
        FILTER_TYPE.STATUS_CATEGORY,
        FILTER_TYPE.DEADLINE,
        FILTER_TYPE.DEPENDENCIES,
        FILTER_TYPE.FOLLOWER,
        FILTER_TYPE.INDICATOR_FILTERED,
        FILTER_TYPE.LABEL,
        FILTER_TYPE.PHASE_CATEGORY,
        FILTER_TYPE.OWNER,
        FILTER_TYPE.RECENT_ACTIVITY,
        FILTER_TYPE.SPRINT_CATEGORY,
        FILTER_TYPE.PROJECT_STAGE,
        FILTER_TYPE.TASK_HIERARCHY,
        FILTER_TYPE.TASK_STATUS,
        FILTER_TYPE.PERSON,
        FILTER_TYPE.TASK_PROJECT_LABEL,
    ];
    if (hasFeatureFlag('timesheet_remaster')) {
        taskFilters.push(FILTER_TYPE.ALL_PROJECT_PHASES);
        taskFilters.push(FILTER_TYPE.RECENT_TASK_ACTIVITY);
    }
    if (!isClientUser()) {
        taskFilters.push(FILTER_TYPE.TEAM);
        taskFilters.push(FILTER_TYPE.CLIENT);
    }
    return taskFilters;
};
const getPrimaryFilters = () => {
    const primaryFilters = {
        [FILTER_SECTIONS.TASKS]: [
            /*FIXME: If we want to bring back the deadline filter we need to address how it interacts with the selected day / week
      Currently we hardcode a start and end date filter based on the calendar selection and offer no way of disabling those
      Since the entire tab is labeled "this weeks work" it also makes sense to keep the date filter hardcoded
      FILTER_TYPE.DEADLINE, */
            FILTER_TYPE.CLIENT,
            FILTER_TYPE.PROJECT,
        ],
    };
    if (hasFeatureFlag('timesheet_remaster')) {
        primaryFilters[FILTER_SECTIONS.TASKS].push(FILTER_TYPE.ALL_PROJECT_PHASES);
    }
    primaryFilters[FILTER_SECTIONS.TASKS].push(FILTER_TYPE.STATUS_CATEGORY, FILTER_TYPE.PERSON);
    return primaryFilters;
};
const getDefaultFilters = (personId) => {
    const defaultFilers = { task: { person: [personId], statusCategory: ['INPROGRESS'] } };
    Util.localStorageSetItem(localStorageKey, JSON.stringify(defaultFilers));
    return defaultFilers;
};
export const getInitialState = (personId) => {
    let localStorageFilters = undefined;
    try {
        localStorageFilters = JSON.parse(Util.localStorageGetItem(localStorageKey));
    }
    catch (e) {
        console.error(e);
    }
    if (localStorageFilters) {
        return localStorageFilters;
    }
    return getDefaultFilters(personId);
};
// FIXME: Exported directly to avoid Relay fragment error. Once timesheets_page is transitioned to TSX, it should start using the fragment instead.
export const TimesheetsFilters = ({ filters, setFilters, viewer }) => {
    const { formatMessage } = useIntl();
    const taskFilters = useMemo(getTaskFilters, []);
    const primaryFilters = useMemo(getPrimaryFilters, []);
    const onFiltersChange = filters => {
        Util.localStorageSetItem(localStorageKey, JSON.stringify(filters));
        setFilters(filters);
    };
    return (React.createElement(FilterV4, { taskFilters: getFiltersAlphabetically(taskFilters, formatMessage), primaryFilters: primaryFilters, defaultSection: FILTER_SECTIONS.TASKS, filterSection: FILTER_SECTION.MY_TIMESHEETS, onFiltersChange: onFiltersChange, appliedFiltersName: localStorageKey, iconOnly: undefined, noMenu: undefined, operatorOptions: undefined, timeRegFilters: undefined, invoiceFilters: undefined, peopleFilters: undefined, projectFilters: undefined, skillFilters: undefined, labelFilters: undefined, placeholderFilters: undefined, resourceFilters: undefined, useSavedReport: undefined, preAppliedFilters: filters, controlledFiltersObject: filters, projectId: undefined, projectGroupId: undefined, programPrefix: undefined, companyProjectGroupId: undefined, companyProjectId: undefined, userpilot: undefined, cy: 'timesheet-filters', enableFilterMode: undefined, useDesignSystemButton: undefined, filterExpandedKey: '-timesheets-filters', viewer: viewer }));
};
//FIXME: this fragment should be added to timesheets_page and TimesheetsBottomSection when timesheets_page is converted to TS
export default createFragmentContainer(TimesheetsFilters, {
    viewer: graphql `
		fragment TimesheetsFilters_viewer on Viewer {
			id
			calendarIntegrations {
				googleCalendar {
					calendarFetchEnabled
				}
				outlookCalendar {
					calendarFetchEnabled
				}
			}
			filters(first: 1000000, filterSection: MY_TIMESHEETS) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
						updatedAt
					}
				}
			}
		}
	`,
});
