import { Button, DatePicker, EMPHASIS } from '@forecast-it/design-system';
import React from 'react';
import styled from 'styled-components';
const DatePickerWithButtonsStyle = styled.div `
	display: flex;
	gap: 4px;
`;
const DatePickerWithButtons = ({ value, onDatePickerChange, onBackButton, onForwardButton, dataCy, }) => {
    return (React.createElement(DatePickerWithButtonsStyle, null,
        React.createElement(Button, { "data-cy": `${dataCy}-back-button`, onClick: onBackButton, emphasis: EMPHASIS.MONO, icon: 'chevronLeft', iconOnly: true }),
        React.createElement(DatePicker, { value: value, onChange: onDatePickerChange, "data-cy": dataCy, "aria-label": "date-picker" }),
        React.createElement(Button, { "data-cy": `${dataCy}-foward-button`, onClick: onForwardButton, emphasis: EMPHASIS.MONO, icon: 'chevronRight', iconOnly: true })));
};
export default DatePickerWithButtons;
