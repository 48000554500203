import React from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';
import { TIME_PERIOD } from '../../../timesheets_change_date_view';
import { buildWeekArr } from '../../time_row_utils';
import { getEntityList } from '../timesheet-table-util/DataBundlingUtil';
import { getDayRegsForSwitchEntity } from '../timesheet-table-util/DataUtil';
import { formatForDayView } from '../timesheet-table-util/TimeRegSuggestionUtil';
import TimesheetTable from '../timesheets_time_row_table';
import { useSuggestions } from './useSuggestions';
const TimesheetTimeTableWrapper = ({ viewer, relay, retry, selectedPerson, enabledColumns, timeRegistrations, timeRegsForPeriod, weekStart, weekEnd, currentViewingDate, timePeriod, searchValue, sort, selectDayFromCalendar, holidays, lockedDate, isFullyLocked, usingTimeApproval, usingInternalTimeApproval, usingTimeOffApproval, periodTimeApprovalStatus, overBudgetProgramsByCompanyProjectId, timeRegistrationTargetSuggestionsData, validTimeRegistrationSuggestions, goToTimesheetsTab, showSuggestions, hasSuggestions, }) => {
    const history = useHistory();
    const intl = useIntl();
    const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;
    const weekDays = isWeekView ? buildWeekArr(currentViewingDate.clone().endOf('week'), 7) : [];
    const { timeRegistrationsWithoutMovedSuggestions, suggestionEntitiesWithTimeRegs } = useSuggestions({
        suggestions: validTimeRegistrationSuggestions,
        timeRegistrations: timeRegsForPeriod,
        currentViewingDate,
        isWeekView,
    });
    //const timeRegsToShow = isWeekView && showSuggestions ? timeRegistrationsWithoutMovedSuggestions : timeRegsForPeriod;
    const timeRegsToShow = showSuggestions ? timeRegistrationsWithoutMovedSuggestions : timeRegsForPeriod;
    const suggestionsToShow = showSuggestions ? suggestionEntitiesWithTimeRegs : new Map();
    const _getDayRegsForSwitchEntity = entity => {
        return getDayRegsForSwitchEntity(isWeekView, new Map(), entity);
    };
    const timeRegEntityList = getEntityList([], timeRegsToShow, sort.value, sort.ascending, isWeekView, weekDays, [], _getDayRegsForSwitchEntity);
    const suggestionEntitiesFormattedForDayView = formatForDayView(suggestionsToShow);
    const allEntities = isWeekView
        ? new Map([...suggestionsToShow, ...timeRegEntityList])
        : [...suggestionEntitiesFormattedForDayView, ...timeRegEntityList];
    return (React.createElement(TimesheetTable, { entityList: allEntities, externalSortValue: sort.value, externalSortAscending: sort.ascending, setExternalSortValue: sort.setValue, setExternalSortAscending: sort.setAscending, loading: false, intl: intl, history: history, viewer: viewer, relay: relay, retry: retry, selectedPerson: selectedPerson, enabledColumns: enabledColumns, timeRegistrations: timeRegistrations, timeRegsForPeriod: timeRegsForPeriod, weekStart: weekStart, weekEnd: weekEnd, currentViewingDate: currentViewingDate, timePeriod: timePeriod, searchActive: !!searchValue, selectDayFromCalendar: selectDayFromCalendar, holidays: holidays, lockedDate: lockedDate, isFullyLocked: isFullyLocked, usingTimeApproval: usingTimeApproval, usingInternalTimeApproval: usingInternalTimeApproval, usingTimeOffApproval: usingTimeOffApproval, periodTimeApprovalStatus: periodTimeApprovalStatus, overBudgetProgramsByCompanyProjectId: overBudgetProgramsByCompanyProjectId, timeRegistrationTargetSuggestionsData: timeRegistrationTargetSuggestionsData, goToTimesheetsTab: goToTimesheetsTab, thisWeekWork: false, showSuggestions: showSuggestions, hasSuggestions: hasSuggestions }));
};
const TimesheetTimeTableWrapperQuery = graphql `
	query TimesheetTimeTableWrapper_Query {
		viewer {
			actualPersonId
			component(name: "TimesheetTimeTableWrapper")
			...TimesheetTimeTableWrapper_viewer
		}
	}
`;
export { TimesheetTimeTableWrapperQuery };
export default createFragmentContainer(TimesheetTimeTableWrapper, {
    viewer: graphql `
		fragment TimesheetTimeTableWrapper_viewer on Viewer {
			id
			actualPersonId
			harvestUser
			unit4User
			company {
				id
				bambooHREnabled
				harvestEnabled
				unit4Enabled
				sageIntacctEnabled
				useTimeOffApproval
				roles(includeDisabled: false) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	`,
});
