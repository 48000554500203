import React, {useEffect, useMemo} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import ProjectSprintsV4 from './project_sprints_v4';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {useForecastFetchQuery} from '../../../shared/hooks/useForecastFetchQuery';
import {shouldShowTaskOverrun, trackPredictionsShown} from '../../../shared/util/PredictionUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {useSecondaryNavState} from '../../../navigation/secondary/SecondaryNavigationStateManager';
import {sprintsPredictionsQuery} from './ProjectSprintsPredictionsQuery';
import {keyBy} from 'lodash';

export const ProjectSprintPageV4 = ({viewer, relay, retry, setSocketVariables, setSocketConfig, buyNowTime}) => {
	const {fetch, data: predictionData} = useForecastFetchQuery(sprintsPredictionsQuery);

	const {expanded: sideNavExpanded, locked: sideNavLocked} = useSecondaryNavState()
		? useSecondaryNavState()
		: {expanded: false, locked: false};

	useEffect(() => {
		if (viewer.project && shouldShowTaskOverrun(viewer.project.estimationUnit === 'HOURS', PERMISSION_TYPE.SPRINT_UPDATE)) {
			fetch({projectId: viewer.project.id});
		} else if (
			viewer.projectGroup &&
			shouldShowTaskOverrun(
				viewer.projectGroup.projects.edges[0].node.estimationUnit === 'HOURS',
				PERMISSION_TYPE.SPRINT_UPDATE
			)
		) {
			fetch({groupId: '' + viewer.projectGroup.companyProjectGroupId});
		}
	}, []);

	const predictionDataMap = useMemo(() => {
		if (predictionData) {
			const {viewer} = predictionData;
			const {predictionProject, predictionProjectGroup} = viewer;
			if (predictionProjectGroup && predictionProjectGroup.predictedOverrunningTasks) {
				const formattedOverrunningTasks = predictionProjectGroup.predictedOverrunningTasks.map(oTask => ({
					taskId: oTask.globalTaskId,
					predictedEstimate: {estimate: oTask.estimate, overrunProbability: oTask.overrunProbability},
				}));
				return keyBy(formattedOverrunningTasks, o => o.taskId);
			} else if (predictionProject && predictionProject.predictedOverrunningTasks) {
				const formattedOverrunningTasks = predictionProject.predictedOverrunningTasks.map(oTask => ({
					taskId: oTask.globalTaskId,
					predictedEstimate: {estimate: oTask.estimate, overrunProbability: oTask.overrunProbability},
				}));
				return keyBy(formattedOverrunningTasks, o => o.taskId);
			}
		}
	}, [predictionData]);

	useEffect(() => {
		if (predictionDataMap) {
			const isConnectedParent = !!(!viewer.project && viewer.projectGroup);
			const tasks = isConnectedParent
				? viewer.projectGroup.projects.edges.reduce(
						(acc, project) => acc.concat(project.node.tasks ? project.node.tasks.edges : []),
						[]
				  )
				: viewer.project.tasks.edges;
			const predictionDataTasks = tasks.map(t => {
				return {
					...t.node,
					predictedEstimate: predictionDataMap[t.node.id]?.predictedEstimate,
				};
			});
			trackPredictionsShown(predictionDataTasks);
		}
	}, [predictionDataMap]);

	const lowHighModelScore = predictionData && predictionData.viewer.company.availableMLModels.lowHighModelScore;

	const refetch = (variables, renderVariables, onSuccess) => {
		relay.refetch(variables, renderVariables, onSuccess);
		setSocketVariables(variables);
	};

	return (
		<ProjectSprintsV4
			viewer={viewer}
			predictionDataMap={predictionDataMap}
			lowHighModelScore={lowHighModelScore}
			relay={relay}
			retry={retry}
			setSocketConfig={setSocketConfig}
			refetch={refetch}
			buyNowTime={buyNowTime}
			sideNavExpanded={sideNavExpanded}
			sideNavLocked={sideNavLocked}
		/>
	);
};

const ProjectSprintPageV4Query = graphql`
	query ProjectSprintPageV4_Query(
		$projectId: String
		$groupId: String
		$fetchLazyData: Boolean!
		$onlyActiveSprint: Boolean!
		$sprintIds: [String]
		$personId: ID!
	) {
		viewer {
			actualPersonId
			component(name: "project_sprints_optimized_v4")
			project(id: $projectId) {
				id
			}
			projectGroup(id: $groupId) {
				projects(first: 1000000) {
					edges {
						node {
							id
						}
					}
				}
			}
			...ProjectSprintPageV4_viewer
				@arguments(
					projectId: $projectId
					groupId: $groupId
					fetchLazyData: $fetchLazyData
					onlyActiveSprint: $onlyActiveSprint
					sprintIds: $sprintIds
					personId: $personId
				)
		}
	}
`;

export {ProjectSprintPageV4Query};

export default withSocketHandling(
	createRefetchContainer(
		ProjectSprintPageV4,
		{
			viewer: graphql`
				fragment ProjectSprintPageV4_viewer on Viewer
				@argumentDefinitions(
					projectId: {type: "String"}
					groupId: {type: "String"}
					fetchLazyData: {type: "Boolean!"}
					onlyActiveSprint: {type: "Boolean!"}
					sprintIds: {type: "[String]"}
					personId: {type: "ID!"}
				) {
					id
					language
					email
					firstName
					lastName
					backendId
					harvestUser
					timerStartDate
					actualPersonId
					excludeFromCompanyLockedPeriod
					submitLockedDateYear
					submitLockedDateMonth
					submitLockedDateDay
					availableFeatureFlags {
						key
					}
					timerTask {
						id
					}
					projects(first: 1000000) {
						edges {
							node {
								id
								companyProjectId
								customProjectId
								name
								status
								isInProjectGroup
								projectColor
							}
						}
					}
					filters(first: 1000000, projectId: $projectId, projectGroupId: $groupId)
						@connection(key: "Viewer_filters", filters: []) {
						edges {
							node {
								id
								name
								section
								value
								updatedAt
							}
						}
					}
					company {
						...genericTaskContextMenu_company
						id
						backendId
						name
						currency
						harvestEnabled
						lockedPeriodYear
						lockedPeriodMonth
						lockedPeriodDay
						isUsingProjectAllocation
						isUsingMixedAllocation
						person(id: $personId) {
							id
							role {
								id
								name
							}
						}
						modules {
							moduleType
						}
						roles(first: 1000000) {
							edges {
								node {
									id
									name
								}
							}
						}
						labels(first: 1000000, labelType: TASK) {
							edges {
								node {
									id
									name
									color
									category {
										id
										name
									}
									...LabelDropdown_labels
								}
							}
						}
						clients(first: 1000000) {
							edges {
								node {
									id
									name
									logoId
									logoDefaultId
								}
							}
						}
						teams(first: 1000000) {
							edges {
								...TeamDropdown_teams
								node {
									id
									name
									teamPersons(first: 1000000) {
										edges {
											node {
												id
												person {
													id
												}
											}
										}
									}
								}
							}
						}
						monday
						tuesday
						wednesday
						thursday
						friday
						saturday
						sunday
					}
					projectGroup(id: $groupId) {
						...ProjectHeader_projectGroup
						...SecondaryNavigation_projectGroup
						id
						companyProjectGroupId
						name
						color
						projectGroupSprints(first: 1000000, lazyLoadData: true)
							@connection(key: "ProjectGroup_projectGroupSprints", filters: []) {
							edges {
								node {
									id
									name
									sprintPersons(first: 1000000) {
										edges {
											node {
												id
												availableMinutes @include(if: $fetchLazyData)
												scheduledMinutes @include(if: $fetchLazyData)
												taskAvailableMinutes @include(if: $fetchLazyData)
												person {
													id
													firstName
													lastName
													profilePictureId
													profilePictureDefaultId
													role {
														id
														name
													}
												}
											}
										}
									}
								}
							}
						}
						projects(first: 1000000) {
							edges {
								node {
									id
									name
									demo
									status
									companyProjectId
									customProjectId
									...DeprecatedProjectIndicatorJS_project
									taskLevels
									sprintTimeBox
									sprintLengthInDays
									useManualAllocations
									remainingAutoCalculated
									projectColor
									estimationUnit
									projectStartYear
									projectStartMonth
									projectStartDay
									projectEndYear
									projectEndMonth
									projectEndDay
									minutesPerEstimationPoint
									manualProgressOnProjectEnabled
									manualProgressOnPhasesEnabled
									manualProgressOnTasksEnabled
									budgetWork
									budgetType
									rateCard {
										id
										currency
										disabledRoles {
											id
										}
									}
									client {
										id
										name
									}
									projectPersons(first: 10000) {
										edges {
											node {
												person {
													id
													active
													permissions
													firstName
													lastName
													fullName
													initials
													profilePictureId
													profilePictureDefaultId
													role {
														id
														name
													}
													client {
														id
													}
												}
												role {
													id
													name
												}
											}
										}
									}
									statusColumnsV2(first: 1000000) {
										edges {
											node {
												id
												order
												name
												projectGroupStatusColumnId
												jiraStatusId
												category
											}
										}
									}
									phases(first: 1000) {
										edges {
											node {
												id
												name
											}
										}
									}
									sprints(first: 1000, onlyProjectGroupSprints: true)
										@connection(key: "Project_sprints", filters: []) {
										edges {
											node {
												id
												name
												projectGroupSprintId
												isProjectGroupSprint
												startDay
												startMonth
												startYear
												endDay
												endMonth
												endYear
												description @include(if: $fetchLazyData)
												savedPerformance
												hasUnfinishedTask
												integrations {
													id
													jiraId
													adoId
												}
											}
										}
									}
									tasks(
										first: 100000
										approvedOnly: true
										simpleResponse: true
										activeSprint: $onlyActiveSprint
										sprintIds: $sprintIds
										context: "SPRINT"
									) @connection(key: "Project_tasks", filters: []) {
										edges {
											node {
												id
												readOnly {
													isReadOnly
												}
												userCanDeleteTask
												userCantDeleteTaskReason
												hasChildren
												sageIntacctId
												name
												description
												highPriority
												companyTaskId
												estimateForecast
												timeLeft @include(if: $fetchLazyData)
												approved
												sortOrder
												blocked
												bug
												estimateForecastPrice
												currentPrice @include(if: $fetchLazyData)
												deadlineYear
												deadlineMonth
												deadlineDay
												startYear
												startMonth
												startDay
												startFrom
												deadlineFrom
												hasDependency
												canStart
												canBeSetToDone
												subTaskCount
												latestUiUpdateAt
												hasLockedTime
												hasInvoicedTime
												favoured
												billable
												parentTaskId
												project {
													id
													demo
													estimationUnit
													minutesPerEstimationPoint
													companyProjectId
													customProjectId # CSV export
													...DeprecatedProjectIndicatorJS_project
													projectEndYear
													projectEndMonth
													projectEndDay
													projectColor
													remainingAutoCalculated
													name
													status
													projectGroupId
													harvestProject {
														id
													}
													client {
														id
													}
													rateCard {
														id
														currency
														disabledRoles {
															id
														}
													}
													useTaskHierarchy
												}
												taskLabels {
													id
													label {
														id
														name
														color
													}
												}
												sprint {
													id
													endYear
													endMonth
													endDay
													savedPerformance
													name
													projectGroupSprintId
													isProjectGroupSprint
												}
												statusColumnV2 {
													id
													name
													category
													projectGroupStatusColumnId
												}
												phase {
													id
													name
													deadlineYear
													deadlineMonth
													deadlineDay
												}
												role {
													id
													name
												}
												assignedPersons {
													id
													active
													firstName
													lastName
													profilePictureId
													profilePictureDefaultId
													permissions
													client {
														id
													}
												}
												taskDismissedNotifications {
													taskOverrunPredictionDismissed
												}
												timeRegistrations(first: 10000)
													@connection(key: "Task_timeRegistrations", filters: [])
													@include(if: $fetchLazyData) {
													edges {
														node {
															id
															minutesRegistered
															billableMinutesRegistered
															price
															invoiced
															person {
																id
																role {
																	id
																}
															}
														}
													}
												}
												thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
													edges {
														node {
															id
															type
															thisDependsOnTask {
																id
																name
																deadlineYear
																deadlineMonth
																deadlineDay
																startYear
																startMonth
																startDay
															}
														}
													}
												}
												owner {
													id
													permissions
												}
												followers {
													id
													permissions
												}
												parentTask {
													id
													name
												}
												progress @include(if: $fetchLazyData)
												progressDetails {
													progress
												}
											}
										}
									}
								}
							}
						}
					}
					projectGroups(first: 1000000) @connection(key: "Viewer_projectGroups", filters: []) {
						edges {
							node {
								id
								companyProjectGroupId
								name
							}
						}
					}
					project(id: $projectId) {
						...ProjectHeader_project
						...SecondaryNavigation_project
						id
						demo
						name
						isInProjectGroup
						projectGroupId
						status
						companyProjectId
						customProjectId
						taskLevels
						sprintTimeBox
						sprintLengthInDays
						projectColor
						estimationUnit
						remainingAutoCalculated
						minutesPerEstimationPoint
						useManualAllocations
						useTaskFollowers
						useTaskOwner
						isJiraProject
						harvestProjectId
						projectStartYear
						projectStartMonth
						projectStartDay
						projectEndYear
						projectEndMonth
						projectEndDay
						manualProgressOnProjectEnabled
						manualProgressOnPhasesEnabled
						manualProgressOnTasksEnabled
						budgetWork
						budgetType
						rateCard {
							id
							currency
							disabledRoles {
								id
							}
						}
						projectPersons(first: 1000000) {
							edges {
								node {
									person {
										id
										active
										permissions
										firstName
										lastName
										fullName
										initials
										profilePictureId
										profilePictureDefaultId
										role {
											id
											name
										}
										client {
											id
										}
									}
									role {
										id
										name
									}
								}
							}
						}
						statusColumnsV2(first: 1000000) {
							edges {
								node {
									id
									order
									name
									jiraStatusId
									category
								}
							}
						}
						phases(first: 1000000) {
							edges {
								node {
									id
									name
								}
							}
						}
						sprints(first: 1000000) @connection(key: "Project_sprints", filters: []) {
							edges {
								node {
									id
									name
									timeLeft
									forecast
									startDay
									startMonth
									startYear
									endDay
									endMonth
									endYear
									description @include(if: $fetchLazyData)
									savedPerformance
									hasUnfinishedTask
									sprintPersons(first: 1000000) {
										edges {
											node {
												id
												availableMinutes @include(if: $fetchLazyData)
												scheduledMinutes @include(if: $fetchLazyData)
												taskAvailableMinutes @include(if: $fetchLazyData)
												person {
													id
													firstName
													lastName
													profilePictureId
													profilePictureDefaultId
													role {
														id
														name
													}
												}
											}
										}
									}
									integrations {
										id
										jiraId
										adoId
									}
								}
							}
						}
						tasks(
							first: 100000
							approvedOnly: true
							simpleResponse: true
							activeSprint: $onlyActiveSprint
							sprintIds: $sprintIds
							context: "SPRINT"
						) @connection(key: "Project_tasks", filters: []) {
							edges {
								node {
									id
									readOnly {
										isReadOnly
									}
									userCanDeleteTask
									userCantDeleteTaskReason
									name
									description
									highPriority
									companyTaskId
									estimateForecast
									timeLeft @include(if: $fetchLazyData)
									approved
									sortOrder
									currentPrice @include(if: $fetchLazyData)
									estimateForecastPrice
									blocked
									bug
									deadlineYear
									deadlineMonth
									deadlineDay
									startYear
									startMonth
									startDay
									startFrom
									deadlineFrom
									hasDependency
									canStart
									canBeSetToDone
									subTaskCount
									latestUiUpdateAt
									hasLockedTime
									hasInvoicedTime
									favoured
									billable
									hasChildren
									parentTaskId
									sageIntacctId
									taskLabels {
										id
										label {
											id
											name
											color
										}
									}
									sprint {
										id
										endYear
										endMonth
										endDay
										name
									}
									statusColumnV2 {
										id
										name
										category
									}
									phase {
										id
										name
										deadlineYear
										deadlineMonth
										deadlineDay
									}
									role {
										id
										name
									}
									assignedPersons {
										id
										active
										firstName
										lastName
										email
										profilePictureId
										profilePictureDefaultId
										permissions
										client {
											id
										}
									}
									taskDismissedNotifications {
										taskOverrunPredictionDismissed
									}
									timeRegistrations(first: 10000)
										@connection(key: "Task_timeRegistrations", filters: [])
										@include(if: $fetchLazyData) {
										edges {
											node {
												id
												minutesRegistered
												billableMinutesRegistered
												price
												invoiced
												person {
													id
													role {
														id
													}
												}
											}
										}
									}
									project {
										id
										name
										demo
										client {
											id
											name
										}
										harvestProject {
											id
										}
										remainingAutoCalculated
										companyProjectId
										customProjectId
										estimationUnit
										minutesPerEstimationPoint
										projectStartYear
										projectStartMonth
										projectStartDay
										projectEndYear
										projectEndMonth
										projectEndDay
										projectColor
										status
										useTaskHierarchy
									}
									thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
										edges {
											node {
												id
												type
												thisDependsOnTask {
													id
													name
													deadlineYear
													deadlineMonth
													deadlineDay
													startYear
													startMonth
													startDay
												}
											}
										}
									}
									owner {
										id
										permissions
									}
									followers {
										id
										permissions
									}
									parentTask {
										id
										name
									}
									progress @include(if: $fetchLazyData)
									progressDetails {
										progress
									}
								}
							}
						}
					}
					psProject(companyProjectId: $projectId) {
						...ProjectHeader_psProject
					}
				}
			`,
		},
		graphql`
			query ProjectSprintPageV4RefetchQuery(
				$projectId: String
				$groupId: String
				$fetchLazyData: Boolean!
				$onlyActiveSprint: Boolean!
				$sprintIds: [String]
				$personId: ID!
			) {
				viewer {
					component(name: "project_sprints_optimized_refetch_v4")
					...ProjectSprintPageV4_viewer
						@arguments(
							projectId: $projectId
							groupId: $groupId
							fetchLazyData: $fetchLazyData
							onlyActiveSprint: $onlyActiveSprint
							sprintIds: $sprintIds
							personId: $personId
						)
				}
			}
		`
	)
);
