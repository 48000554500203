import React from 'react';
import ForecastQueryRenderer from '../../../../../../ForecastQueryRenderer';
import InlineLoader from '../../../../../shared/components/inline-loader/inline_loader';
import {toggleSlideout} from '../../../../../shared/components/slideout/SlideoutConductor';
import SprintGoalSlideout, {SprintGoalSlideoutQuery} from './SprintGoalSlideout';

const sprintGoalSlideoutContent = ({sprintId, projectGroupSprintId, projectColor}) => (
	<ForecastQueryRenderer
		key="query-render-sprint-goal-slideout"
		query={SprintGoalSlideoutQuery}
		variables={{sprintId: sprintId, projectGroupSprintId: projectGroupSprintId}}
		customLoader={() => <InlineLoader />}
		render={(relayProps, retry) => {
			// @ts-ignore
			return <SprintGoalSlideout {...relayProps} projectColor={projectColor} />;
		}}
	/>
);

export const toggleSprintGoalsSlideout = ({slideoutId, sprintId, projectGroupSprintId, projectColor, invokerRef}) => {
	toggleSlideout(slideoutId, {
		content: sprintGoalSlideoutContent({sprintId, projectGroupSprintId, projectColor}),
		trackingName: 'Sprint Goals Slideout',
		invokerRef,
		width: 570,
	});
};
