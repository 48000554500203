import styled, { css } from 'styled-components';
import { FlexRow, calendarTokens } from '@forecast-it/design-system';
import { CalendarEventStyled } from './calendar-events/CalendarEvent_styled';
const tokens = calendarTokens.calendar;
export const Ellipsis = styled.div `
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-all;
`;
export const HeaderContainer = styled.div `
	display: flex;
	height: 100%;
`;
export const HeaderText = styled.div `
	color: ${tokens.color.text.content.auxiliary} ${tokens.font.cell.bold};
`;
export const CellTextBold = styled.div `
	${({ color }) => css `
			color: ${color === 'onLight'
    ? `${tokens.color.text.content.onlight}`
    : color === 'disabledOnDisabled'
        ? `${tokens.color.text.content.auxiliary}`
        : `${tokens.color.text.content.main}`};
		`}
	${tokens.font.cell.bold}
`;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const CellText = styled(Ellipsis) `
	${({ color }) => css `
			color: ${color === 'onLight'
    ? `${tokens.color.text.content.onlight}`
    : color === 'disabledOnDisabled'
        ? `${tokens.color.text.content.auxiliary}`
        : `${tokens.color.text.content.main}`};
		`}
	${tokens.font.cell.regular}
`;
const afterRules = `
	border-radius: inherit;
	content: '';
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	pointer-events: none;
`;
const weekBorder = `
	position: relative;

	&:after {
		${afterRules};
		border-top: solid 1px ${tokens.color.border.midemphasis};
		border-bottom: solid 1px ${tokens.color.border.midemphasis};
	}

	&:last-of-type {
		position: relative;

		&:after {
			${afterRules};
			border-right: solid 1px ${tokens.color.border.midemphasis};
		}
	}
`;
export const RowWrapper = styled.div `
	display: contents;
	border-radius: inherit;
`;
export const LinkWrapper = styled(FlexRow) `
	align-self: flex-start;
`;
export const DayInfoWrapper = styled.div `
	padding: ${props => (props.isDayToday ? `6px 8px 6px 14px` : `8px 8px 8px 14px`)};
	min-height: 36px;
	display: flex;
	align-items: center;
`;
export const DayCellStyle = styled.div `
	height: 100%;
	width: 100%;
	padding: 8px 8px 8px 2px;
	background-color: ${props => props.showCheckMark ? tokens.color.cell.filled.background.default : tokens.color.cell.notfilled.background.default};
	cursor: pointer;

	outline-color: ${tokens.color.border.decorative};
	outline-style: solid;
	outline-width: 1px;

	${({ nonWorkingDay }) => nonWorkingDay === true &&
    css `
			background-image: linear-gradient(
				-45deg,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 0) 40%,
				rgba(0, 0, 0, 0.05) 40%,
				rgba(0, 0, 0, 0.05) 50%,
				rgba(0, 0, 0, 0) 50%,
				rgba(0, 0, 0, 0) 90%,
				rgba(0, 0, 0, 0.05) 90%,
				rgba(0, 0, 0, 0.05)
			);
			background-size: 12px 12px;
		`}

	${({ selected }) => selected === true &&
    css `
			outline-style: solid;
			outline-width: 1px;
			outline-color: ${tokens.color.border.midemphasis};
			z-index: 2;
		`}
    ${({ hoverable, locked, showCheckMark }) => {
    if (!hoverable) {
        return;
    }
    const hoverColor = showCheckMark
        ? tokens.color.cell.filled.background.hover
        : tokens.color.cell.notfilled.background.hover;
    /**
     * Hello future confused developer!
     * The below css adds a background color to <DayCell> only if you are not hovering a <CalendarEvent> or a <LinkWrapper>
     */
    return css `
			&:hover:not(:has(${CalendarEventStyled}:hover)):not(:has(${LinkWrapper}:hover)) {
				background-color: ${locked ? `${hoverColor.locked}` : `${hoverColor.default}`};
			}
		`;
}};
`;
export const WeekCellStyle = styled.div `
	height: 100%;
	width: 100%;
	padding: 16px;
	min-height: 52px;
	align-content: baseline;
	background-color: ${props => props.showWeekFull ? tokens.color.cell.filled.background.default : tokens.color.cell.notfilled.background.default};

	cursor: pointer;

	outline-color: ${tokens.color.border.decorative};
	outline-style: solid;
	outline-width: 1px;

	${({ weekSelected }) => weekSelected &&
    css `
			position: relative;

			&:after {
				${afterRules};
				border-left: solid 1px ${tokens.color.border.midemphasis};
				border-top: solid 1px ${tokens.color.border.midemphasis};
				border-bottom: solid 1px ${tokens.color.border.midemphasis};
			}

			~ div {
				${weekBorder}
			}
		`}

	${({ hoverable, showWeekFull }) => hoverable !== false &&
    `
			&:hover {
				background-color: ${showWeekFull
        ? tokens.color.cell.filled.background.hover.default
        : tokens.color.cell.notfilled.background.hover.default};
			}

			&:hover ~ ${DayCellStyle} {
				background-color: ${tokens.color.cell.notfilled.background.hover.default};
			}
		`}
`;
export const Grid = styled.div `
	${({ firstDayOfWeek, hideWeekend }) => firstDayOfWeek === 0
    ? css `
					${hideWeekend
        ? css `
								grid-template-columns: minmax(196px, 3fr) repeat(5, minmax(172px, 6fr));
						  `
        : css `
								grid-template-columns: minmax(196px, 3fr) minmax(122px, 3fr) repeat(5, minmax(172px, 6fr)) minmax(
										122px,
										3fr
									);
						  `}
			  `
    : css `
					${hideWeekend
        ? css `
								grid-template-columns: minmax(196px, 3fr) repeat(5, minmax(172px, 6fr));
						  `
        : css `
								grid-template-columns: minmax(196px, 3fr) repeat(5, minmax(172px, 6fr)) repeat(
										2,
										minmax(122px, 3fr)
									);
						  `}
			  `}

	display: grid;
	width: 100%;
	gap: 1px;
	padding: 1px;
	border-radius: ${props => props.theme.sys.size.borderradius['3']};

	> ${RowWrapper}:first-child {
		> ${DayCellStyle}:last-child {
			border-top-right-radius: inherit;
		}

		> ${WeekCellStyle}:first-child {
			border-top-left-radius: inherit;
		}
	}

	> ${RowWrapper}:last-child {
		> ${WeekCellStyle}:first-child {
			border-bottom-left-radius: inherit;
		}

		> ${DayCellStyle}:last-child {
			border-bottom-right-radius: inherit;
		}
	}
`;
