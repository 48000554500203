/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderInlineDataFragment } from 'relay-runtime';
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TASK_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type GenericSavedReportConductor_savedReport$ref: FragmentReference;
declare export opaque type GenericSavedReportConductor_savedReport$fragmentType: GenericSavedReportConductor_savedReport$ref;
export type GenericSavedReportConductor_savedReport = {|
  +id: string,
  +updatedAt: ?string,
  +updatedBy: ?{|
    +id: string,
    +fullName: ?string,
  |},
  +type: ?string,
  +name: ?string,
  +person: ?{|
    +id: string,
    +active: ?boolean,
    +email: ?string,
    +fullName: ?string,
    +profilePictureId: ?string,
  |},
  +groupingOne: ?string,
  +groupingTwo: ?string,
  +groupingThree: ?string,
  +groupingFour: ?string,
  +eyeApplied: ?string,
  +filterApplied: ?string,
  +filters: ?$ReadOnlyArray<?{|
    +field: ?GLOBAL_FILTER_FIELD,
    +value: ?$ReadOnlyArray<?string>,
    +operator: ?GLOBAL_FILTER_OPERATOR,
  |}>,
  +startDate: ?string,
  +endDate: ?string,
  +periodType: ?string,
  +periodDate: ?string,
  +reportService: ?boolean,
  +shares: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +user: ?{|
          +id: string,
          +email: ?string,
        |},
      |}
    |}>
  |},
  +privateAccess: ?boolean,
  +state: ?string,
  +$refType: GenericSavedReportConductor_savedReport$ref,
|};
export type GenericSavedReportConductor_savedReport$data = GenericSavedReportConductor_savedReport;
export type GenericSavedReportConductor_savedReport$key = {
  +$data?: GenericSavedReportConductor_savedReport$data,
  +$fragmentRefs: GenericSavedReportConductor_savedReport$ref,
  ...
};
*/


const node/*: ReaderInlineDataFragment*/ = {
  "kind": "InlineDataFragment",
  "name": "GenericSavedReportConductor_savedReport"
};
// prettier-ignore
(node/*: any*/).hash = '0e1ec97185e1e3b87b2ff29dd2ad0dd8';

module.exports = node;
