import Moment from 'moment';
import {cloneDeep, groupBy} from 'lodash';
import {getFilterFuncForKey, isInFilter, keyToFilterField} from './filter_util';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import CreateFilterMutation from '../../../../mutations/create_filter_mutation';
import UpdateFilterMutation from '../../../../mutations/update_filter_mutation';
import DeleteFilterMutation from '../../../../mutations/delete_filter_mutation';
import Util from '../../util/util';
import {BUDGET_TYPE, FILTER_TYPE, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import {FILTER_SECTIONS} from './dropdown_section';

const shouldFilter = filterCategory => {
	return filterCategory && filterCategory.length !== 0;
};

const getRequireAllFilterPredicate = (entity, filterValues, options, filterFunc) => {
	let filterPredicate = true;

	filterValues.forEach(value => {
		filterPredicate = filterPredicate && filterFunc(entity, [value], options);
	});

	return filterPredicate;
};

export const FILTER_OPERATOR = {
	EXCLUDE: 'exclude',
	REQUIRE_ALL: 'requireAll',
};

export const FILTER_OPERATORS_EXTENSION = 'operators';

export const getEntityOperatorKey = key => {
	return `${key}_${FILTER_OPERATORS_EXTENSION}`;
};

export const getFilterOperator = (filter, key) => {
	return filter[getEntityOperatorKey(key)];
};

export const isOperator = key => {
	return key.endsWith(`_${FILTER_OPERATORS_EXTENSION}`);
};

const entityFilterPredicate = (entity, entityType, filters, options, skipFilterType = () => false) => {
	let filterPredicate = true;
	if (filters?.[entityType]) {
		const filter = filters[entityType];
		for (const key of Object.keys(filter)) {
			if (shouldFilter(filter[key]) && !isOperator(key) && !skipFilterType(key)) {
				const operators = getFilterOperator(filter, key);
				const filterFunc = getFilterFuncForKey(key);

				const applyExclude = operators?.[FILTER_OPERATOR.EXCLUDE] === true;
				const applyRequireAll = operators?.[FILTER_OPERATOR.REQUIRE_ALL] === true;

				const filterLogicPredicate = applyRequireAll
					? getRequireAllFilterPredicate(entity, filter[key], options, filterFunc)
					: filterFunc(entity, filter[key], options);

				filterPredicate = applyExclude ? !filterLogicPredicate : filterLogicPredicate;

				if (!filterPredicate) {
					break;
				}
			}
		}
	}

	return filterPredicate;
};

/*
 * Each filter function can optionally take an options object to supply to the filterFunc. This should ONLY be used for backwards compatibility with old filters.
 * In the future, all new filters should take input: (task, filters).
 * Currently, it is used when supplying a value which is not included in the passed object itself.
 */
export const getFilterFunctions = filters => {
	const taskFilter = (task, options) => entityFilterPredicate(task, FILTER_SECTIONS.TASKS, filters, options);
	const projectFilter = (project, options) =>
		entityFilterPredicate(project, FILTER_SECTIONS.PROJECTS, filters, options, key =>
			[FILTER_TYPE.INTERNAL_TIME, FILTER_TYPE.TIME_OFF].includes(key)
		);
	const personFilter = (person, options) => entityFilterPredicate(person, FILTER_SECTIONS.PEOPLE, filters, options);
	const allocationFilter = (allocation, options) =>
		entityFilterPredicate(
			allocation,
			FILTER_SECTIONS.PROJECTS,
			filters,
			options,
			key => ![FILTER_TYPE.INTERNAL_TIME, FILTER_TYPE.TIME_OFF].includes(key)
		);
	const invoiceFilter = (invoice, options) => entityFilterPredicate(invoice, FILTER_SECTIONS.INVOICES, filters, options);
	const skillFilter = (skill, options) => entityFilterPredicate(skill, FILTER_SECTIONS.SKILLS, filters, options);
	const labelFilter = (label, options) => entityFilterPredicate(label, FILTER_SECTIONS.LABELS, filters, options);
	const placeholderFilter = (placeholder, options) =>
		entityFilterPredicate(placeholder, FILTER_SECTIONS.PLACEHOLDERS, filters, options);
	const resourceFilter = (resource, options) => entityFilterPredicate(resource, FILTER_SECTIONS.RESOURCES, filters, options);
	const timeRegFilter = (timeReg, options) => entityFilterPredicate(timeReg, FILTER_SECTIONS.TIMEREGS, filters, options);
	const expenseFilter = (invoice, options) => entityFilterPredicate(invoice, FILTER_SECTIONS.EXPENSES, filters, options);

	return {
		taskFilter,
		projectFilter,
		personFilter,
		allocationFilter,
		invoiceFilter,
		skillFilter,
		labelFilter,
		placeholderFilter,
		resourceFilter,
		timeRegFilter,
		expenseFilter,
	};
};

export const removeFilter = (key, k, id, appliedFilters, changeAppliedFilters) => {
	let appliedFiltersCpy = cloneDeep(appliedFilters);
	const idx = appliedFiltersCpy[key][k].findIndex(x => id === x);
	appliedFiltersCpy[key][k].splice(idx, 1);
	changeAppliedFilters(appliedFiltersCpy);
};

export const saveFilters = (filterName, viewerId, filterValues, filterSection, projectId, projectGroupId, callback) => {
	const mutationObject = {
		viewerId: viewerId,
		name: filterName,
		value: JSON.stringify(filterValues),
		section: filterSection ? filterSection : undefined,
		projectId: projectId ? projectId : undefined,
		projectGroupId: projectGroupId ? projectGroupId : undefined,
	};

	Util.CommitMutation(CreateFilterMutation, mutationObject, response => {
		dispatch(EVENT_ID.SAVED_FILTERS_UPDATE, response);
		if (callback) {
			callback(response.createFilter.filter.node.id);
		}
	});
};

export const filtersToArray = storedFilters => {
	if (!storedFilters) {
		return [];
	} else {
		const appliedFilters = [];
		Object.entries(storedFilters).forEach(([section, filterTypes]) => {
			Object.entries(filterTypes).forEach(([filterType, ids]) => {
				if (filterType && !isOperator(filterType)) {
					if (filterType === FILTER_TYPE.SKILL_AND) {
						// Backwards compatibility to old require-all logic
						filterType = FILTER_TYPE.SKILL;
					}
					ids.forEach(id => {
						appliedFilters.push({section, filterType, id});
					});
				}
			});
		});
		return appliedFilters;
	}
};

export const isFilterOperatorEnabled = (appliedFilterOperators, section, filterType, filterOperator) => {
	return !!appliedFilterOperators[section]?.[getEntityOperatorKey(filterType)]?.[filterOperator];
};

export const updateFilterOperator = (filterOperators, section, filterType, operator, checked) => {
	const newAppliedFilterOperators = cloneDeep(filterOperators);
	let sectionObj = newAppliedFilterOperators[section];
	if (!sectionObj) {
		sectionObj = newAppliedFilterOperators[section] = {};
	}
	const filterTypeKey = getEntityOperatorKey(filterType);
	if (!sectionObj[filterTypeKey]) {
		sectionObj[filterTypeKey] = {};
	}
	sectionObj[filterTypeKey][operator] = checked;
	return newAppliedFilterOperators;
};

export const filtersToOperators = storedFilters => {
	const appliedOperators = {};
	if (storedFilters) {
		Object.entries(storedFilters).forEach(([section, filterTypes]) => {
			Object.entries(filterTypes).forEach(([filterType, operators]) => {
				if (filterType && isOperator(filterType)) {
					if (!appliedOperators[section]) {
						appliedOperators[section] = {};
					}
					appliedOperators[section][filterType] = operators;
				} else if (filterType && filterType === FILTER_TYPE.SKILL_AND) {
					// Backwards compatibility to old require-all logic
					const filterOperatorType = getEntityOperatorKey(FILTER_TYPE.SKILL);
					if (!appliedOperators[section][filterOperatorType]) {
						appliedOperators[section][filterOperatorType] = {};
					}
					appliedOperators[section][filterOperatorType].requireAll = true;
				}
			});
		});
	}
	return appliedOperators;
};

export const filtersToObject = (filters, appliedFilterOperators) => {
	let initObject = {};
	if (appliedFilterOperators) {
		initObject = cloneDeep(appliedFilterOperators);
	}
	if (!filters) {
		return initObject;
	}
	const localStorageFilters = initObject;
	const groupedBySection = groupBy(filters, 'section');
	Object.entries(groupedBySection).forEach(([section, filters]) => {
		localStorageFilters[section] = localStorageFilters[section] || {};
		const groupedByFilterType = groupBy(filters, 'filterType');
		Object.entries(groupedByFilterType).forEach(([filterType, ids]) => {
			localStorageFilters[section][filterType] = ids.map(id => id.id);
		});
	});
	return localStorageFilters;
};

export const renameSavedFilter = (id, name) => {
	Util.CommitMutation(UpdateFilterMutation, {id: id, name: name}, response => {
		dispatch(EVENT_ID.SAVED_FILTERS_UPDATE, response);
	});
};

export const deleteSavedFilter = (id, viewerId) => {
	Util.CommitMutation(DeleteFilterMutation, {id, viewerId}, response => {
		dispatch(EVENT_ID.SAVED_FILTERS_UPDATE, response);
	});
};

export const getUnifiedFilterOperator = (filterKey, filters, reverseOperator = false) => {
	const entityOperators = filters[getEntityOperatorKey(filterKey)] ?? {requireAll: false, exclude: false};
	let operator = isInFilter(filterKey) ? GLOBAL_FILTER_OPERATOR.IN : GLOBAL_FILTER_OPERATOR.IS;

	const exclude = reverseOperator ? !entityOperators?.exclude : entityOperators?.exclude;

	if (operator === GLOBAL_FILTER_OPERATOR.IN) {
		if (entityOperators.requireAll && exclude) {
			operator = GLOBAL_FILTER_OPERATOR.NOT_ALL_IN;
		} else if (entityOperators.requireAll && !exclude) {
			operator = GLOBAL_FILTER_OPERATOR.ALL_IN;
		} else if (!entityOperators.requireAll && exclude) {
			operator = GLOBAL_FILTER_OPERATOR.NOT_IN;
		} else if (!entityOperators.requireAll && !exclude) {
			operator = GLOBAL_FILTER_OPERATOR.IN;
		}
	} else if (operator === GLOBAL_FILTER_OPERATOR.IS) {
		if (entityOperators.requireAll && exclude) {
			operator = GLOBAL_FILTER_OPERATOR.IS_NOT_ALL;
		} else if (entityOperators.requireAll && !exclude) {
			operator = GLOBAL_FILTER_OPERATOR.IS_ALL;
		} else if (!entityOperators.requireAll && exclude) {
			operator = GLOBAL_FILTER_OPERATOR.IS_NOT;
		} else if (!entityOperators.requireAll && !exclude) {
			operator = GLOBAL_FILTER_OPERATOR.IS;
		}
	}

	return operator;
};

/**
 * This is a list of filters that need some conversion before changing it to a "Global" filter.
 *
 * @type {string[]} The the list of filters
 */
const CONVERT_TO_GLOBAL_FILTER = [FILTER_TYPE.PROJECT_TYPE];

/**
 * This method is used to convert a local filter to a global filter.
 *
 * It is used, when there is a need for some manipulation etc.
 *
 * @param key The filter key
 * @param filterValues The filter values
 * @param allFilters All filter values for context (to find operators)
 * @returns {{field: (string|*), value, operator: (string)}} The Global filter
 */
export const convertToGlobalFilter = (key, filterValues, allFilters) => {
	if (key === FILTER_TYPE.PROJECT_TYPE) {
		const budgetValues = filterValues.map(value => Util.stringToProjectType(value));
		const fixedPrice = budgetValues.filter(value => value === BUDGET_TYPE.FIXED_PRICE).length > 0;
		if (fixedPrice) {
			budgetValues.push(BUDGET_TYPE.FIXED_PRICE_V2);
		}
		let valueString = budgetValues.map(val => val.toString());
		const operator = getUnifiedFilterOperator(key, allFilters);
		return {
			field: keyToFilterField(key, false),
			operator: operator,
			value: valueString,
		};
	}
};

/**
 * This method will convert local task filters
 * into server side Global filters.
 *
 * Some of them re converted into multiple Global Filters, and some of them
 * is a one-2-one conversion.
 *
 * @param filters The list of task filters
 * @param isProjectGroup Is this from a project group?
 * @paramt oldRecentActivity Change recentActivity to dates (Default true)
 * @returns {*[]} Array of Server Side Global task filters.
 */
export const parseTaskFilters = (filters, isProjectGroup, oldRecentActivity = true) => {
	const filterValues = [];

	if (filters?.task) {
		for (const [key, value] of Object.entries(filters.task)) {
			if (isOperator(key)) {
				continue;
			}
			if (key === FILTER_TYPE.INDICATOR_FILTERED) {
				value.forEach(val => {
					if (val === 'non-billable') {
						const operator = getUnifiedFilterOperator(key, filters.task, true);
						const formattedFilter = {
							field: keyToFilterField(val, isProjectGroup),
							operator: operator,
							value: ['true'],
						};
						filterValues.push(formattedFilter);
					} else {
						const operator = getUnifiedFilterOperator(key, filters.task);
						const formattedFilter = {
							field: keyToFilterField(val, isProjectGroup),
							operator: operator,
							value: ['true'],
						};
						filterValues.push(formattedFilter);
					}
				});
			} else if (
				(key === FILTER_TYPE.RECENT_ACTIVITY || key === FILTER_TYPE.RECENT_TASK_ACTIVITY) &&
				value.length > 0 &&
				oldRecentActivity
			) {
				let timeVal = Moment().startOf('day').format('YYYY-MM-DD');
				if (value.includes('month')) {
					timeVal = Moment().subtract(1, 'month').startOf('day').format('YYYY-MM-DD');
				} else if (value.includes('week')) {
					timeVal = Moment().subtract(1, 'week').startOf('day').format('YYYY-MM-DD');
				}
				const entityOperators = filters.task[getEntityOperatorKey(key)];
				const formattedFilter = {
					field: keyToFilterField(key, isProjectGroup),
					operator: entityOperators?.exclude ? GLOBAL_FILTER_OPERATOR.LESS : GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
					value: [timeVal],
				};
				filterValues.push(formattedFilter);
				// If the filter is in the list of filters that needs some conversion - do it.
			} else if (CONVERT_TO_GLOBAL_FILTER.includes(key) && value.length > 0) {
				filterValues.push(convertToGlobalFilter(key, value, filters.task));
			} else if (value.length > 0) {
				const operator = getUnifiedFilterOperator(key, filters.task);
				const valueString = value.map(val => val.toString());
				const formattedFilter = {
					field: keyToFilterField(key, isProjectGroup),
					operator: operator,
					value: valueString,
				};
				filterValues.push(formattedFilter);
			}
		}
	}

	return filterValues;
};
