/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AIBulkButton_viewer$ref = any;
export type AIBulkButton_QueryVariables = {||};
export type AIBulkButton_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: AIBulkButton_viewer$ref,
  |}
|};
export type AIBulkButton_Query = {|
  variables: AIBulkButton_QueryVariables,
  response: AIBulkButton_QueryResponse,
|};
*/


/*
query AIBulkButton_Query {
  viewer {
    actualPersonId
    component(name: "ai_bulk_button")
    ...AIBulkButton_viewer
    id
  }
}

fragment AIBulkButton_viewer on Viewer {
  availableFeatureFlags {
    key
    id
  }
  company {
    availableMLModels {
      lowHighModel
      roleModel
      assigneeModel
      labelModel
    }
    sageIntacctEnabled
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "ai_bulk_button"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"ai_bulk_button\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AIBulkButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AIBulkButton_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AIBulkButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailableMLModels",
                "kind": "LinkedField",
                "name": "availableMLModels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lowHighModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelModel",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sageIntacctEnabled",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AIBulkButton_Query",
    "operationKind": "query",
    "text": "query AIBulkButton_Query {\n  viewer {\n    actualPersonId\n    component(name: \"ai_bulk_button\")\n    ...AIBulkButton_viewer\n    id\n  }\n}\n\nfragment AIBulkButton_viewer on Viewer {\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    availableMLModels {\n      lowHighModel\n      roleModel\n      assigneeModel\n      labelModel\n    }\n    sageIntacctEnabled\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76a0bfeb3ab2139870c52e6e86ff12a2';

module.exports = node;
