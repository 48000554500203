import {cloneDeep} from 'lodash';
import DeleteTaskMutation from '../../../../mutations/delete_task_mutation';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import Util from '../../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../shared/components/toasts/toast';
import {compareSprintDates} from './ProjectScopingUtil';
import {isClientUser} from '../../../shared/util/PermissionsUtil';
import {shouldShowTaskOverrun, showOverrunPredictionWarning} from '../../../shared/util/PredictionUtil';
import CreateTaskDismissedNotification from '../../../../mutations/CreateTaskDismissedNotification';
import React from 'react';
import {ArrowRightOutline, BinOutline, MoreActionsOutline} from '../../../../images/svg-collection';
import {BUTTON_VARIANT} from '../../../../constants';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {isJiraToForecastOneWaySync} from '../../../shared/util/JiraUtil';

const bulkUpdateMoveToPhase = (selectedTasks, phases, setSelectedTasks, intl) => {
	const callbackPositive = selected => {
		const onSuccess = result => {
			createToast({duration: 5000, message: intl.formatMessage({id: 'bulk_update.toast'})});
		};
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: selectedTasks.map(t => t.id),
				phaseId: selected ? selected.value : null,
			},
			onSuccess
		);

		if (setSelectedTasks) {
			setSelectedTasks([]);
		}
	};
	const phase_select_options = phases.edges.map(phase => ({value: phase.node.id, label: phase.node.name}));
	showModal({
		type: MODAL_TYPE.SELECT_V2,
		title: intl.formatMessage({id: 'project_section.bulk_move_cards_milesone'}),
		defaultCallback: callbackPositive,
		options: phase_select_options,
		label: intl.formatMessage({id: 'common.scope-group'}),
		multi: false,
		initialValue: null,
	});
};

const bulkUpdateStatus = (selectedTasks, statusColumns, intl) => {
	const callbackPositive = selected => {
		const onSuccess = result => {
			createToast({duration: 5000, message: intl.formatMessage({id: 'bulk_update.toast'})});
		};
		const mutationObject = {
			ids: selectedTasks.map(t => t.id),
		};
		mutationObject.statusColumnId = selected ? selected.value : null;
		mutationObject.statusColumnCategory = selected ? selected.category : null;
		Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
	};
	const status_select_options = cloneDeep(statusColumns.edges)
		.sort((a, b) => a.node.order - b.node.order)
		.map(col => ({value: col.node.id, label: col.node.name, category: col.node.category}));
	showModal({
		type: MODAL_TYPE.SELECT_V2,
		title: intl.formatMessage({id: 'bulk_update_option.move_to_status'}),
		defaultCallback: callbackPositive,
		options: status_select_options,
		label: intl.formatMessage({id: 'common.status'}),
		multi: false,
		initialValue: null,
	});
};

const bulkUpdateSprint = (selectedTasks, sprints, isConnectedParent, intl) => {
	const callbackPositive = selected => {
		const onSuccess = result => {
			createToast({duration: 5000, message: intl.formatMessage({id: 'bulk_update.toast'})});
		};
		const mutationObject = {
			ids: selectedTasks.map(t => t.id),
		};
		if (!selected) return;
		if (isConnectedParent) {
			mutationObject.projectGroupSprintId = selected.value;
		} else {
			mutationObject.sprintId = selected.value;
		}

		Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
	};
	const sprint_select_options = cloneDeep(sprints.edges)
		.sort(compareSprintDates)
		.map(sprint => ({value: sprint.node.id, label: sprint.node.name}));

	sprint_select_options.push({value: null, label: intl.formatMessage({id: 'project_sprints.backlog'})});

	showModal({
		type: MODAL_TYPE.SELECT_V2,
		title: intl.formatMessage({id: 'project_section.bulk_move_cards_sprint'}),
		defaultCallback: callbackPositive,
		options: sprint_select_options,
		label: intl.formatMessage({id: 'common.sprint'}),
		multi: false,
		initialValue: null,
	});
};

const bulkMoveToJira = (selectedTasks, project) => {
	const taskIds = selectedTasks.map(task => task.id);
	if (project && taskIds && taskIds.length > 0) {
		const hasParent = selectedTasks.some(t => t.parentTaskId != null);
		showModal({
			type: MODAL_TYPE.CREATE_JIRA_ISSUE,
			projectId: project.id,
			taskIds: taskIds,
			jiraType: project.jiraCloudProject || project.jiraCloudEpicIds?.length ? 'cloud' : 'server',
			useTaskHierarchy: project.useTaskHierarchy,
			hasParent,
		});
	}
};

const bulkMoveToAdo = (selectedTasks, project) => {
	const taskIds = selectedTasks.map(task => task.id);
	if (project && taskIds && taskIds.length > 0) {
		if (project.vstsProject && project.vstsAccount) {
			showModal({
				type: MODAL_TYPE.CREATE_ADO_WORKITEM,
				projectId: project.id,
				vstsAccount: project.vstsAccount,
				vstsArea: project.vstsArea,
				taskIds: taskIds,
			});
		}
	}
};

const bulkUpdateMoveToProject = (selectedTasks, projectId, setSelectedTasks) => {
	const currentProjectId = projectId;
	showModal({type: MODAL_TYPE.TASK_LOCATION, currentProjectId: currentProjectId, taskId: selectedTasks.map(task => task.id)});

	setSelectedTasks([]);
};

const bulkUpdateTask = (
	selectedTasks,
	roles,
	projectPersons,
	statusColumns,
	labels,
	companyId,
	estimationUnit,
	isJiraProject,
	useTaskFollowers,
	availableFeatureFlags,
	intl
) => {
	const onSuccess = errors => {
		if (errors && errors.length !== 0) {
			Util.resolveTaskStatusErrors(errors, intl);
		}
	};
	const role_options = roles.edges.map(role => ({value: role.node.id, label: role.node.name}));
	const people_options = projectPersons.edges.map(pp => ({
		value: pp.node.person.id,
		label: pp.node.person.firstName + ' ' + pp.node.person.lastName,
		profilePictureId: pp.node.person.profilePictureId,
		profilePictureDefaultId: pp.node.person.profilePictureDefaultId,
	}));
	const status_options = statusColumns.edges.map(col => ({
		value: col.node.id,
		label: col.node.name,
		logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
	}));
	showModal({
		type: MODAL_TYPE.BULK_TASK_UPDATE,
		useTaskFollowers: useTaskFollowers,
		role_options: role_options,
		people_options: people_options,
		status_options: status_options,
		labels: labels.edges,
		companyId: companyId,
		tasks: selectedTasks.map(t => t.id),
		estimationUnit: estimationUnit,
		saveCallback: onSuccess,
		isJiraProject: isJiraProject,
		availableFeatureFlags: availableFeatureFlags,
	});
};

const stopTimer = (task, project, preventedDelete) => {
	if (hasFeatureFlag('new_time_registration_modal')) {
		showModal({
			type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
			timerActionTaskId: task.id,
			preventedDelete: preventedDelete,
		});
	} else {
		showModal({
			type: MODAL_TYPE.TIMER_V3,
			isPreviousTimerReminder: false,
			timerProject: project,
			timerTask: task,
			preventedDelete: preventedDelete,
		});
	}
};

const bulkUpdateDelete = (selectedTasks, project, timerStartDate, timerTask, intl, setSelectedTasks) => {
	const {formatMessage} = intl;
	if (timerStartDate) {
		for (let task of selectedTasks) {
			if (timerTask && task.id === timerTask.id) {
				stopTimer(task, project, true);
				return;
			}
		}
	}
	const callbackPositive = () => {
		const onSuccess = result => {
			setSelectedTasks([]);
			createToast({duration: 5000, message: formatMessage({id: 'bulk_delete.toast'})});
		};

		Util.CommitMutation(DeleteTaskMutation, {projectId: project.id, ids: selectedTasks.map(t => t.id)}, onSuccess);
	};
	showModal({
		type: MODAL_TYPE.GENERIC_DELETION_WARNING,
		deletedItems: selectedTasks,
		deleteCallback: callbackPositive,
	});
};

const bulkDiscardPredictions = (selectedTasks, setSelectedTasks, intl) => {
	const onSuccess = result => {
		createToast({duration: 5000, message: intl.formatMessage({id: 'common.predictions_discarded'})});
		setSelectedTasks([]);
	};

	const mutation = {
		taskIds: selectedTasks.map(t => t.id),
		eventType: 'TASK_OVERRUN',
	};
	Util.CommitMutation(CreateTaskDismissedNotification, mutation, onSuccess);
};

export const getBulkUpdateOptions = (
	selectedTasks,
	setSelectedTasks,
	project,
	company,
	timerTask,
	timerStartDate,
	availableFeatureFlags,
	intl
) => {
	const {id: companyId, roles, labels} = company;
	const {
		id: projectId,
		sprints,
		phases,
		statusColumnsV2: statusColumns,
		isJiraProject,
		vstsProject,
		vstsTwoWaySync,
		projectPersons,
		estimationUnit,
		useTaskFollowers,
	} = project;

	const invoiced = selectedTasks && selectedTasks.some(task => task.hasInvoicedTime);
	const lockedTimeReg = selectedTasks && selectedTasks.some(task => task.hasLockedTime);
	const selectionHasBlockingTask = selectedTasks.some(t => !t.userCanDeleteTask);
	const isAdoProject = vstsProject && vstsTwoWaySync;
	const isSageIntacctProject = project.sageProject.sageProjectId;

	const taskSubtreeSelected =
		selectedTasks &&
		selectedTasks.some(t => t.parentTaskId != null && selectedTasks.filter(t2 => t2.id === t.parentTaskId).length === 0);

	let list = [
		{
			id: 'bulk update',
			label: intl.formatMessage({id: 'project_section.bulk_card_update'}),
			icon: color => <MoreActionsOutline color={color} width={16} height={16} />,
			callback: () =>
				bulkUpdateTask(
					selectedTasks,
					roles,
					projectPersons,
					statusColumns,
					labels,
					companyId,
					estimationUnit,
					isJiraProject,
					useTaskFollowers,
					availableFeatureFlags,
					intl
				),
			userpilot: 'bulk-update-button',
			cy: 'bulk-update-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: isSageIntacctProject,
		},
	];
	if (!isClientUser()) {
		const bulkMoveOptions = [
			{
				label: intl.formatMessage({id: 'bulk_update_option.move_to_status'}),
				callback: () => bulkUpdateStatus(selectedTasks, statusColumns, intl),
				disabled: isJiraProject,
			},
			{
				label: intl.formatMessage({id: 'project_section.bulk_move_cards_milesone'}),
				callback: () => bulkUpdateMoveToPhase(selectedTasks, phases, setSelectedTasks, intl),
				disabled: taskSubtreeSelected,
			},
			{
				label: intl.formatMessage({id: 'task_location_modal.title'}),
				callback: () => bulkUpdateMoveToProject(selectedTasks, projectId, setSelectedTasks),
				disabled: invoiced || lockedTimeReg || project.harvestProjectId || isJiraProject,
			},
		];
		if (project.sprintTimeBox) {
			bulkMoveOptions.splice(1, 0, {
				label: intl.formatMessage({id: 'bulk_update_option.move_to_sprint'}),
				callback: () => bulkUpdateSprint(selectedTasks, sprints, false, intl),
			});
		}
		if (!isJiraToForecastOneWaySync(company) && isJiraProject) {
			const tasksWithoutJiraId = selectedTasks.filter(task => !task.jiraId);
			bulkMoveOptions.push({
				label: intl.formatMessage({id: 'common.move_to_jira'}),
				callback: () => bulkMoveToJira(tasksWithoutJiraId, project),
				disabled: !tasksWithoutJiraId || !tasksWithoutJiraId.length,
			});
		}
		if (isAdoProject) {
			const tasksWithoutAdoId = selectedTasks.filter(task => !task.vstsId);
			bulkMoveOptions.push({
				label: intl.formatMessage({id: 'common.move_to_ado'}),
				callback: () => bulkMoveToAdo(tasksWithoutAdoId, project),
				disabled: !tasksWithoutAdoId || !tasksWithoutAdoId.length,
			});
		}

		list.push({
			id: 'move_tasks',
			label: intl.formatMessage({id: 'bulk_edit.move_tasks'}),
			icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
			options: bulkMoveOptions,
			userpilot: 'bulk-move-button',
			cy: 'bulk-move-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: isSageIntacctProject,
		});
	}

	list.push({
		id: 'delete',
		icon: color => <BinOutline color={color} width={16} height={16} />,
		label: intl.formatMessage({id: 'common.delete'}),
		callback: () => bulkUpdateDelete(selectedTasks, project, timerStartDate, timerTask, intl, setSelectedTasks),
		disabled: selectionHasBlockingTask || invoiced || lockedTimeReg || isJiraProject || isSageIntacctProject,
		userpilot: 'bulk-delete-button',
		cy: 'bulk-delete-button',
		variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
	});

	return list;
};

export const getAdditionalBulkUpdateAutomateOptions = (
	selectedTasks,
	setSelectedTasks,
	predictionDataMap,
	project,
	permission,
	availableFeatureFlags,
	intl
) => {
	let list = [];
	if (predictionDataMap) {
		const selectedTasksWithPrediction = selectedTasks.map(t => {
			return {
				...t,
				predictedEstimate: predictionDataMap[t.id]?.predictedEstimate,
			};
		});
		const tasksToUpdate = selectedTasksWithPrediction.filter(task => showOverrunPredictionWarning(task));
		if (tasksToUpdate.length > 0 && shouldShowTaskOverrun(project.estimationUnit === 'HOURS', permission, project.demo)) {
			list.push({
				label: intl.formatMessage({id: 'common.discard_predictions'}),
				callback: () => bulkDiscardPredictions(tasksToUpdate, setSelectedTasks, intl),
			});
		}
	}
	return list;
};
