/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type appHiBob_viewer$ref: FragmentReference;
declare export opaque type appHiBob_viewer$fragmentType: appHiBob_viewer$ref;
export type appHiBob_viewer = {|
  +id: string,
  +company: ?{|
    +id: string,
    +hiBobEnabled: ?boolean,
    +workatoCustomerId: ?string,
    +workatoForecastHiBobConnectionId: ?string,
    +workatoHiBobConnectionId: ?string,
    +workatoIframeToken: ?string,
  |},
  +$refType: appHiBob_viewer$ref,
|};
export type appHiBob_viewer$data = appHiBob_viewer;
export type appHiBob_viewer$key = {
  +$data?: appHiBob_viewer$data,
  +$fragmentRefs: appHiBob_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appHiBob_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hiBobEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workatoCustomerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workatoForecastHiBobConnectionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workatoHiBobConnectionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workatoIframeToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf4af1ed409fba0c75ad5645cd56ca7c';

module.exports = node;
