import {
	DeprecatedRichTextField as RichTextField,
	Icon,
	RichTextEditor,
	useRichTextEditorFunctions,
} from '@forecast-it/design-system';
import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {CrossIcon, Dropdown, IconText} from 'web-components';
import {WarningIcon} from 'web-components/icons';
import {WorkflowCategories} from '../../../../../../constants';
import {convertRagStatusToWarningVariant} from '../../../../../../containers/project/project_health/project_health_status/StatusIndicator';
import {CSS_CONSTANTS} from '../../../../../../css_variables';
import DeleteSprintGoalMutation from '../../../../../../mutations/DeleteSprintGoalMutation';
import UpdateSprintGoalMutation from '../../../../../../mutations/UpdateSprintGoalMutation';
import {MODAL_TYPE, showModal} from '../../../../../shared/components/modals/generic_modal_conductor';
import {convertWarningVariantToRagStatus} from '../../../../../shared/components/status/StatusUtil';
import {createToast} from '../../../../../shared/components/toasts/toast';
import Util from '../../../../../shared/util/util';
import {hasFeatureFlag} from '../../../../../shared/util/FeatureUtil';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../../../containers/event_manager';

const StageOptions = [
	{
		label: 'To-do',
		value: WorkflowCategories.TODO,
		icon: <Icon icon={'todo'} size="s"></Icon>,
	},
	{
		label: 'In progress',
		value: WorkflowCategories.INPROGRESS,
		icon: <Icon icon={'inProgress'} size="s"></Icon>,
	},
	{
		label: 'Done',
		value: WorkflowCategories.DONE,
		icon: <Icon icon={'done'} size="s"></Icon>,
	},
	{
		label: 'Incomplete',
		value: 'INCOMPLETE',
		icon: <Icon icon={'incomplete'} size="s"></Icon>,
	},
];

const StatusOptions = [
	{
		label: 'On track',
		value: WarningIcon.VARIANT.NO_WARNING,
		icon: <WarningIcon variant={WarningIcon.VARIANT.NO_WARNING} />,
	},
	{
		label: 'At risk',
		value: WarningIcon.VARIANT.WARNING,
		icon: <WarningIcon variant={WarningIcon.VARIANT.WARNING} />,
	},
	{
		label: 'Off track',
		value: WarningIcon.VARIANT.DANGER,
		icon: <WarningIcon variant={WarningIcon.VARIANT.DANGER} />,
	},
];

const EditorStyleOverride = styled.div`
	flex-grow: 1;
	background-color: #ffffff;
	.ck-editor__editable_inline {
		min-height: 40px !important;
	}
`;

const GoalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	margin-bottom: 16px;
	padding: 8px;
	gap: 12px;
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const RowWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

const CrossIconWrapper = styled.button`
	padding: 0;
	margin: 4px 0;
	border: none;
	border-radius: 4px;
	&:hover,
	&:focus {
		background-color: #e7e7f3;
	}
`;

const DropdownWrapper = styled.div`
	width: 150px;
`;

const setSprintGoalDescription = (sprintGoalId, prevDesc, newDesc) => {
	if (prevDesc !== newDesc) {
		Util.CommitMutation(UpdateSprintGoalMutation, {
			id: sprintGoalId,
			description: newDesc,
		});
	}
};

const setSprintGoalRag = (sprintGoalId, warningVariant) => {
	const ragStatus = convertWarningVariantToRagStatus(warningVariant[0]);
	Util.CommitMutation(
		UpdateSprintGoalMutation,
		{
			id: sprintGoalId,
			statusColor: ragStatus,
		},
		() =>
			createToast({
				duration: 2000,
				message: 'Sprint Goal Updated',
			})
	);
};

const setSprintGoalStage = (sprintGoalId, stage) => {
	Util.CommitMutation(
		UpdateSprintGoalMutation,
		{
			id: sprintGoalId,
			stage: stage[0],
		},
		() =>
			createToast({
				duration: 2000,
				message: 'Sprint Goal Updated',
			})
	);
};

const deleteSprintGoal = (sprintGoalId, sprintId) => {
	const callbackPositive = () => {
		Util.CommitMutation(
			DeleteSprintGoalMutation,
			{
				id: sprintGoalId,
				sprintId: sprintId,
			},
			() =>
				createToast({
					duration: 5000,
					message: 'Sprint Goal Deleted',
				})
		);
	};
	showModal({
		type: MODAL_TYPE.GENERIC_DELETION_WARNING,
		deleteCallback: callbackPositive,
	});
};

const SprintGoalRow = ({sprintGoal, sprintId}) => {
	const {id, stage, description, statusColor} = sprintGoal;
	const sprintGoalWarningVariant = convertRagStatusToWarningVariant(statusColor);
	const goalIsDone = stage === WorkflowCategories.DONE || stage === 'INCOMPLETE';
	const {editorRef, getEditorValueAsHtml} = useRichTextEditorFunctions();

	const handleCloseSlideout = useCallback(() => {
		const newDesc = getEditorValueAsHtml();
		setSprintGoalDescription(id, description, newDesc);
	}, []);

	useEffect(() => {
		subscribe(EVENT_ID.CLOSE_SLIDEOUT, handleCloseSlideout);
		return () => {
			unsubscribe(EVENT_ID.CLOSE_SLIDEOUT, handleCloseSlideout);
		};
	}, []);

	return (
		<GoalWrapper>
			<HeaderWrapper>
				<RowWrapper>
					<DropdownWrapper>
						<Dropdown
							name={'Set stage'}
							selectedItems={[stage]}
							onSelect={stage => setSprintGoalStage(id, stage)}
							usePortal
							arrowCollapseFix
						>
							{StageOptions.map(option => (
								<IconText
									value={option.value}
									key={option.label}
									searchString={option.label}
									text={option.label}
								>
									{option.icon}
								</IconText>
							))}
						</Dropdown>
					</DropdownWrapper>
					{goalIsDone ? null : (
						<DropdownWrapper>
							<Dropdown
								name={'Set status'}
								selectedItems={[sprintGoalWarningVariant]}
								onSelect={warningVariant => setSprintGoalRag(id, warningVariant)}
								usePortal
								arrowCollapseFix
							>
								{StatusOptions.map(option => (
									<IconText
										value={option.value}
										key={option.label}
										searchString={option.label}
										text={option.label}
									>
										{option.icon}
									</IconText>
								))}
							</Dropdown>
						</DropdownWrapper>
					)}
				</RowWrapper>
				<CrossIconWrapper tabIndex={0} onClick={() => deleteSprintGoal(id, sprintId)}>
					<CrossIcon size={CrossIcon.SIZE.SMALL} />
				</CrossIconWrapper>
			</HeaderWrapper>
			<RowWrapper>
				<EditorStyleOverride>
					{hasFeatureFlag('plate_editor') ? (
						<RichTextEditor
							id={id}
							placeholder={`Enter a description for your sprint goal...`}
							onBlur={newDesc => setSprintGoalDescription(id, description, newDesc)}
							initialHtml={description}
							pluginToggles={{
								link: true,
								paragraphStyles: true,
							}}
							data-cy={'sprint-goal-description'}
							ref={editorRef}
						/>
					) : (
						<RichTextField
							limitations={[
								'BlockQuote',
								'Code',
								'CodeBlock',
								'Table',
								'TableToolbar',
								'Indent',
								'MediaEmbed',
								'Mention',
								'Image',
								'ImageToolbar',
								'ImageStyle',
								'ImageCaption',
								'ImageUpload',
							]}
							editor={'InlineEditor'}
							onSaveHandler={newDesc => setSprintGoalDescription(id, description, newDesc)}
							data={description}
							placeholder={`Enter a description for your sprint goal...`}
						/>
					)}
				</EditorStyleOverride>
			</RowWrapper>
		</GoalWrapper>
	);
};

export default SprintGoalRow;
