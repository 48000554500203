import { useRef, useEffect } from 'react';
import { subscribe, unsubscribe } from '../../../containers/event_manager';
/*
 * Generic subscription add and cleanup for functional components
 * handler passed to useSubscription should generally be created using useCallback so reference never changes.
 */
function useSubscription(eventId, handler) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler);
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);
    useEffect(() => {
        // Create subscription handler that calls handler function stored in ref
        const subscriptionHandler = event => savedHandler.current(event);
        // Add event listener
        subscribe(eventId, subscriptionHandler);
        // Remove event listener on cleanup
        return () => {
            unsubscribe(eventId, subscriptionHandler);
        };
    }, [eventId] // Re-run if eventId changes
    );
}
export default useSubscription;
