export const REPORT_COLUMNS = {
    ID: 'id',
    NAME: 'name',
    TYPE: 'type',
    EDITED: 'edited',
    CREATED_BY: 'createdBy',
    LAST_VIEWED: 'lastViewed',
    LAST_VIEWED_DATE: 'lastViewedDate',
    FAVORITES: 'favorites',
    ACCESS: 'privateAccess',
};
export const REPORT_ACCESS = {
    SHARED: 'Shared',
    COMPANY: 'Company',
    PRIVATE: 'Private',
};
