/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AIBulkButton_viewer$ref: FragmentReference;
declare export opaque type AIBulkButton_viewer$fragmentType: AIBulkButton_viewer$ref;
export type AIBulkButton_viewer = {|
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +availableMLModels: ?{|
      +lowHighModel: ?boolean,
      +roleModel: ?boolean,
      +assigneeModel: ?boolean,
      +labelModel: ?boolean,
    |},
    +sageIntacctEnabled: ?boolean,
  |},
  +$refType: AIBulkButton_viewer$ref,
|};
export type AIBulkButton_viewer$data = AIBulkButton_viewer;
export type AIBulkButton_viewer$key = {
  +$data?: AIBulkButton_viewer$data,
  +$fragmentRefs: AIBulkButton_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AIBulkButton_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableMLModels",
          "kind": "LinkedField",
          "name": "availableMLModels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lowHighModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assigneeModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "labelModel",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '054789e547140df644c00206a8bdbbb9';

module.exports = node;
