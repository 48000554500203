var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button, FlexColumn, FlexRow, Icon, Link, Text } from '@forecast-it/design-system';
import { ClickAwayListener } from '@material-ui/core';
import { TRACKING_OBJECTS } from '../../../../tracking/amplitude/constants/TrackingObjects';
import { useTrackModal } from '../../../../tracking/amplitude/hooks/useTrackModal';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { trackGlobalSearch } from '../../../../tracking/tracking_types/TrackGlobalSearch';
import DeprecatedScrollToNativeScroll from '../../../shared/components/scroll-bars/DeprecatedScrollToNativeScroll';
import useEventListener from '../../../shared/hooks/useEventListener';
import useRoveFocus from '../../../shared/hooks/useRoveFocus';
import { useWindowSize } from '../../../shared/hooks/useWindowSize';
import { parseLocalStorageJSONOrDefault } from '../../../shared/util/LocalStorageUtil';
import GlobalSearchInput from './GlobalSearchInput';
import { MiniLoader } from './MiniLoader';
import { searchQuery, searchSuggestionQuery } from './SearchQuery';
import SearchResultEmptyState from './SearchResultEmptyState';
import SearchResults from './SearchResults';
import { SearchResultVariant } from './types/SearchResultVariant';
export const SEARCH_SHOWN_LIMIT = 5;
export const recentVisitedTaskKey = 'recently-visited-task';
export var SEARCH_ENTITIES;
(function (SEARCH_ENTITIES) {
    SEARCH_ENTITIES["TASK"] = "task";
    SEARCH_ENTITIES["PROJECT"] = "project";
    SEARCH_ENTITIES["INTERNAL_TIME"] = "internal time";
})(SEARCH_ENTITIES || (SEARCH_ENTITIES = {}));
function getStaticContentSize(showGiveFeedback, showSearchFilters) {
    const baseStaticContentSize = 168;
    const giveFeedbackSize = showGiveFeedback ? 40 : 0;
    const searchFiltersSize = showSearchFilters ? 100 : 0;
    return baseStaticContentSize + giveFeedbackSize + searchFiltersSize;
}
const SearchModalBackdrop = styled.div `
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 900;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: flex-start;
`;
const SearchModal = styled.div.attrs(({ modalYOffset, modalYPosition, modalXPosition, modalWidth = 800 }) => ({
    style: modalYPosition && modalXPosition
        ? {
            position: 'absolute',
            top: `${modalYPosition}px`,
            left: `${modalXPosition}px`,
            width: `${modalWidth}px`,
        }
        : {
            marginTop: `${modalYOffset}px`,
            width: `${modalWidth}px`,
        },
})) `
	display: flex;
	flex-direction: column;
	background-color: white;
	border: 1px solid #d3d3df;
	border-radius: 8px;
	padding: 24px;
	gap: 16px;
`;
const SearchInputIconWrapper = styled.div `
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: -10px; // small hack to make the clear button look better
`;
const SearchSection = styled.div `
	display: flex;
	flex-direction: column;
	gap: 16px;
	flex: 1;
	min-height: 0;
	max-height: 100%;
`;
const SectionDivider = styled.div `
	flex: 1;
	border-top: 1px solid #d3d3df;
`;
const ShowMore = styled.button `
	padding: 4px 8px;
	cursor: pointer;
	border-radius: 4px;
	color: #008bfa;
	align-self: flex-start;
	border: 2px solid transparent;

	&:hover {
		background-color: #e7e7f3;
	}

	&:focus-visible {
		background-color: #e7e7f3;
		border: 2px solid #3b94ff;
		outline: none;
	}
`;
const defaultSearchModalConfig = {
    inputPlaceholder: 'Search for projects and tasks...',
    searchEndpoint: 'globalsearch',
    trackingObject: TRACKING_OBJECTS.GLOBAL_SEARCH_MODAL,
    recentSearchKey: 'recently-searched',
    allowLinks: true,
    showGiveFeedback: true,
    allowedEntities: [SEARCH_ENTITIES.TASK, SEARCH_ENTITIES.PROJECT],
    suggestionsFilter: () => true,
};
const GlobalSearchModal = ({ companyId, personId, isEmbedded = false, modalYOffset = 12, modalXPosition, modalYPosition = 12, modalWidth, searchModalConfig = defaultSearchModalConfig, hideSearch, onResultSelected, }) => {
    var _a;
    const recentSearchKey = searchModalConfig.recentSearchKey;
    const recentSearches = useMemo(() => parseLocalStorageJSONOrDefault(recentSearchKey, []), []);
    // If we have limited search to a specific project, switch to showing task indicator on results instead of project indicator
    const showTaskIndicator = !!searchModalConfig.projectId;
    const [showMore, setShowMore] = useState(false);
    const [searchData, setSearchData] = useState({
        searchResults: [],
        accessibleProjectIds: { projectIds: [], projectGroupIds: [] },
    });
    const [suggestionsData, setSuggestionsData] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const baseSearchString = searchModalConfig === null || searchModalConfig === void 0 ? void 0 : searchModalConfig.baseSearch;
    const searchResults = (searchData === null || searchData === void 0 ? void 0 : searchData.searchResults) || [];
    const accessibleProjectIds = searchData === null || searchData === void 0 ? void 0 : searchData.accessibleProjectIds;
    const searchInputRef = useRef(null);
    const showMoreRef = useRef(null);
    const latestSuccessfulSearchRef = useRef('');
    const searchStringHistoryRef = useRef([]);
    const firstInSessionRef = useRef(true);
    const abortControllerRef = useRef(new AbortController());
    // Don't run modal tracking if the modal is embedded.
    useTrackModal(isEmbedded ? null : 'Global Search Modal');
    const size = useWindowSize();
    const windowHeight = (_a = size === null || size === void 0 ? void 0 : size.height) !== null && _a !== void 0 ? _a : window.innerHeight;
    const handleSearchStringChange = (value) => {
        setIsLoading(true);
        setSearchString(value);
    };
    const fetchSearchSuggestions = () => __awaiter(void 0, void 0, void 0, function* () {
        const recentTaskId = localStorage.getItem(recentVisitedTaskKey);
        const result = yield searchSuggestionQuery(recentTaskId);
        setSuggestionsData(result);
    });
    const fetchSearchData = (query) => __awaiter(void 0, void 0, void 0, function* () {
        abortControllerRef.current.abort(); // FIXME: Consider if this should be moved down to the handleSearchInputChange field
        abortControllerRef.current = new AbortController();
        const actualSearch = baseSearchString ? baseSearchString + ' ' + query : query;
        const result = yield searchQuery(actualSearch, searchModalConfig.projectId, searchModalConfig.searchEndpoint, abortControllerRef.current.signal);
        if (result) {
            searchStringHistoryRef.current.push(query);
            setIsLoading(false);
            setSearchData(result);
        }
    });
    // Do an initial search if a baseSearch query is defined
    useEffect(() => {
        if (baseSearchString) {
            setIsLoading(true);
            fetchSearchData('');
        }
    }, []);
    const trackSearch = useCallback((query) => {
        trackEvent(searchModalConfig.trackingObject, 'Searched', { searchString: query });
    }, [searchString]);
    const fetchSearchDataDebounced = useMemo(() => {
        return debounce(fetchSearchData, 150);
    }, []);
    const trackSearchDebounced = useMemo(() => {
        return debounce(trackSearch, 1000);
    }, []);
    const updateSearchString = useCallback((value) => {
        fetchSearchData(value);
        trackSearch(value);
        handleSearchStringChange(value);
    }, []);
    const updateSearchStringDebounced = useCallback((searchString) => {
        fetchSearchDataDebounced(searchString);
        trackSearchDebounced(searchString);
        handleSearchStringChange(searchString);
    }, [fetchSearchDataDebounced, trackSearchDebounced]);
    const handleRecentSelected = useCallback((value) => {
        var _a;
        trackEvent(`${searchModalConfig.trackingObject} Recent Item`, 'Selected');
        updateSearchString(value);
        (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [searchInputRef.current]);
    const trackAndHideSearch = useCallback((selectedItem = null, hideSearchModal = true) => {
        if (selectedItem && onResultSelected) {
            onResultSelected(selectedItem);
        }
        if (hideSearchModal) {
            hideSearch();
        }
        if (selectedItem || latestSuccessfulSearchRef.current !== searchString) {
            trackGlobalSearch({
                trackingObject: searchModalConfig.trackingObject,
                companyId,
                personId,
                searchString,
                searchStringHistory: searchStringHistoryRef.current,
                selectedItem,
                searchResults,
                accessibleProjectIds,
                firstInSession: firstInSessionRef.current,
            });
            latestSuccessfulSearchRef.current = searchString;
            firstInSessionRef.current = false;
        }
    }, [companyId, personId, searchString, searchResults]);
    const showRecentSearches = !searchString && !baseSearchString;
    const showShowMoreButton = !showRecentSearches && searchResults.length > SEARCH_SHOWN_LIMIT;
    const showTypeSelectors = !searchString.includes('type:') &&
        !(searchModalConfig.allowedEntities.length === 1 && searchString.length > 0) &&
        (!baseSearchString || (!!baseSearchString && !baseSearchString.includes('type:')));
    const searchResultsFormatted = useMemo(() => {
        return showMore ? searchResults : searchResults.slice(0, SEARCH_SHOWN_LIMIT);
    }, [showMore, searchResults]);
    const recentSearchesFormatted = useMemo(() => {
        return recentSearches.slice(0, 3).map(recentVal => ({ name: recentVal, variant: SearchResultVariant.Recent }));
    }, [recentSearches]);
    const suggestionsFormatted = useMemo(() => {
        return suggestionsData === null || suggestionsData === void 0 ? void 0 : suggestionsData.filter(searchModalConfig.suggestionsFilter).map(suggestion => (Object.assign(Object.assign({}, suggestion), { variant: SearchResultVariant.Suggestion })));
    }, [suggestionsData, searchModalConfig]);
    const shownSearchResultsCount = showRecentSearches
        ? recentSearchesFormatted.length + (suggestionsFormatted === null || suggestionsFormatted === void 0 ? void 0 : suggestionsFormatted.length)
        : searchResultsFormatted.length;
    const searchInputIndex = 0;
    const showMoreButtonIndex = shownSearchResultsCount + 1;
    const [focus, setFocus] = useRoveFocus(shownSearchResultsCount + (showShowMoreButton ? 2 : 1)); // Show more button (if visible) and search input are both included in rove focus
    const toggleShowMore = () => {
        trackEvent(!showMore ? `${searchModalConfig.trackingObject} Show More` : `${searchModalConfig.trackingObject} Show Less`, 'Clicked');
        setShowMore(!showMore);
        if (showMore) {
            setFocus(SEARCH_SHOWN_LIMIT);
        }
        else {
            setFocus(showMoreButtonIndex);
        }
    };
    const setType = type => {
        var _a;
        updateSearchString(`type:${type} ${searchString || ''}`);
        if (searchInputIndex === focus) {
            (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            setFocus(searchInputIndex);
        }
    };
    const focusSearchInput = useCallback(() => {
        var _a;
        setFocus(searchInputIndex);
        (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [searchInputIndex]);
    const focusFirstResult = useCallback(() => {
        if (shownSearchResultsCount > 0) {
            setFocus(1);
        }
    }, [shownSearchResultsCount]);
    const handleModalKeyDown = useCallback((e) => {
        if (e.key === 'Escape' && !e.defaultPrevented) {
            e.stopPropagation();
            trackAndHideSearch();
        }
    }, [companyId, personId, searchString, searchResults]);
    useEventListener('keydown', handleModalKeyDown, document);
    useEffect(() => {
        var _a, _b;
        if (focus === searchInputIndex) {
            (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else if (focus === showMoreButtonIndex) {
            (_b = showMoreRef.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
    }, [focus]);
    useEffect(() => {
        return () => {
            fetchSearchDataDebounced.cancel();
            trackSearchDebounced.cancel();
        };
    }, [fetchSearchDataDebounced, trackSearchDebounced]);
    useEffect(() => {
        fetchSearchSuggestions();
        return () => {
            var _a;
            (_a = abortControllerRef.current) === null || _a === void 0 ? void 0 : _a.abort();
        };
    }, []);
    const portalRoot = document.querySelector('#root-portal-container');
    return portalRoot
        ? ReactDOM.createPortal(React.createElement(SearchModalBackdrop, null,
            React.createElement(ClickAwayListener, { onClickAway: () => trackAndHideSearch(), mouseEvent: 'onMouseDown' },
                React.createElement(SearchModal, { role: "search", "aria-label": "Site-wide", modalYOffset: modalYOffset, modalYPosition: modalYPosition, modalXPosition: modalXPosition, modalWidth: modalWidth },
                    React.createElement(SearchInputIconWrapper, null,
                        isLoading ? React.createElement(MiniLoader, null) : React.createElement(Icon, { icon: 'search', size: 'm' }),
                        React.createElement(GlobalSearchInput, { ref: searchInputRef, searchString: searchString, recentSearchKey: recentSearchKey, recentSearches: recentSearches, inputPlaceholder: searchModalConfig.inputPlaceholder, updateSearchString: updateSearchString, updateSearchStringDebounced: updateSearchStringDebounced, focusSearchInput: focusSearchInput, focusFirstResult: focusFirstResult })),
                    React.createElement(SectionDivider, null),
                    showTypeSelectors && (React.createElement(FlexColumn, { gap: "l" },
                        React.createElement(Text, { color: "medium", size: "2" }, searchString.length > 0 ? 'Filter by type' : 'Show all'),
                        React.createElement(FlexRow, { gap: "s" },
                            searchModalConfig.allowedEntities.includes(SEARCH_ENTITIES.TASK) && (React.createElement(Button, { icon: "task", emphasis: "medium", onClick: () => setType('task') }, "Task")),
                            searchModalConfig.allowedEntities.includes(SEARCH_ENTITIES.PROJECT) && (React.createElement(Button, { icon: "project", emphasis: "medium", onClick: () => setType('project') }, "Project")),
                            searchModalConfig.allowedEntities.includes(SEARCH_ENTITIES.INTERNAL_TIME) && (React.createElement(Button, { icon: "internalTime", emphasis: "medium", onClick: () => setType('internal_time') }, "Internal time"))))),
                    shownSearchResultsCount > 0 ? (React.createElement(SearchSection, null,
                        React.createElement(DeprecatedScrollToNativeScroll, { maxHeight: `${windowHeight -
                                (getStaticContentSize(searchModalConfig.showGiveFeedback, showTypeSelectors) +
                                    modalYPosition)}px`, nativeMaxHeight: windowHeight -
                                (getStaticContentSize(searchModalConfig.showGiveFeedback, showTypeSelectors) +
                                    modalYPosition), hasFocusableContent: true },
                            !showRecentSearches && searchResults.length > 0 && (React.createElement(FlexColumn, { gap: 's' },
                                React.createElement(FlexColumn, { gap: 'xs' },
                                    React.createElement(Text, { color: "medium", size: "2" }, `Results (${searchResults.length >= 100 ? '99+' : searchResults.length})`),
                                    React.createElement(SearchResults, { searchResults: searchResultsFormatted, allowLinks: searchModalConfig.allowLinks, focus: focus, setFocus: setFocus, indexOffset: 0, trackAndHideSearch: trackAndHideSearch, handleRecentSelected: handleRecentSelected, showTaskIndicator: showTaskIndicator })),
                                showShowMoreButton && (React.createElement(ShowMore, { ref: showMoreRef, onClick: toggleShowMore, "data-cy": 'search-modal-show-more' }, showMore ? 'Show Less' : 'Show More')))),
                            showRecentSearches && recentSearches.length > 0 && (React.createElement(FlexColumn, { gap: 'xs' },
                                React.createElement(Text, { color: "medium", size: "2" }, "Recent"),
                                React.createElement(SearchResults, { searchResults: recentSearchesFormatted, allowLinks: searchModalConfig.allowLinks, focus: focus, setFocus: setFocus, indexOffset: 0, trackAndHideSearch: trackAndHideSearch, handleRecentSelected: handleRecentSelected, searchResultSection: SearchResultVariant.Recent }))),
                            showRecentSearches && suggestionsFormatted && suggestionsFormatted.length > 0 && (React.createElement(React.Fragment, null,
                                recentSearches.length > 0 ? React.createElement(SectionDivider, null) : null,
                                React.createElement(FlexColumn, { gap: 'xs' },
                                    React.createElement(Text, { color: "medium", size: "2" }, "Suggestions"),
                                    React.createElement(SearchResults, { searchResults: suggestionsFormatted, allowLinks: searchModalConfig.allowLinks, focus: focus, setFocus: setFocus, indexOffset: recentSearchesFormatted.length, trackAndHideSearch: trackAndHideSearch, handleRecentSelected: handleRecentSelected }))))))) : !isLoading && (searchString || baseSearchString) ? (React.createElement(SearchResultEmptyState, { searchString: searchString })) : null,
                    searchModalConfig.showGiveFeedback && (React.createElement(React.Fragment, null,
                        React.createElement(SectionDivider, null),
                        React.createElement(Link, { href: "https://www.forecast.app/feedback-search", size: "2", target: "_blank" }, "Give Feedback")))))), portalRoot
        // eslint-disable-next-line no-mixed-spaces-and-tabs
        )
        : null;
};
export default GlobalSearchModal;
