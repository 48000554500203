import { Button, EMPHASIS } from '@forecast-it/design-system';
import React from 'react';
import styled from 'styled-components';
const DateDisplayWithButtonsStyle = styled.div `
	display: flex;
	gap: 4px;
`;
// DS lookalike component, could probably be made in DS if not lazy
const DateDisplay = styled.div `
	min-width: 175px;
	height: 44px;
	margin: 2px 0;
	padding: 8px;

	border: 1px solid rgb(107, 109, 123);
	border-radius: 8px;
	justify-content: center;
	align-content: center;
	text-align: center;
`;
const DateDisplayWithButtons = ({ value, onBackButton, onForwardButton, dataCy }) => {
    return (React.createElement(DateDisplayWithButtonsStyle, null,
        React.createElement(Button, { "data-cy": `${dataCy}-back-button`, onClick: onBackButton, emphasis: EMPHASIS.MONO, icon: 'chevronLeft', iconOnly: true }),
        React.createElement(DateDisplay, null, value),
        React.createElement(Button, { "data-cy": `${dataCy}-foward-button`, onClick: onForwardButton, emphasis: EMPHASIS.MONO, icon: 'chevronRight', iconOnly: true })));
};
export default DateDisplayWithButtons;
