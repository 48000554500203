import React, {useEffect, useMemo, useRef, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {theEyeToColumns} from '../../../shared/util/the-eye/task_section_they_eye_util';
import {searchFilter} from '../../../shared/components/filters/filter_util';
import {getSocketConfig} from '../My_work_socket_handling';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import {MyWorkMyTasksV2} from './MyWorkMyTasksV2';
import _ from 'lodash';
import {HeaderPageContainer} from '../my_work_styled';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

const MyWorkTasksSectionV2 = ({
	retry,
	viewer,
	intl,
	history,
	selectedPersonId,
	showThisWeek,
	theEyeOptions,
	searchFilterValue,
	filterFunctions,
	localFilters,
	isStaredSectionCollapsed,
	setSocketConfig,
	isInPast,
}) => {
	const myWorkTasksSection = useRef(null);
	const [titleWidth, setTitleWidth] = useState('100%');
	const enabledColumns = useMemo(() => theEyeToColumns(theEyeOptions), [theEyeOptions]);

	let tasks = viewer.company.allTasks.edges
		.concat(viewer.company.doneTasks.edges)
		.concat(viewer.company.noDatesTasks.edges)
		.filter(task => task.node.fullAccessToProject);

	const socketTaskIds = tasks.map(edge => parseInt(atob(edge.node.id).replace('Task:', '')));

	const refetchTasks = props => {
		if (
			props.duplicateTask ||
			(props.input &&
				(props.input.deadlineYear ||
					props.input.startYear ||
					props.input.assignedPersons ||
					props.input.statusColumnId))
		) {
			retry();
		}
	};

	useEffect(() => {
		if (myWorkTasksSection && myWorkTasksSection.current) {
			setTitleWidth(myWorkTasksSection.current.getBoundingClientRect().width);
		}

		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchTasks);
		return () => {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchTasks);
		};
	}, []);

	useEffect(() => {
		const actualPersonIdInt = parseInt(atob(viewer.actualPersonId).replace('Person:', ''));
		const selectedPersonIdInt = parseInt(atob(selectedPersonId).replace('Person:', ''));
		setSocketConfig(getSocketConfig([actualPersonIdInt, selectedPersonIdInt], socketTaskIds));
	}, [selectedPersonId]);

	useEffect(() => {
		if (searchFilterValue && searchFilterValue !== '') {
			const taskCount = tasks.filter(task => searchFilter(task, searchFilterValue)).length;
			trackEvent('Search', 'Results', {searchString: searchFilterValue, matching: taskCount});
		}
	}, [searchFilterValue]);

	useEffect(() => {
		if (myWorkTasksSection && myWorkTasksSection.current) {
			setTitleWidth(myWorkTasksSection.current.getBoundingClientRect().width);
		}
	}, [isStaredSectionCollapsed, selectedPersonId]);

	const getWidth = () =>
		myWorkTasksSection && myWorkTasksSection.current ? myWorkTasksSection.current.getBoundingClientRect().width : null;

	function useCurrentWidth() {
		let [width, setWidth] = useState(getWidth());
		useEffect(() => {
			let timeoutId = null;
			const resizeListener = () => {
				clearTimeout(timeoutId);
				timeoutId = setTimeout(() => setWidth(getWidth()), 150);
			};
			window.addEventListener('resize', resizeListener);

			return () => {
				window.removeEventListener('resize', resizeListener);
			};
		}, []);

		return width || titleWidth;
	}

	const filterOptions = {teams: viewer.company.teams.edges};

	if (searchFilterValue.length > 0) {
		tasks = tasks.filter(task => searchFilter(task.node, searchFilterValue));
	}
	if (filterFunctions && filterFunctions.taskFilter) {
		tasks = tasks.filter(task => filterFunctions.taskFilter(task.node, filterOptions));
	}
	const listHeaderWidth = useCurrentWidth();

	return (
		<>
			<HeaderPageContainer ref={myWorkTasksSection} />
			<MyWorkMyTasksV2
				viewer={viewer}
				intl={intl}
				history={history}
				showThisWeek={showThisWeek}
				isInPast={isInPast}
				tasks={tasks}
				selectedPersonId={selectedPersonId}
				listHeaderWidth={listHeaderWidth}
				enabledColumns={enabledColumns}
				searchFilterValue={searchFilterValue}
				filterFunctions={filterFunctions}
				filterOptions={filterOptions}
				hasFillterApplied={!_.isEmpty(localFilters)} //lodash isEmpty on Object{}
			/>
		</>
	);
};

const MyWorkTasksSectionV2Query = graphql`
	query MyWorkTasksSectionV2_Query(
		$searchQuery: TaskSearchQueryType
		$noDatesSearchQuery: TaskSearchQueryType
		$doneTasksSearchQuery: TaskSearchQueryType
		$isClientUser: Boolean!
		$personId: ID
	) {
		viewer {
			component(name: "my_work_tasks_section")
			actualPersonId
			id
			...MyWorkTasksSectionV2_viewer
				@arguments(
					searchQuery: $searchQuery
					noDatesSearchQuery: $noDatesSearchQuery
					doneTasksSearchQuery: $doneTasksSearchQuery
					isClientUser: $isClientUser
					personId: $personId
				)
		}
	}
`;

export {MyWorkTasksSectionV2Query};

export default withSocketHandling(
	withRouter(
		createFragmentContainer(MyWorkTasksSectionV2, {
			viewer: graphql`
				fragment MyWorkTasksSectionV2_viewer on Viewer
				@argumentDefinitions(
					searchQuery: {type: "TaskSearchQueryType"}
					noDatesSearchQuery: {type: "TaskSearchQueryType"}
					doneTasksSearchQuery: {type: "TaskSearchQueryType"}
					isClientUser: {type: "Boolean!"}
					personId: {type: "ID"}
				) {
					id
					actualPersonId
					harvestUser
					unit4User
					excludeFromCompanyLockedPeriod
					submitLockedDateYear
					submitLockedDateMonth
					submitLockedDateDay
					availableFeatureFlags {
						key
					}
					startDate
					endDate
					createdAt
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					# start -- needed only for context menu
					email
					timerStartDate
					timerTask {
						id
					}
					# end -- needed only for context menu
					company {
						...genericTaskContextMenu_company
						id
						harvestEnabled
						unit4Enabled
						lockedPeriodYear
						lockedPeriodMonth
						lockedPeriodDay
						modules {
							moduleType
						}
						roles {
							edges {
								node {
									id
									name
								}
							}
						}
						teams(first: 1000000) @connection(key: "Company_teams", filters: []) {
							edges {
								node {
									id
									name
									teamPersons(first: 1000) @connection(key: "Team_teamPersons", filters: []) {
										edges {
											node {
												id
												person {
													id
												}
											}
										}
									}
								}
							}
						}
						# filter end
						allTasks(first: 10000000, searchQuery: $searchQuery, approvedOnly: true, runningProjectOnly: true)
							@connection(key: "Company_allTasks", filters: []) {
							edges {
								node {
									...TaskRow_task @arguments(personId: $personId)
									id
									fullAccessToProject
									sortOrder
									statusColumnV2 {
										id
										name
										category
									}
									# filter and sort start
									companyTaskId
									name
									latestUiUpdateAt
									subTaskCount
									startDay
									startMonth
									startYear
									deadlineDay
									deadlineMonth
									deadlineYear
									bug
									blocked
									highPriority
									hasChildren
									billable
									sageIntacctId
									favoured
									hasDependency
									project {
										id
										status
										companyProjectId
										projectColor #sprint section only
										projectGroupId #sprint section only
										name
										client {
											id
										}
									}
									taskLabels {
										label {
											id
											name
											color
										}
									}
									assignedPersons {
										id
									}
									phase {
										id
										name
										deadlineDay
										deadlineMonth
										deadlineYear
										startDay
										startMonth
										startYear
									}
									sprint {
										id
										endDay
										endMonth
										endYear
										startDay
										startMonth
										startYear
										#start -sprint section
										name
										isProjectGroupSprint
										projectGroupSprintId
										#end - sprint section
									}
									owner {
										id
									}
									followers {
										id
									}
									# filter and sort end
								}
							}
						}
						noDatesTasks: allTasks(
							first: 1000000
							searchQuery: $noDatesSearchQuery
							approvedOnly: true
							runningProjectOnly: true
						) @connection(key: "Company_noDatesTasks", filters: []) {
							edges {
								node {
									...TaskRow_task @arguments(personId: $personId)
									id
									fullAccessToProject
									sortOrder
									# filter start
									companyTaskId
									name
									latestUiUpdateAt
									subTaskCount
									startDay
									startMonth
									startYear
									deadlineDay
									deadlineMonth
									deadlineYear
									bug
									blocked
									highPriority
									billable
									favoured
									hasDependency
									project {
										id
										status
										companyProjectId
										name
										client {
											id
											name
										}
									}
									statusColumnV2 {
										id
										name
										category
									}
									taskLabels {
										label {
											id
											name
											color
										}
									}
									assignedPersons {
										id
									}
									phase {
										id
										name
										deadlineDay
										deadlineMonth
										deadlineYear
										startDay
										startMonth
										startYear
									}
									timeRegistrations(first: 1000000) {
										edges {
											node {
												id
												minutesRegistered
											}
										}
									}
									sprint {
										id
										endDay
										endMonth
										endYear
										startDay
										startMonth
										startYear
									}
									owner {
										id
									}
									followers {
										id
									}
									# filter end
								}
							}
						}
						doneTasks: allTasks(
							first: 1000000
							searchQuery: $doneTasksSearchQuery
							approvedOnly: true
							runningProjectOnly: true
						) @connection(key: "Company_doneTasks", filters: []) {
							edges {
								node {
									...TaskRow_task @arguments(personId: $personId)
									id
									fullAccessToProject
									sortOrder
									# filter start
									companyTaskId
									name
									latestUiUpdateAt
									subTaskCount
									startDay
									startMonth
									startYear
									deadlineDay
									deadlineMonth
									deadlineYear
									bug
									blocked
									highPriority
									billable
									favoured
									hasDependency
									project {
										id
										status
										companyProjectId
										name
										client {
											id
											name
										}
									}
									statusColumnV2 {
										id
										name
										category
									}
									taskLabels {
										label {
											id
											name
											color
										}
									}
									assignedPersons {
										id
									}
									phase {
										id
										name
										deadlineDay
										deadlineMonth
										deadlineYear
										startDay
										startMonth
										startYear
									}
									timeRegistrations(first: 1000000) {
										edges {
											node {
												id
												minutesRegistered
											}
										}
									}
									sprint {
										id
										endDay
										endMonth
										endYear
										startDay
										startMonth
										startYear
									}
									owner {
										id
									}
									followers {
										id
									}
									# filter end
								}
							}
						}
					}
				}
			`,
		})
	)
);
