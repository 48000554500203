import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {createRefetchContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom/cjs/react-router-dom';
import {FILTER_SECTIONS} from '../../../shared/components/filters/FilterWrapper';
import {getFiltersAlphabetically} from '../../../shared/components/filters/filter_util';
import {buildHeaderBar} from '../../../shared/components/headers/header-bar/header_bar';
import {TopHeaderBar} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {GROUP_TYPE, TaskTable} from '../../../shared/components/task-tables/task-table-v3/TaskTable';
import {TYPES} from '../../../shared/components/task-tables/task-table-v3/Theme/NodeContentRenderer';
import {
	BUDGET_TYPE,
	BUTTON_COLOR,
	BUTTON_STYLE,
	ELEMENT_TYPE,
	ESTIMATION_UNIT,
	FILTER_SECTION,
	FILTER_TYPE,
	MODULE_TYPES,
} from '../../../../constants';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import CreatePhaseMutation from '../../../../mutations/create_phase_mutation';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import * as tracking from '../../../../tracking';
import Util from '../../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../shared/components/toasts/toast';
import GenericTaskContextMenu from '../../../shared/components/context-menus/generic_task_context_menu';
import {
	getExpandedPhasesMap,
	getExpandedTaskMap,
	getHiddenFinancialFields,
	getInitialGroupType,
	getInitialSelectedColumns,
	getInitialSelectedTab,
	getOrderedPhaseIds,
	getPhasesFilters,
	tabs,
	updateSelectTasks,
} from './ProjectScopingUtil';
import {canDrag, getCanDropFunc, onMoveNode} from '../../../shared/components/task-tables/task-table-v3/table-util/TableLogic';
import {
	generateTree,
	getProjectTotalData,
	getSharedOptions,
	phaseActiveFilter,
	searchFilter,
} from '../../../shared/components/task-tables/task-table-v3/table-util/TableUtil';
import {getSocketConfig} from './ProjectScopingSocket';
import {useDataExport} from '../../../shared/hooks/useDataExport';
import TaskFormatter from '../../../shared/util/export-formatters/TaskFormatter';
import {hasPermission, hasSomePermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import ProjectScopingTotals from './totals-header/ProjectScopingTotals';
import {useForecastFetchQuery} from '../../../shared/hooks/useForecastFetchQuery';
import {scopingPredictionsQuery} from './ProjectScopingPredictionsQuery';
import {shouldShowTaskOverrun, trackPredictionsShown} from '../../../shared/util/PredictionUtil';
import {keyBy} from 'lodash';
import {ProjectScopingTotalsProvider} from './totals-header/ProjectScopingTotalsContext';
import {BulkSelectPopup} from 'web-components';
import {getComplexBulkOptions} from '../../../shared/util/BulkUtil';
import {getAdditionalBulkUpdateAutomateOptions, getBulkUpdateOptions} from './ProjectScopingBulkLogic';
import {useRecentProjects} from '../hooks/useRecentProjects';
import useUpdateEffect from '../../../shared/hooks/useUpdateEffect';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import {trackCSVExport, trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {createAvailabilityMap, getHolidaysMap} from './ResourceUtil';
import ProjectHeader from '../shared/ProjectHeader';
import {FlexColumn, SubHeading} from '@forecast-it/design-system';
import {ZenModeable} from '../../../shared/components/zen-mode/ZenModeable';
import {eyeOptionsToCols, groupEyeOptions} from './ProjectScopingEyeUtil';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {projectUrl} from '../../../../directApi';
import CompanySetupUtil from '../../../shared/util/CompanySetupUtil';
import {getProjectIndicatorString} from '../../../shared/components/project-indicator/support/ProjectIndicatorLogic';
import {optionTraverser} from '../../../../the_eye_util';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';

const shouldShowResolvedTasksOption = project => {
	return hasFeatureFlag('scoping_no_stale_tasks') && project.isNoStaleTasksAllowed;
};

const getOrderedPhases = project => {
	if (project && project.phases && project.phases.edges)
		return getOrderedPhaseIds(project.phases.edges.map(phase => phase.node));

	return [];
};

export const ProjectScopingPage = ({viewer, history, relay, refetch, setSocketConfig}) => {
	const intl = useIntl();

	// Set as recent project
	let recentProjects = useRecentProjects();
	useEffect(() => {
		recentProjects.projectVisited(viewer.project.id);
	}, [viewer.project.id]);

	const shouldShowOverrunPrediction = shouldShowTaskOverrun(
		viewer.project.estimationUnit === ESTIMATION_UNIT.HOURS,
		PERMISSION_TYPE.PHASE_UPDATE,
		viewer.project?.demo
	);
	const preAppliedFilters =
		viewer.project.estimationUnit === ESTIMATION_UNIT.HOURS && Util.getUrlQueryParameter('show-overrunning')
			? {
					task: {
						predictedOverrun: shouldShowOverrunPrediction
							? ['predicted-overrun', 'currently-overrun']
							: ['currently-overrun'],
					},
			  }
			: null;
	const baselineRoleFinancialNumbers = viewer.project.baselineFinancialNumbers.filter(
		financialNumbers => financialNumbers.phaseBaselineRoleId !== null
	);
	const totalFinancialNumbers = viewer.project.totalFinancialNumbers;

	const hiddenFinancialFields = getHiddenFinancialFields(viewer.project.financialSourceSettings, intl);
	const isBaselineProject = Util.isBaselineProject(viewer.company.modules, viewer.project);

	// eslint-disable-next-line
	const [lazyDataFetched, setLazyDataFetched] = useState(true);
	const [orderedPhaseIds, setOrderedPhaseIds] = useState(getOrderedPhases(viewer.project));
	const getInitialPhaseExpansionState = () => {
		const localStorageItem = Util.localStorageGetItem(viewer.project.id + '-phaseMap');
		return localStorageItem && localStorageItem !== '{}'
			? new Map(JSON.parse(localStorageItem))
			: getExpandedPhasesMap(viewer.project.phases.edges.map(phase => phase.node));
	};

	const getInitialTaskExpansionState = () => {
		const localStorageItem = Util.localStorageGetItem(viewer.project.id + '-taskMap');
		return localStorageItem && localStorageItem !== '{}'
			? new Map(JSON.parse(localStorageItem))
			: getExpandedTaskMap(viewer.project.tasks.edges.map(task => task.node));
	};

	// Expansion maps
	const [phasesExpansionMap, setPhasesExpansionMap] = useState(getInitialPhaseExpansionState());
	const [tasksExpansionMap, setTasksExpansionMap] = useState(getInitialTaskExpansionState());

	const [personGroupExpansionMap, setPersonGroupExpansionMap] = useState(new Map());
	const [roleGroupExpansionMap, setRoleGroupExpansionMap] = useState(new Map());
	const [selectedTab, setSelectedTab] = useState(getInitialSelectedTab());
	const [filterFunctions, setFilterFunctions] = useState(getPhasesFilters(viewer.project.id, preAppliedFilters));
	const [searchFilterValue, setSearchFilterValue] = useState('');

	// saving state in ref also
	const [selectedTasks, setSelectedTasks] = useState([]);

	// The eye
	const [showBaselineInfo, setShowBaselineInfo] = useState(
		isBaselineProject
			? JSON.parse(localStorage.getItem('project-phases-show-baseline-info' + viewer.project.companyProjectId)) ?? true
			: false
	);
	const [groupSetting, setGroupSetting] = useState(getInitialGroupType());

	const [displayScopingAvailability, setDisplayScopingAvailability] = useState(
		JSON.parse(localStorage.getItem('project-phases-display-scoping-availability' + viewer.project.companyProjectId)) ??
			true
	);

	const SHOW_PERIODS_SETTING = 'project-scoping-show-periods';
	const SHOW_EMPTY_PERIODS_SETTING = 'project-scoping-show-empty-periods';

	/**
	 * Is this a retainer project, and are you allowed to see it.
	 *
	 * @type {boolean} Show the retainer project options
	 */
	const isRetainerProject =
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]) &&
		viewer.project.budgetType === BUDGET_TYPE.RETAINER;

	const [showPeriods, setShowPeriods] = useState(
		localStorage.getItem(SHOW_PERIODS_SETTING)
			? JSON.parse(localStorage.getItem(SHOW_PERIODS_SETTING)) && isRetainerProject
			: false
	);
	const [showEmptyPeriods, setShowEmptyPeriods] = useState(
		localStorage.getItem(SHOW_EMPTY_PERIODS_SETTING)
			? JSON.parse(localStorage.getItem(SHOW_EMPTY_PERIODS_SETTING)) && isRetainerProject
			: true
	);

	const [showContextMenu, setShowContextMenu] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState(null);
	const [contextMenuTask, setContextMenuTask] = useState(null);
	const [createSubtaskTask, setCreateSubtaskTask] = useState(null);

	const [availableColumns, setAvailableColumns] = useState(
		getInitialSelectedColumns(intl.formatMessage, viewer, showPeriods)
	); // Consider refactoring to new approach to the eye

	const showResolvedTasksOption = shouldShowResolvedTasksOption(viewer.project);
	const [showResolvedTasks, setShowResolvedTasks] = useState(false);

	// Refs

	const isMounted = useRef(false);

	const {fetch, data: predictionData} = useForecastFetchQuery(scopingPredictionsQuery);

	const exportData = useDataExport(
		new TaskFormatter(
			intl,
			['phase_name'],
			[
				'chip_right',
				'expand',
				'phase',
				'selector',
				'starred',
				'context_menu',
				'show_scoping_project_total',
				'show_overrun_prediction',
			],
			true
		),
		null,
		data => data,
		_ => `${viewer.project.name}_scoping_data`
	);

	const currencySymbol = Util.GetCurrencySymbol(
		viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency
	);

	const predictionDataMap = useMemo(() => {
		if (predictionData) {
			const {viewer} = predictionData;
			const {predictionProject} = viewer;
			if (predictionProject && predictionProject.predictedOverrunningTasks) {
				const formattedOverrunningTasks = predictionProject.predictedOverrunningTasks.map(oTask => ({
					taskId: oTask.globalTaskId,
					predictedEstimate: {estimate: oTask.estimate, overrunProbability: oTask.overrunProbability},
				}));
				return keyBy(formattedOverrunningTasks, o => o.taskId);
			}
		}
	}, [predictionData]);

	const toggleCollapseAll = shouldCollapse => {
		const map = new Map();
		phasesExpansionMap.forEach((v, k) => map.set(k, !shouldCollapse));
		setPhasesExpansionMap(map);
		trackEvent('Scoping Phases', shouldCollapse ? 'Collapsed' : 'Expanded');
	};

	const togglePhaseExpanded = phaseId => {
		const map = new Map(phasesExpansionMap);
		const curVal = map.get(phaseId);
		map.set(phaseId, !curVal);
		setPhasesExpansionMap(map);
		trackEvent('Scoping Phase', curVal ? 'Collapsed' : 'Expanded');
		// Update expansion in localStorage
		Util.localStorageSetItem(viewer.project.id + '-phaseMap', JSON.stringify(map));
	};

	const toggleTaskExpanded = taskId => {
		const map = new Map(tasksExpansionMap);
		const curVal = map.get(taskId);
		map.set(taskId, !curVal);

		setTasksExpansionMap(map);
		// Update expansion in localStorage
		Util.localStorageSetItem(viewer.project.id + '-taskMap', JSON.stringify(map));
		if (taskId === createSubtaskTask) {
			setCreateSubtaskTask(null);
		}
	};

	/**
	 * Toggle expanded on person group
	 * @param  personGroupId
	 */
	const togglePersonGroupExpanded = personGroupId => {
		const map = new Map(personGroupExpansionMap);
		let curVal = map.get(personGroupId);
		//curVal will be undefined the first time the group is toggled
		//and by default groups are expanded
		if (curVal === undefined) {
			map.set(personGroupId, false);
		} else {
			map.set(personGroupId, !curVal);
		}
		setPersonGroupExpansionMap(map);
	};

	/**
	 * Toggle expanded on role group
	 * @param  roleGroupId
	 */
	const toggleRoleGroupExpanded = roleGroupId => {
		const map = new Map(roleGroupExpansionMap);
		let curVal = map.get(roleGroupId);
		//curVal will be undefined the first time the group is toggled
		//and by default groups are expanded
		if (curVal === undefined) {
			map.set(roleGroupId, false);
		} else {
			map.set(roleGroupId, !curVal);
		}
		setRoleGroupExpansionMap(map);
	};

	const toggleExpansion = useCallback(
		({node}) => {
			if (node.type === TYPES.HEADER) {
				togglePhaseExpanded(node.id);
			} else if (node.type === TYPES.TASK) {
				toggleTaskExpanded(node.id);
			} else if (node.type === TYPES.PERSON_GROUPING) {
				togglePersonGroupExpanded(node.id);
			} else if (node.type === TYPES.ROLE_GROUPING) {
				if (showBaselineInfo && node.phaseId !== 'no-phase') {
					toggleRoleGroupExpanded(node.role.id + '#' + node.phaseId);
				} else {
					toggleRoleGroupExpanded(node.id);
				}
			}
		},
		[phasesExpansionMap, tasksExpansionMap, createSubtaskTask, personGroupExpansionMap, roleGroupExpansionMap]
	);

	const showTaskModal = useCallback(
		taskId => {
			Util.showTaskModal(taskId, history);
		},
		[history]
	);

	let treeData = null;

	const handleRowSelected = useCallback(
		(taskToSelect, shiftKey) => {
			updateSelectTasks(
				viewer,
				intl,
				selectedTasks,
				taskToSelect,
				shiftKey,
				createToast,
				setSelectedTasks,
				groupSetting,
				treeData
			);
		},
		[viewer, selectedTasks, groupSetting]
	);

	const closeContextMenu = () => {
		if (showContextMenu) {
			setShowContextMenu(false);
			setContextMenuPosition(null);
			setContextMenuTask(null);
		}
	};

	const onContextMenu = useCallback((task, taskProject, e) => {
		const el = e.target;
		if (task.readOnly?.isReadOnly) {
			return;
		}
		//clicked is when the context menu is open from the 3 dot-options on table-row
		if (
			el &&
			(el.tagName === 'INPUT' ||
				el.tagName === 'BUTTON' ||
				el.tagName === 'A' ||
				(el.className.toLowerCase && el.className.toLowerCase().includes('clicked')))
		) {
			closeContextMenu();
			return;
		}
		e.preventDefault();

		const contextMenuPosition = {};

		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			contextMenuPosition.x = btnBounds.right - btn.clientWidth - 2;
			if (window.innerHeight - btnBounds.bottom < 250) {
				contextMenuPosition.y = btnBounds.bottom - 282;
			} else {
				contextMenuPosition.y = btnBounds.bottom + 2;
			}
		} else {
			contextMenuPosition.x = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				contextMenuPosition.y = e.pageY - 250;
			} else {
				contextMenuPosition.y = e.pageY;
			}
		}
		// If the context menu is opened from somewhere other than the 3 dot-options, clear bulk select.
		if (el.className.toLowerCase && !el.className.toLowerCase().includes('dot-options')) {
			setSelectedTasks([]);
		}
		setShowContextMenu(true);
		setContextMenuPosition(contextMenuPosition);
		setContextMenuTask(task);
	}, []);

	const onCreateSubtask = useCallback(
		taskId => {
			if (!tasksExpansionMap.get(taskId)) {
				toggleTaskExpanded(taskId);
			}
			setCreateSubtaskTask(createSubtaskTask === taskId ? null : taskId);
		},
		[createSubtaskTask, tasksExpansionMap]
	);

	const newPhaseId = useRef(null);
	const isCreatingPhase = useRef(false);

	const phases = useMemo(() => {
		return viewer.project.phases.edges
			.map(phase => phase.node)
			.filter(phase => phaseActiveFilter(phase, selectedTab))
			.sort((a, b) => {
				return orderedPhaseIds.indexOf(a.id) - orderedPhaseIds.indexOf(b.id);
			});
	}, [viewer.project.phases.edges, selectedTab, orderedPhaseIds]);

	const sharedOptions = useMemo(() => {
		const visibility = {
			showBaselineInfo,
			showPeriods: showPeriods,
			showEmptyPeriods: showEmptyPeriods,
			displayScopingAvailability,
			groupSetting,
		};

		return getSharedOptions(
			viewer,
			viewer.projectGroup,
			availableColumns,
			visibility,
			phases,
			createSubtaskTask,
			showTaskModal,
			onContextMenu,
			relay.refetch,
			handleRowSelected,
			onCreateSubtask,
			predictionData,
			selectedTasks.length > 0,
			intl
		);
	}, [
		viewer,
		selectedTasks,
		availableColumns,
		showBaselineInfo,
		phases,
		createSubtaskTask,
		tasksExpansionMap,
		predictionData,
		showPeriods,
		showEmptyPeriods,
		displayScopingAvailability,
	]);

	const holidaysMap = getHolidaysMap(viewer.company.holidayCalendars);
	const allocations = viewer.project.allocations.edges.map(edge => edge.node);
	const projectPersons = viewer.project.projectPersons.edges.map(edge => edge.node);
	const availabilityMap = createAvailabilityMap(phases, allocations, projectPersons, holidaysMap, sharedOptions);

	treeData = useMemo(() => {
		return generateTree(
			viewer,
			phases,
			phasesExpansionMap,
			tasksExpansionMap,
			selectedTasks,
			availableColumns,
			groupSetting,
			filterFunctions,
			searchFilterValue,
			predictionDataMap,
			sharedOptions,
			intl,
			personGroupExpansionMap,
			roleGroupExpansionMap,
			availabilityMap,
			baselineRoleFinancialNumbers
		);
	}, [
		viewer,
		phases,
		phasesExpansionMap,
		tasksExpansionMap,
		personGroupExpansionMap,
		roleGroupExpansionMap,
		availableColumns,
		groupSetting,
		filterFunctions,
		searchFilterValue,
		predictionDataMap,
		selectedTasks,
		sharedOptions,
		showPeriods,
	]);

	const projectTotalData = useMemo(() => {
		return getProjectTotalData(treeData, viewer.project, viewer.availableFeatureFlags);
	}, [treeData, viewer.project, viewer.availableFeatureFlags]);

	const handleOnEscapeKeydown = () => {
		setSelectedTasks([]);
	};

	const handleKeyDownListener = useCallback(
		e => {
			const {key} = e;
			switch (key) {
				case 'Escape':
					handleOnEscapeKeydown();
					break;
			}
		},
		[selectedTasks]
	);

	const showAutomateModal = variant => {
		showModal({
			type: MODAL_TYPE.PROJECT_AUTOMATE,
			variant: variant,
			tasks: selectedTasks,
			intl: intl,
			labels: viewer.company.labels,
			roles: viewer.company.roles,
			persons: viewer.project.projectPersons.edges.map(edge => edge.node.person),
		});
	};

	const addPhase = () => {
		const onSuccess = result => {
			isCreatingPhase.current = false;

			const createdPhaseId = result.createPhase.phase.node.id;
			newPhaseId.current = createdPhaseId;
			const orderedPhases = [createdPhaseId, ...orderedPhaseIds];

			const expansionMap = new Map(phasesExpansionMap);
			expansionMap.set(createdPhaseId, true);

			setOrderedPhaseIds(orderedPhases);
			setPhasesExpansionMap(expansionMap);

			createToast({duration: 5000, message: intl.formatMessage({id: 'project_scopes.scope-group-created'})});
			//scroll to the new phase

			const gridNode = document.querySelector('.ReactVirtualized__Grid');
			gridNode.scroll({top: 0, behavior: 'smooth'});
		};
		//const formattedDeadline = Util.GetYearMonthDateFromMomentDate(deadline);
		if (isCreatingPhase.current === false) {
			isCreatingPhase.current = true;
			Util.CommitMutation(
				CreatePhaseMutation,
				{
					projectId: viewer.project.id,
					name: intl.formatMessage({id: 'project_scoping.new-scope-group'}),
				},
				onSuccess
			);
		}
	};

	const theEyeSelector = (selectedElem, _, __, changedOptions) => {
		const savedOptionValues = {};
		optionTraverser(changedOptions, (option, path) => {
			savedOptionValues[path] = option.checked;
		});

		// custom logic for showing baseline info.
		// this is project specific, and not a global setting
		// that is why we save it in its own localStorage
		let baselineInfoOption = showBaselineInfo;
		if (selectedElem.name === 'show-scoping-baseline-info') {
			baselineInfoOption = !showBaselineInfo;
		}
		setShowBaselineInfo(baselineInfoOption);

		let displayScopingAvailabilityOption = displayScopingAvailability;
		if (selectedElem.name === 'display-scoping-availability') {
			displayScopingAvailabilityOption = !displayScopingAvailability;
			setDisplayScopingAvailability(displayScopingAvailabilityOption);
		}

		// To avoid messing too much with the state management of Scoping, we will continue to store the columns themselves instead of just the eyeOptions object.
		// Eventually, it would likely make sense to align the approach to eye options with the newer pages by using the useEyeOptions hook.
		const cols = eyeOptionsToCols(savedOptionValues, availableColumns);

		setAvailableColumns(cols);

		const theEye = cols.map(c => {
			return {
				id: c.name,
				checked: c.checked,
			};
		});

		localStorage.setItem(`project-phases-show-baseline-info${viewer.project.companyProjectId}`, baselineInfoOption);
		localStorage.setItem(
			`project-phases-display-scoping-availability${viewer.project.companyProjectId}`,
			displayScopingAvailabilityOption
		);
		Util.localStorageSetItem('project-new-scoping-selected-cols', JSON.stringify(theEye));
	};

	const toggleGroupByPerson = () => {
		// Deselect tasks when changing grouping
		setSelectedTasks([]);
		const toggleOn = groupSetting !== GROUP_TYPE.PERSON;
		setGroupSetting(toggleOn ? GROUP_TYPE.PERSON : GROUP_TYPE.NO_GROUPING);

		Util.localStorageSetItem('project-scoping-group-by-person', toggleOn);
		Util.localStorageSetItem('project-scoping-group-by-role', false);

		tracking.trackElementClicked('Group by person', {enabled: toggleOn});
		trackEvent('Group By Person', toggleOn ? 'Enabled' : 'Disabled');
	};

	const toggleGroupByRole = () => {
		// Deselect tasks when changing grouping
		setSelectedTasks([]);
		const toggleOn = groupSetting !== GROUP_TYPE.ROLE;
		setGroupSetting(toggleOn ? GROUP_TYPE.ROLE : GROUP_TYPE.NO_GROUPING);

		Util.localStorageSetItem('project-scoping-group-by-role', toggleOn);
		Util.localStorageSetItem('project-scoping-group-by-person', false);

		tracking.trackElementClicked('Group by role', {enabled: toggleOn});
		trackEvent('Group By Role', toggleOn ? 'Enabled' : 'Disabled');
	};

	const toggleShowPeriods = () => {
		setShowPeriods(value => {
			const newValue = !value;
			Util.localStorageSetItem(SHOW_PERIODS_SETTING, newValue);
			tracking.trackElementClicked('Show Periods', {enabled: newValue});
			trackEvent('Show Periods', newValue ? 'Enabled' : 'Disabled');
			return newValue;
		});
	};

	const toggleShowEmptyPeriods = () => {
		setShowEmptyPeriods(value => {
			const newValue = !value;
			Util.localStorageSetItem(SHOW_EMPTY_PERIODS_SETTING, newValue);
			tracking.trackElementClicked('Show Empty Periods', {enabled: newValue});
			trackEvent('Show Empty Periods', newValue ? 'Enabled' : 'Disabled');
			return newValue;
		});
	};

	const toggleShowResolvedTasks = () => {
		const newValue = !showResolvedTasks;
		const willLoadResolvedTasks = newValue === true;

		const toggleResolvedTasks = () => {
			dispatch(EVENT_ID.SHOW_LOADER);

			trackEvent('Dormant Tasks', willLoadResolvedTasks ? 'Shown' : 'Hidden');

			// If dormant tasks are being loaded, stop socket until it is turned off again (socket events will likely be too large for the backend to handle)
			if (willLoadResolvedTasks) {
				setSocketConfig([]);
			} else {
				setSocketConfig(getSocketConfig(viewer.project.id, viewer.actualPersonId));
			}

			relay.refetch(
				{
					noStaleTasks: !newValue, // To avoid confusion - When showResolvedTasks is true, noStaleTasks should be false.
				},
				null,
				() => {
					dispatch(EVENT_ID.HIDE_LOADER);
					dispatch(EVENT_ID.CLOSE_MODAL);
					setShowResolvedTasks(() => {
						return newValue;
					});
				}
			);
		};

		if (willLoadResolvedTasks) {
			showModal({
				type: MODAL_TYPE.CONFIRMATION,
				title: 'Show Dormant Tasks',
				description: 'This is likely to be slow. Please be patient.',
				autoClosePositive: false,
				callbackPositive: () => toggleResolvedTasks(),
			});
		} else {
			toggleResolvedTasks();
		}
	};

	const handleTabChange = tab => {
		setSelectedTab(tab);
		trackEvent('Phases Dropdown', 'Changed', {value: tab.value});
		Util.localStorageSetItem('project-scoping-selected-tab', tab.value);
	};

	const onAutoScheduleButtonClicked = () => {
		tracking.trackElementClicked('Auto Schedule project from the Scoping page');
		trackEvent('Auto Schedule', 'Opened');
		//  open new tab on the project timeline and with the autoschedule modal
		window.open(`${projectUrl(viewer.project.companyProjectId)}/schedule#auto-schedule`);
	};

	const dataCSV = () => {
		const allTasks = viewer.project.tasks.edges;

		let csvTasks = allTasks.map(task => task.node);
		csvTasks = csvTasks.sort((a, b) => {
			const aPhase = a.phase ? a.phase : null;
			const bPhase = b.phase ? b.phase : null;
			const samePhase = aPhase && bPhase && aPhase.id === bPhase.id;

			// Both are in same phase, compare sort order
			if ((aPhase === null && bPhase === null) || samePhase) {
				return a.sortOrder - b.sortOrder;
			} else if (aPhase == null && bPhase) {
				return 1;
			} else if (aPhase && bPhase == null) {
				return -1;
			}
			//	Else sort by phase
			const aStartDate = aPhase.startYear
				? Util.CreateMomentDate(aPhase.startYear, aPhase.startMonth, aPhase.startDay)
				: null;
			const bStartDate = bPhase.startYear
				? Util.CreateMomentDate(bPhase.startYear, bPhase.startMonth, bPhase.startDay)
				: null;

			const sameDate = aStartDate && bStartDate && aStartDate.isSame(bStartDate);
			// Both phases have the same start date, sort by phase id
			if ((aStartDate == null && bStartDate == null) || sameDate) {
				return aPhase.id < bPhase.id ? 1 : -1;
			} else if (aStartDate == null && bStartDate) {
				return 1;
			} else if (aStartDate && bStartDate == null) {
				return -1;
			} else return aStartDate.isBefore(bStartDate) ? -1 : 1;
		});
		return csvTasks;
	};

	const exportCSVNew = () => {
		const hiddenColumnNames = ['total_availability', 'remaining_availability'];
		// Clone columns and replace - to _ in all columns to match CSV formatter.
		let columns = JSON.parse(JSON.stringify(availableColumns))?.filter(column => !hiddenColumnNames.includes(column.name));
		columns.forEach(column => (column.name = column.name.replace('-', '_')));
		exportData(columns, dataCSV(), {projects: [viewer.project]}, null, 'CSV');
		trackCSVExport('Scoping');
	};

	const getHeaderTitleContent = () => {
		const content = [];
		const onboardingFlows = [
			{
				id: 'scoping-introduction',
				title: 'Introduction to the page',
				description: null,
				contentId: '1681821491mYer9167',
			},
			{
				id: 'scoping-first-phase',
				title: 'How to scope out your first phase',
				description: null,
				contentId: '1681888433yDqg606',
			},
		];

		const onboardingComponent = {
			id: 'onboarding-component',
			type: TopHeaderBar.TYPE.ONBOARDING,
			title: intl.formatMessage({id: 'onboarding.scoping_onboarding_title'}),
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/sections/4419344459665-Projects',
			subLink: 'https://support.forecast.app/hc/en-us/articles/5302898580369-Defining-your-project-scope',
		};
		if (hiddenFinancialFields.length) {
			content.push({
				id: 'warning-component',
				type: TopHeaderBar.TYPE.WARNING,
				width: 'l',
				tooltip: (
					<FlexColumn gap={'xl'}>
						<SubHeading>{intl.formatMessage({id: 'financial.task_breakdown.finance_information_na'})}</SubHeading>
						<FormattedHTMLMessage
							id="financial.task_breakdown.finance_information_na_description"
							values={{
								link: `${location.pathname.split('/').slice(0, -1).join('/')}/settings#financials`,
							}}
						></FormattedHTMLMessage>
						<ul>
							{hiddenFinancialFields.map(field => (
								<li>{field}</li>
							))}
						</ul>
					</FlexColumn>
				),
			});
		}
		content.push(onboardingComponent);

		return content;
	};

	const constructHeaderBar = () => {
		const parentGroup = viewer.project && viewer.project.projectGroupId;

		const projectLocked = viewer.project.status === 'DONE' || viewer.project.status === 'HALTED';
		const createPhaseAccess = hasPermission(PERMISSION_TYPE.PHASE_CREATE);
		const {formatMessage} = intl;
		const leftContent = [];
		const rightContent = [];

		const autoSchedulingEnabled =
			hasFeatureFlag('auto_schedule_access') &&
			!viewer.project.useManualAllocations &&
			hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE);
		tabs.forEach(tab => {
			tab.label = formatMessage({id: tab.translationId});
		});
		const pastOrActivePhase = {
			type: ELEMENT_TYPE.DROPDOWN_PHASES,
			dropdownOptions: tabs,
			value: selectedTab.value,
			buttonCy: 'new-ui-scoping-phase-selector-tab',
			listDataCy: 'new-ui-scoping-phase-selector-options',
			userpilot: 'milestone-dropdown',
			callback: tab => handleTabChange(tab),
		};
		rightContent.push(pastOrActivePhase);

		const customEyeOptions = [];

		if (viewer.project.estimationUnit === ESTIMATION_UNIT.HOURS && isBaselineProject) {
			customEyeOptions.push({
				name: 'show-scoping-baseline-info',
				checked: showBaselineInfo,
				translationId: 'scoping.show_scoping_baseline_info',
			});
		}

		if (viewer.company.isUsingProjectAllocation) {
			customEyeOptions.push({
				name: 'display-scoping-availability',
				checked: displayScopingAvailability,
				translationId: 'scoping.display_scoping_availability',
			});
		}

		const eyeAvailableColumns = groupEyeOptions(availableColumns.concat(customEyeOptions));

		const theEye = {
			type: ELEMENT_TYPE.THE_EYE,
			options: availableColumns.length > 0 ? eyeAvailableColumns : null,
			onSelect: theEyeSelector,
			openRight: true,
			ignoreScroll: true,
		};
		leftContent.push(theEye);

		if (!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) {
			const csv = {
				type: ELEMENT_TYPE.CSV,
				callback: () => exportCSVNew(),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.LIGHTGREY,
				text: formatMessage({id: 'common.export-csv'}),
				tooltipEnabled: true,
				tooltipProps: {
					autoPlace: true,
					grey: true,
					infoText: formatMessage({id: 'common.export-csv'}),
				},
				disabled: !lazyDataFetched,
			};
			leftContent.push(csv);
		}
		if (projectLocked) {
			const indicator = {
				type: ELEMENT_TYPE.INDICATOR,
				status: viewer.project.status,
			};
			leftContent.push(indicator);
		}

		const search = {
			type: ELEMENT_TYPE.SEARCH_LAZY,
			onChange: searchString => setSearchFilterValue(searchString),
			placeholder: intl.formatMessage({id: 'global_search.placeholder'}),
		};

		rightContent.push(search);

		if (hasFeatureFlag('zen_mode')) {
			rightContent.push({
				type: ELEMENT_TYPE.ZEN_MODE,
			});
		}

		const taskFilters = [
			FILTER_TYPE.PROJECT_PERSON,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.PROJECT_STATUS_COLUMN,
			FILTER_TYPE.APPROUVED,
			FILTER_TYPE.DEADLINE,
			FILTER_TYPE.DEPENDENCIES,
			FILTER_TYPE.INDICATOR,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.PROJECT_PHASE,
			FILTER_TYPE.RECENT_ACTIVITY,
		];

		if (viewer.company.teams.edges.length > 0) {
			taskFilters.push(FILTER_TYPE.TEAM);
		}

		if (viewer.project && viewer.project.sprintTimeBox) {
			taskFilters.push(FILTER_TYPE.PROJECT_SPRINT);
			taskFilters.push(FILTER_TYPE.SPRINT_CATEGORY);
		}

		if (viewer.project.useTaskOwner) {
			taskFilters.push(FILTER_TYPE.PROJECT_OWNER);
		}

		if (viewer.project.useTaskFollowers) {
			taskFilters.push(FILTER_TYPE.PROJECT_FOLLOWER);
		}

		if (viewer.project && viewer.project.taskLevels === 2) {
			taskFilters.push(FILTER_TYPE.SUB_TASKS);
		}

		if (viewer.project.estimationUnit === ESTIMATION_UNIT.HOURS) {
			taskFilters.push(FILTER_TYPE.PREDICTED_OVERRUN);
		}

		const projectFilters = [];
		const peopleFilters = [];
		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.TASKS,
			projectFilters,
			peopleFilters,
			taskFilters: getFiltersAlphabetically(taskFilters, intl.formatMessage),
			primaryFilters: {
				[FILTER_SECTIONS.TASKS]: [
					FILTER_TYPE.PROJECT_STATUS_COLUMN,
					FILTER_TYPE.PROJECT_PERSON,
					FILTER_TYPE.PROJECT_PHASE,
					FILTER_TYPE.ROLE,
					FILTER_TYPE.DEADLINE,
					FILTER_TYPE.LABEL,
				],
			},
			viewer: viewer,
			preAppliedFilters: preAppliedFilters,
			appliedFiltersName: `project-scoping-filters-v4-${viewer.project.id}`,
			filterSection: FILTER_SECTION.SCOPING,
			onFiltersChange: (_, filterFunctions) => setFilterFunctions(filterFunctions),
			projectId: viewer.project.id,
			companyProjectId: viewer.project.companyProjectId,
		});

		if (createPhaseAccess) {
			const newMilestonButton = {
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'project_scoping.new-scope-group'}),
				callback: () => addPhase(),
				disabled: projectLocked,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
				userpilot: 'new-milestone-button',
				dataCy: 'new-ui-scoping-custom-button-container',
			};
			rightContent.push(newMilestonButton);
		}

		if (autoSchedulingEnabled && !parentGroup && !projectLocked) {
			const autoScheduleButton = {
				key: 'auto-schedule-button',
				type: ELEMENT_TYPE.BUTTON,
				customClassName: 'auto-scheduling-button',
				autoScheduleStyle: true,
				text: intl.formatMessage({id: 'auto_scheduling.auto_schedule'}),
				callback: () => onAutoScheduleButtonClicked(),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.AI_BLUE,
			};
			rightContent.push(autoScheduleButton);
		}

		const shouldCollapse = [...phasesExpansionMap.values()].some(val => val === true);
		const collapse = {
			type: ELEMENT_TYPE.COLLAPSE,
			collapsed: shouldCollapse,
			toggleCollapse: () => toggleCollapseAll(shouldCollapse),
		};

		rightContent.push(collapse);

		const actionsMenuOptions = [];

		actionsMenuOptions.push({
			key: 'GROUP_BY_PERSONS',
			text: formatMessage({id: 'common.group_by_person'}),
			hotkey: 'Shift + G',
			onClick: () => toggleGroupByPerson(),
			slider: true,
			checked: groupSetting === GROUP_TYPE.PERSON,
		});

		actionsMenuOptions.push({
			key: 'GROUP_BY_ROLE',
			text: formatMessage({id: 'common.group_by_role'}),
			onClick: () => toggleGroupByRole(),
			slider: true,
			checked: groupSetting === GROUP_TYPE.ROLE,
		});

		if (isRetainerProject) {
			actionsMenuOptions.push({
				key: 'SHOW_PERIODS',
				text: 'Show Periods',
				onClick: toggleShowPeriods,
				slider: true,
				checked: showPeriods,
			});
			actionsMenuOptions.push({
				key: 'SHOW_EMPTY_PERIODS',
				text: 'Show Empty Periods',
				onClick: toggleShowEmptyPeriods,
				// locked: !showPeriods,
				slider: true,
				checked: showPeriods && showEmptyPeriods,
			});
		}

		if (showResolvedTasksOption) {
			actionsMenuOptions.push({
				key: 'SHOW_DORMANT_TASKS',
				text: 'Show Dormant Tasks',
				onClick: toggleShowResolvedTasks,
				slider: true,
				checked: showResolvedTasks,
			});
		}

		if (actionsMenuOptions.length > 0) {
			const actionsMenu = {
				type: ELEMENT_TYPE.ACTIONS_MENU,
				options: actionsMenuOptions,
				whiteInner: true,
				isWhite: true,
				blackIcon: true,
				userpilot: showResolvedTasksOption ? 'actions-menu-top-with-resolved-tasks' : 'actions-menu-top',
			};

			rightContent.push(actionsMenu);
		}

		return buildHeaderBar(leftContent, rightContent);
	};

	const selectedTaskCount = selectedTasks.length;

	const getContextMenu = () => {
		return (
			<GenericTaskContextMenu
				cy="scoping-task-context-menu"
				selectedTasks={selectedTasks}
				task={contextMenuTask}
				viewer={viewer}
				contextMenuPosition={contextMenuPosition}
				closeContextMenu={closeContextMenu}
				unselectTasks={() => setSelectedTasks([])}
			/>
		);
	};

	const canDrop = useMemo(() => {
		return getCanDropFunc(groupSetting, showBaselineInfo);
	}, [groupSetting, showBaselineInfo]);

	const hasFinancialAccess =
		CompanySetupUtil.hasFinance() &&
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]);

	const isUsingProjectAllocation = viewer.company.isUsingProjectAllocation;
	const isPointsProject = viewer.project.estimationUnit === ESTIMATION_UNIT.POINTS;

	// UseEffects

	useTrackPage('Project Scoping', {selectedTab: selectedTab.value});

	useEffect(() => {
		tracking.trackPage('project-scoping-v2');

		if (isMounted.current) setOrderedPhaseIds(getOrderedPhases(viewer.project));

		const name =
			viewer.project.name !== null && viewer.project.name !== ''
				? viewer.project.name
				: getProjectIndicatorString(viewer.project.companyProjectId, viewer.project.customProjectId);
		document.title = 'Scoping - ' + name + ' - Forecast';
		setSocketConfig(getSocketConfig(viewer.project.id, viewer.actualPersonId));
		if (shouldShowOverrunPrediction) {
			fetch({projectId: viewer.project.id});
		}

		if (refetch) {
			refetch(
				{
					fetchLazyData: true,
				},
				null,
				() => setLazyDataFetched(true)
			);
		}

		window.addEventListener('keydown', handleKeyDownListener);

		isMounted.current = true;

		return () => {
			window.removeEventListener('keydown', handleKeyDownListener);
		};
	}, [viewer.project.id]);

	useUpdateEffect(() => {
		const initialSelectedColumns = getInitialSelectedColumns(intl.formatMessage, viewer, showPeriods);
		setAvailableColumns(initialSelectedColumns);
	}, [showPeriods]);

	useEffect(() => {
		if (predictionData) {
			const tasks = viewer.project.tasks.edges;
			const predictionDataTasks = tasks.map(t => {
				return {
					...t.node,
					predictedEstimate: predictionDataMap[t.node.id]?.predictedEstimate,
				};
			});
			trackPredictionsShown(predictionDataTasks);
		}
	}, [predictionDataMap]);

	useEffect(() => {
		if (searchFilterValue && searchFilterValue !== '') {
			const taskCount = viewer.project.tasks.edges.filter(task => searchFilter(task, searchFilterValue)).length;
			trackEvent('Search', 'Results', {searchString: searchFilterValue, matching: taskCount});
		}
	}, [searchFilterValue]);

	return (
		<div
			className="section-content project-scoping no-margin"
			style={{flexGrow: 1, display: 'flex', flexDirection: 'column', overflowX: 'auto', overflowY: 'hidden'}} // Hiding overflowY to prevent outermost scrollbar from ever appearing. Outer scroll can cause a flickering loop because of the calculated height of the table combined with grid/flex elements outside the table.
			data-cy={'scoping-page'}
		>
			{selectedTaskCount > 0 && (
				<BulkSelectPopup
					itemCount={selectedTaskCount}
					counterText={intl.formatMessage({id: 'bulk_edit.tasks_selected'})}
					actionOptions={getBulkUpdateOptions(
						selectedTasks,
						setSelectedTasks,
						viewer.project,
						viewer.company,
						viewer.timerTask,
						viewer.timeStartDate,
						viewer.availableFeatureFlags,
						intl
					)}
					onClose={() => setSelectedTasks([])}
					complexOptions={getComplexBulkOptions(
						variant => showAutomateModal(variant),
						getAdditionalBulkUpdateAutomateOptions(
							selectedTasks,
							setSelectedTasks,
							predictionDataMap,
							viewer.project,
							PERMISSION_TYPE.PHASE_UPDATE,
							viewer.availableFeatureFlags,
							intl
						),
						intl
					)}
				/>
			)}
			<ProjectHeader
				title={intl.formatMessage({id: 'project_section.scope'})}
				titleContent={getHeaderTitleContent()}
				buttons={constructHeaderBar()}
				project={viewer.project}
				psProject={viewer.psProject}
			/>
			<ZenModeable>
				<ProjectScopingTotalsProvider
					projectData={projectTotalData}
					settings={{
						currencySymbol,
						hasFinancialAccess,
						isUsingProjectAllocation,
						isPointsProject,
						isBaselineProject,
						financialSourceSettings: viewer.project.financialSourceSettings,
					}}
					baselineFinancialNumbers={baselineRoleFinancialNumbers}
					totalFinancialNumbers={totalFinancialNumbers}
					phases={phases}
				>
					<ProjectScopingTotals />
				</ProjectScopingTotalsProvider>
			</ZenModeable>
			{showContextMenu ? getContextMenu() : null}
			<div style={{flexGrow: 1, display: 'flex'}}>
				<TaskTable
					newTopNode={newPhaseId.current}
					onToggleExpansion={toggleExpansion}
					groupType={groupSetting}
					showBaselineInfo={showBaselineInfo}
					useTaskHierarchy={sharedOptions.useTaskHierarchy}
					tableData={treeData}
					setSelectedTasks={setSelectedTasks}
					selectedTasks={selectedTasks}
					availableColumns={availableColumns}
					onMoveNode={onMoveNode}
					onVisibilityToggle={toggleExpansion}
					canDrag={canDrag}
					canDrop={canDrop}
					refetch={relay.refetch}
				/>
			</div>
		</div>
	);
};

const ProjectScopingPageQuerry = graphql`
	query ProjectScopingPage_Query(
		$projectId: String
		$noStaleTasks: Boolean
		$fetchLazyData: Boolean!
		$fetchCustomFields: Boolean!
		$personId: ID!
	) {
		viewer {
			actualPersonId
			component(name: "new_scoping")
			project(id: $projectId) {
				id
			}
			...ProjectScopingPage_viewer
				@arguments(
					projectId: $projectId
					noStaleTasks: $noStaleTasks
					fetchLazyData: $fetchLazyData
					fetchCustomFields: $fetchCustomFields
					personId: $personId
				)
		}
	}
`;

export {ProjectScopingPageQuerry};

export default withSocketHandling(
	withRouter(
		createRefetchContainer(
			ProjectScopingPage,
			{
				viewer: graphql`
					fragment ProjectScopingPage_viewer on Viewer
					@argumentDefinitions(
						projectId: {type: "String"}
						noStaleTasks: {type: "Boolean"}
						fetchLazyData: {type: "Boolean!"}
						fetchCustomFields: {type: "Boolean!"}
						personId: {type: "ID!"}
					) {
						id
						language
						email
						firstName
						lastName
						timerStartDate
						actualPersonId
						harvestUser
						unit4User
						excludeFromCompanyLockedPeriod
						submitLockedDateYear
						submitLockedDateMonth
						submitLockedDateDay
						startDate
						endDate
						createdAt
						monday
						tuesday
						wednesday
						thursday
						friday
						saturday
						sunday
						availableFeatureFlags {
							key
						}
						timerTask {
							id
						}
						company {
							...genericTaskContextMenu_company
							jiraCloudEnabled
							jiraServerEnabled
							integrations {
								jiraCloud {
									syncSettings {
										isJiraToForecastOneWaySync
									}
								}
								jiraServer {
									syncSettings {
										isJiraToForecastOneWaySync
									}
								}
							}
							modules {
								moduleType
							}
							person(id: $personId) {
								id
								role {
									id
									name
								}
							}
							isUsingProjectAllocation
							isUsingMixedAllocation
							holidayCalendars(first: 10000) @connection(key: "Company_holidayCalendars") {
								edges {
									node {
										id
										holidayCalendarEntries(first: 100000)
											@connection(key: "HolidayCalendar_holidayCalendarEntries") {
											edges {
												node {
													id
													name
													year
													month
													day
												}
											}
										}
									}
								}
							}
							allPersons(first: 1000000, onlyActive: true) @connection(key: "Company_allPersons", filters: []) {
								edges {
									node {
										id
										firstName
										lastName
									}
								}
							}
							id
							currency
							harvestEnabled
							unit4Enabled
							jiraCloudEnabled
							lockedPeriodYear
							lockedPeriodMonth
							lockedPeriodDay
							labels(first: 1000000, labelType: TASK) {
								edges {
									node {
										id
										name
										color
										category {
											id
											name
										}
										...LabelDropdown_labels
									}
								}
							}
							roles(first: 1000000) {
								edges {
									...RoleDropdown_roles
									node {
										id
										name
									}
								}
							}
							teams(first: 1000000) {
								edges {
									node {
										id
										name
										teamPersons(first: 1000) {
											edges {
												node {
													id
													person {
														id
													}
												}
											}
										}
									}
								}
							}
							customFieldDefinitions(first: 1000)
								@connection(key: "Company_customFieldDefinitions")
								@include(if: $fetchCustomFields) {
								edges {
									node {
										id
										key
										displayName
										entityType
										readOnly
									}
								}
							}
						}
						filters(first: 1000000, projectId: $projectId, filterSection: SCOPING)
							@connection(key: "Viewer_filters", filters: []) {
							edges {
								node {
									id
									name
									section
									value
									updatedAt
								}
							}
						}
						projects(first: 1000000, excludeRestricted: true) {
							edges {
								node {
									id
									companyProjectId
									customProjectId
									name
									status
									isInProjectGroup
									projectColor
								}
							}
						}
						project(id: $projectId) {
							...ProjectHeader_project
							...SecondaryNavigation_project
							projectFirstDateYear
							projectFirstDateMonth
							projectFirstDateDay
							projectLastDateYear
							projectLastDateMonth
							projectLastDateDay
							baselineTargetPrice
							baselineTargetPriceNoExpenses
							baselineTargetMinutes
							id
							isNoStaleTasksAllowed
							name
							demo
							budgetWork
							budgetType
							defaultPeriodBudgetType
							budget
							billable
							synchBaselineAndScopingDates
							program {
								name
								prefix
								budgetType
								members {
									edges {
										node {
											role
											person {
												id
											}
										}
									}
								}
							}
							sprintTimeBox
							isInProjectGroup
							useBaseline
							projectGroupId
							status
							companyProjectId
							customProjectId
							estimationUnit
							minutesPerEstimationPoint
							timeRegistrations(first: 10000000, onlyProjectRegistrations: true)
								@connection(key: "Project_timeRegistrations", filters: [])
								@include(if: $fetchLazyData) {
								edges {
									node {
										id
										minutesRegistered
										billableMinutesRegistered
										invoiced
										price
										updatedAt
										year
										month
										day
										retainerConflictHandled
										task {
											id
										}
										person {
											id
										}
									}
								}
							}
							retainerPeriods(first: 100000) {
								edges {
									node {
										id
										name
										startYear
										startMonth
										startDay
										endYear
										endMonth
										endDay
										available
										periodLength
										periodPeriodicity
										periodPriceAmount
										periodHoursAmount
										periodBudgetType
										periodSettingIgnoreForBilling
										periodSettingSubtractValue
										periodSettingRollValue
										periodSettingAddExpenses
										periodDifferencePriceAmount
										periodDifferenceHoursAmount
										sharedPeriodDifferenceHoursAmount
										sharedPeriodDifferencePriceAmount
										ignoredRolloverHours
										ignoredRolloverPrice
										periodLocked
										periodLockedTime
									}
								}
							}
							minutesPerEstimationPoint
							projectColor
							projectStartYear
							projectStartMonth
							projectStartDay
							projectEndYear
							projectEndMonth
							projectEndDay
							useManualAllocations
							remainingAutoCalculated
							isJiraProject
							useTaskOwner
							useTaskFollowers
							taskLevels
							useTaskHierarchy
							vstsProject
							vstsAccount
							harvestProjectId
							vstsTwoWaySync
							jiraSubtaskType
							progress
							manualProgressOnProjectEnabled
							manualProgressOnPhasesEnabled
							manualProgressOnTasksEnabled
							financialSourceSettings {
								plannedRevenue
								plannedCost
								actualRevenue
								actualCost
								forecastRevenue
							}
							jiraCloudProject {
								id
							}
							jiraCloudEpicIds
							jiraServerProject {
								id
							}
							sageProject {
								sageProjectId
							}
							statusColumnsV2(first: 1000000) {
								edges {
									node {
										id
										name
										order
										jiraStatusId
										category
									}
								}
							}
							rateCard {
								id
								currency
								rates(first: 10000) {
									edges {
										node {
											id
											rate
											defaultRate
											startDate
											role {
												id
											}
										}
									}
								}
								disabledRoles {
									id
								}
							}
							projectPersons(first: 1000000) {
								edges {
									node {
										id
										role {
											id
											name
										}
										person {
											id
											firstName
											lastName
											fullName
											initials
											profilePictureId
											profilePictureDefaultId
											active
											monday
											tuesday
											wednesday
											thursday
											friday
											saturday
											sunday
											holidayCalendar {
												id
											}
											role {
												id
												name
											}
											client {
												id
											}
											allocations(first: 1000000, idleTimeOnly: true) {
												edges {
													node {
														id
														monday
														tuesday
														wednesday
														thursday
														friday
														saturday
														sunday
														startYear
														startMonth
														startDay
														endYear
														endMonth
														endDay
														idleTime {
															isInternalTime
														}
													}
												}
											}
										}
									}
								}
							}
							phases(first: 1000000) @connection(key: "Project_phases") {
								edges {
									node {
										baselineTargetPrice
										baselineTargetPriceNoExpenses
										baselineTargetMinutes
										baselineCost
										inheritDatesFrom
										progress
										progressDetails {
											progress
										}
										phaseBaselineRoles {
											edges {
												node {
													role {
														id
													}
													baselineMinutes
													baselinePrice
												}
											}
										}
										id
										name
										startYear
										startMonth
										startDay
										startFrom
										deadlineDay
										deadlineMonth
										deadlineYear
										deadlineFrom
										baselineStartYear
										baselineStartMonth
										baselineStartDay
										baselineDeadlineDay
										baselineDeadlineMonth
										baselineDeadlineYear
										unit4Id
										jiraId
										phasePersons(first: 10000) {
											edges {
												node {
													id
													availableMinutes
													scheduledMinutes
													person {
														id
													}
												}
											}
										}
										customFieldValues @include(if: $fetchCustomFields) {
											edges {
												node {
													key
													displayName
													value
													readOnly
												}
											}
										}
									}
								}
							}
							baselineFinancialNumbers: groupedFinancialNumbers(
								convertToProjectCurrency: true
								groupBy: ["PHASE_BASELINE_ROLE", "PHASE"]
							) {
								baselineRevenue
								baselineTimeAndExpenses
								baselineCost
								baselineProfit
								baselineMargin
								baselineRatePerHour
								baselineCostPerHour
								baselineMinutes
								phaseBaselineRoleId
								phaseBaselineExpenseId
								phaseId
							}
							sprints(first: 1000000) {
								edges {
									node {
										id
										name
										endYear
										endMonth
										endDay
										startYear
										startMonth
										startDay
									}
								}
							}
							tasks(first: 100000, simpleResponse: true, noStaleTasks: $noStaleTasks)
								@connection(key: "Project_tasks", filters: []) {
								edges {
									node {
										id
										readOnly {
											isReadOnly
										}
										userCanDeleteTask
										userCantDeleteTaskReason
										hasInvoicedTime
										hasLockedTime
										hasTimeRegistrations
										approved
										name
										description
										sortOrder
										done
										billable
										blocked
										progress @include(if: $fetchLazyData)
										bug
										latestUiUpdateAt
										startFrom
										deadlineFrom
										startYear
										startMonth
										startDay
										deadlineDay
										deadlineMonth
										deadlineYear
										estimateForecast
										estimateForecastMinutes
										estimateForecastPrice
										totalPriceAtCompletion
										totalCostAtCompletion
										allTotalTimeAndExpensesAtCompletion
										currentPrice @include(if: $fetchLazyData)
										actualCost @include(if: $fetchLazyData)
										plannedCost @include(if: $fetchLazyData)
										companyTaskId
										timeLeft @include(if: $fetchLazyData)
										deadlineFrom
										canStart
										canBeSetToDone
										approved
										highPriority
										hasDependency
										jiraId
										vstsId
										isUnit4Task
										subTaskCount
										parentTaskId
										hasChildren
										sageIntacctId
										favoured
										progressDetails {
											progress
										}
										owner {
											id
											permissions
										}
										followers {
											id
											permissions
										}
										phase {
											id
											name
											startYear
											startMonth
											startDay
											startFrom
											deadlineDay
											deadlineMonth
											deadlineYear
											deadlineFrom
										}
										role {
											id
											name
										}
										sprint {
											id
											name
											startYear
											startMonth
											startDay
											endYear
											endMonth
											endDay
										}
										statusColumnV2 {
											id
											name
											category
										}
										project {
											id
											demo
											billable
											estimationUnit
											minutesPerEstimationPoint
											projectStartYear
											projectStartMonth
											projectStartDay
											projectEndYear
											projectEndMonth
											projectEndDay
											budgetType
											projectPerson(personId: $personId) {
												role {
													id
													name
												}
											}
											program {
												name
												prefix
												budgetType
												members {
													edges {
														node {
															role
															person {
																id
															}
														}
													}
												}
											}
											name
											status
											isJiraProject
											vstsProject
											vstsAccount
											vstsTwoWaySync
											projectColor
											companyProjectId
											customProjectId
											harvestProject {
												id
											}
											unit4Project {
												id
												activitiesEnabled
											}
											sprintTimeBox
										}
										taskLabels {
											id
											label {
												id
												name
												color
											}
										}
										timeRegistrations(first: 10000)
											@connection(key: "Task_timeRegistrations", filters: [])
											@include(if: $fetchLazyData) {
											edges {
												node {
													id
													minutesRegistered
													billableMinutesRegistered
													price
													invoiced
													year
													month
													day
													retainerConflictHandled
													person {
														id
														role {
															id
														}
													}
												}
											}
										}
										taskDismissedNotifications {
											taskOverrunPredictionDismissed
										}
										assignedPersons {
											id
											firstName
											lastName
											fullName
											active
											email
											profilePictureId
											profilePictureDefaultId
											permissions
										}
										thisTaskDependsOn(first: 1000) {
											edges {
												node {
													id
													type
													thisDependsOnTask {
														id
														name
														deadlineYear
														deadlineMonth
														deadlineDay
														startYear
														startMonth
														startDay
													}
												}
											}
										}
										customFieldValues @include(if: $fetchCustomFields) {
											edges {
												node {
													key
													displayName
													value
													readOnly
												}
											}
										}
									}
								}
							}
							allocations(first: 100000) {
								edges {
									node {
										id
										monday
										tuesday
										wednesday
										thursday
										friday
										saturday
										sunday
										startYear
										startMonth
										startDay
										endYear
										endMonth
										endDay
										person {
											id
											role {
												id
											}
											holidayCalendar {
												id
											}
										}
										project {
											id
										}
										isSoft
									}
								}
							}
							expenseFinancialNumbers: financialNumbers(
								searchQuery: {filters: [{field: ROLE, operator: IS, value: "Um9sZTotMQ=="}]}
								convertToProjectCurrency: true
							) {
								allTotalTimeAndExpensesAtCompletion
							}
							totalFinancialNumbers: financialNumbers(convertToProjectCurrency: true) {
								totalCostAtCompletion
								totalRevenueRecognition
								totalRevenueProfitAtCompletion
								totalRevenueMarginAtCompletion
								forecastMinutes
								registeredMinutes
								billableActualMinutes
								nonBillableActualMinutes
								billableTotalTimeAndExpensesAtCompletion
								baselineMinutes
							}
						}
						psProject(companyProjectId: $projectId) {
							...ProjectHeader_psProject
						}
					}
				`,
			},
			graphql`
				query ProjectScopingPageRefetchQuery(
					$projectId: String
					$noStaleTasks: Boolean
					$fetchLazyData: Boolean!
					$fetchCustomFields: Boolean!
					$personId: ID!
				) {
					viewer {
						component(name: "new_scoping_refetch")
						...ProjectScopingPage_viewer
							@arguments(
								projectId: $projectId
								noStaleTasks: $noStaleTasks
								fetchLazyData: $fetchLazyData
								fetchCustomFields: $fetchCustomFields
								personId: $personId
							)
					}
				}
			`
		)
	)
);
