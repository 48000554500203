import React from 'react';

export const Bullseye = ({color = 'white', width = 24, height = 24}) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="11.6926" cy="12.3157" r="3.78719" stroke={color} strokeWidth="1.4" />
			<circle cx="11.6929" cy="12.3157" r="7.48408" stroke={color} strokeWidth="1.4" />
			<path d="M11.7319 12L20.1642 3.56778" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
			<path
				d="M19.3808 3.36813L19.3151 2.84234L19.9413 2.21605L19.9905 2.75841L19.3808 3.36813Z"
				stroke={color}
				strokeWidth="1.4"
				strokeLinecap="round"
			/>
			<path
				d="M20.3659 4.35827L20.8978 4.425L21.5259 3.79685L20.9753 3.74614L20.3659 4.35827Z"
				stroke={color}
				strokeWidth="1.4"
				strokeLinecap="round"
			/>
		</svg>
	);
};
