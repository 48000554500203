import React, {Component} from 'react';
import StartRecipes from '../../../../mutations/start_recipes_mutation';
import StopRecipes from '../../../../mutations/stop_recipes_mutation';
import Util from '../../../shared/util/util';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import {FlexColumn, Label, RagStatusMessage} from '@forecast-it/design-system';
import {injectIntl} from 'react-intl';

class ConnectionIframe extends Component {
	constructor(props) {
		super(props);

		this.state = {
			connected: null,
		};
	}

	componentDidMount() {
		// The Workato embedded iframe sends messages when a connecion is updated.
		// We listen to these to stop/start recipes.
		window.addEventListener('message', this.connectionUpdate.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.connectionUpdate.bind(this));
	}

	connectionUpdate(e) {
		let data;

		// Sometimes a string is returned...
		if (typeof e.data === 'string' || e.data instanceof String) {
			// Just in case the string can not be parsed
			try {
				data = JSON.parse(e.data);
			} catch (e) {
				// Do nothing
			}
		} else {
			data = e.data;
		}

		if (data?.type === 'connectionStatusChange') {
			if (this.state.connected != null && data.payload?.connected) {
				const onSuccess = data => {
					if (data.startRecipes.outcome === 'Success') {
						createToast({duration: 10000, message: 'Connection enabled'});
					}
				};

				Util.CommitMutation(StartRecipes, {integration: this.props.integration}, onSuccess);
			} else if (!data.payload?.connected) {
				const onSuccess = data => {
					if (data.stopRecipes.outcome === 'Success') {
						createToast({duration: 10000, message: 'Connection disabled'});
					}
				};

				Util.CommitMutation(StopRecipes, {integration: this.props.integration}, onSuccess);
			}

			if (data.payload) {
				this.setState({connected: data.payload.connected});
			}
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {connected} = this.state;

		return (
			<>
				<div className={'status'}>
					<FlexColumn>
						<Label>Status</Label>
						<RagStatusMessage
							status={
								connected === null
									? RagStatusMessage.STATUS.NEUTRAL
									: connected
									? RagStatusMessage.STATUS.GREEN
									: RagStatusMessage.STATUS.RED
							}
						>
							<RagStatusMessage.Message>
								{formatMessage({
									id:
										connected === null
											? 'common.loading'
											: connected
											? 'common.connected'
											: 'common.not_connected',
								})}
							</RagStatusMessage.Message>
						</RagStatusMessage>
					</FlexColumn>
				</div>
				<iframe
					title="Workato connection iframes"
					style={{
						width: '1000px',
						height: '770px',
						padding: '20px 20px 20px 0px',
						border: '0',
						display: 'block',
					}}
					src={`https://app.eu.workato.com/direct_link/embedded/connections/${this.props.connectionId}?workato_dl_token=${this.props.viewer.company.workatoIframeToken}`}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
					allowFullScreen
				/>
			</>
		);
	}
}

export default injectIntl(ConnectionIframe);
