import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedMessage, injectIntl} from 'react-intl';
import gdriveLogo from '../../../../images/integrations/gdrive-logo.png';
import zapierLogo from '../../../../images/integrations/zapier-logo.png';
import asanaLogo from '../../../../images/integrations/asana-logo.png';
import jiraLogo from '../../../../images/integrations/jira-software.png';
import trelloLogo from '../../../../images/integrations/trello-logo.png';
import harvestLogo from '../../../../images/integrations/harvest-logo.png';
import sageIntacctLogo from '../../../../images/integrations/sage-logo.png';
import hiBobLogo from '../../../../images/integrations/hibob/HiBob-logo.png';
import unit4Logo from '../../../../images/integrations/unit4-logo.svg';
import oktaLogo from '../../../../images/integrations/okta-logo-square.png';
import oneloginLogo from '../../../../images/integrations/onelogin-logo.png';
import googleSSOLogo from '../../../../images/integrations/google-logo.svg';
import microsoftEntraLogo from '../../../../images/integrations/microsoft-entra-id-logo.svg';
import gitlabLogo from '../../../../images/integrations/gitlab.png';
import goSimploLogo from '../../../../images/integrations/third-party/go-simplo.png';
import * as tracking from '../../../../tracking';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import {HIDDEN_FEATURES, MODULE_TYPES, TIERS} from '../../../../constants';
import AppCatalogApplication from './app_catalog_application';
import {
	AdoIcon,
	BambooHRIcon,
	EconomicIcon,
	GithubIcon,
	HubspotIcon,
	MSTeamsIcon,
	PipedriveIcon,
	QuickbooksIcon,
	SalesforceIcon,
	SIZE,
	SlackIcon,
	XeroIcon,
} from 'web-components';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import Util from '../../../shared/util/util';
import {trackPage, unregisterPageInfo} from '../../../../tracking/amplitude/TrackingV2';
import DirectApi from '../../../../directApi';
import ToggleIsSSOMandatory from './ToggleIsSSOMandatory';
import {hasModule} from '../../../shared/util/ModuleUtil';

class settingsAppCatalog extends React.Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('Settings Integrations');
	}

	componentDidMount() {
		document.title = 'App catalog - Account - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	getTopHeader() {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'settings_account.section-integrations'})} />
			</TopHeaderBarWrapper>
		);
	}

	render() {
		const isUS = DirectApi.getDatacenter() === 'US';
		return (
			<div className="section-content settings-app settings-app-catalog" data-cy={'settings-integrations-page'}>
				{this.props.children}
				{this.getTopHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner unavailable">
							<fieldset className="general">
								<h3>
									<FormattedMessage id="settings.app_catalog.file-sharing-productivity-content" />
								</h3>
								<ul className="integrations">
									<AppCatalogApplication
										path={window.location.pathname + '/zapier'}
										alt="zapier logo"
										logoSrc={zapierLogo}
										title="Zapier"
										descriptionId="settings.app_catalog.description-zapier"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.LITE}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/slack'}
										alt="slack logo"
										webCompLogo={<SlackIcon size={SIZE.EXTRA_BIG} />}
										title="Slack"
										descriptionId="settings.app_catalog.description-slack"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.LITE}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/teams'}
										alt="teams logo"
										webCompLogo={<MSTeamsIcon size={SIZE.EXTRA_BIG} />}
										title="Teams"
										descriptionId="settings.app_catalog.description_teams"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.LITE}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/calendar'}
										title="Calendar"
										descriptionId="settings.app_catalog.description-google-calendar"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.LITE}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/google-drive'}
										alt="gdrive logo"
										logoSrc={gdriveLogo}
										title="Google drive"
										descriptionId="settings.app_catalog.description-google-drive"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.LITE}
									/>
								</ul>
							</fieldset>
							<fieldset className="general">
								<h3>
									<FormattedMessage id="settings.app_catalog.project-management-devops-code" />
								</h3>
								<ul className="integrations">
									<AppCatalogApplication
										path={window.location.pathname + '/trello'}
										alt="trello logo"
										logoSrc={trelloLogo}
										title="Trello"
										descriptionId="settings.app_catalog.description-trello"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.LITE}
									/>
									{!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && (
										<>
											<AppCatalogApplication
												path={window.location.pathname + '/jira'}
												alt="jira logo"
												logoSrc={jiraLogo}
												title="JIRA Cloud"
												descriptionId="settings.app_catalog.description-jira"
												available={!isUS}
												requiredTier={TIERS.ENTERPRISE}
												requiredModuleType={MODULE_TYPES.JIRA}
											/>
											<AppCatalogApplication
												path={window.location.pathname + '/jira-server'}
												alt="jira logo"
												logoSrc={jiraLogo}
												title="JIRA Server / Data Center"
												descriptionId="settings.app_catalog.description-jira-server"
												available={!isUS}
												requiredTier={TIERS.ENTERPRISE}
												requiredModuleType={MODULE_TYPES.JIRA}
											/>
											<AppCatalogApplication
												path={window.location.pathname + '/gitlab'}
												alt="gitlab logo"
												logoSrc={gitlabLogo}
												title="GitLab"
												descriptionId="settings.app_catalog.description-gitlab"
												available={!isUS}
												tier={this.props.viewer.company.tier}
												requiredTier={TIERS.LITE}
											/>
											<AppCatalogApplication
												path={window.location.pathname + '/github'}
												alt="github logo"
												webCompLogo={<GithubIcon size={SIZE.EXTRA_BIG} />}
												title="GitHub"
												descriptionId="settings.app_catalog.description-github"
												available={!isUS}
												tier={this.props.viewer.company.tier}
												requiredTier={TIERS.LITE}
											/>
											<AppCatalogApplication
												path={window.location.pathname + '/azure-devops'}
												alt="vsts logo"
												webCompLogo={<AdoIcon size={SIZE.EXTRA_BIG} />}
												title="Azure DevOps"
												descriptionId="settings.app_catalog.description-ado-short"
												available={!isUS}
												tier={this.props.viewer.company.tier}
												requiredTier={TIERS.ENTERPRISE}
												requiredModuleType={MODULE_TYPES.AZURE_DEVOPS}
											/>
										</>
									)}

									{hasModule(MODULE_TYPES.ASANA) && (
										<AppCatalogApplication
											path={window.location.pathname + '/asana'}
											alt="asana logo"
											logoSrc={asanaLogo}
											title="Asana"
											descriptionId="settings.app_catalog.description-asana"
											available={!isUS}
											tier={this.props.viewer.company.tier}
											requiredTier={TIERS.LITE}
										/>
									)}
								</ul>
							</fieldset>
							{!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) &&
								!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) &&
								hasFeatureFlag('show_harvest_integration') && (
									<fieldset className="general">
										<h3>
											<FormattedMessage id="settings.app_catalog.time_tracking" />
										</h3>
										<ul className="integrations">
											{!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) &&
											hasFeatureFlag('show_harvest_integration') ? (
												<AppCatalogApplication
													path={window.location.pathname + '/harvest'}
													alt="harvest logo"
													logoSrc={harvestLogo}
													title="Harvest"
													descriptionId="settings.app_catalog.description-harvest"
													available={!isUS}
													tier={this.props.viewer.company.tier}
													requiredTier={TIERS.LITE}
												/>
											) : null}
										</ul>
									</fieldset>
								)}
							{!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && (
								<fieldset className="general">
									<h3>
										<FormattedMessage id="settings.app_catalog.accounting-billing" />
									</h3>
									<ul className="integrations">
										{!isUS ? (
											<AppCatalogApplication
												path={window.location.pathname + '/e-conomic'}
												alt="e-conomic logo"
												webCompLogo={<EconomicIcon size={SIZE.EXTRA_BIG} />}
												title="e-conomic"
												descriptionId="settings.app_catalog.description-e-conomic"
												available={true}
												tier={this.props.viewer.company.tier}
												requiredTier={TIERS.PRO}
												requiredModuleType={MODULE_TYPES.FINANCE}
											/>
										) : null}
										<AppCatalogApplication
											path={window.location.pathname + '/xero'}
											alt="xero logo"
											webCompLogo={<XeroIcon size={SIZE.EXTRA_BIG} />}
											title="Xero"
											descriptionId="settings.app_catalog.description-xero"
											available={!isUS}
											tier={this.props.viewer.company.tier}
											requiredTier={TIERS.PRO}
											requiredModuleType={MODULE_TYPES.FINANCE}
										/>
										<AppCatalogApplication
											path={window.location.pathname + '/quickbooks'}
											alt="quickbooks logo"
											webCompLogo={<QuickbooksIcon size={SIZE.EXTRA_BIG} />}
											title="QuickBooks Online"
											descriptionId="settings.app_catalog.description-quickbooks"
											available={!isUS}
											tier={this.props.viewer.company.tier}
											requiredTier={TIERS.PRO}
											requiredModuleType={MODULE_TYPES.FINANCE}
										/>
										{hasFeatureFlag('sage_intacct_integration') && (
											<AppCatalogApplication
												path={window.location.pathname + '/sage-intacct'}
												alt="sage intacct logo"
												logoSrc={sageIntacctLogo}
												title="Sage Intacct"
												descriptionId="settings.app_catalog.description-sage_intacct"
												available={!isUS}
												tier={this.props.viewer.company.tier}
												requiredTier={TIERS.PRO}
												requiredModuleType={MODULE_TYPES.FINANCE}
											/>
										)}
									</ul>
								</fieldset>
							)}

							<fieldset className="general">
								<h3>
									<FormattedMessage id="settings.app_catalog.sales-crm" />
								</h3>
								<ul className="integrations">
									<AppCatalogApplication
										path={window.location.pathname + '/salesforce'}
										alt="salesforce logo"
										webCompLogo={<SalesforceIcon size={SIZE.EXTRA_BIG} />}
										title="Salesforce"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										descriptionId="settings.app_catalog.description-salesforce"
										requiredModuleType={MODULE_TYPES.SALESFORCE}
										requiredTier={TIERS.ENTERPRISE}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/hubspot'}
										alt={'hubspot logo'}
										webCompLogo={<HubspotIcon size={SIZE.EXTRA_BIG} />}
										title={'Hubspot'}
										available={!isUS}
										tier={this.props.viewer.company.tier}
										descriptionId="settings.app_catalog.description-hubspot"
										requiredModuleType={MODULE_TYPES.HUBSPOT}
										requiredTier={TIERS.ENTERPRISE}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/pipedrive'}
										webCompLogo={<PipedriveIcon size={SIZE.EXTRA_BIG} />}
										title={'Pipedrive'}
										available={!isUS}
										tier={this.props.viewer.company.tier}
										descriptionId="settings.app_catalog.description-pipedrive"
										requiredModuleType={MODULE_TYPES.PIPEDRIVE}
										requiredTier={TIERS.ENTERPRISE}
									/>
								</ul>
							</fieldset>
							{!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) ? (
								<fieldset className="general">
									<h3>
										<FormattedMessage id="settings.app_catalog.erp" />
									</h3>
									<ul className="integrations">
										<AppCatalogApplication
											path={window.location.pathname + '/unit4'}
											alt="unit4 logo"
											logoSrc={unit4Logo}
											title="Unit4"
											available={!isUS}
											descriptionId="settings.app_catalog.description_unit4"
											requiredTier={TIERS.ENTERPRISE}
											requiredModuleType={MODULE_TYPES.UNIT4}
										/>
									</ul>
								</fieldset>
							) : null}
							<fieldset className="general">
								<h3>
									<FormattedMessage id="settings.app_catalog.hr" />
								</h3>
								<ul className="integrations">
									<AppCatalogApplication
										path={window.location.pathname + '/bamboohr'}
										webCompLogo={<BambooHRIcon size={SIZE.EXTRA_BIG} />}
										title="BambooHR"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.ENTERPRISE}
										descriptionId="settings.app_catalog.description_bamboohr"
										requiredModuleType={MODULE_TYPES.BAMBOOHR}
									/>
									{hasFeatureFlag('hibob_integration') && (
										<AppCatalogApplication
											path={window.location.pathname + '/hibob'}
											logoSrc={hiBobLogo}
											title="HiBob"
											available={!isUS}
											tier={this.props.viewer.company.tier}
											requiredTier={TIERS.ENTERPRISE}
											descriptionId="settings.app_catalog.description_hibob"
											requiredModuleType={MODULE_TYPES.HIBOB}
										/>
									)}
								</ul>
							</fieldset>
							<fieldset className="general">
								<h3>
									<FormattedMessage id="settings.app_catalog.security-single-sign-on" />
								</h3>
								<ul className="integrations">
									<AppCatalogApplication
										path={window.location.pathname + '/okta'}
										alt="okta logo"
										logoSrc={oktaLogo}
										title="Okta"
										descriptionId="settings.app_catalog.description-okta"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.ENTERPRISE}
										requiredModuleType={MODULE_TYPES.OKTA}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/onelogin'}
										alt="onelogin logo"
										logoSrc={oneloginLogo}
										title="Onelogin"
										descriptionId="settings.app_catalog.description-onelogin"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.ENTERPRISE}
										requiredModuleType={MODULE_TYPES.ONELOGIN}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/microsoft-entra-id'}
										alt="Microsoft Entra logo"
										logoSrc={microsoftEntraLogo}
										title="Microsoft Entra ID"
										descriptionId="settings.app_catalog.description-microsoft-entra-id"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.ENTERPRISE}
										requiredModuleType={MODULE_TYPES.AZURE_ACTIVE_DIRECTORY}
									/>
									<AppCatalogApplication
										path={window.location.pathname + '/google-sso'}
										alt="Google logoo"
										logoSrc={googleSSOLogo}
										title="Google SSO"
										descriptionId="settings.app_catalog.google_sso_description_short"
										available={!isUS}
										tier={this.props.viewer.company.tier}
										requiredTier={TIERS.LITE}
									/>
								</ul>
								{hasFeatureFlag('is_sso_mandatory') && (
									<ToggleIsSSOMandatory company={this.props.viewer.company} />
								)}
							</fieldset>
							<fieldset className="general">
								<h3>
									<FormattedMessage id="settings.app_catalog.bi_and_reporting" />
								</h3>
								<ul className="integrations">
									<AppCatalogApplication
										path={`${window.location.pathname}/gosimplo`}
										alt="Go simplo logo"
										logoSrc={goSimploLogo}
										title="GoSimplo"
										descriptionId="settings.app_catalog.description_go_simplo"
										available={!isUS}
										requiredModuleType={MODULE_TYPES.GO_SIMPLO}
									/>
								</ul>
							</fieldset>
							<div className="new-integration">
								<h2>
									<FormattedMessage id="settings.app_catalog.dont-see-integration" />
									<br />
									{/*<a href="https://github.com/Forecast-it/API">Build your own</a>&nbsp;or&nbsp;*/}
									<a href="mailto:success@forecast.it">
										<FormattedMessage id="settings.app_catalog.get-in-touch" />
									</a>
								</h2>
							</div>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const settingsAppCatalogQuery = graphql`
	query settingsAppCatalog_Query {
		viewer {
			actualPersonId
			component(name: "settings_catalog")
			...settingsAppCatalog_viewer
		}
	}
`;

export {settingsAppCatalogQuery};

export default injectIntl(
	createFragmentContainer(settingsAppCatalog, {
		viewer: graphql`
			fragment settingsAppCatalog_viewer on Viewer {
				company {
					...ToggleIsSSOMandatory_company
					tier
				}
			}
		`,
	})
);
