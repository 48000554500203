import React, {Component} from 'react';
import Util from '../../util/util';
import {getCantDeleteTaskMessage} from '../../util/TaskUtil';
import Moment from 'moment';
import DuplicateTaskMutation from '../../../../mutations/duplicate_task_mutation';
import UpdateTaskMutationModern from '../../../../mutations/update_task_mutation.modern';
import DeleteTaskMutation from '../../../../mutations/delete_task_mutation';
import UpdateViewerMutation from '../../../../mutations/update_viewer_mutation.modern';
import {createToast} from '../toasts/toast';
import {MODAL_TYPE, showModal} from '../modals/generic_modal_conductor';
import {injectIntl} from 'react-intl';
import ReactDOM from 'react-dom';
import {hasFeatureFlag} from '../../util/FeatureUtil';
import {hasPermission, isClientUser} from '../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {HIDDEN_FEATURES} from '../../../../constants';
import ProjectUtil from '../../util/project_util';
import {canLoggedInPersonManageProgram, hasTopDownProgramBudgetFeature} from '../../util/ProgramFinancialLogic';
import {ACTION} from '../modals/program-management/ProgramBudgetErrorMessage';
import {toProperCase, trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {isTaskTimeRegistrationAllowed} from '../../util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import {toEntityId} from '../modals/time-registration/time-registration-modal/util/TimeRegistrationModalUtil';
import {TRACKING_OBJECTS} from '../../../../tracking/amplitude/constants/TrackingObjects';
import {createFragmentContainer, graphql} from 'react-relay';
import {isJiraToForecastOneWaySync} from '../../util/JiraUtil';

class GenericTaskContextMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focusAtIndex: 0,
			options: [],
		};
		this.getContextMenuOptions = this.getContextMenuOptions.bind(this);
		this.startTimer = this.startTimer.bind(this);
		this.closeContextMenu = this.closeContextMenu.bind(this);
	}

	componentDidMount() {
		trackEvent(TRACKING_OBJECTS.CONTEXT_MENU, 'Expanded');

		if (this.option_0) {
			this.option_0.focus();
		}
		document.addEventListener('wheel', this.closeContextMenu);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.focusAtIndex !== this.state.focusAtIndex && this['option_' + this.state.focusAtIndex]) {
			this['option_' + this.state.focusAtIndex].focus();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('wheel', this.closeContextMenu);
	}

	closeContextMenu() {
		this.props.closeContextMenu();
	}

	// BEGIN Menu options functionality //
	duplicateTask(task) {
		const onSuccess = response => {
			const errors = response.duplicateTask.errors;
			if (errors && errors.length === 1) {
				if (Util.checkForSageErrorAndShowModal(errors, false)) {
					return;
				}
			}
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'task_modal.has-been-duplicated'}),
			});
		};
		Util.CommitSchedulingModalUpdate(
			DuplicateTaskMutation,
			{
				taskId: task.id,
				supportTaskHierarchy: true,
				companyId: this.props.viewer.company.id,
				projectId: this.props.viewer.project ? this.props.viewer.project.id : task.project.id,
				filter: this.props.isConnectedParent ? task.statusColumnV2.projectGroupStatusColumnId : task.statusColumnV2.id,
				viewerId: this.props.viewer.id,
				viewer: this.props.viewer,
			},
			onSuccess
		);
	}

	deleteTask(task) {
		if (this.props.viewer.timerStartDate && this.props.viewer.timerTask && task.id === this.props.viewer.timerTask.id) {
			this.stopTimer(task, true);
			return;
		}
		const onSuccess = () => {
			createToast({
				duration: 4000,
				message: this.props.intl.formatMessage({id: 'task_modal.has-been-deleted'}),
				actionText: null,
				actionCallback: null,
			});
		};
		const callbackPositive = () => {
			const ids = [task.id];
			Util.CommitSchedulingModalUpdate(
				DeleteTaskMutation,
				{
					ids: ids,
					projectId: this.props.viewer.project ? this.props.viewer.project.id : task.project.id,
					filters: this.props.isConnectedParent
						? [task.statusColumnV2.projectGroupStatusColumnId]
						: [task.statusColumnV2.id],
					viewer: this.props.viewer,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.TASK_DELETION_WARNING,
			task,
			deleteCallback: callbackPositive,
		});
		this.setState({actionMenuExpanded: false});
	}

	blockTask(task) {
		if (task) {
			Util.CommitMutation(UpdateTaskMutationModern, {
				ids: [task.id],
				projectId: task.project.id,
				blocked: !task.blocked,
			});

			createToast({
				duration: 5000,
				message: !task.blocked
					? this.props.intl.formatMessage({id: 'task_modal.has-been-blocked'})
					: this.props.intl.formatMessage({id: 'task_modal.has-been-unblocked'}),
			});
		}
	}

	markAsBug(task) {
		if (task) {
			Util.CommitMutation(UpdateTaskMutationModern, {
				ids: [task.id],
				projectId: task.project.id,
				bug: !task.bug,
			});

			createToast({
				duration: 5000,
				message: !task.bug
					? this.props.intl.formatMessage({id: 'task_modal.has-been-marked-as-bug'})
					: this.props.intl.formatMessage({id: 'task_modal.has-been-unmarked-as-bug'}),
			});
		}
	}

	moveToAnotherProject(task) {
		const currentProjectId = this.props.viewer.project ? this.props.viewer.project.id : task.project.id;
		this.props.closeContextMenu();
		showModal({
			type: MODAL_TYPE.TASK_LOCATION,
			currentProjectId: currentProjectId,
			taskId: task.id,
			statusColumns: [task.statusColumnV2],
		});
	}

	openTimeRegistrationModal(task) {
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
				personId: this.props.viewer.actualPersonId,
				entityId: toEntityId({taskId: task.id}),
			});
		} else {
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				isPreviousTimerReminder: false,
				timerProject: null,
				timerTask: null,
				useTimer: false,
				personId: this.props.viewer.actualPersonId,
				taskId: task.id,
			});
		}
	}

	stopTimer(task, preventedDelete) {
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
				timerActionTaskId: task.id,
				preventedDelete: preventedDelete,
			});
		} else {
			const project = this.props.viewer.project
				? this.props.viewer.project
				: this.props.viewer.projectGroup
				? this.props.viewer.projectGroup.projects.edges.find(edge =>
						edge.node.tasks.edges.find(taskEdge => taskEdge.node.id === task.id)
				  ).node
				: this.props.task.project;
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				isPreviousTimerReminder: false,
				timerProject: project,
				timerTask: task,
				preventedDelete: preventedDelete,
				personId: this.props.viewer.actualPersonId,
			});
		}
	}

	startTimer(task) {
		if (this.props.viewer.timerStartDate) {
			if (hasFeatureFlag('new_time_registration_modal')) {
				showModal({
					type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
					timerActionTaskId: task.id,
				});
			} else {
				showModal({
					type: MODAL_TYPE.TIMER_V3,
					isPreviousTimerReminder: true,
					timerTask: this.props.viewer.timerTask ? this.props.viewer.timerTask : task,
					personId: this.props.viewer.actualPersonId,
				});
			}
		} else {
			const onSuccess = result => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'timer.started_toast'})});
			};
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					viewer: this.props.viewer,
					timerStartDate: Moment.utc().format(),
					timerEndDate: null,
					timerTaskId: task.id,
				},
				onSuccess
			);
		}
	}

	contextMenuFunction(func) {
		return () => {
			func();
			this.props.closeContextMenu();
		};
	}

	showProgramRevenueLockedMessage(project) {
		const program = project.program;
		const loggedInPersonId = this.props.viewer.actualPersonId;
		const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
			action: ACTION.MARK_TASK_BILLABLE,
			programName: program?.name,
			canManageProgram,
			programPrefix: program?.prefix,
		});
	}

	toggleBillable(task) {
		const project = this.props.viewer.project || task.project;

		const newBillableStatus = !task.billable;
		if (hasTopDownProgramBudgetFeature() && newBillableStatus && project && project.isProgramRevenueLocked) {
			trackEvent('Task', 'Set As Billable', {
				location: 'From Generic Task Context Menu',
				error: 'Program Revenue Locked',
			});
			this.showProgramRevenueLockedMessage(project);
			return;
		}
		const onSuccess = result => {
			if (result.updateTask.errors && result.updateTask.errors.length === 1) {
				Util.checkForSageErrorAndShowModal(result.updateTask.errors, false);
			} else {
				createToast({
					duration: 5000,
					message: newBillableStatus
						? this.props.intl.formatMessage({id: 'task_modal.has-been-marked-billable'})
						: this.props.intl.formatMessage({id: 'task_modal.has-been-unmarked-billable'}),
				});
			}
		};
		Util.CommitMutation(
			UpdateTaskMutationModern,
			{
				ids: [task.id],
				billable: !task.billable,
			},
			onSuccess
		);
	}

	moveToJira(taskIds, hasParent) {
		const project = this.props.viewer.project;
		if (project && taskIds && taskIds.length > 0) {
			showModal({
				type: MODAL_TYPE.CREATE_JIRA_ISSUE,
				projectId: project.id,
				taskIds: taskIds,
				jiraType: project.jiraCloudProject || project.jiraCloudEpicIds?.length ? 'cloud' : 'server',
				useTaskHierarchy: project.useTaskHierarchy,
				hasParent,
				callback: () => {
					this.props.unselectTasks();
				},
			});
			this.props.closeContextMenu();
		}
	}

	moveToAdo(taskIds) {
		const project = this.props.viewer.project;
		if (project && taskIds && taskIds.length > 0) {
			if (project.vstsProject && project.vstsAccount) {
				showModal({
					type: MODAL_TYPE.CREATE_ADO_WORKITEM,
					projectId: project.id,
					vstsAccount: project.vstsAccount,
					vstsArea: project.vstsArea,
					taskIds: taskIds,
				});
			}
			this.props.closeContextMenu();
		}
	}

	getContextMenuOptions(task) {
		const {formatMessage} = this.props.intl;
		const {viewer} = this.props;
		const menuOptions = [];
		const userCanDelete = hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE);
		const statusCategory = task.statusColumnV2?.category;
		const assignedPersonIds = task.assignedPersons ? task.assignedPersons.map(person => person.id) : [];

		//Do not show timer for clients and for users with time registration
		if (!isClientUser() && !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)) {
			const shouldShowStopTimerOption = viewer.timerStartDate && viewer.timerTask && task.id === viewer.timerTask.id;
			menuOptions.push({
				text: formatMessage({id: shouldShowStopTimerOption ? 'timer.stop' : 'timer.start'}),
				onClick: shouldShowStopTimerOption
					? this.contextMenuFunction(this.stopTimer.bind(this, task))
					: this.contextMenuFunction(this.startTimer.bind(this, task)),
			});
		}

		const project = task.project;

		const allowSageAction = task.sageIntacctId ? !task.hasChildren : true;

		//Everyone sees block/bug/duplicate options
		menuOptions.push({
			text: formatMessage({id: `card_modal.${task.blocked ? 'un' : ''}block-card`}),
			onClick: this.contextMenuFunction(this.blockTask.bind(this, task)),
		});
		menuOptions.push({
			text: formatMessage({id: `card_modal.${task.bug ? 'un' : ''}mark-as-bug`}),
			onClick: this.contextMenuFunction(this.markAsBug.bind(this, task)),
		});
		if (!task.jiraId && allowSageAction) {
			menuOptions.push({
				text: formatMessage({id: 'common.duplicate'}),
				onClick: this.contextMenuFunction(this.duplicateTask.bind(this, task)),
			});
		}

		//Only show billable option if project is billable
		if (ProjectUtil.projectTracksRevenue(project) && hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE)) {
			const disableBillableChange = task.hasInvoicedTime && task.billable;
			menuOptions.push({
				text: formatMessage({id: task.billable ? 'card_modal.unmark-billable' : 'card_modal.mark-billable'}),
				onClick: this.contextMenuFunction(this.toggleBillable.bind(this, task)),
				disabled: disableBillableChange,
				disabledDescription: formatMessage({id: 'invoicing.task_invoiced'}),
			});
		}

		const moveDisabledText = task.userCanDeleteTask
			? userCanDelete
				? formatMessage({id: 'scheduling.task_move_locked'})
				: formatMessage({id: 'common.no_move_rights'})
			: formatMessage({id: 'common.action_not_allowed'}, {reason: task.userCantDeleteTaskReason});
		//Do not allow changing project of the task if task is in a project that is connected to harvest or task is linked to jira
		if (
			!project.harvestProject &&
			!task.jiraId &&
			!isClientUser() &&
			!task.hasChildren &&
			!task.parentTaskId &&
			!task.sageIntacctId
		) {
			menuOptions.push({
				text: formatMessage({id: 'task_location_modal.title'}),
				onClick: this.contextMenuFunction(this.moveToAnotherProject.bind(this, task)),
				disabled: !task.userCanDeleteTask || !task.userCanDeleteTask,
				disabledDescription: moveDisabledText,
				cy: 'move-to-another-project-option',
			});
		}

		if (
			!isClientUser() &&
			isTaskTimeRegistrationAllowed(statusCategory, this.props.viewer.actualPersonId, assignedPersonIds, task.project) &&
			!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)
		) {
			menuOptions.push({
				text: formatMessage({id: 'card_modal.add-time'}),
				onClick: this.contextMenuFunction(this.openTimeRegistrationModal.bind(this, task)),
				cy: 'action-add-time',
			});
		}

		const startingDate = Util.CreateNonUtcMomentDate(task.startYear, task.startMonth, task.startDay);
		const today = Moment();
		const meetingOptions = {
			title: `T${task.companyTaskId} - ${task.name} - meeting`,
			attendees: task.assignedPersons.filter(p => p.email).map(per => per.email),
			startTime:
				startingDate && today.isBefore(startingDate)
					? Moment(startingDate.toDate()).set('h', 8).startOf('h')
					: today.startOf('h').add(1, 'h'),
			endTime:
				startingDate && today.isBefore(startingDate)
					? Moment(startingDate.toDate()).set('h', 9).startOf('h')
					: today.startOf('h').add(2, 'h'),
			organizerEmail: viewer.email,
			organizerName: ((viewer.firstName ? viewer.firstName : '') + ' ' + (viewer.lastName ? viewer.lastName : '')).trim(),
			description: task.description ? Util.ConvertDraftJsToPlainText(task.description, '%0A') : '',
		};

		menuOptions.push({
			text: formatMessage({id: 'meeting_creator.create_google_meeting'}),
			onClick: this.contextMenuFunction(() => Util.googleCalendarExport(meetingOptions)),
		});

		menuOptions.push({
			text: formatMessage({id: 'meeting_creator.create_client_meeting'}),
			onClick: this.contextMenuFunction(() => Util.iCalExport(meetingOptions)),
		});

		if (!isJiraToForecastOneWaySync(viewer.company) && project.isJiraProject && !task.jiraId) {
			menuOptions.push({
				text: formatMessage({id: 'common.move_to_jira'}),
				onClick: this.contextMenuFunction(this.moveToJira.bind(this, [task.id], task.parentTaskId)),
			});
		}
		if (project.vstsProject && project.vstsTwoWaySync && !task.vstsId) {
			menuOptions.push({
				text: formatMessage({id: 'common.move_to_ado'}),
				onClick: this.contextMenuFunction(this.moveToAdo.bind(this, [task.id])),
			});
		}

		if (allowSageAction) {
			menuOptions.push({
				text: formatMessage({id: 'common.delete'}),
				onClick: this.contextMenuFunction(this.deleteTask.bind(this, task)),
				disabled: !task.userCanDeleteTask,
				disabledDescription: getCantDeleteTaskMessage(this.props.intl, task.userCantDeleteTaskReason),
				cy: 'delete-task',
			});
		}

		return menuOptions;
	}

	handleKeyDown(option, e) {
		if (e.keyCode === 27) {
			//escape
			if (this.props.closeContextMenu) {
				this.props.closeContextMenu();
			}
		} else if (e.keyCode === 32 || e.keyCode === 13) {
			//enter or space
			if (option.onClick) {
				const trackingName = option.trackingName ?? option.text;
				trackEvent(`${TRACKING_OBJECTS.CONTEXT_MENU} ${toProperCase(trackingName)}`, 'Selected With Keyboard');
				option.onClick();
			}
		} else if (e.keyCode === 38) {
			//arrow up
			if (this.state.focusAtIndex !== 0) {
				this.setState({focusAtIndex: this.state.focusAtIndex - 1});
			}
		} else if (e.keyCode === 40) {
			//arrow down
			if (this.state.focusAtIndex !== this.options.length - 1) {
				this.setState({focusAtIndex: this.state.focusAtIndex + 1});
			}
		}
	}

	onOptionClicked(option) {
		const trackingName = option.trackingName ?? option.text;
		trackEvent(`${TRACKING_OBJECTS.CONTEXT_MENU} - ${toProperCase(trackingName)}`, 'Clicked');
		option.onClick(this);
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (!(newTarget && this.list_container && this.list_container.contains(newTarget))) {
			this.props.closeContextMenu();
		}
	}

	render() {
		this.options = this.props.customOptions ? this.props.customOptions : this.getContextMenuOptions(this.props.task);
		const w = Math.max(document.documentElement.clientWidth, window.innerWidth);
		const openLeft = this.props.contextMenuOpenLeft || Math.abs(w - this.props.contextMenuPosition.x) <= 190;
		return ReactDOM.createPortal(
			<ul
				ref={ul => (this.list_container = ul)}
				onBlur={this.handleBlur.bind(this)}
				className="task-context-menu"
				data-cy={this.props.cy}
				style={{
					top: this.props.contextMenuPosition.y,
					left: this.props.contextMenuPosition.x - (openLeft ? 195 : 0),
				}}
				role="menu"
			>
				{this.options.map((option, index) =>
					option.icon === 'download' || option.icon === 'open' ? (
						<li data-cy={option.cy} className="context-menu-option download-option" key={index} role="menuitem">
							<a
								className="file-download"
								//for some reason when '?123 is added after file id it downloads file with correct filename otherwise filename is ignored'
								href={option.link ? option.link : option.fileSrc}
								target={option.link ? '_blank' : ''}
								ref={el => {
									this['option_' + index] = el;
								}}
								onKeyDown={this.handleKeyDown.bind(this, option)}
								onClick={option.onClick && !option.disabled ? option.onClick.bind(this) : null}
							>
								<div
									id="context-menu-option"
									className={'option-icon ' + option.icon + (option.disabled ? ' disabled' : '')}
								/>
								<span id="context-menu-option">{option.text}</span>
							</a>
						</li>
					) : (
						<li
							data-cy={option.cy}
							title={option.disabled ? option.disabledDescription : option.description}
							className={'context-menu-option' + (option.disabled ? ' disabled' : '')}
							key={index}
							id="context-menu-option"
							onClick={
								option.onClick && !option.disabled && !option.locked
									? this.onOptionClicked.bind(this, option)
									: null
							}
							tabIndex={0}
							ref={el => {
								this['option_' + index] = el;
							}}
							onKeyDown={this.handleKeyDown.bind(this, option)}
							role="menuitem"
						>
							<div id="context-menu-option" className={'option-icon ' + option.icon} />
							<span id="context-menu-option">{option.text}</span>
						</li>
					)
				)}
			</ul>,
			document.querySelector('#root-portal-container')
		);
	}
}

export default injectIntl(
	createFragmentContainer(GenericTaskContextMenu, {
		company: graphql`
			fragment genericTaskContextMenu_company on Company {
				id
				jiraCloudEnabled
				jiraServerEnabled
				integrations {
					jiraCloud {
						syncSettings {
							isJiraToForecastOneWaySync
						}
					}
					jiraServer {
						syncSettings {
							isJiraToForecastOneWaySync
						}
					}
				}
			}
		`,
	})
);
