import moment from 'moment';
import Util from '../../util/util';
import _ from 'lodash';
import {
	BUDGET_TYPE,
	EXPENSE_BILLING_OPTIONS,
	FILTER_FIELD_VALUES,
	FILTER_GROUP,
	FILTER_TYPE,
	GLOBAL_FILTER_FIELD,
	GroupProgressCategories,
	PersonAllocations,
	PROJECT_STATUS,
	ProjectAllocations,
	ProjectWinChance,
	RECENT_ACTIVITY,
	STATUS_PROJECT_COLOR,
	WorkflowCategories,
} from '../../../../constants';
import {personIsClientUser, personIsVirtualUser} from '../../util/PermissionsUtil';
import {isTaskOverrun, showOverrunPredictionWarning} from '../../util/PredictionUtil';
import {getSkillNameAndLevel} from '../../../../containers/settings/skills/SkillsUtil';
import ProgramUtil from '../../util/ProgramUtil';
import {hasFeatureFlag} from '../../util/FeatureUtil';
import {FILTER_SECTIONS} from './dropdown_section';

export const searchFilter = (entity, searchFilterValue) => {
	if (entity.name) {
		// A name is always required to allow search
		if (entity.companyTaskId) {
			let taskValue = 'T' + entity.companyTaskId + entity.name;
			if (entity.project.companyProjectId) {
				taskValue = taskValue + 'P' + entity.project.companyProjectId;
			}
			const searchValue = searchFilterValue.trim();
			return Util.normalizedIncludes(taskValue, searchValue);
		}
	}
	return true;
};

// FILTER FUNCTIONS SECTION
// To add a new filter function, add it below then map it to the corresponding key in getFilterFuncForKey

const roleFilterValue = (element, filterValues) => {
	if (!element.role) return filterValues.includes(null);
	return filterValues.includes(element.role.id);
};

const blockedBugFilterValue = (task, filterValues) => {
	if (filterValues.includes('error') || filterValues.includes('warning')) {
		const warnings = Util.GetTaskWarnings(task);
		if (filterValues.includes('error')) {
			if (warnings.some(w => w.color === 'red')) {
				return true;
			}
		}

		if (filterValues.includes('warning')) {
			if (warnings.some(w => w.color === 'yellow')) {
				return true;
			}
		}
	}
	return (
		(filterValues.includes('bug') && task.bug) ||
		(filterValues.includes('blocked') && task.blocked) ||
		(filterValues.includes('dependency') && task.hasDependency) ||
		(filterValues.includes('high-priority') && task.highPriority) ||
		(filterValues.includes('non-billable') && !task.billable) ||
		(filterValues.includes('starred') && task.favoured)
	);
};

const dependencyFilterValue = (task, filterValues) => {
	return (
		(filterValues.includes('has_dependencies') && task.hasDependency) ||
		(filterValues.includes('no_dependencies') && !task.hasDependency)
	);
};

const approuvedFilterValue = (task, filterValues) => {
	return (filterValues.includes('approved') && task.approved) || (filterValues.includes('not-approved') && !task.approved);
};

const predictedOverrunFilterValue = (task, filterValues) => {
	return (
		(filterValues.includes('predicted-overrun') && showOverrunPredictionWarning(task)) ||
		(filterValues.includes('currently-overrun') && isTaskOverrun(task))
	);
};

const recentlyUpdatedFilterValue = (task, filterValues) => {
	if (task.latestUiUpdateAt === null || task.latestUiUpdateAt === undefined) {
		return false;
	}
	const latestUpdate = moment(task.latestUiUpdateAt);
	if (filterValues.includes('month')) {
		const monthAgo = moment().subtract(1, 'month').startOf('day');
		return latestUpdate.startOf('day').isSameOrAfter(monthAgo);
	} else if (filterValues.includes('week')) {
		const weekAgo = moment().subtract(1, 'week').startOf('day');
		return latestUpdate.startOf('day').isSameOrAfter(weekAgo);
	} else if (filterValues.includes('today')) {
		const today = moment().startOf('day');
		return latestUpdate.startOf('day').isSameOrAfter(today);
	}
	return false;
};

export const labelFilterValue = (element, filterValues) => {
	const projectLabels =
		element.projectLabels?.edges ||
		element.projects?.reduce((result, project) => result.concat(project.projectLabels?.edges || []), []);
	const labelIds =
		element.taskLabels && element.taskLabels.length !== 0
			? element.taskLabels
					.filter(taskLabel => taskLabel !== null)
					.map(taskLabel => {
						return taskLabel.label.id;
					})
			: projectLabels && projectLabels.length !== 0
			? projectLabels
					.filter(pLabel => pLabel.node.label !== undefined && pLabel.node.label !== null)
					.map(pLabel => {
						return pLabel.node.label.id;
					})
			: element.personLabels && element.personLabels.edges && element.personLabels.edges.length !== 0
			? element.personLabels.edges
					.filter(personLabel => personLabel !== null)
					.map(personLabel => {
						return personLabel.label.id;
					})
			: [];

	if (filterValues.includes(null) && labelIds.length === 0) {
		return true;
	}
	for (const labelId of labelIds) {
		if (filterValues.includes(labelId)) return true;
	}
	return false;
};

function getPersonSkills(element) {
	return element.personSkills && element.personSkills.length !== 0
		? element.personSkills.filter(personSkill => personSkill !== null).map(personSkill => personSkill.skill)
		: [];
}

function skillLevelMatchesFilterValue(personSkillLevel, filterValue) {
	// Old skill filter id's are just plain string skill id's. New ones are objects including skillLevelId.
	const filteredSkillId = filterValue.skillId || filterValue;
	const filteredSkillLevelId = filterValue.skillLevelId;
	return (
		personSkillLevel.skillId === filteredSkillId &&
		(!filteredSkillLevelId ||
			personSkillLevel.skillLevelId === null ||
			personSkillLevel.skillLevelId === filteredSkillLevelId)
	);
}

const skillFilterValue = (element, filterValues) => {
	const personSkillLevels = getPersonSkills(element);

	if (filterValues.includes(null) && personSkillLevels.length === 0) {
		return true;
	}

	return filterValues.some(filterValue =>
		personSkillLevels.some(personSkill => skillLevelMatchesFilterValue(personSkill, filterValue))
	);
};

const skillAndFilterValue = (element, filterValues) => {
	const personSkillLevels = getPersonSkills(element);

	if (filterValues.includes(null) && personSkillLevels.length === 0) {
		return true;
	}

	return Object.values(_.groupBy(filterValues, fv => fv.skillId || fv)).every(filterValuesForSkill =>
		filterValuesForSkill.some(fv => personSkillLevels.some(ps => skillLevelMatchesFilterValue(ps, fv)))
	);
};

const phaseFilterValue = (task, filterValues) => {
	return filterValues.includes(task.phase ? task.phase.id : null);
};

const sprintFilterValue = (task, filterValues, options) => {
	if (task.sprint) {
		if (filterValues.includes(options && options.isProjectGroup ? task.sprint.projectGroupSprintId : task.sprint.id))
			return true;
	} else {
		if (filterValues.includes(null)) return true;
	}
	return false;
};

const teammemberFilterValue = (element, filterValues) => {
	const assignedPersons = element.assignedPersons
		? element.assignedPersons
		: element.projectPersons && element.projectPersons.edges
		? element.projectPersons.edges.map(pp => pp.node.person)
		: element.person
		? [{id: element.person.id}]
		: [{id: element.id}]; // person filter -> team member
	if (assignedPersons.length === 0 && filterValues.includes(null)) return true;
	for (const person of assignedPersons) {
		if (filterValues.includes(person.id)) return true;
	}
	return false;
};

const teamFilterValue = (element, filterValues, options) => {
	if (element.teamIds) {
		return element.teamIds.some(teamId => filterValues.includes(teamId));
	}
	const teamPersonsIds = [];
	const teams = (options && options.teams ? options.teams : []).filter(team => filterValues.includes(team.node.id));
	if (teams.length > 0) {
		teams.forEach(team => {
			if (team.node.id !== null) {
				team.node.teamPersons.edges.forEach(tp => {
					if (!teamPersonsIds.includes(tp.node.person.id)) {
						teamPersonsIds.push(tp.node.person.id);
					}
				});
			} else {
				// no team option selected
				// if the person is not present in any of the team show it
				if (
					!team.node.teamPersons.edges.some(tp => tp.node.person.id === element.id) &&
					!teamPersonsIds.includes(element.id)
				) {
					teamPersonsIds.push(element.id);
				}
			}
		});
	}
	return teammemberFilterValue(element, teamPersonsIds);
};

const statusColumnFilterValue = (task, filterValues, options) => {
	let includesCol = false,
		includesColV2 = false;
	if (task.statusColumnV2) {
		includesColV2 = filterValues.includes(
			options && options.isProjectGroup ? task.statusColumnV2.projectGroupStatusColumnId : task.statusColumnV2.id
		);
	}
	if (!includesColV2 && task.statusColumn) {
		includesCol = filterValues.includes(
			options && options.isProjectGroup ? task.statusColumn.projectGroupStatusColumnId : task.statusColumn.id
		);
	}
	return includesCol || includesColV2;
};

const statusCategoryFilterValue = (task, filterValues) => {
	return filterValues.includes(task.statusColumnV2.category);
};

const phaseCategoryFilterValue = (task, filterValues) => {
	let isMatch = false;
	const phase = task.phase;
	const startDate = phase ? Util.CreateNonUtcMomentDate(phase.startYear, phase.startMonth, phase.startDay) : null;
	const endDate = phase ? Util.CreateNonUtcMomentDate(phase.deadlineYear, phase.deadlineMonth, phase.deadlineDay) : null;
	let today = moment();
	today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
	filterValues.forEach(filterValue => {
		if (!isMatch) {
			if (filterValue === 'PAST') {
				isMatch = endDate !== null && endDate.isBefore(today);
			} else if (filterValue === 'ACTIVE') {
				isMatch =
					startDate !== null && endDate !== null && startDate.isSameOrBefore(today) && endDate.isSameOrAfter(today);
			} else if (filterValue === 'FUTURE') {
				isMatch = startDate !== null && startDate.isAfter(today);
			} else if (filterValue === 'NONE') {
				isMatch = phase === null;
			}
		}
	});
	return isMatch;
};

const sprintCategoryFilterValue = (task, filterValues) => {
	let isMatch = false;
	const sprint = task.sprint;
	const startDate = sprint ? Util.CreateNonUtcMomentDate(sprint.startYear, sprint.startMonth, sprint.startDay) : null;
	const endDate = sprint ? Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay) : null;
	let today = moment();
	today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
	filterValues.forEach(filterValue => {
		if (!isMatch) {
			if (filterValue === 'PAST') {
				isMatch = endDate !== null && endDate.isBefore(today);
			} else if (filterValue === 'ACTIVE') {
				isMatch =
					startDate !== null && endDate !== null && startDate.isSameOrBefore(today) && endDate.isSameOrAfter(today);
			} else if (filterValue === 'FUTURE') {
				isMatch = startDate !== null && startDate.isAfter(today);
			} else if (filterValue === 'NONE') {
				isMatch = sprint === null;
			}
		}
	});
	return isMatch;
};

const projectFilterValue = (element, filterValues) => {
	if (element.project) {
		return filterValues.includes(element.project.id);
	} else if (element.projects) {
		const projectIds = element.projects.map(project => project.id);
		const matchingIds = _.intersection(projectIds, filterValues);
		return matchingIds.length > 0;
	} else {
		return filterValues.includes(element.id);
	}
};

const clientFilterValue = (element, filterValues) => {
	if (element.project) {
		return filterValues.includes(element.project.client ? element.project.client.id : null);
	}
	return filterValues.includes(element.client ? element.client.id : null);
};

const taskOwnerFilterValue = (task, filterValues) => {
	if (filterValues.includes(null) && !task.owner) return true;
	else if (task.owner) return filterValues.includes(task.owner.id);
	return false;
};

const taskFollowersFilterValue = (task, filterValues) => {
	let isMatch = filterValues.includes(null) && task.followers.length === 0;
	if (!isMatch) {
		task.followers?.forEach(follower => {
			if (!isMatch) {
				isMatch = filterValues.includes(follower.id);
			}
		});
	}
	return isMatch;
};

const taskSubtasksFilterValue = (task, filterValues) => {
	//if filter values has 2 elemnts it means both has and does not have subtasks option is selected. All tasks match in this case
	return (
		filterValues.length === 2 ||
		(filterValues.includes('no_sub_tasks') &&
			(task.subTaskCount === 0 || (task.subTasks && task.subTasks.edges.length === 0))) ||
		(filterValues.includes('has_sub_tasks') &&
			((task.subTaskCount !== null && task.subTaskCount !== undefined && task.subTaskCount !== 0) ||
				(task.subTasks && task.subTasks.edges.length !== 0)))
	);
};

const taskClientGuestUsersFilterValue = (task, filterValues) => {
	let isMatch = filterValues.length === 0;
	if (!isMatch) {
		filterValues.forEach(filterValue => {
			if (!isMatch) {
				if (filterValue === 'assigned_client_user') {
					isMatch =
						task.assignedPersons &&
						task.assignedPersons.filter(assignedPerson => personIsClientUser(assignedPerson)).length !== 0;
				} else if (filterValue === 'owner_client_user') {
					isMatch = task.owner !== null && task.owner !== undefined && personIsClientUser(task.owner);
				} else if (filterValue === 'follower_client_user') {
					isMatch = task.followers && task.followers.filter(follower => personIsClientUser(follower)).length !== 0;
				}
			}
		});
	}
	return isMatch;
};

const deadlineFilterValue = (deadlineYear, deadlineMonth, deadlineDay, filterValues, isDone) => {
	let isMatch = filterValues.length === 0;
	const deadline = Util.CreateNonUtcMomentDate(deadlineYear, deadlineMonth, deadlineDay);
	if (!isMatch) {
		filterValues.forEach(filterValue => {
			if (!isMatch) {
				if (filterValue === 'overdue') {
					if (isDone) {
						let today = moment();
						today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
						isMatch = deadline !== null && deadline.isBefore(today);
					}
				} else if (filterValue === 'today') {
					let today = moment();
					today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					isMatch = deadline !== null && deadline.isSame(today);
				} else if (filterValue === 'this_week') {
					const today = moment();
					const beginningOfWeek = today.clone().startOf('week');
					beginningOfWeek.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					const endOfWeek = today.clone().endOf('week');
					endOfWeek.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					isMatch =
						deadline !== null && deadline.isSameOrAfter(beginningOfWeek) && deadline.isSameOrBefore(endOfWeek);
				} else if (filterValue === 'within_five_days') {
					const today = moment();
					today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					const endCriteria = today.clone().add(5, 'day');
					isMatch = deadline !== null && deadline.isSameOrAfter(today) && deadline.isSameOrBefore(endCriteria);
				} else if (filterValue === 'within_seve_days') {
					const today = moment();
					today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					const endCriteria = today.clone().add(7, 'day');
					isMatch = deadline !== null && deadline.isSameOrAfter(today) && deadline.isSameOrBefore(endCriteria);
				} else if (filterValue === 'next_week') {
					const startOfNextWeek = moment().startOf('week'),
						endOfNextWeek = moment().endOf('week');
					startOfNextWeek.add(1, 'week');
					endOfNextWeek.add(1, 'week');
					endOfNextWeek.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					isMatch =
						deadline !== null && deadline.isSameOrAfter(startOfNextWeek) && deadline.isSameOrBefore(endOfNextWeek);
				} else if (filterValue === 'this_month') {
					const thisMonthStart = moment().startOf('month'),
						thisMonthEnd = moment().endOf('month');
					thisMonthEnd.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					isMatch =
						deadline !== null && deadline.isSameOrAfter(thisMonthStart) && deadline.isSameOrBefore(thisMonthEnd);
				} else if (filterValue === 'within_thirty_days') {
					const today = moment();
					today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					const endCriteria = today.clone().add(30, 'day');
					isMatch = deadline !== null && deadline.isSameOrAfter(today) && deadline.isSameOrBefore(endCriteria);
				} else if (filterValue === 'next_month') {
					const nextMonthStart = moment().startOf('month');
					nextMonthStart.add(1, 'month');
					const nextMonthEnd = nextMonthStart.clone().endOf('month');
					isMatch =
						deadline !== null && deadline.isSameOrAfter(nextMonthStart) && deadline.isSameOrBefore(nextMonthEnd);
				} else if (filterValue === 'future') {
					let today = moment();
					today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					isMatch = deadline !== null && deadline.isAfter(today);
				} else if (filterValue === 'past') {
					let today = moment();
					today.set({hour: 0, minute: 0, second: 0, millisecond: 0});
					isMatch = deadline !== null && deadline.isBefore(today);
				} else if (filterValue === 'no_dates') {
					isMatch = deadline === null;
				}
			}
		});
	}
	return isMatch;
};

const dueDateFilterValue = (invoice, filterValues) => {
	let isMatch = filterValues.length === 0;
	const dueDate = Util.CreateNonUtcMomentDate(invoice.dueYear, invoice.dueMonth, invoice.dueDay);
	if (!isMatch) {
		filterValues.forEach(filterValue => {
			if (!isMatch) {
				let startDate = moment();
				let endDate = moment();

				switch (filterValue) {
					case 'this_month':
						startDate = new moment().startOf('month');
						endDate = new moment().endOf('month');
						break;
					case 'next_month':
						startDate = new moment().add(1, 'month').startOf('month');
						endDate = new moment().add(1, 'month').endOf('month');
						break;
					case 'past_month':
						startDate = new moment().subtract(1, 'month').startOf('month');
						endDate = new moment().subtract(1, 'month').endOf('month');
						break;
					case 'next_7_days':
						endDate = new moment().add(6, 'day');
						break;
					case 'next_14_days':
						endDate = new moment().add(13, 'day');
						break;
					case 'next_30_days':
						endDate = new moment().add(29, 'day');
						break;
					case 'next_60_days':
						endDate = new moment().add(59, 'day');
						break;
					case 'past_7_days':
						startDate = new moment().subtract(6, 'day');
						break;
					case 'past_14_days':
						startDate = new moment().subtract(13, 'day');
						break;
					case 'past_30_days':
						startDate = new moment().subtract(29, 'day');
						break;
					case 'past_60_days':
						startDate = new moment().subtract(59, 'day');
						break;
				}
				isMatch = dueDate.isBetween(startDate, endDate, 'day', '[]');
			}
		});
	}
	return isMatch;
};

const elementDeadlineFilterValue = (element, filterValues) => {
	if (element.projectEndYear) {
		return deadlineFilterValue(
			element.projectEndYear,
			element.projectEndMonth,
			element.projectEndDay,
			filterValues,
			element.status !== PROJECT_STATUS.DONE && element.status !== PROJECT_STATUS.HALTED
		);
	} else {
		return deadlineFilterValue(
			element.deadlineYear,
			element.deadlineMonth,
			element.deadlineDay,
			filterValues,
			element.statusColumnV2 && element.statusColumnV2.category !== WorkflowCategories.DONE
		);
	}
};

const projectStatusFilterValue = (project, filterValues) => {
	if (Array.isArray(project.currentProjectStatus)) {
		// connected projects contain an array with all the child project statuses
		const projectStatusColorNames = project.currentProjectStatus.map(ps => Util.getProjectStatusColorName(ps.color));
		return _.intersection(filterValues, projectStatusColorNames).length > 0;
	} else {
		const projectStatus =
			project.currentProjectStatus && Util.getProjectStatusColorName(project.currentProjectStatus.color);
		return filterValues.includes(projectStatus);
	}
};

const projectPriorityFilterValue = (projectOrFilterParameterObject, filterValues) => {
	if (projectOrFilterParameterObject.project && projectOrFilterParameterObject.project.priorityLevelId) {
		// this block is for project scheduling - format of input is different
		let priorityLevelId = projectOrFilterParameterObject.project.priorityLevelId;
		if (Util.isNumber(priorityLevelId)) {
			// convert to GlobalId if needed
			priorityLevelId = btoa('PriorityLevel:' + projectOrFilterParameterObject.project.priorityLevelId);
		}
		return filterValues.includes(priorityLevelId);
	} else if (projectOrFilterParameterObject.priorityLevel && projectOrFilterParameterObject.priorityLevel.id) {
		let priorityLevelId = projectOrFilterParameterObject.priorityLevel.id;
		if (priorityLevelId === btoa('PriorityLevel:')) {
			priorityLevelId = null;
		}
		return filterValues.includes(priorityLevelId);
	} else {
		return filterValues.includes(null);
	}
};

export const projectContactFilterValue = (element, filterValues) => {
	let projectContacts = [];
	if (element.projectPersons) {
		projectContacts = element.projectPersons.edges.filter(pp => pp.node.isContactPerson).map(pp => pp.node.person);
	} else if (element.projects) {
		const projectPersons = element.projects.reduce((result, project) => result.concat(project.projectPersons.edges), []);
		projectContacts = projectPersons.filter(pp => pp.node.isContactPerson).map(pp => pp.node.person);
	}
	return projectContacts.some(contact => filterValues.includes(contact.id));
};

const projectStageFilterValue = (element, filterValues) => {
	if (element.project) {
		return filterValues.includes(element.project.status);
	} else if (element.projects) {
		const projectStatuses = element.projects.map(project => project.status);
		const matchingStatuses = _.intersection(projectStatuses, filterValues);
		return matchingStatuses.length > 0;
	} else {
		return filterValues.includes(element.status);
	}
};

const projectTypeFilterValue = (project, filterValues) => {
	const typeFilterValues = filterValues.map(val => Util.stringToProjectType(val));
	if (typeFilterValues.includes(BUDGET_TYPE.FIXED_PRICE)) {
		typeFilterValues.push(BUDGET_TYPE.FIXED_PRICE_V2);
	}
	if (!Array.isArray(project.budgetType)) {
		// if connected project, should contain an array with all the budget types of the child projects
		return typeFilterValues.includes(project.budgetType);
	} else {
		// if Project group
		const matchingBudgetTypes = _.intersection(project.budgetType, typeFilterValues);
		return matchingBudgetTypes.length > 0;
	}
};

const projectRateCardFilterValue = (project, filterValues) => {
	const rateCardId = project.rateCard
		? project.rateCard.parentRateCardId
			? project.rateCard.parentRateCardId
			: project.rateCard.id
		: null;
	if (filterValues.includes(null) && !rateCardId) return true;
	else if (rateCardId) return filterValues.includes(rateCardId);
	return false;
};

const progressFilterValue = (project, filterValues, options) => {
	const completion = options ? options.completion : null;
	let progressCriteria = false;
	for (const progressValue of filterValues) {
		if (progressCriteria) break;
		if (progressValue === 0 || progressValue === 100) {
			progressCriteria = completion === progressValue;
		} else {
			progressCriteria = completion > progressValue;
		}
	}
	return progressCriteria;
};

const departmentFilterValue = (person, filterValues) => {
	if (!person.departmentId && !person.department) return filterValues.includes(null);
	return filterValues.includes(person.departmentId || person?.department?.id);
};

const idleTimeFilterValue = (element, filterValues) => {
	if (element.allocatedToIds) {
		const matchingValues = _.intersection(element.allocatedToIds, filterValues);
		return matchingValues.length > 0;
	}
	const id = element.idleTime ? element.idleTime.id : null;
	if (!id) return filterValues.includes(null);
	return filterValues.includes(id);
};

// PEOPLE SCHEDULING CUSTOM FILTERS
const permissionLevelFilterValue = (person, filterValues) => {
	if (!person.permissionLevel) return filterValues.includes(null);
	return filterValues.includes(person.permissionLevel);
};

const allocationFilterValue = (person, filterValues) => {
	// expects the person object to contain an array with all the allocations
	if (!person.allocations.length) return false;
	const allocationIds = person.allocations.map(allocation => allocation.id);
	const matchingIds = _.intersection(allocationIds, filterValues);
	return matchingIds.length > 0;
};

const capacityWorkloadFilterValue = (person, filterValues) => {
	// expects the person object to contain a property that indicates the capacity
	if (!person.workloadArray) return false;
	if (person.workloadArray.includes('visible')) return true;
	const matchingValues = _.intersection(person.workloadArray, filterValues);
	return matchingValues.length > 0;
};

const projectAndIdleFilterValue = (element, filterValues) => {
	if (element.allocatedToIds) {
		if (!element.allocatedToIds.length) return false;
		const matchingValues = _.intersection(element.allocatedToIds, filterValues);
		return matchingValues.length > 0;
	} else {
		if (element.isIdleTime) {
			// we are currently showing all idle times if the filter contains an idle time
			return filterValues.some(id => atob(id).startsWith('IdleTime'));
		}
		return projectFilterValue(element, filterValues);
	}
};

const invoiceStatusFilterValue = (invoice, filterValues) => {
	if (invoice.status) {
		return filterValues.includes(invoice.status);
	}

	return false;
};

const paymentStatusFilterValue = (invoice, filterValues) => {
	if (invoice.paymentStatus) {
		return filterValues.includes(invoice.paymentStatus);
	}

	return false;
};

const invoiceExternalStatusFilterValue = (invoice, filterValues) => {
	if (invoice.externalStatus) {
		return filterValues.includes(invoice.externalStatus);
	}

	return false;
};
const invoiceBillFromFilterValue = (invoice, filterValues) => {
	return filterValues.includes(invoice.billTo?.billFrom?.id);
};
const invoiceBillToFilterValue = (invoice, filterValues) => {
	return filterValues.includes(invoice.billTo?.id);
};

const expenseCategoryFilterValue = (expense, filterValues) => {
	return filterValues.includes(expense.category?.id);
};

const expenseBillingOptionsFilterValue = (expense, filterValues) => {
	const {billable, partOfFixedPrice} = expense;
	return (
		(filterValues.includes(EXPENSE_BILLING_OPTIONS.BILLABLE) && billable) ||
		(filterValues.includes(EXPENSE_BILLING_OPTIONS.NON_BILLABLE) && !billable) ||
		(filterValues.includes(EXPENSE_BILLING_OPTIONS.BILLABLE_AS_PART_OF_FIXED) && billable && partOfFixedPrice === true) ||
		(filterValues.includes(EXPENSE_BILLING_OPTIONS.BILLABLE_ON_TOP_OF_FIXED) && billable && partOfFixedPrice === false)
	);
};

const skillPersonFilterValue = (skill, filterValues) => {
	if (skill.skillPersons.length) {
		const persons = skill.skillPersons.map(sp => sp.person);
		return persons.some(person => filterValues.includes(person.id));
	}

	return false;
};

const skillRoleFilterValue = (skill, filterValues) => {
	if (skill.skillPersons.length) {
		const persons = skill.skillPersons.map(sp => sp.person);
		return persons.some(person => filterValues.includes(person.role?.id));
	}

	return false;
};

const skillUserResourcesFilterValue = (skill, filterValues) => {
	if (skill.skillPersons.length) {
		const persons = skill.skillPersons.map(sp => sp.person);
		return persons.some(person => {
			if (filterValues.includes('VIRTUAL_RESOURCE') && person.active && personIsVirtualUser(person)) return true;

			if (filterValues.includes('ACTIVE_USER') && person.active && !personIsVirtualUser(person)) return true;

			if (filterValues.includes('DEACTIVATED_USER') && !person.active) return true;

			return false;
		});
	}

	return false;
};

const labelAppliedFilterValue = (label, filterValues) => {
	if (filterValues.includes('APPLIED_TO_PEOPLE') && label.peopleCount > 0) return true;

	if (filterValues.includes('APPLIED_TO_TASKS') && label.taskCount > 0) return true;

	if (filterValues.includes('APPLIED_TO_PROJECTS') && label.projectCount > 0) return true;

	return false;
};

const labelAllowedFilterValue = (label, filterValues) => {
	if (!label.category) return true;

	const category = label.category;
	if (filterValues.includes('ALLOWED_ON_PEOPLE') && category.allowOnPeople) return true;

	if (filterValues.includes('ALLOWED_ON_TASKS') && category.allowOnTasks) return true;

	if (filterValues.includes('ALLOWED_ON_PROJECTS') && category.allowOnProjects) return true;

	return false;
};

const projectWinChanceFilterValue = (project, filterValues) => {
	const baselineWinChance = project.baselineWinChance;

	if (filterValues.includes(ProjectWinChance.ZERO) && baselineWinChance === 0.0) return true;

	if (filterValues.includes(ProjectWinChance.OVER_10) && baselineWinChance > 0.1) return true;

	if (filterValues.includes(ProjectWinChance.OVER_30) && baselineWinChance > 0.3) return true;

	if (filterValues.includes(ProjectWinChance.OVER_50) && baselineWinChance > 0.5) return true;

	if (filterValues.includes(ProjectWinChance.OVER_70) && baselineWinChance > 0.7) return true;

	if (filterValues.includes(ProjectWinChance.OVER_90) && baselineWinChance > 0.9) return true;

	if (filterValues.includes(ProjectWinChance.HUNDRED) && baselineWinChance === 1.0) return true;

	return false;
};

const projectAllocationsFilterValue = (project, filterValues) => {
	if (filterValues.includes(ProjectAllocations.CONTAINS_PLACEHOLDER) && project.placeholderAllocations?.length > 0) {
		return true;
	}

	const hasSoftAllocations = project?.allocations?.edges ? project.allocations.edges.some(alloc => alloc.node.isSoft) : false;

	if (filterValues.includes(ProjectAllocations.CONTAINS_SOFT) && hasSoftAllocations) {
		return true;
	}

	return false;
};

const personAllocationsFilterValue = (person, filterValues) => {
	const hasSoftAllocations = person.allocations.some(alloc => alloc.isSoft);

	if (filterValues.includes(PersonAllocations.CONTAINS_SOFT) && hasSoftAllocations) return true;

	return false;
};

const skillTeamFilterValue = (skill, filterValues, options) => {
	const teams = (options?.teams?.edges || []).filter(team => filterValues.includes(team.node.id));

	if (skill.skillPersons && teams.length > 0) {
		for (const team of teams) {
			if (team.node.id !== null) {
				return team.node.teamPersons.edges.some(teamPerson =>
					skill.skillPersons.some(sp => sp.person.id === teamPerson.node.person.id)
				);
			}
		}
	}

	return false;
};

const skillDepartmentFilterValue = (skill, filterValues) => {
	if (skill.skillPersons.length) {
		return skill.skillPersons.some(sp => sp.person.department && filterValues.includes(sp.person.department.id));
	}

	return false;
};

const skillAssignedFilterValue = (skill, filterValues) => {
	const hasUsers = skill.skillPersons.length;
	if (filterValues.includes('SKILLS_WITH_USERS') && hasUsers) {
		return true;
	}
	if (filterValues.includes('SKILLS_WITHOUT_USERS') && !hasUsers) {
		return true;
	}
	return false;
};

const skillLevelFilterValue = (skill, filterValues) => {
	if (skill.skillPersons.length) {
		return skill.skillPersons.some(
			sp => (sp.level && filterValues.includes(sp.level.id)) || filterValues.includes(sp.level)
		);
	}

	return false;
};

export const getFilterFuncForKey = key => {
	switch (key) {
		case 'project':
			return projectFilterValue;
		case 'role':
			return roleFilterValue;
		case 'label':
			return labelFilterValue;
		case 'projectOwner':
		case 'owner':
			return taskOwnerFilterValue;
		case 'projectFollower':
		case 'follower':
			return taskFollowersFilterValue;
		case 'contact':
			return projectContactFilterValue;
		case 'projectStage':
			return projectStageFilterValue;
		case 'projectType':
			return projectTypeFilterValue;
		case 'rateCard':
			return projectRateCardFilterValue;
		case 'progress':
			return progressFilterValue;
		case 'recentActivity':
			return recentlyUpdatedFilterValue;
		case 'teamMember':
		case 'projectPerson':
		case 'person':
			return teammemberFilterValue;
		case 'projectStatus':
			return projectStatusFilterValue;
		case 'priorityLevel':
			return projectPriorityFilterValue;
		case 'projectPhase':
			return phaseFilterValue;
		case 'projectSprint':
			return sprintFilterValue;
		case 'projectStatusColumn':
			return statusColumnFilterValue;
		case 'sprintCategory':
			return sprintCategoryFilterValue;
		case 'statusCategory':
			return statusCategoryFilterValue;
		case 'phaseCategory':
			return phaseCategoryFilterValue;
		case 'indicator':
		case 'indicator_filtered':
			return blockedBugFilterValue;
		case 'sub_tasks':
			return taskSubtasksFilterValue;
		case 'client':
			return clientFilterValue;
		case 'client_guest_users':
			return taskClientGuestUsersFilterValue;
		case 'deadline':
			return elementDeadlineFilterValue;
		case 'team':
			return teamFilterValue;
		case 'dependencies':
			return dependencyFilterValue;
		case 'approuved':
			return approuvedFilterValue;
		case 'predictedOverrun':
			return predictedOverrunFilterValue;
		case 'permissionLevel':
			return permissionLevelFilterValue;
		case 'allocation':
			return allocationFilterValue;
		case 'capacityWorkload':
			return capacityWorkloadFilterValue;
		case 'projectAndIdle':
		case 'personAllocatedToProjectAndIdle':
			return projectAndIdleFilterValue;
		case 'department':
			return departmentFilterValue;
		case 'internalTime':
		case 'timeOff':
			return idleTimeFilterValue;
		case 'invoiceStatus':
			return invoiceStatusFilterValue;
		case 'skill_person':
			return skillPersonFilterValue;
		case 'skill_role':
			return skillRoleFilterValue;
		case 'skill_resource_type':
			return skillUserResourcesFilterValue;
		case 'skill_team':
			return skillTeamFilterValue;
		case 'skill_department':
			return skillDepartmentFilterValue;
		case 'skill_assigned':
			return skillAssignedFilterValue;
		case 'skill_level':
			return skillLevelFilterValue;
		case 'skill':
			return skillFilterValue;
		case 'skill_and':
			return skillAndFilterValue;
		case FILTER_TYPE.LABEL_APPLIED:
			return labelAppliedFilterValue;
		case FILTER_TYPE.LABEL_ALLOWED:
			return labelAllowedFilterValue;
		case FILTER_TYPE.PROJECT_WIN_CHANCE:
			return projectWinChanceFilterValue;
		case FILTER_TYPE.PROJECT_ALLOCATIONS:
			return projectAllocationsFilterValue;
		case FILTER_TYPE.PERSON_ALLOCATIONS:
			return personAllocationsFilterValue;
		case FILTER_TYPE.DUE_DATE:
			return dueDateFilterValue;
		case FILTER_TYPE.QBO_STATUS:
			return invoiceExternalStatusFilterValue;
		case FILTER_TYPE.XERO_STATUS:
			return invoiceExternalStatusFilterValue;
		case FILTER_TYPE.ECONOMIC_STATUS:
			return invoiceExternalStatusFilterValue;
		case FILTER_TYPE.SAGE_INTACCT_STATUS:
			return invoiceExternalStatusFilterValue;
		case FILTER_TYPE.PAYMENT_STATUS:
			return paymentStatusFilterValue;
		case FILTER_TYPE.BILL_FROM:
			return invoiceBillFromFilterValue;
		case FILTER_TYPE.EXPENSE_CATEGORY:
			return expenseCategoryFilterValue;
		case FILTER_TYPE.EXPENSE_BILLING_OPTIONS:
			return expenseBillingOptionsFilterValue;
		case FILTER_TYPE.BILL_TO:
			return invoiceBillToFilterValue;
		default:
			return () => true;
	}
};

export const findElementFromId = (category, id, viewer, formatMessage, intl) => {
	switch (category) {
		case FILTER_TYPE.PROJECT:
			const project = viewer.projects
				? viewer.projects.edges.find(p => p.node.id === id)
				: viewer.projectGroup
				? viewer.projectGroup.projects.edges.find(p => p.node.id === id)
				: null;
			return {id: id, value: project ? project.node.name : null};
		case FILTER_TYPE.SKILL_ROLE:
		case FILTER_TYPE.ROLE:
			const role = viewer.company.roles.edges.find(r => r.node.id === id);
			return {id, value: role ? role.node.name : null};
		case FILTER_TYPE.SKILL_DEPARTMENT:
		case FILTER_TYPE.DEPARTMENT:
			const department = viewer.company.departments.edges.find(dep => dep.node.id === id);
			return {id, value: department ? department.node.name : null};
		case FILTER_TYPE.INTERNAL_TIME:
		case FILTER_TYPE.TIME_OFF:
			const idleTime = viewer.company.idleTimes.edges.find(it => it.node.id === id);
			return {id, value: idleTime ? idleTime.node.name : null};
		case FILTER_TYPE.LABEL_TIME_REG:
		case FILTER_TYPE.LABEL:
			const label = viewer.company.labels.edges.find(l => l.node.id === id);
			return {id, value: label ? label.node.name : null};
		case FILTER_TYPE.PROJECT_STAGE:
			let projectStage = {id: id};
			switch (id) {
				case PROJECT_STATUS.PLANNING:
					projectStage.value = formatMessage({id: 'project_status.planning'});
					break;
				case PROJECT_STATUS.RUNNING:
					projectStage.value = formatMessage({id: 'project_status.running'});
					break;
				case PROJECT_STATUS.HALTED:
					projectStage.value = formatMessage({id: 'project_status.halted'});
					break;
				case PROJECT_STATUS.DONE:
					projectStage.value = formatMessage({id: 'project_status.done'});
					break;
				case PROJECT_STATUS.OPPORTUNITY:
					projectStage.value = formatMessage({id: 'project_status.opportunity'});
					break;
				default:
					projectStage.value = null;
			}
			return projectStage;
		case FILTER_TYPE.SKILL_ASSIGNED:
			let skillAssigned = {id: id};
			switch (id) {
				case 'SKILLS_WITH_USERS':
					skillAssigned.value = formatMessage({id: 'filter_type.assigned.skills_with_users'});
					break;
				case 'SKILLS_WITHOUT_USERS':
					skillAssigned.value = formatMessage({id: 'filter_type.assigned.skills_without_users'});
					break;
				default:
					skillAssigned.value = null;
			}
			return skillAssigned;
		case FILTER_TYPE.SKILL_LEVEL:
			const skillLevel = viewer.company.skillLevels.find(l => l.id === id);
			return {
				id: id,
				value: skillLevel ? skillLevel.description : formatMessage({id: 'settings_skills.levels.no_level'}),
			};
		case FILTER_TYPE.SKILL_RESOURCE_TYPE:
			let resourceType = {id: id};
			switch (id) {
				case 'ACTIVE_USER':
					resourceType.value = formatMessage({id: 'filter_type.resource_type.active'});
					break;
				case 'DEACTIVATED_USER':
					resourceType.value = formatMessage({id: 'filter_type.resource_type.deactivated'});
					break;
				case 'VIRTUAL_RESOURCE':
					resourceType.value = formatMessage({id: 'filter_type.resource_type.virtual_resource'});
					break;
				default:
					resourceType.value = null;
			}
			return resourceType;
		case FILTER_TYPE.PERMISSION_LEVEL:
			let permission = {id: id};
			switch (id) {
				case 'NO_LOGIN':
					permission.value = formatMessage({id: 'permissions_modal.user_type_virtual'});
					break;
				case 'RESTRICTED':
					permission.value = formatMessage({id: 'permissions_modal.user_type_collaborator'});
					break;
				case 'NO_FINANCIAL':
					permission.value = formatMessage({id: 'permissions_modal.user_type_owner'});
					break;
				case 'FULL':
					permission.value = formatMessage({id: 'permissions_modal.user_type_controller'});
					break;
				case 'ADMIN':
					permission.value = formatMessage({id: 'permissions_modal.user_type_admin'});
					break;
				default:
					permission.value = null;
			}
			return permission;
		case FILTER_TYPE.PROJECT_TYPE:
			const projectType = Util.getProjectTypeFilterOptions(intl).find(pt => pt.value === id);
			return {id, value: projectType ? projectType.label : null};
		case FILTER_TYPE.RATE_CARD:
			const rateCard = viewer.company.rateCards.edges.find(rc => rc.node.id === id);
			return {
				id,
				value: rateCard ? rateCard.node.name : formatMessage({id: 'rate_card_selector.no_rate_card_text'}),
			};
		case FILTER_TYPE.PROGRESS: {
			const progress = Util.getProgressFilterOptions(intl).find(f => f.value === id);
			return {id, value: progress ? progress.label : null};
		}
		case FILTER_TYPE.RECENT_TASK_ACTIVITY:
		case FILTER_TYPE.RECENT_ACTIVITY:
			let recentActivity = {id: id};
			switch (id) {
				case RECENT_ACTIVITY.TODAY:
					recentActivity.value = formatMessage({id: 'common.today'});
					break;
				case RECENT_ACTIVITY.WEEK:
					recentActivity.value = formatMessage({id: 'filter_type.past_7_days'});
					break;
				case RECENT_ACTIVITY.MONTH:
					recentActivity.value = formatMessage({id: 'filter_type.past_month'});
					break;
				default:
					recentActivity.value = null;
			}
			return recentActivity;
		case FILTER_TYPE.SKILL_PERSON:
		case FILTER_TYPE.PROJECT_OWNER:
		case FILTER_TYPE.CONTACT:
		case FILTER_TYPE.PROJECT_PERSON:
		case FILTER_TYPE.FOLLOWER:
		case FILTER_TYPE.PROJECT_FOLLOWER:
		case FILTER_TYPE.OWNER:
		case FILTER_TYPE.PROJECT_OWNER_TIME_REG_REPORT:
		case FILTER_TYPE.PERSON:
			const person = viewer.company.allPersons.edges.find(p => p.node.id === id);
			return {id, value: person ? person.node.firstName + ' ' + person.node.lastName : null};
		case FILTER_TYPE.PROJECT_STATUS:
			let projectStatus = {id};
			switch (id) {
				case STATUS_PROJECT_COLOR.GREEN:
					projectStatus.value = formatMessage({id: 'project_status_filter.green'});
					break;
				case STATUS_PROJECT_COLOR.RED:
					projectStatus.value = formatMessage({id: 'project_status_filter.red'});
					break;
				case STATUS_PROJECT_COLOR.YELLOW:
					projectStatus.value = formatMessage({id: 'project_status_filter.yellow'});
					break;
				default:
					projectStatus.value = null;
			}
			return projectStatus;
		case FILTER_TYPE.ALL_PROJECT_PHASES:
			if (id === null) {
				return {id, value: intl.formatMessage({id: 'common.without-phase'})};
			}

			let projectPhase = null;
			let projectEdge = null;
			if (hasFeatureFlag('timesheet_remaster') && viewer.phases && viewer.projects) {
				projectPhase = viewer.phases ? viewer.phases.edges.find(edge => edge.node.id === id) : null;
				projectEdge =
					projectPhase && viewer.projects
						? viewer.projects.edges.find(edge => edge.node.id === projectPhase.node.projectId)
						: null;
			}
			return {
				id,
				value: projectPhase ? projectPhase.node.name : null,
				title: projectEdge ? projectEdge.node.name : null,
			};
		case FILTER_TYPE.PROJECT_PHASE:
			if (id === null) {
				return {id, value: intl.formatMessage({id: 'common.without-phase'})};
			}
			const phases = viewer.projectGroup
				? viewer.projectGroup.projects.edges.reduce((acc, project) => acc.concat(project.node.phases.edges), [])
				: viewer.project
				? viewer.project.phases.edges
				: [];
			const phase = phases.find(phase => phase.node.id === id);
			return {id, value: phase ? phase.node.name : null};
		case FILTER_TYPE.PROJECT_SPRINT:
			let sprint = null;
			if (viewer.project && viewer.project.sprints && viewer.project.sprints.edges) {
				sprint = viewer.project.sprints.edges.find(sprint => sprint.node.id === id);
			} else if (
				viewer.projectGroup &&
				viewer.projectGroup.projects &&
				viewer.projectGroup.projects.edges &&
				viewer.projectGroup.projects.edges.length !== 0
			) {
				sprint = viewer.projectGroup.projects.edges[0].node.sprints.edges.find(
					sprint => sprint.node.projectGroupSprintId === id
				);
			}
			return {id, value: sprint ? sprint.node.name : null};
		case FILTER_TYPE.PROJECT_STATUS_COLUMN:
			let column = null;
			if (viewer.project && viewer.project.statusColumnsV2 && viewer.project.statusColumnsV2.edges) {
				column = viewer.project.statusColumnsV2.edges.find(column => column.node.id === id);
			} else if (
				viewer.projectGroup &&
				viewer.projectGroup.projects &&
				viewer.projectGroup.projects.edges &&
				viewer.projectGroup.projects.edges.length !== 0
			) {
				column = viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges.find(
					column => column.node.projectGroupStatusColumnId === id
				);
			}
			return {id, value: column ? column.node.name : null};
		case FILTER_TYPE.SPRINT_CATEGORY:
			let sprintCategory = {id: id};
			switch (id) {
				case GroupProgressCategories.PAST:
					sprintCategory.value = formatMessage({id: 'common.past_sprints'});
					break;
				case GroupProgressCategories.ACTIVE:
					sprintCategory.value = formatMessage({id: 'common.active_sprints'});
					break;
				case GroupProgressCategories.FUTURE:
					sprintCategory.value = formatMessage({id: 'common.future_sprints'});
					break;
				case GroupProgressCategories.NONE:
					sprintCategory.value = formatMessage({id: 'project_sprints.backlog'});
					break;
				default:
					sprintCategory.value = null;
			}
			return sprintCategory;
		case FILTER_TYPE.STATUS_CATEGORY:
			let status = {id: id};
			switch (id) {
				case 'TODO':
					status.value = formatMessage({id: `common.to_do`});
					break;
				case 'INPROGRESS':
					status.value = formatMessage({id: `common.in_progress`});
					break;
				case 'DONE':
					status.value = formatMessage({id: `common.done`});
					break;
				default:
					status.value = null;
			}
			return status;
		case FILTER_TYPE.PHASE_CATEGORY:
			let phaseCategory = {id: id};
			switch (id) {
				case GroupProgressCategories.PAST:
					phaseCategory.value = formatMessage({id: 'common.past_phases'});
					break;
				case GroupProgressCategories.ACTIVE:
					phaseCategory.value = formatMessage({id: 'common.active_phases'});
					break;
				case GroupProgressCategories.FUTURE:
					phaseCategory.value = formatMessage({id: 'common.future_phases'});
					break;
				case GroupProgressCategories.NONE:
					phaseCategory.value = formatMessage({id: 'common.without-phase'});
					break;
				default:
					phaseCategory.value = null;
			}
			return phaseCategory;

		case FILTER_TYPE.INDICATOR:
		case FILTER_TYPE.INDICATOR_FILTERED: {
			const indicator = Util.GetIndicatorFiltersV2(intl).find(i => i.value === id);
			return {id, value: indicator ? indicator.label : null};
		}

		case FILTER_TYPE.SUB_TASKS: {
			const filter = Util.getSubTasksFilters(intl).find(f => f.value === id);
			return {id, value: filter ? filter.label : null};
		}
		case FILTER_TYPE.TASK_HIERARCHY: {
			const filter = Util.getTaskHierarchyFilters(intl).find(f => f.value === id);
			return {id, value: filter ? filter.label : null};
		}
		case FILTER_TYPE.CLIENT:
			const client = viewer.company.clients.edges.find(c => c.node.id === id);
			return {id, value: client ? client.node.name : null};
		case FILTER_TYPE.CLIENT_GUEST_USERS: {
			const guestUser = Util.getClientGuestUsersFilters(intl).find(f => f.value === id);
			return {id, value: guestUser ? guestUser.label : null};
		}
		case FILTER_TYPE.DEADLINE: {
			const deadline = Util.getDeadlineFilters(intl).find(f => f.value === id);
			return {id, value: deadline ? deadline.label : null};
		}
		case FILTER_TYPE.DUE_DATE:
			const dueDate = Util.getDueDateFilters(intl).find(f => f.value === id);
			return {id, value: dueDate ? dueDate.label : null};
		case FILTER_TYPE.SKILL_TEAM:
		case FILTER_TYPE.TEAM:
			const team = viewer.company.teams.edges.find(p => p.node.id === id);
			return {id: id, value: team ? team.node.name : null};
		case FILTER_TYPE.TEAM_MEMBER: {
			const teamMember = viewer.company.allPersons.edges.find(p => p.node.id === id);
			return {id, value: teamMember ? `${teamMember.node.firstName} ${teamMember.node.lastName}` : null};
		}
		case FILTER_TYPE.DEPENDENCIES:
			const dependencies = {id};
			switch (id) {
				case 'has_dependencies':
					dependencies.value = formatMessage({id: 'common.has-dependencies'});
					break;
				case 'no_dependencies':
					dependencies.value = formatMessage({id: 'common.no-dependencies'});
					break;
				default:
					dependencies.value = null;
			}
			return dependencies;
		case FILTER_TYPE.APPROUVED:
			const approved = {id};
			switch (id) {
				case 'approved':
					approved.value = formatMessage({id: 'common.approved'});
					break;
				case 'not-approved':
					approved.value = formatMessage({id: 'common.not-approved'});
					break;
				default:
					approved.value = null;
			}
			return approved;
		case FILTER_TYPE.APPROVAL_STATUS:
			const approvalStatus = {id};
			switch (id) {
				case 'true':
					approvalStatus.value = formatMessage({id: 'common.approved'});
					break;
				case 'false':
					approvalStatus.value = formatMessage({id: 'common.not-approved'});
					break;
				default:
					approvalStatus.value = null;
			}
			return approvalStatus;
		case FILTER_TYPE.CAPACITY_WORKLOAD:
			const capacityWorkload = {id: id};
			switch (id) {
				case 'overbooked':
					capacityWorkload.value = formatMessage({id: 'filter_option.overbooked'});
					break;
				case 'underbooked':
					capacityWorkload.value = formatMessage({id: 'filter_option.underbooked'});
					break;
				case 'fullybooked':
					capacityWorkload.value = formatMessage({id: 'filter_option.fully_booked'});
					break;
				case 'booked':
					capacityWorkload.value = formatMessage({id: 'filter_option.booked'});
					break;
				case 'noworkload':
					capacityWorkload.value = formatMessage({id: 'filter_option.no_workload'});
					break;
				default:
					capacityWorkload.value = null;
			}
			return capacityWorkload;
		case FILTER_TYPE.BILLABLE:
			const billable = Util.getBillableFilters().find(f => f.value === id);
			return {id, value: billable ? billable.label : null};
		case FILTER_TYPE.INVOICED:
			const invoiced = Util.getInvoicedFilters(intl).find(f => f.value === id);
			return {id, value: invoiced ? invoiced.label : null};
		case FILTER_TYPE.PREDICTED_OVERRUN:
			const overrun = Util.getOverrunFilters(viewer?.project?.demo).find(f => f.value === id);
			return {id, value: overrun ? overrun.label : null};
		case FILTER_TYPE.INVOICE_STATUS:
		case FILTER_TYPE.PAYMENT_STATUS:
		case FILTER_TYPE.QBO_STATUS:
		case FILTER_TYPE.XERO_STATUS:
		case FILTER_TYPE.SAGE_INTACCT_STATUS:
		case FILTER_TYPE.ECONOMIC_STATUS:
			return {value: Util.getInvoiceStatusTranslation(id, intl)};
		case FILTER_TYPE.TASK_LEVEL:
			let level = {id};
			switch (id) {
				case FILTER_FIELD_VALUES.PARENT_TASK:
					level.value = formatMessage({id: `filter_type.task_level.parent_task`});
					break;
				case FILTER_FIELD_VALUES.SUB_TASK:
					level.value = formatMessage({id: `filter_type.task_level.subtask_task`});
					break;
				default:
					level.value = null;
			}
			return level;
		case FILTER_TYPE.SKILL:
		case FILTER_TYPE.SKILL_AND:
			// Old skill filter id's are just plain string skill id's. New ones are objects including skillLevelId.
			if (id.skillId) {
				const skill = viewer.company.skills.edges.find(p => p.node.id === id.skillId)?.node;
				const skillLevel = id.skillLevelId && viewer.company.skillLevels.find(sl => sl.id === id.skillLevelId);
				return {
					id,
					value: skill ? (skillLevel ? getSkillNameAndLevel(skill, skillLevel) : skill.name) : undefined,
				};
			} else {
				const skill = viewer.company.skills.edges.find(p => p.node.id === id);
				return {id, value: skill ? skill.node.name : null};
			}
		case FILTER_TYPE.LABEL_APPLIED:
			const labelApplied = Util.getLabelAppliedFilterOptions(intl).find(f => f.value === id);
			return {id, value: labelApplied?.label};
		case FILTER_TYPE.LABEL_ALLOWED:
			const labelAllowed = Util.getLabelAllowedFilterOptions(intl).find(f => f.value === id);
			return {id, value: labelAllowed?.label};
		case FILTER_TYPE.PRIORITY_LEVEL:
			const priorityLevel = viewer.company.priorityLevels.edges.find(priorityLevel => priorityLevel.node.id === id);
			return {id, value: priorityLevel ? priorityLevel.node.name : formatMessage({id: 'common.no_priority'})};
		case FILTER_TYPE.PROGRAM:
			const program = viewer.company.programs.edges.find(program => program.node.id === id);
			return {
				id,
				value: program
					? program.node.name
					: formatMessage({id: 'common.no_program'}, {program: ProgramUtil.programText(formatMessage)}),
			};
		case FILTER_TYPE.PROJECT_WIN_CHANCE:
			const winChance = Util.getProjectWinChanceFilters(intl).find(f => f.value === id);
			return {id, value: winChance?.label};
		case FILTER_TYPE.PROJECT_ALLOCATIONS:
			const allocation = Util.getProjectAllocationsFilters(intl).find(f => f.value === id);
			return {id, value: allocation?.label};
		case FILTER_TYPE.PERSON_ALLOCATIONS:
			const personAllocation = Util.getPersonAllocationsFilters(intl).find(f => f.value === id);
			return {id, value: personAllocation?.label};
		case FILTER_TYPE.TASK_PROJECT_LABEL:
			const taskProjectLabel = viewer.company.labels.edges.find(labels => labels.node.id === id);
			return {id, value: taskProjectLabel ? taskProjectLabel.node.name : null};
		case FILTER_TYPE.BILL_FROM:
			const billFrom = viewer.company.billFroms.find(billFrom => billFrom.id === id);
			return {id, value: billFrom?.name};
		case FILTER_TYPE.BILL_TO:
			const billTo = viewer.company.billFroms
				.flatMap(billFrom => billFrom.billTos.edges)
				.find(edge => edge.node.id === id);
			return {id, value: billTo?.node?.name};
		case FILTER_TYPE.EXPENSE_CATEGORY:
			const expenseCategory = viewer.company.expenseCategories.edges.find(edge => edge.node.id === id);
			return {id, value: expenseCategory ? expenseCategory.node.name : null};
		case FILTER_TYPE.EXPENSE_BILLING_OPTIONS:
			let value;
			switch (id) {
				case EXPENSE_BILLING_OPTIONS.NON_BILLABLE:
					value = formatMessage({id: 'expense_item_modal.non_billable'});
					break;
				case EXPENSE_BILLING_OPTIONS.BILLABLE:
					value = formatMessage({id: 'expense_item_modal.billable'});
					break;
				case EXPENSE_BILLING_OPTIONS.BILLABLE_AS_PART_OF_FIXED:
					value = formatMessage({id: 'expense_item_modal.billable_part_of_fixed_price'});
					break;
				case EXPENSE_BILLING_OPTIONS.BILLABLE_ON_TOP_OF_FIXED:
					value = formatMessage({id: 'expense_item_modal.billable_fixed_price'});
					break;
				default:
					value = null;
			}
			return {id, value};
		default:
			return () => true;
	}
};

export const getGroupLabel = (group, formatMessage) => {
	switch (group) {
		case FILTER_GROUP.PERSON:
			return formatMessage({id: 'common.people'});
		case FILTER_GROUP.PROJECT:
			return formatMessage({id: 'common.project'});
		case FILTER_GROUP.TASK:
			return formatMessage({id: 'common.task'});
		case FILTER_GROUP.TIMEREGS:
			return formatMessage({id: 'common.time_registrations'});
		case FILTER_GROUP.INVOICES:
			return formatMessage({id: 'common.invoices'});
		case FILTER_GROUP.PLACEHOLDER:
			return formatMessage({id: 'common.placeholder'});
		case FILTER_GROUP.RESOURCES:
			return formatMessage({id: 'common.resources'});
		case FILTER_GROUP.EXPENSES:
			return formatMessage({id: 'common.expenses'});
		default:
			return 'Invalid group';
	}
};

export const getTypeLabel = (type, formatMessage, selectedSection) => {
	switch (type) {
		case FILTER_TYPE.ALL:
			return formatMessage({id: 'common.all'});
		case FILTER_TYPE.INDICATOR:
		case FILTER_TYPE.INDICATOR_FILTERED:
			return formatMessage({id: 'task_activity_log.MARKED_AS'});
		case FILTER_TYPE.LABEL:
			return selectedSection === FILTER_SECTIONS.TASKS && hasFeatureFlag('timesheet_remaster')
				? 'Task Label'
				: formatMessage({id: 'common.label'});
		case FILTER_TYPE.LABEL_TIME_REG:
			return formatMessage({id: 'common.label'});
		case FILTER_TYPE.TASK_PROJECT_LABEL:
			return formatMessage({id: 'common.project_label'});
		case FILTER_TYPE.RECENT_TASK_ACTIVITY:
			return formatMessage({id: 'common.recent_activity'});
		case FILTER_TYPE.RECENT_ACTIVITY:
			return formatMessage({id: 'common.recently_updated'});
		case FILTER_TYPE.OWNER:
			return selectedSection === FILTER_SECTIONS.TASKS && hasFeatureFlag('timesheet_remaster')
				? 'Task Owner'
				: formatMessage({id: 'common.owner'});
		case FILTER_TYPE.PROJECT_OWNER_TIME_REG_REPORT:
		case FILTER_TYPE.PROJECT_OWNER:
			return formatMessage({id: 'common.project_owner'});
		case FILTER_TYPE.FOLLOWER:
			return selectedSection === FILTER_SECTIONS.TASKS && hasFeatureFlag('timesheet_remaster')
				? 'Task Follower'
				: formatMessage({id: 'common.follower'});
		case FILTER_TYPE.PROJECT_FOLLOWER:
			return formatMessage({id: 'common.follower'});
		case FILTER_TYPE.PROJECT:
			return formatMessage({id: 'common.project'});
		case FILTER_TYPE.CLIENT:
			return formatMessage({id: 'common.client'});
		case FILTER_TYPE.SKILL_ROLE:
		case FILTER_TYPE.ROLE:
			return formatMessage({id: 'common.role'});
		case FILTER_TYPE.SKILL_DEPARTMENT:
		case FILTER_TYPE.DEPARTMENT:
			return formatMessage({id: 'common.department'});
		case FILTER_TYPE.INTERNAL_TIME:
			return formatMessage({id: 'common.internal_time'});
		case FILTER_TYPE.TIME_OFF:
			return formatMessage({id: 'common.time_off'});
		case FILTER_TYPE.PROJECT_STAGE:
			return formatMessage({id: 'settings.project-stage'});
		case FILTER_TYPE.PROJECT_STATUS:
			return formatMessage({id: 'settings.project-status'});
		case FILTER_TYPE.SKILL_PERSON:
		case FILTER_TYPE.PERSON:
			return selectedSection === FILTER_SECTIONS.TASKS && hasFeatureFlag('timesheet_remaster')
				? 'Assignee'
				: formatMessage({id: 'common.people'});
		case FILTER_TYPE.PROJECT_PERSON:
			return formatMessage({id: 'common.people'});
		case FILTER_TYPE.PROJECT_PHASE:
			return formatMessage({id: 'common.scope-group'});
		case FILTER_TYPE.ALL_PROJECT_PHASES:
			return formatMessage({id: 'common.phase_name'});
		case FILTER_TYPE.PROJECT_SPRINT:
			return formatMessage({id: 'common.sprint'});
		case FILTER_TYPE.SKILL_TEAM:
		case FILTER_TYPE.TEAM:
			return selectedSection === FILTER_SECTIONS.TASKS && hasFeatureFlag('timesheet_remaster')
				? 'Project Team'
				: formatMessage({id: 'common.team'});
		case FILTER_TYPE.STATUS_CATEGORY:
			return selectedSection === FILTER_SECTIONS.TASKS && hasFeatureFlag('timesheet_remaster')
				? 'Task Status'
				: formatMessage({id: 'common.workflow'});
		case FILTER_TYPE.PHASE_CATEGORY:
			return hasFeatureFlag('timesheet_remaster') ? 'Phase Status' : formatMessage({id: 'common.phase'});
		case FILTER_TYPE.SPRINT_CATEGORY:
			return formatMessage({id: 'common.sprint_status'});
		case FILTER_TYPE.PERMISSION_LEVEL:
			return formatMessage({id: 'common.permissions'});
		case FILTER_TYPE.PROJECT_STATUS_COLUMN:
			return formatMessage({id: 'common.workflow'});
		case FILTER_TYPE.CONTACT:
			return formatMessage({id: 'common.project_owners'});
		case FILTER_TYPE.CLIENT_GUEST_USERS:
			return formatMessage({id: 'filter_type.client_guest_users_title'});
		case FILTER_TYPE.SUB_TASKS:
			return formatMessage({id: 'common.sub_tasks'});
		case FILTER_TYPE.TASK_HIERARCHY:
			return formatMessage({id: 'common.sub_tasks'});
		case FILTER_TYPE.DEADLINE:
			return formatMessage({id: 'common.deadline'});
		case FILTER_TYPE.PERSON_SPRINT_WORKLOAD:
			return formatMessage({id: 'filter_type.sprint_workload_title'});
		case FILTER_TYPE.PERSON_PHASE_WORKLOAD:
			return formatMessage({id: 'filter_type.phase_workload_title'});
		case FILTER_TYPE.PERSON_ALLOCATED_IN_SPRINT_AND_PROJECT:
		case FILTER_TYPE.PERSON_ALLOCATED_IN_PHASE_AND_PROJECT:
		case FILTER_TYPE.PERSON_ALLOCATED_IN_PROJECT:
			return formatMessage({id: 'filter_type.allocated_in_project_title'});
		case FILTER_TYPE.RATE_CARD:
			return formatMessage({id: 'common.rate-card'});
		case FILTER_TYPE.PROJECT_TYPE:
			return formatMessage({id: 'new_project_modal.budget_type'});
		case FILTER_TYPE.SPRINT_END_DATE:
			return formatMessage({id: 'filter_type.sprint_end_date_title'});
		case FILTER_TYPE.PROGRESS:
			return formatMessage({id: 'common.progress'});
		case FILTER_TYPE.CAPACITY_WORKLOAD:
			return formatMessage({id: 'common.workload'});
		case FILTER_TYPE.PROJECT_AND_IDLE:
			return formatMessage({id: 'common.project'});
		case FILTER_TYPE.PERSON_ALLOCATED_TO_PROJECT_AND_IDLE:
			return formatMessage({id: 'filter.allocated_to'});
		case FILTER_TYPE.TEAM_MEMBER:
			return formatMessage({id: 'common.people'});
		case FILTER_TYPE.DEPENDENCIES:
			return formatMessage({id: 'common.dependencies'});
		case FILTER_TYPE.APPROVAL_STATUS:
			return formatMessage({id: 'common.approved'});
		case FILTER_TYPE.APPROUVED:
			return selectedSection === FILTER_SECTIONS.TIMEREGS ? 'Task Approved' : formatMessage({id: 'common.approved'});
		case FILTER_TYPE.BILLABLE:
			return formatMessage({id: 'common.billable'});
		case FILTER_TYPE.INVOICED:
			return formatMessage({id: 'project_budget.invoiced'});
		case FILTER_TYPE.PREDICTED_OVERRUN:
			return 'Overrun';
		case FILTER_TYPE.INVOICE_STATUS:
			return formatMessage({id: 'common.status'});
		case FILTER_TYPE.PAYMENT_STATUS:
			return formatMessage({id: 'invoicing.payment_status'});
		case FILTER_TYPE.TASK_LEVEL:
			return formatMessage({id: 'common.task_level'});
		case FILTER_TYPE.SKILL_RESOURCE_TYPE:
			return formatMessage({id: 'common.resource_type'});
		case FILTER_TYPE.SKILL_ASSIGNED:
			return formatMessage({id: 'common.assigned'});
		case FILTER_TYPE.SKILL_LEVEL:
			return formatMessage({id: 'filter_type.skill_level'});
		case FILTER_TYPE.SKILL:
		case FILTER_TYPE.SKILL_AND:
			return formatMessage({id: 'common.skill'});
		case FILTER_TYPE.LABEL_APPLIED:
			return formatMessage({id: 'filter_type.label.applied_to'});
		case FILTER_TYPE.LABEL_ALLOWED:
			return formatMessage({id: 'filter_type.label.allowed_on'});
		case FILTER_TYPE.PRIORITY_LEVEL:
			return formatMessage({id: 'project_settings.priority_level'});
		case FILTER_TYPE.PROGRAM:
			return ProgramUtil.programText(formatMessage);
		case FILTER_TYPE.PROJECT_WIN_CHANCE:
			return formatMessage({id: 'filter_type.project.win_chance'});
		case FILTER_TYPE.PROJECT_ALLOCATIONS:
			return formatMessage({id: 'filter_type.project.allocations'});
		case FILTER_TYPE.PERSON_ALLOCATIONS:
			return formatMessage({id: 'filter_type.project.allocations'});
		case FILTER_TYPE.DUE_DATE:
			return formatMessage({id: 'common.due_date'});
		case FILTER_TYPE.QBO_STATUS:
			return formatMessage({id: 'filter_type.quickbooks_status'});
		case FILTER_TYPE.XERO_STATUS:
			return formatMessage({id: 'filter_type.xero_status'});
		case FILTER_TYPE.ECONOMIC_STATUS:
			return formatMessage({id: 'filter_type.economic_status'});
		case FILTER_TYPE.SAGE_INTACCT_STATUS:
			return formatMessage({id: 'filter_type.sage_intacct_status'});
		case FILTER_TYPE.BILL_FROM:
			return formatMessage({id: 'filter_type.bill_from'});
		case FILTER_TYPE.BILL_TO:
			return formatMessage({id: 'filter_type.bill_to'});
		case FILTER_TYPE.EXPENSE_CATEGORY:
			return formatMessage({id: 'common.expense-category'});
		case FILTER_TYPE.EXPENSE_BILLING_OPTIONS:
			return formatMessage({id: 'expense_item_modal.billing_options'});
		default:
			return 'Invalid type';
	}
};

/**
 * @param {FILTER_TYPE[]} importantFilters List of FILTER_TYPE that should be listed first and outside the advanced section.
 * @param {FILTER_TYPE[]} advancedFilters List of filters that should be listed inside the advanced filters section.
 * @param {Function} formatMessage Optional translate function, supply to sort filters by label instead of by FILTER_TYPE value
 * @returns {FILTER_TYPE[]} Ordered list of FILTER_TYPE.
 */
export const getOrderedFilters = (importantFilters, advancedFilters, formatMessage) => {
	const orderedAdvancedFilters = advancedFilters.sort((a, b) => {
		const aLabel = formatMessage ? getTypeLabel(a, formatMessage) : a;
		const bLabel = formatMessage ? getTypeLabel(b, formatMessage) : b;
		if (aLabel < bLabel) return -1;
		if (aLabel > bLabel) return 1;
		return 0;
	});
	return importantFilters.concat(orderedAdvancedFilters);
};

export const getFiltersAlphabetically = (filters, formatMessage) => {
	return filters.sort((a, b) => {
		const aLabel = formatMessage ? getTypeLabel(a, formatMessage) : a;
		const bLabel = formatMessage ? getTypeLabel(b, formatMessage) : b;
		if (aLabel < bLabel) return -1;
		if (aLabel > bLabel) return 1;
		return 0;
	});
};

export const matchSaved = (savedFilter, appliedFilters, appliedFilterOperators) => {
	const filterValue = savedFilter.value;
	const filterOperators = savedFilter.operators;

	if (filterValue.length !== appliedFilters.length) {
		return false;
	}

	let match = true;
	filterValue.forEach(filter => {
		let exists = appliedFilters.some(appliedFilter => {
			return (
				filter.section === appliedFilter.section &&
				filter.filterType === appliedFilter.filterType &&
				_.isEqual(filter.id, appliedFilter.id)
			);
		});
		if (!exists) {
			match = false;
		}
	});

	if (!_.isEqual(filterOperators, appliedFilterOperators)) {
		match = false;
	}

	return match;
};

export const contextMenuOptions = (intl, filterId, setRenamingFilter, deleteFilter) => {
	return [
		{value: 'rename', text: intl.formatMessage({id: 'common.rename'}), callback: () => setRenamingFilter(filterId)},
		{value: 'delete', text: intl.formatMessage({id: 'common.delete'}), callback: () => deleteFilter(filterId)},
	];
};

export const keyToFilterField = (key, isProjectGroup) => {
	switch (key) {
		case 'role':
			return GLOBAL_FILTER_FIELD.ROLE;
		case 'bug':
			return GLOBAL_FILTER_FIELD.BUG;
		case 'blocked':
			return GLOBAL_FILTER_FIELD.BLOCKED;
		case 'non-billable':
			return GLOBAL_FILTER_FIELD.BILLABLE;
		case 'high-priority':
		case 'highPriority':
			return GLOBAL_FILTER_FIELD.HIGH_PRIORITY;
		case 'starred':
			return GLOBAL_FILTER_FIELD.STARRED;
		case 'taskProjectLabel':
			return GLOBAL_FILTER_FIELD.PROJECT_LABELS;
		case 'label':
		case 'labels':
			return GLOBAL_FILTER_FIELD.LABELS;
		case 'labelTimeReg':
			return GLOBAL_FILTER_FIELD.LABEL_TIME_REG;
		case 'person':
		case 'projectPerson':
			return GLOBAL_FILTER_FIELD.ASSIGNED;
		case 'owner':
			return GLOBAL_FILTER_FIELD.OWNER;
		case 'projectOwner':
			return GLOBAL_FILTER_FIELD.PROJECT_OWNER;
		case 'follower':
			return GLOBAL_FILTER_FIELD.FOLLOWER;
		case 'projectFollower':
			return GLOBAL_FILTER_FIELD.PROJECT_FOLLOWER;
		case 'statusCategory':
		case 'status':
			return GLOBAL_FILTER_FIELD.STATUS;
		case 'deadline':
			return GLOBAL_FILTER_FIELD.DEADLINE;
		case 'recentTaskActivity':
			return GLOBAL_FILTER_FIELD.RECENT_TASK_ACTIVITY;
		case 'recentActivity':
			return GLOBAL_FILTER_FIELD.RECENT_ACTIVITY;
		case 'updatedAt':
			return GLOBAL_FILTER_FIELD.UPDATED_AT;
		case 'project':
			return GLOBAL_FILTER_FIELD.PROJECT;
		case 'client':
			return GLOBAL_FILTER_FIELD.CLIENT;
		case 'projectStage':
			return GLOBAL_FILTER_FIELD.PROJECT_STAGE;
		case 'phaseCategory':
			return GLOBAL_FILTER_FIELD.PHASE_CATEGORY;
		case 'sprintCategory':
			return GLOBAL_FILTER_FIELD.SPRINT_CATEGORY;
		case 'projectStatusColumn':
			if (isProjectGroup) {
				return GLOBAL_FILTER_FIELD.PROJECT_GROUP_STATUS;
			}
			return GLOBAL_FILTER_FIELD.PROJECT_STATUS;
		case 'projectPhase':
			return GLOBAL_FILTER_FIELD.PROJECT_PHASE;
		case 'allProjectPhases':
			return GLOBAL_FILTER_FIELD.PROJECT_PHASE;
		case 'projectSprint':
			if (isProjectGroup) {
				return GLOBAL_FILTER_FIELD.PROJECT_GROUP_SPRINT;
			}
			return GLOBAL_FILTER_FIELD.PROJECT_SPRINT;
		case 'client_guest_users':
			return GLOBAL_FILTER_FIELD.CLIENT_GUEST_USERS;
		case 'dependencies':
			return GLOBAL_FILTER_FIELD.DEPENDENCIES;
		case 'sub_tasks':
			return GLOBAL_FILTER_FIELD.SUB_TASKS;
		case 'task_hierarchy':
			return GLOBAL_FILTER_FIELD.TASK_HIERARCHY;
		case 'team':
			return GLOBAL_FILTER_FIELD.TEAM;
		case 'permissionLevel':
			return GLOBAL_FILTER_FIELD.PERMISSION_LEVEL;
		case 'department':
			return GLOBAL_FILTER_FIELD.DEPARTMENT;
		case 'internalTime':
			return GLOBAL_FILTER_FIELD.INTERNAL_TIME;
		case 'timeOff':
			return GLOBAL_FILTER_FIELD.TIME_OFF;
		case 'startDate':
			return GLOBAL_FILTER_FIELD.START_DATE;
		case 'endDate':
			return GLOBAL_FILTER_FIELD.END_DATE;
		case 'projectType':
			return GLOBAL_FILTER_FIELD.PROJECT_BUDGET_TYPE;
		case 'rateCard':
			return GLOBAL_FILTER_FIELD.RATE_CARD;
		case 'projectStatus':
			return GLOBAL_FILTER_FIELD.PROJECT_STATUS;
		case 'contact':
			return GLOBAL_FILTER_FIELD.CONTACT;
		case 'projectBudgetType':
			return GLOBAL_FILTER_FIELD.PROJECT_BUDGET_TYPE;
		case FILTER_TYPE.SKILL:
			return GLOBAL_FILTER_FIELD.SKILL;
		case FILTER_TYPE.SKILL_AND:
			return GLOBAL_FILTER_FIELD.SKILL_AND;
		case 'priorityLevel':
			return GLOBAL_FILTER_FIELD.PRIORITY_LEVEL;
		case FILTER_TYPE.TASK_LEVEL:
			return GLOBAL_FILTER_FIELD.TASK_LEVEL;
		case 'projectGroup':
			return GLOBAL_FILTER_FIELD.PROJECT_GROUP;
		case 'program':
			return GLOBAL_FILTER_FIELD.PROGRAM;
		case 'approvalStatus':
			return GLOBAL_FILTER_FIELD.APPROVAL_STATUS;
		case 'bill_to':
			return GLOBAL_FILTER_FIELD.BILL_TO;
		case 'bill_from':
			return GLOBAL_FILTER_FIELD.BILL_FROM;
		default:
			return key;
	}
};

export const isInFilter = key => {
	return (
		key === FILTER_TYPE.LABELS ||
		key === FILTER_TYPE.LABEL ||
		key === FILTER_TYPE.PERSON ||
		key === FILTER_TYPE.PROJECT_PERSON ||
		key === FILTER_TYPE.TEAM ||
		key === FILTER_TYPE.FOLLOWER ||
		key === FILTER_TYPE.PROJECT_FOLLOWER ||
		key === FILTER_TYPE.TASK_PROJECT_LABEL ||
		key === FILTER_TYPE.PROJECT_TYPE ||
		key === FILTER_TYPE.TASK_LEVEL ||
		key === FILTER_TYPE.APPROVAL_STATUS ||
		key === FILTER_TYPE.ASSIGNED ||
		key === FILTER_TYPE.PROJECT_STAGE ||
		key === FILTER_TYPE.CLIENT ||
		key === FILTER_TYPE.PROJECT ||
		key === FILTER_TYPE.DEPARTMENT ||
		key === FILTER_TYPE.PROJECT_OWNER_TIME_REG_REPORT ||
		(key === FILTER_TYPE.SKILL && hasFeatureFlag('unified_filters_reports')) ||
		key === FILTER_TYPE.TIME_OFF ||
		key === FILTER_TYPE.INTERNAL_TIME ||
		key === FILTER_TYPE.APPROUVED
	);
};

export const sortPrimaryFiltersFirst = (filters, primaryOrder) => {
	if (filters && primaryOrder) {
		filters.sort((a, b) => {
			const indexB = primaryOrder.indexOf(b);
			const indexA = primaryOrder.indexOf(a);
			if (indexA !== -1 && indexB !== -1) {
				return indexA - indexB;
			} else {
				return indexB - indexA;
			}
		});
	}
};
