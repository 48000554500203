import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import ActivateWorkatoIntegration from '../../../mutations/activate_workato_integration_mutation';
import DeactivateHiBob from '../../../mutations/deactivateHiBobMutation';
import Util from '../../shared/util/util';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import hiBobLogo from '../../../images/integrations/hibob/HiBob-logo.png';
import ConnectionIframe from './workato/connectionIframe';
import {Breadcrumbs, Button, FlexRow, Heading, Link, Text} from '@forecast-it/design-system';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import InlineLoader from '../../shared/components/inline-loader/inline_loader';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';

const INTEGRATION = 'HiBob';

class appHiBob extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activating: false,
			deactivating: false,
			showLoader: false,
		};
	}

	enableHiBob() {
		this.setState({activating: true, showLoader: true});

		const onSuccess = data => {
			if (data.activateWorkatoIntegration.outcome !== 'Success') {
				this.setState({activating: false});
				createToast({duration: 10000, message: 'An error occured. Please try again'});
			} else {
				this.setState({activating: false, showLoader: false});
			}
		};

		Util.CommitMutation(ActivateWorkatoIntegration, {integration: INTEGRATION}, onSuccess);
	}

	disableHiBob() {
		const onSuccess = data => {
			if (data.deactivateHiBob.outcome !== 'Success') {
				this.setState({deactivating: false});
				createToast({duration: 10000, message: 'An error occured. Please try again'});
			} else {
				this.setState({deactivating: false, showLoader: false});
			}
		};

		const deactivateForReal = () => {
			this.setState({deactivating: true, showLoader: true});
			Util.CommitMutation(DeactivateHiBob, null, onSuccess);
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.unlink_jira_warning_title',
			warningInformation: [<FormattedMessage id="integration.deactivate_hibob_integration" />],
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.deactivate" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: deactivateForReal,
				},
			],
		});
	}

	openHelpCenter() {
		window.open('');
	}

	openInstructionsGuide() {
		window.open('');
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				<div className={'breadcrumbs-wrapper'}>
					<Breadcrumbs>
						<Breadcrumbs.Item>
							<Link href={`/settings/integrations`} data-cy="breadcrumbs-integrations-link" size="2">
								{formatMessage({id: 'settings.integrations'})}
							</Link>
						</Breadcrumbs.Item>
						<Breadcrumbs.Item>
							<Text>{formatMessage({id: 'integrations.hibob'})}</Text>
						</Breadcrumbs.Item>
					</Breadcrumbs>
				</div>
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							{!this.props.viewer.company.hiBobEnabled ? (
								<fieldset className="general">
									<div className="header-wrapper">
										<img height={100} alt="HiBob logo" src={hiBobLogo} />
										<div style={{marginTop: '20px'}} className="title-description-wrapper">
											<div className="description">
												{formatMessage({id: 'settings.app_catalog.hibob.description'})}
											</div>
										</div>
									</div>

									<div className="qb-buttons">
										<FlexRow gap={'l'}>
											<Button onClick={this.enableHiBob.bind(this)} disabled={this.state.activating}>
												{formatMessage({
													id: 'common.activate',
												})}
											</Button>
											{this.state.showLoader && (
												<div className="content-loading" style={{height: '20px', width: '20px'}}>
													<InlineLoader />
												</div>
											)}
											<Button onClick={this.openHelpCenter.bind(this)} emphasis="low">
												{formatMessage({
													id: 'settings.app_catalog.help_button',
												})}
											</Button>
										</FlexRow>
									</div>
								</fieldset>
							) : (
								<div className={'heading-wrapper'}>
									<Heading size={'1'}>
										{formatMessage({
											id: 'integrations.hibob',
										})}
									</Heading>
								</div>
							)}

							{this.props.viewer.company.hiBobEnabled ? (
								<>
									<ConnectionIframe
										{...this.props}
										integration={INTEGRATION}
										connectionId={this.props.viewer.company.workatoHiBobConnectionId}
									></ConnectionIframe>
									<FlexRow>
										<Button onClick={this.disableHiBob.bind(this)} disabled={this.state.deactivating}>
											{formatMessage({
												id: 'common.deactivate',
											})}
										</Button>
										{this.state.showLoader && (
											<div className="content-loading" style={{height: '20px', width: '20px'}}>
												<InlineLoader />
											</div>
										)}
									</FlexRow>
								</>
							) : null}
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appHiBobQuery = graphql`
	query appHiBob_Query {
		viewer {
			actualPersonId
			component(name: "app_hibob")
			...appHiBob_viewer
		}
	}
`;

export {appHiBobQuery};

export default createFragmentContainer(injectIntl(withRouter(appHiBob)), {
	viewer: graphql`
		fragment appHiBob_viewer on Viewer {
			id
			company {
				id
				hiBobEnabled
				workatoCustomerId
				workatoForecastHiBobConnectionId
				workatoHiBobConnectionId
				workatoIframeToken
			}
		}
	`,
});
